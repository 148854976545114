@charset "UTF-8";
body,
h1,
h2,
h3,
h4,
h5,
p,
dl,
dd,
ul,
ol,
form,
input,
textarea,
th,
td,
select {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: "PingFang SC";
}

em {
  font-style: normal;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #000000;
}

img {
  border: none;
  vertical-align: top;
}

table {
  border-collapse: collapse;
}

input,
textarea {
  outline: none;
}

textarea {
  resize: none;
  overflow: auto;
}

button {
  border: none;
  background: none;
  outline: none;
}

html,
body {
  width: 100%;
  overflow-x: hidden !important;
}

md-content {
  background: none;
}

md-list {
  padding: 0;
}

/* 修改md的radio默认行为，hover时边框反馈 */
md-radio-button .md-off:hover {
  border-color: rgba(3, 169, 244, 0.87);
}

/*z-index控制*/
.md-datepicker-calendar-pane {
  z-index: 200;
}

md-toast {
  z-index: 100001;
  max-width: 300px;
  width: 210px;
  min-width: 150px;
  position: absolute;
  top: 7% !important;
  left: 50%;
  margin-left: -102px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
}

md-toast .md-toast-content {
  text-align: center;
  background-color: #fff !important;
  color: #6D6D6D !important;
}

.loading {
  z-index: 200;
}

md-sidenav {
  z-index: 99;
}

div.md-dialog-container {
  z-index: 109;
}

div.md-dialog-container md-dialog {
  overflow: hidden;
  max-width: 800px;
  max-height: 80%;
  margin: 0;
}

md-backdrop.md-dialog-backdrop {
  z-index: 102;
}

.md-select-menu-container {
  z-index: 110;
}

.md-open-menu-container {
  z-index: 109;
}

.md-button {
  text-transform: initial;
}

md-card {
  margin: 8px 0px;
  margin-bottom: 16px;
}

md-card md-card-title {
  padding: 8px;
}

md-card md-card-content {
  padding: 8px;
}

md-input-container:not(.md-input-invalid).md-input-focused .md-input {
  border-color: #106cc8;
}

md-input-container:not(.md-input-invalid).md-input-focused label:not(.md-no-float) {
  color: #106cc8;
}

md-input-container:not(.md-input-invalid).md-input-focused label:not(.md-no-float)::after {
  color: #106cc8;
}

md-input-container:not(.md-input-has-value) input:not(:focus) {
  color: black;
}

md-datepicker button.md-datepicker-button {
  margin-right: 0px;
  padding-right: 0px;
}

md-datepicker .md-icon-button + .md-datepicker-input-container {
  margin-left: 0px;
}

md-checkbox.md-default-theme.md-checked .md-icon:after,
md-checkbox.md-checked .md-icon:after {
  border-color: white;
}

md-tabs .md-tab.md-active {
  color: black;
}

.md-tab {
  text-transform: none;
  padding: 12px;
}

.project_progress .md-container {
  border-radius: 10px;
  background: rgba(35, 183, 229, 0.25);
  height: 6px;
  margin-top: -0px;
}

.project_progress .md-container .md-bar2 {
  height: 100%;
  background: #23b7e5;
}

.upload_progress .md-container {
  border-radius: 10px;
  background: #fff;
  height: 14px;
  margin-top: -0px;
  border: 1px solid #78CAF1;
}

.upload_progress .md-container .md-bar2 {
  height: 100%;
  background: linear-gradient(90deg, #78caf1, #439adf);
}

.grid-list-header figure {
  font-weight: 700;
}

.grid-list-item {
  outline: none;
}

.grid-list-item:hover {
  background: #f6f8f8;
}

.inputEditClass input:focus {
  border-bottom: 1px solid #536DFE !important;
}

md-icon svg path.cls-1 {
  fill: #757575;
  min-width: 20px;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.roadmap-note-panel.md-panel {
  font-size: 12px;
}

.roadmap-note-panel.md-panel span.show-description {
  min-height: 80px;
  position: relative;
  white-space: pre-wrap;
  display: block;
  text-align: center;
  padding: 10px;
  word-break: break-all;
}

.roadmap-note-panel.md-panel .menu-container {
  background: rgba(0, 0, 0, 0.3);
  height: 30px;
}

.roadmap-note-panel.md-panel .table-edit {
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.flex-simditor .simditor {
  height: 100%;
}

.flex-simditor .simditor .simditor-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.flex-simditor .simditor .simditor-wrapper .simditor-body {
  overflow-y: auto;
}

.layui-laydate .layui-laydate-main .laydate-time-list > li {
  width: 50%;
  text-align: center;
}

.layui-laydate .layui-laydate-main .laydate-time-list > li ol li {
  width: 100%;
  padding-left: 0px;
  text-align: center;
}

.layui-laydate .layui-laydate-main .laydate-time-list > li:nth-child(2) ol li {
  display: none;
}

.layui-laydate .layui-laydate-main .laydate-time-list > li:nth-child(2) ol li:first-child, .layui-laydate .layui-laydate-main .laydate-time-list > li:nth-child(2) ol li:nth-child(31) {
  display: block;
}

.layui-laydate .layui-laydate-main .laydate-time-list > li:last-child {
  display: none;
}

.black_bold {
  color: #4a4a4a;
  font-weight: 700;
}

.grade {
  display: inline-block;
  color: white;
  height: 26px;
  width: 24px;
  border-radius: 3px;
  font-family: PingFang SC;
  line-height: 26px;
  text-align: center;
  min-width: 24px;
}

.grade.grade-0 {
  background-color: #439adf;
}

.grade.grade-1 {
  background-color: #dfc45a;
}

.grade.grade-2 {
  background-color: #5db37e;
}

.grade.grade-3 {
  background-color: #7b87c6;
}

.loading {
  display: flex;
  min-height: 300px;
  justify-content: center;
  align-items: center;
}

.selectdemoSelectHeader .demo-select-header .demo-header-searchbox {
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  padding: 0;
}

.selectdemoSelectHeader .demo-select-header {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
  padding-left: 15px;
  height: 48px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
}

.selectdemoSelectHeader .demo-select-header md-content._md {
  max-height: 240px;
}

.bac_incomplete {
  background: #fff;
}

.bac_submitted {
  background: #ECF3FC;
}

.bac_reject {
  background: #FAE5E8;
}

.bac_admit {
  background: #F2FAE8;
}

.bac_other {
  background: #FEF6E9;
}

.bac_noapply {
  background: #e2e2e2;
}


@charset "UTF-8";
ap-form-input, .ap-form-input {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;
}

ap-form-input:not(.no-label), .ap-form-input:not(.no-label) {
  border-right: 1px #DCDCDC solid;
  border-bottom: 1px #DCDCDC solid;
  max-width: 50%;
}

ap-form-input.hidden, .ap-form-input.hidden {
  display: none;
}

ap-form-input.text > .ap-input, ap-form-input.single_select > .ap-input, ap-form-input.long_text > .ap-input, .ap-form-input.text > .ap-input, .ap-form-input.single_select > .ap-input, .ap-form-input.long_text > .ap-input {
  padding: 5px;
}

ap-form-input.single_select > .ap-input, .ap-form-input.single_select > .ap-input {
  background-color: #fff;
}

ap-form-input.date > .ap-input:focus-within, .ap-form-input.date > .ap-input:focus-within {
  outline: 2px solid #0091ea;
  outline-offset: -2px;
}

ap-form-input.date ap-datepicker, .ap-form-input.date ap-datepicker {
  display: flex;
}

ap-form-input.date ap-datepicker input, .ap-form-input.date ap-datepicker input {
  flex: 1 1 100%;
  border: none;
  text-indent: 17px;
  min-height: 100%;
  box-sizing: border-box;
}

ap-form-input.long_text, ap-form-input.table, ap-form-input.score_table, .ap-form-input.long_text, .ap-form-input.table, .ap-form-input.score_table {
  max-width: 100%;
}

ap-form-input.long_text:not(.no-label) > .ap-input-label, ap-form-input.table:not(.no-label) > .ap-input-label, ap-form-input.score_table:not(.no-label) > .ap-input-label, .ap-form-input.long_text:not(.no-label) > .ap-input-label, .ap-form-input.table:not(.no-label) > .ap-input-label, .ap-form-input.score_table:not(.no-label) > .ap-input-label {
  max-width: 20%;
}

ap-form-input.long_text:not(.no-label) > .ap-input, ap-form-input.table:not(.no-label) > .ap-input, ap-form-input.score_table:not(.no-label) > .ap-input, .ap-form-input.long_text:not(.no-label) > .ap-input, .ap-form-input.table:not(.no-label) > .ap-input, .ap-form-input.score_table:not(.no-label) > .ap-input {
  max-width: 80%;
}

ap-form-input.textarea, .ap-form-input.textarea {
  max-width: 100%;
}

ap-form-input.textarea:not(.no-label) > .ap-input-label, .ap-form-input.textarea:not(.no-label) > .ap-input-label {
  max-width: 20%;
}

ap-form-input.textarea:not(.no-label) > .ap-textarea, .ap-form-input.textarea:not(.no-label) > .ap-textarea {
  padding: 5px;
  text-indent: 17px;
  height: 80px;
  width: 80%;
  overflow-y: auto;
  border: 1px solid #fff;
}

ap-form-input.table > .ap-input, .ap-form-input.table > .ap-input {
  height: auto;
}

ap-form-input.table > .ap-input .table-row, .ap-form-input.table > .ap-input .table-row {
  padding-bottom: 10px;
}

ap-form-input.table > .ap-input .table-row:only-child, .ap-form-input.table > .ap-input .table-row:only-child {
  padding-bottom: 0px;
}

ap-form-input.table > .ap-input .table-row .opt-col, .ap-form-input.table > .ap-input .table-row .opt-col {
  min-width: 75px;
}

ap-form-input.score_table table.md-table, ap-form-input.table table.md-table, .ap-form-input.score_table table.md-table, .ap-form-input.table table.md-table {
  border-collapse: separate;
  padding-right: 40px;
  overflow-y: auto;
  position: relative;
  padding-bottom: 20px;
}

ap-form-input.score_table table.md-table thead.md-head > tr.md-row, ap-form-input.table table.md-table thead.md-head > tr.md-row, .ap-form-input.score_table table.md-table thead.md-head > tr.md-row, .ap-form-input.table table.md-table thead.md-head > tr.md-row {
  height: 36px;
}

ap-form-input.score_table table.md-table thead.md-head > tr.md-row th.md-column, ap-form-input.table table.md-table thead.md-head > tr.md-row th.md-column, .ap-form-input.score_table table.md-table thead.md-head > tr.md-row th.md-column, .ap-form-input.table table.md-table thead.md-head > tr.md-row th.md-column {
  padding: 0px 17px;
  background-color: #EEEEEE;
  border-left: 1px solid #DCDCDC;
  text-indent: 0px;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
}

ap-form-input.score_table table.md-table thead.md-head > tr.md-row th.md-column:last-of-type, ap-form-input.table table.md-table thead.md-head > tr.md-row th.md-column:last-of-type, .ap-form-input.score_table table.md-table thead.md-head > tr.md-row th.md-column:last-of-type, .ap-form-input.table table.md-table thead.md-head > tr.md-row th.md-column:last-of-type {
  border-right: 1px solid #DCDCDC;
}

ap-form-input.score_table table.md-table tbody.md-body tr.md-row, ap-form-input.table table.md-table tbody.md-body tr.md-row, .ap-form-input.score_table table.md-table tbody.md-body tr.md-row, .ap-form-input.table table.md-table tbody.md-body tr.md-row {
  position: relative;
  height: 36px;
}

ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell, ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell, .ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell, .ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell {
  position: relative;
  padding: 0 0 0 17px;
  text-indent: 0px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 2px solid #fff;
}

ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell select.ap-input, ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell select.ap-input, .ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell select.ap-input, .ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell select.ap-input {
  background: #fff;
}

ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell p.ap-input, ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell p.ap-input, .ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell p.ap-input, .ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell p.ap-input {
  padding: 5px;
}

ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell ap-datepicker.ap-input, ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell ap-datepicker.ap-input, .ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell ap-datepicker.ap-input, .ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell ap-datepicker.ap-input {
  display: flex;
}

ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell ap-datepicker.ap-input input, ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell ap-datepicker.ap-input input, .ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell ap-datepicker.ap-input input, .ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell ap-datepicker.ap-input input {
  flex: 1 1 100%;
  border: none;
  text-indent: 17px;
  min-height: 100%;
  box-sizing: border-box;
}

ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell .ap-input, ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell .ap-input, .ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell .ap-input, .ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell .ap-input {
  border: none !important;
  height: 36px;
  width: 100%;
  box-sizing: border-box;
}

ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell:focus-within, ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell:focus-within, .ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell:focus-within, .ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell:focus-within {
  border: 2px solid #0091ea;
}

ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container, ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container, .ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container, .ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container {
  margin-right: 25px;
}

ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .file-list.collapse, ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .file-list.collapse, .ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .file-list.collapse, .ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .file-list.collapse {
  text-indent: 0px;
}

ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .file-list .file, ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .file-list .file, .ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .file-list .file, .ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .file-list .file {
  padding-right: 25px;
}

ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .file-list .file .remove-file, ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .file-list .file .remove-file, .ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .file-list .file .remove-file, .ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .file-list .file .remove-file {
  top: 8px;
}

ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .ap-input-btn, ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .ap-input-btn, .ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .ap-input-btn, .ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .ap-input-btn {
  height: 17px;
  width: 17px;
  min-height: 17px;
  min-width: 17px;
  line-height: 17px;
  margin: 0px;
}

ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .ap-input-btn md-icon, ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .ap-input-btn md-icon, .ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .ap-input-btn md-icon, .ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .ap-input-btn md-icon {
  height: 15px;
  width: 15px;
  min-height: 15px;
  min-width: 15px;
  font-size: 12px;
}

ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .ap-input-btn.more-btn, ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .ap-input-btn.more-btn, .ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .ap-input-btn.more-btn, .ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .ap-input-btn.more-btn {
  display: none;
  right: 0px;
  top: 19px;
}

ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .ap-input-btn.add-btn, ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .ap-input-btn.add-btn, .ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .ap-input-btn.add-btn, .ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container .ap-input-btn.add-btn {
  display: none;
  right: 0px;
  top: 0px;
}

ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container:hover .ap-input-btn.more-btn, ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container:hover .ap-input-btn.add-btn, ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container:hover .ap-input-btn.more-btn, ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container:hover .ap-input-btn.add-btn, .ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container:hover .ap-input-btn.more-btn, .ap-form-input.score_table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container:hover .ap-input-btn.add-btn, .ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container:hover .ap-input-btn.more-btn, .ap-form-input.table table.md-table tbody.md-body tr.md-row td.md-cell .attachment-container:hover .ap-input-btn.add-btn {
  display: block;
}

ap-form-input.score_table table.md-table tbody.md-body tr.md-row:hover .table-btn.delete-btn, ap-form-input.table table.md-table tbody.md-body tr.md-row:hover .table-btn.delete-btn, .ap-form-input.score_table table.md-table tbody.md-body tr.md-row:hover .table-btn.delete-btn, .ap-form-input.table table.md-table tbody.md-body tr.md-row:hover .table-btn.delete-btn {
  display: block;
}

ap-form-input.score_table table.md-table tbody.md-body tr.md-row .table-btn.delete-btn, ap-form-input.table table.md-table tbody.md-body tr.md-row .table-btn.delete-btn, .ap-form-input.score_table table.md-table tbody.md-body tr.md-row .table-btn.delete-btn, .ap-form-input.table table.md-table tbody.md-body tr.md-row .table-btn.delete-btn {
  position: absolute;
  right: 10px;
  display: none;
}

ap-form-input.score_table > .ap-input-label, ap-form-input.table > .ap-input-label, .ap-form-input.score_table > .ap-input-label, .ap-form-input.table > .ap-input-label {
  background-color: #fff;
  height: 100%;
}

ap-form-input.score_table > .ap-input-label:hover, ap-form-input.table > .ap-input-label:hover, .ap-form-input.score_table > .ap-input-label:hover, .ap-form-input.table > .ap-input-label:hover {
  background-color: #fff;
}

ap-form-input.score_table .ap-form-input .redColor, ap-form-input.table .ap-form-input .redColor, .ap-form-input.score_table .ap-form-input .redColor, .ap-form-input.table .ap-form-input .redColor {
  border: none !important;
}

ap-form-input.score_table > md-table-container, ap-form-input.table > md-table-container, .ap-form-input.score_table > md-table-container, .ap-form-input.table > md-table-container {
  height: 100%;
}

ap-form-input.score_table .table-btn, ap-form-input.table .table-btn, .ap-form-input.score_table .table-btn, .ap-form-input.table .table-btn {
  background: #439ADF;
  width: 27px;
  height: 27px;
  border-radius: 3px;
  padding: 0;
  min-width: 27px;
  min-height: 27px;
  line-height: 27px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

ap-form-input.score_table .table-btn:not([disabled]).md-focused, ap-form-input.score_table .table-btn:not([disabled]):hover, ap-form-input.table .table-btn:not([disabled]).md-focused, ap-form-input.table .table-btn:not([disabled]):hover, .ap-form-input.score_table .table-btn:not([disabled]).md-focused, .ap-form-input.score_table .table-btn:not([disabled]):hover, .ap-form-input.table .table-btn:not([disabled]).md-focused, .ap-form-input.table .table-btn:not([disabled]):hover {
  background-color: #439ADF;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

ap-form-input.score_table .table-btn > md-icon, ap-form-input.table .table-btn > md-icon, .ap-form-input.score_table .table-btn > md-icon, .ap-form-input.table .table-btn > md-icon {
  color: #fff;
  font-size: 21px;
}

ap-form-input .ap-link, .ap-form-input .ap-link {
  cursor: pointer;
}

ap-form-input .ap-multiple-select, .ap-form-input .ap-multiple-select {
  overflow-x: auto;
  cursor: pointer;
}

ap-form-input .ap-multiple-select .ap-selected-items, .ap-form-input .ap-multiple-select .ap-selected-items {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
}

ap-form-input .ap-multiple-select .ap-selected-items > span, .ap-form-input .ap-multiple-select .ap-selected-items > span {
  margin-right: 5px;
}

ap-form-input:not(.no-label) > .ap-input-label, .ap-form-input:not(.no-label) > .ap-input-label {
  flex: 1 1 100%;
  max-width: 40%;
  background-color: #EEEEEE;
  border-left: 1px solid #DCDCDC;
  border-right: 1px solid #DCDCDC;
  min-height: 36px;
  padding-left: 15px;
  text-indent: 0px;
  transition: all 100ms;
  -webkit-transition: all 50ms;
  cursor: pointer;
  box-sizing: border-box;
}

ap-form-input:not(.no-label) > .ap-input-label:hover, .ap-form-input:not(.no-label) > .ap-input-label:hover {
  background: -webkit-linear-gradient(left, #EEEEEE, #ccc);
}

ap-form-input.no-label > .ap-input-label, .ap-form-input.no-label > .ap-input-label {
  display: none;
}

ap-form-input > .ap-input, .ap-form-input > .ap-input {
  flex: 1 1 100%;
  border: none;
  min-height: 36px;
  text-indent: 17px;
  box-sizing: border-box;
}

ap-form-input > .ap-input:focus, .ap-form-input > .ap-input:focus {
  outline: 2px solid #0091ea;
}

ap-form-input:not(.no-label) > .ap-input, .ap-form-input:not(.no-label) > .ap-input {
  max-width: 60%;
}

ap-form-input .attachment-container, .ap-form-input .attachment-container {
  position: relative;
}

ap-form-input .attachment-container .file-list, .ap-form-input .attachment-container .file-list {
  text-indent: 0px;
  z-index: 1000;
  position: absolute;
  left: 0px;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  padding: 16px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

ap-form-input .attachment-container .file-list .close-btn, .ap-form-input .attachment-container .file-list .close-btn {
  position: absolute;
  right: 2px;
  top: 2px;
  font-size: 14px;
  display: block;
  cursor: pointer;
  color: #DCDCDC;
  width: 16px;
  height: 16px;
  line-height: 14px;
  text-align: center;
}

ap-form-input .attachment-container .file-list.collapse, .ap-form-input .attachment-container .file-list.collapse {
  text-indent: 17px;
  height: 36px;
  overflow: hidden;
  z-index: inherit;
  box-shadow: none;
  padding: 0px;
  position: relative;
}

ap-form-input .attachment-container .file-list.collapse .file, .ap-form-input .attachment-container .file-list.collapse .file {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

ap-form-input .attachment-container .file-list.collapse .file .file-upload-progress, .ap-form-input .attachment-container .file-list.collapse .file .file-upload-progress {
  bottom: 7px;
  left: 17px;
}

ap-form-input .attachment-container .file-list.collapse .close-btn, .ap-form-input .attachment-container .file-list.collapse .close-btn {
  display: none;
}

ap-form-input .attachment-container .file-list .file, .ap-form-input .attachment-container .file-list .file {
  position: relative;
  min-height: 36px;
  line-height: 36px;
  padding-right: 37px;
  white-space: nowrap;
}

ap-form-input .attachment-container .file-list .file > a, .ap-form-input .attachment-container .file-list .file > a {
  cursor: pointer;
}

ap-form-input .attachment-container .file-list .file > a:hover, .ap-form-input .attachment-container .file-list .file > a:hover {
  text-decoration: underline;
}

ap-form-input .attachment-container .file-list .file.in-queue, .ap-form-input .attachment-container .file-list .file.in-queue {
  cursor: pointer;
}

ap-form-input .attachment-container .file-list .file .file-upload-progress, .ap-form-input .attachment-container .file-list .file .file-upload-progress {
  bottom: 7px;
  left: 0px;
}

ap-form-input .attachment-container .file-list .file .remove-file, .ap-form-input .attachment-container .file-list .file .remove-file {
  position: absolute;
  right: 0px;
  top: 0px;
  display: none;
}

ap-form-input .attachment-container .file-list .file:hover .remove-file, .ap-form-input .attachment-container .file-list .file:hover .remove-file {
  display: block;
}

ap-form-input .attachment-container .more-btn, .ap-form-input .attachment-container .more-btn {
  position: absolute;
  right: 37px;
}

ap-form-input .attachment-container .add-btn, .ap-form-input .attachment-container .add-btn {
  position: absolute;
  right: 2px;
}

ap-form-input .ap-input-btn, .ap-form-input .ap-input-btn {
  background: #439ADF;
  width: 27px;
  height: 27px;
  border-radius: 3px;
  padding: 0px;
  min-width: 27px;
  min-height: 27px;
  line-height: 27px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  text-indent: 0px;
}

ap-form-input .ap-input-btn:not([disabled]).md-focused, ap-form-input .ap-input-btn:not([disabled]):hover, .ap-form-input .ap-input-btn:not([disabled]).md-focused, .ap-form-input .ap-input-btn:not([disabled]):hover {
  background-color: #439ADF;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

ap-form-input .ap-input-btn md-icon, .ap-form-input .ap-input-btn md-icon {
  color: #fff;
  font-size: 21px;
}

ap-form-input .ap-input-msg, .ap-form-input .ap-input-msg {
  display: none;
  position: absolute;
}

ap-form-input .redColor, .ap-form-input .redColor {
  border: 1px solid red !important;
}

/* 在body下的组件 */
.ap-multiple-select.ap-options {
  position: absolute;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  padding: 16px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  z-index: 1000;
}

.ap-tag {
  height: 18px;
  border-radius: 2px;
  padding: 3px 9px;
  font-size: 11px !important;
  color: #fff !important;
  display: inline-block;
  line-height: 12px !important;
}

.ap-tag.is_new {
  background: #F4511E;
  padding-left: 2px;
  padding-right: 2px;
}

ap-uploader {
  position: relative;
}

ap-uploader input[type=file] {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

#attachment-dialog .file-content .grid-file-over {
  border: dotted 3px green;
}

#attachment-dialog .file-content .file-upload-progress {
  border-radius: 3px;
  position: absolute;
  bottom: 20%;
  width: 100%;
}

#attachment-dialog .file-content .file-upload-progress .md-bar2 {
  background: #23b7e5;
}

#attachment-dialog .file-content .file-icon {
  position: absolute;
  font-size: 48px;
  height: 48px;
  width: 48px;
}

#attachment-dialog .file-content .file-icon.ready {
  opacity: 0.1;
}

#attachment-dialog .file-content .file-footer {
  overflow: inherit;
  height: 20%;
}

#attachment-dialog .file-content .file-footer > figcaption {
  width: 100%;
}

#attachment-dialog .file-content .file-footer > figcaption .file-name {
  font-size: 14px;
  width: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#attachment-dialog .file-content .file-footer > figcaption .file-actions {
  right: 0px;
  bottom: 0px;
}

#attachment-dialog .file-content .file-footer > figcaption .file-actions .md-fab {
  font-size: 14px;
  background-color: #c8c8c8;
}

#attachment-dialog .file-content .file-footer > figcaption .file-upload-actions .md-icon-button {
  width: 30px;
  height: 30px;
  padding: 0px;
  margin: 0px;
}

#attachment-dialog .file-content .file-upload-zone {
  border: 3px dashed #dee5e7;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

#attachment-dialog .file-content .file-upload-zone input[type="file"] {
  height: inherit;
  width: inherit;
  box-sizing: inherit;
  opacity: 0;
  cursor: pointer;
}

#addSchoolDialog #td_width table {
  overflow: auto;
}

#addSchoolDialog #td_width table th:not(:last-child),
#addSchoolDialog #td_width table td:not(:last-child) {
  min-width: 100px;
}

#CalendarDialog md-tabs-canvas {
  margin-left: 20px;
}

#CalendarDialog .ep_title {
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}

#CalendarDialog .textInput {
  width: 70%;
  padding: 5px;
  border: 1px solid;
  border-color: #c0c0c0 #d9d9d9 #d9d9d9;
  color: #888;
}

#CalendarDialog .textInput:focus {
  border-color: #4d90fe;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3);
  outline: none;
}

#CalendarDialog .ep_date {
  margin-bottom: 10px;
}

#CalendarDialog .ep_date .date_first_line {
  padding-left: 30px;
  padding-bottom: 10px;
}

#CalendarDialog .ep_date .date_second_line {
  line-height: 30px;
  height: 30px;
  padding-left: 30px;
}

#CalendarDialog .ep_ts {
  padding-left: 24px;
  padding-right: 24px;
  box-sizing: border-box;
  cursor: pointer;
}

#CalendarDialog .ep_dp {
  margin-top: 10px;
}

#CalendarDialog .ep_dp .explain_textarea {
  overflow: hidden;
  resize: vertical;
  margin-top: 0px;
  margin-bottom: 0px;
  height: 36px;
}

#CalendarDialog .ep_dp .ep_dp_access {
  border-bottom: 1px solid #ccc;
}

#CalendarDialog .ep_dp .ep_dp_color {
  padding-top: 10px;
}

#CalendarDialog .ep_dp .ep_dp_inform {
  padding-top: 10px;
  border-bottom: 1px solid #ccc;
}

#CalendarDialog .ep_dp .ep_dp_inform .inform_time {
  margin-left: 4px;
}

#CalendarDialog .ep_dp .ep_dp_status {
  padding-top: 10px;
}

#CalendarDialog .ep_dp .ep_dp_status input {
  vertical-align: middle;
  margin-bottom: 1px;
}

#CalendarDialog .ep_dp .ep_dp_degree {
  padding-top: 10px;
}

#CalendarDialog .ep_dp .ep_dp_degree input {
  vertical-align: middle;
  margin-bottom: 1px;
}

#CalendarDialog .ep_dp > div {
  padding-bottom: 10px;
}

#CalendarDialog .square {
  margin-left: 2px;
}

#CalendarDialog .square md-checkbox {
  height: 14px;
  margin: 0 2.5px;
  width: 14px;
}

#CalendarDialog .square md-checkbox:not(.md-checked) .md-icon {
  border: none;
}

#CalendarDialog .square md-checkbox.color1 .md-icon {
  background-color: #9fe1e7;
}

#CalendarDialog .square md-checkbox.color2 .md-icon {
  background-color: #5484ed;
}

#CalendarDialog .square md-checkbox.color3 .md-icon {
  background-color: #a4bdfc;
}

#CalendarDialog .square md-checkbox.color4 .md-icon {
  background-color: #46d6db;
}

#CalendarDialog .square md-checkbox.color5 .md-icon {
  background-color: #7ae7bf;
}

#CalendarDialog .square md-checkbox.color6 .md-icon {
  background-color: #51b749;
}

#CalendarDialog .square md-checkbox.color7 .md-icon {
  background-color: #fbd75b;
}

#CalendarDialog .square md-checkbox.color8 .md-icon {
  background-color: #ffb878;
}

#CalendarDialog .square md-checkbox.color9 .md-icon {
  background-color: #ff887c;
}

#CalendarDialog .square md-checkbox.color10 .md-icon {
  background-color: #dc2127;
}

#CalendarDialog .square md-checkbox.color11 .md-icon {
  background-color: #dbadff;
}

#CalendarDialog .square md-checkbox.color12 .md-icon {
  background-color: #e1e1e1;
}

#CalendarDialog .access_a {
  cursor: pointer;
  color: #15c;
  margin-left: 4px;
}

#CalendarDialog .access_a:hover {
  text-decoration: underline;
}

#CalendarDialog .bClass {
  width: 75px;
  text-align: right;
  display: inline-block;
  margin-right: 10px;
}

#CalendarDialog .invited_object {
  padding-left: 20px;
}

#CalendarDialog .invited_object md-checkbox.md-checked.pink .md-icon {
  background-color: #FF4081;
}

#calendar_create .md-icon {
  border: none;
}

#calendar_create .c_ac725e .md-icon {
  background-color: #ac725e;
}

#calendar_create .c_d06b64 .md-icon {
  background-color: #d06b64;
}

#calendar_create .c_f83a22 .md-icon {
  background-color: #f83a22;
}

#calendar_create .c_fa573c .md-icon {
  background-color: #fa573c;
}

#calendar_create .c_ff7537 .md-icon {
  background-color: #ff7537;
}

#calendar_create .c_ff7537 .md-icon {
  background-color: #ff7537;
}

#calendar_create .c_ffad46 .md-icon {
  background-color: #ffad46;
}

#calendar_create .c_42d692 .md-icon {
  background-color: #42d692;
}

#calendar_create .c_16a765 .md-icon {
  background-color: #16a765;
}

#calendar_create .c_7bd148 .md-icon {
  background-color: #7bd148;
}

#calendar_create .c_b3dc6c .md-icon {
  background-color: #b3dc6c;
}

#calendar_create .c_fbe983 .md-icon {
  background-color: #fbe983;
}

#calendar_create .c_fad165 .md-icon {
  background-color: #fad165;
}

#calendar_create .c_92e1c0 .md-icon {
  background-color: #92e1c0;
}

#calendar_create .c_9fe1e7 .md-icon {
  background-color: #9fe1e7;
}

#calendar_create .c_9ec6e7 .md-icon {
  background-color: #9ec6e7;
}

#calendar_create .c_4985e7 .md-icon {
  background-color: #4985e7;
}

#calendar_create .c_9a9bff .md-icon {
  background-color: #9a9bff;
}

#calendar_create .c_b999ff .md-icon {
  background-color: #b999ff;
}

#calendar_create .c_c2c2c2 .md-icon {
  background-color: #c2c2c2;
}

#calendar_create .c_cabdbf .md-icon {
  background-color: #cabdbf;
}

#calendar_create .c_a479e3 .md-icon {
  background-color: #a479e3;
}

#calendar_create tbody tr:nth-child(2n) {
  background: #ccc;
}

.repeatTime {
  padding: 5px;
}

.repeatTime .first_line {
  position: relative;
  padding: 10px 15px;
}

.repeatTime .first_line .first_line_icon {
  position: absolute;
  right: 15px;
  top: 8px;
}

.repeatTime .padding_b {
  padding-bottom: 10px;
}

.repeatTime .padding_b b {
  width: 75px;
  text-align: right;
  display: inline-block;
  margin-right: 10px;
}

.repeatTime .labelF label {
  padding: 0 8px 0 3px;
  vertical-align: top;
}

.repeatTime .endT input[type="radio"] {
  margin: 1px 5px 3px 0;
  vertical-align: middle;
}

#upload_video_dialog .input_common {
  width: 340px;
  height: 28px;
  border: 1px solid #e4e4e4;
}

#change_video_info_dialog .input_common {
  width: 340px;
  height: 28px;
  border: 1px solid #e4e4e4;
}

#Monthly_plan .simditor .simditor-wrapper,
.roadmapMonthlyReporting .simditor .simditor-wrapper,
#evaluation_advisory_report_content .simditor .simditor-wrapper,
.notes_records .simditor .simditor-wrapper {
  height: 200px;
  overflow-y: auto;
}

#Monthly_plan .simditor .simditor-body,
.roadmapMonthlyReporting .simditor .simditor-body,
#evaluation_advisory_report_content .simditor .simditor-body,
.notes_records .simditor .simditor-body {
  min-height: 60px;
}

#Monthly_plan .simditor .simditor-toolbar > ul li:nth-of-type(7),
#Monthly_plan .simditor .simditor-toolbar > ul li:nth-of-type(13),
#Monthly_plan .simditor .simditor-toolbar > ul li:nth-of-type(15),
#Monthly_plan .simditor .simditor-toolbar > ul li:nth-of-type(17),
#Monthly_plan .simditor .simditor-toolbar > ul li:nth-of-type(18),
#Monthly_plan .simditor .simditor-toolbar > ul li:nth-of-type(19),
#Monthly_plan .simditor .simditor-toolbar > ul li:nth-of-type(20),
#Monthly_plan .simditor .simditor-toolbar > ul li:nth-of-type(21),
#Monthly_plan .simditor .simditor-toolbar > ul li:nth-of-type(22),
.roadmapMonthlyReporting .simditor .simditor-toolbar > ul li:nth-of-type(7),
.roadmapMonthlyReporting .simditor .simditor-toolbar > ul li:nth-of-type(13),
.roadmapMonthlyReporting .simditor .simditor-toolbar > ul li:nth-of-type(15),
.roadmapMonthlyReporting .simditor .simditor-toolbar > ul li:nth-of-type(17),
.roadmapMonthlyReporting .simditor .simditor-toolbar > ul li:nth-of-type(18),
.roadmapMonthlyReporting .simditor .simditor-toolbar > ul li:nth-of-type(19),
.roadmapMonthlyReporting .simditor .simditor-toolbar > ul li:nth-of-type(20),
.roadmapMonthlyReporting .simditor .simditor-toolbar > ul li:nth-of-type(21),
.roadmapMonthlyReporting .simditor .simditor-toolbar > ul li:nth-of-type(22),
#evaluation_advisory_report_content .simditor .simditor-toolbar > ul li:nth-of-type(7),
#evaluation_advisory_report_content .simditor .simditor-toolbar > ul li:nth-of-type(13),
#evaluation_advisory_report_content .simditor .simditor-toolbar > ul li:nth-of-type(15),
#evaluation_advisory_report_content .simditor .simditor-toolbar > ul li:nth-of-type(17),
#evaluation_advisory_report_content .simditor .simditor-toolbar > ul li:nth-of-type(18),
#evaluation_advisory_report_content .simditor .simditor-toolbar > ul li:nth-of-type(19),
#evaluation_advisory_report_content .simditor .simditor-toolbar > ul li:nth-of-type(20),
#evaluation_advisory_report_content .simditor .simditor-toolbar > ul li:nth-of-type(21),
#evaluation_advisory_report_content .simditor .simditor-toolbar > ul li:nth-of-type(22),
.notes_records .simditor .simditor-toolbar > ul li:nth-of-type(7),
.notes_records .simditor .simditor-toolbar > ul li:nth-of-type(13),
.notes_records .simditor .simditor-toolbar > ul li:nth-of-type(15),
.notes_records .simditor .simditor-toolbar > ul li:nth-of-type(17),
.notes_records .simditor .simditor-toolbar > ul li:nth-of-type(18),
.notes_records .simditor .simditor-toolbar > ul li:nth-of-type(19),
.notes_records .simditor .simditor-toolbar > ul li:nth-of-type(20),
.notes_records .simditor .simditor-toolbar > ul li:nth-of-type(21),
.notes_records .simditor .simditor-toolbar > ul li:nth-of-type(22) {
  display: none;
}

#Monthly_plan .simditor .simditor-toolbar > ul > li > .toolbar-item,
.roadmapMonthlyReporting .simditor .simditor-toolbar > ul > li > .toolbar-item,
#evaluation_advisory_report_content .simditor .simditor-toolbar > ul > li > .toolbar-item,
.notes_records .simditor .simditor-toolbar > ul > li > .toolbar-item {
  width: 45px;
}

#Monthly_plan .simditor .simditor-toolbar .toolbar-menu ul > li .menu-item,
.roadmapMonthlyReporting .simditor .simditor-toolbar .toolbar-menu ul > li .menu-item,
#evaluation_advisory_report_content .simditor .simditor-toolbar .toolbar-menu ul > li .menu-item,
.notes_records .simditor .simditor-toolbar .toolbar-menu ul > li .menu-item {
  line-height: 1.5em;
}

#Monthly_plan .simditor .simditor-body ol > li,
.roadmapMonthlyReporting .simditor .simditor-body ol > li,
#evaluation_advisory_report_content .simditor .simditor-body ol > li,
.notes_records .simditor .simditor-body ol > li {
  list-style: decimal;
  list-style-position: inside;
}

#Monthly_plan .simditor .simditor-body ul > li,
.roadmapMonthlyReporting .simditor .simditor-body ul > li,
#evaluation_advisory_report_content .simditor .simditor-body ul > li,
.notes_records .simditor .simditor-body ul > li {
  list-style: disc;
  list-style-position: inside;
}

#Monthly_plan .simditor .simditor-toolbar > ul > li > span.separator,
.roadmapMonthlyReporting .simditor .simditor-toolbar > ul > li > span.separator,
#evaluation_advisory_report_content .simditor .simditor-toolbar > ul > li > span.separator,
.notes_records .simditor .simditor-toolbar > ul > li > span.separator {
  margin: 0;
}

#Monthly_plan .simditor .simditor-body h1,
#Monthly_plan .simditor .simditor-body h2,
#Monthly_plan .simditor .simditor-body h3,
#Monthly_plan .simditor .simditor-body h4,
#Monthly_plan .simditor .simditor-body h5,
#Monthly_plan .simditor .simditor-body h6,
#Monthly_plan .editor-style h1,
#Monthly_plan .editor-style h2,
#Monthly_plan .editor-style h3,
#Monthly_plan .editor-style h4,
#Monthly_plan .editor-style h5,
#Monthly_plan .editor-style h6,
.roadmapMonthlyReporting .simditor .simditor-body h1,
.roadmapMonthlyReporting .simditor .simditor-body h2,
.roadmapMonthlyReporting .simditor .simditor-body h3,
.roadmapMonthlyReporting .simditor .simditor-body h4,
.roadmapMonthlyReporting .simditor .simditor-body h5,
.roadmapMonthlyReporting .simditor .simditor-body h6,
.roadmapMonthlyReporting .editor-style h1,
.roadmapMonthlyReporting .editor-style h2,
.roadmapMonthlyReporting .editor-style h3,
.roadmapMonthlyReporting .editor-style h4,
.roadmapMonthlyReporting .editor-style h5,
.roadmapMonthlyReporting .editor-style h6,
#evaluation_advisory_report_content .simditor .simditor-body h1,
#evaluation_advisory_report_content .simditor .simditor-body h2,
#evaluation_advisory_report_content .simditor .simditor-body h3,
#evaluation_advisory_report_content .simditor .simditor-body h4,
#evaluation_advisory_report_content .simditor .simditor-body h5,
#evaluation_advisory_report_content .simditor .simditor-body h6,
#evaluation_advisory_report_content .editor-style h1,
#evaluation_advisory_report_content .editor-style h2,
#evaluation_advisory_report_content .editor-style h3,
#evaluation_advisory_report_content .editor-style h4,
#evaluation_advisory_report_content .editor-style h5,
#evaluation_advisory_report_content .editor-style h6,
.notes_records .simditor .simditor-body h1,
.notes_records .simditor .simditor-body h2,
.notes_records .simditor .simditor-body h3,
.notes_records .simditor .simditor-body h4,
.notes_records .simditor .simditor-body h5,
.notes_records .simditor .simditor-body h6,
.notes_records .editor-style h1,
.notes_records .editor-style h2,
.notes_records .editor-style h3,
.notes_records .editor-style h4,
.notes_records .editor-style h5,
.notes_records .editor-style h6 {
  margin: 0;
}

#Monthly_plan .simditor .simditor-body p,
#Monthly_plan .simditor .simditor-body div,
#Monthly_plan .editor-style p,
#Monthly_plan .editor-style div,
.roadmapMonthlyReporting .simditor .simditor-body p,
.roadmapMonthlyReporting .simditor .simditor-body div,
.roadmapMonthlyReporting .editor-style p,
.roadmapMonthlyReporting .editor-style div,
#evaluation_advisory_report_content .simditor .simditor-body p,
#evaluation_advisory_report_content .simditor .simditor-body div,
#evaluation_advisory_report_content .editor-style p,
#evaluation_advisory_report_content .editor-style div,
.notes_records .simditor .simditor-body p,
.notes_records .simditor .simditor-body div,
.notes_records .editor-style p,
.notes_records .editor-style div {
  margin: 0;
}

#Monthly_plan .simditor .simditor-body ul,
#Monthly_plan .simditor .simditor-body ol,
#Monthly_plan .editor-style ul,
#Monthly_plan .editor-style ol,
.roadmapMonthlyReporting .simditor .simditor-body ul,
.roadmapMonthlyReporting .simditor .simditor-body ol,
.roadmapMonthlyReporting .editor-style ul,
.roadmapMonthlyReporting .editor-style ol,
#evaluation_advisory_report_content .simditor .simditor-body ul,
#evaluation_advisory_report_content .simditor .simditor-body ol,
#evaluation_advisory_report_content .editor-style ul,
#evaluation_advisory_report_content .editor-style ol,
.notes_records .simditor .simditor-body ul,
.notes_records .simditor .simditor-body ol,
.notes_records .editor-style ul,
.notes_records .editor-style ol {
  margin: 0;
  padding: 0;
}

#Monthly_plan .simditor .simditor-body blockquote,
#Monthly_plan .editor-style blockquote,
.roadmapMonthlyReporting .simditor .simditor-body blockquote,
.roadmapMonthlyReporting .editor-style blockquote,
#evaluation_advisory_report_content .simditor .simditor-body blockquote,
#evaluation_advisory_report_content .editor-style blockquote,
.notes_records .simditor .simditor-body blockquote,
.notes_records .editor-style blockquote {
  margin: 0;
}

#Monthly_plan .simditor .simditor-body code,
#Monthly_plan .editor-style code,
.roadmapMonthlyReporting .simditor .simditor-body code,
.roadmapMonthlyReporting .editor-style code,
#evaluation_advisory_report_content .simditor .simditor-body code,
#evaluation_advisory_report_content .editor-style code,
.notes_records .simditor .simditor-body code,
.notes_records .editor-style code {
  margin: 0;
}

#Monthly_plan .simditor .simditor-body pre,
#Monthly_plan .editor-style pre,
.roadmapMonthlyReporting .simditor .simditor-body pre,
.roadmapMonthlyReporting .editor-style pre,
#evaluation_advisory_report_content .simditor .simditor-body pre,
#evaluation_advisory_report_content .editor-style pre,
.notes_records .simditor .simditor-body pre,
.notes_records .editor-style pre {
  margin: 0;
}

#Monthly_plan .simditor .simditor-body hr,
#Monthly_plan .editor-style hr,
.roadmapMonthlyReporting .simditor .simditor-body hr,
.roadmapMonthlyReporting .editor-style hr,
#evaluation_advisory_report_content .simditor .simditor-body hr,
#evaluation_advisory_report_content .editor-style hr,
.notes_records .simditor .simditor-body hr,
.notes_records .editor-style hr {
  margin: 0;
}

#Monthly_plan .simditor .simditor-body table,
#Monthly_plan .editor-style table,
.roadmapMonthlyReporting .simditor .simditor-body table,
.roadmapMonthlyReporting .editor-style table,
#evaluation_advisory_report_content .simditor .simditor-body table,
#evaluation_advisory_report_content .editor-style table,
.notes_records .simditor .simditor-body table,
.notes_records .editor-style table {
  margin: 0;
}

#Monthly_plan .commentDiv,
#Monthly_plan .description,
.roadmapMonthlyReporting .commentDiv,
.roadmapMonthlyReporting .description,
#evaluation_advisory_report_content .commentDiv,
#evaluation_advisory_report_content .description,
.notes_records .commentDiv,
.notes_records .description {
  padding-bottom: 5px;
  padding-top: 5px;
}

#Monthly_plan .commentDiv h1,
#Monthly_plan .description h1,
.roadmapMonthlyReporting .commentDiv h1,
.roadmapMonthlyReporting .description h1,
#evaluation_advisory_report_content .commentDiv h1,
#evaluation_advisory_report_content .description h1,
.notes_records .commentDiv h1,
.notes_records .description h1 {
  font-size: 24px;
  font-weight: normal;
}

#Monthly_plan .commentDiv h2,
#Monthly_plan .description h2,
.roadmapMonthlyReporting .commentDiv h2,
.roadmapMonthlyReporting .description h2,
#evaluation_advisory_report_content .commentDiv h2,
#evaluation_advisory_report_content .description h2,
.notes_records .commentDiv h2,
.notes_records .description h2 {
  font-size: 22px;
  font-weight: normal;
}

#Monthly_plan .commentDiv h3,
#Monthly_plan .description h3,
.roadmapMonthlyReporting .commentDiv h3,
.roadmapMonthlyReporting .description h3,
#evaluation_advisory_report_content .commentDiv h3,
#evaluation_advisory_report_content .description h3,
.notes_records .commentDiv h3,
.notes_records .description h3 {
  font-size: 20px;
  font-weight: normal;
}

#Monthly_plan .commentDiv h4,
#Monthly_plan .description h4,
.roadmapMonthlyReporting .commentDiv h4,
.roadmapMonthlyReporting .description h4,
#evaluation_advisory_report_content .commentDiv h4,
#evaluation_advisory_report_content .description h4,
.notes_records .commentDiv h4,
.notes_records .description h4 {
  font-size: 18px;
  font-weight: normal;
}

#Monthly_plan .commentDiv h5,
#Monthly_plan .description h5,
.roadmapMonthlyReporting .commentDiv h5,
.roadmapMonthlyReporting .description h5,
#evaluation_advisory_report_content .commentDiv h5,
#evaluation_advisory_report_content .description h5,
.notes_records .commentDiv h5,
.notes_records .description h5 {
  font-size: 16px;
  font-weight: normal;
}

#Monthly_plan .commentDiv h6,
#Monthly_plan .description h6,
.roadmapMonthlyReporting .commentDiv h6,
.roadmapMonthlyReporting .description h6,
#evaluation_advisory_report_content .commentDiv h6,
#evaluation_advisory_report_content .description h6,
.notes_records .commentDiv h6,
.notes_records .description h6 {
  font-size: 16px;
  font-weight: normal;
}

#Monthly_plan .commentDiv ol > li,
#Monthly_plan .description ol > li,
.roadmapMonthlyReporting .commentDiv ol > li,
.roadmapMonthlyReporting .description ol > li,
#evaluation_advisory_report_content .commentDiv ol > li,
#evaluation_advisory_report_content .description ol > li,
.notes_records .commentDiv ol > li,
.notes_records .description ol > li {
  list-style: decimal;
  list-style-position: inside;
}

#Monthly_plan .commentDiv ul > li,
#Monthly_plan .description ul > li,
.roadmapMonthlyReporting .commentDiv ul > li,
.roadmapMonthlyReporting .description ul > li,
#evaluation_advisory_report_content .commentDiv ul > li,
#evaluation_advisory_report_content .description ul > li,
.notes_records .commentDiv ul > li,
.notes_records .description ul > li {
  list-style: disc;
  list-style-position: inside;
}

#Monthly_plan .commentDiv blockquote,
#Monthly_plan .description blockquote,
.roadmapMonthlyReporting .commentDiv blockquote,
.roadmapMonthlyReporting .description blockquote,
#evaluation_advisory_report_content .commentDiv blockquote,
#evaluation_advisory_report_content .description blockquote,
.notes_records .commentDiv blockquote,
.notes_records .description blockquote {
  border-left: 6px solid #ddd;
  padding: 5px 0 5px 10px;
  margin: 0;
}

#Monthly_plan .commentDiv pre,
#Monthly_plan .description pre,
.roadmapMonthlyReporting .commentDiv pre,
.roadmapMonthlyReporting .description pre,
#evaluation_advisory_report_content .commentDiv pre,
#evaluation_advisory_report_content .description pre,
.notes_records .commentDiv pre,
.notes_records .description pre {
  padding: 10px 5px 10px 10px;
  display: block;
  line-height: 18px;
  background: #F0F0F0;
  border-radius: 3px;
  font-size: 13px;
  font-family: 'monaco', 'Consolas', "Liberation Mono", Courier, monospace;
  white-space: pre;
  word-wrap: normal;
  overflow-x: auto;
}

#Monthly_plan .commentDiv table td,
#Monthly_plan .commentDiv table th,
#Monthly_plan .description table td,
#Monthly_plan .description table th,
.roadmapMonthlyReporting .commentDiv table td,
.roadmapMonthlyReporting .commentDiv table th,
.roadmapMonthlyReporting .description table td,
.roadmapMonthlyReporting .description table th,
#evaluation_advisory_report_content .commentDiv table td,
#evaluation_advisory_report_content .commentDiv table th,
#evaluation_advisory_report_content .description table td,
#evaluation_advisory_report_content .description table th,
.notes_records .commentDiv table td,
.notes_records .commentDiv table th,
.notes_records .description table td,
.notes_records .description table th {
  min-width: 40px;
  height: 30px;
  border: 1px solid #ccc;
  vertical-align: top;
  padding: 2px 4px;
  text-align: left;
  box-sizing: border-box;
}

.roadmapMonthlyReporting {
  min-height: 700px;
}

.roadmapMonthlyReporting .simditor .simditor-toolbar {
  width: 100% !important;
}

.roadmapMonthlyReporting .simditor .simditor-wrapper .simditor-placeholder {
  display: none !important;
}

.roadmapMonthlyReporting .simditor .simditor-wrapper {
  min-height: 500px;
}

#Monthly_plan .simditor .simditor-wrapper .simditor-placeholder {
  display: none !important;
}

#evaluation_advisory_report_content .simditor .simditor-toolbar {
  width: 100% !important;
}

#evaluation_advisory_report_content .simditor .simditor-wrapper .simditor-placeholder {
  display: none !important;
}

#evaluation_advisory_report_content .simditor .simditor-wrapper {
  min-height: 300px;
}

#evaluation_advisory_report_content .simditor .simditor-body {
  max-width: 700px;
}

.userDetail {
  min-width: 860px;
}

.userDetail .tags {
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  border-radius: 2px;
  padding: 0 3px;
}

.userDetail .status_btn .md-button {
  margin: 0;
  min-width: 84px;
  min-height: 28px;
  line-height: 28px;
  border-radius: 4px;
  font-size: 12px;
}

.userDetail .status_btn .dea {
  border: 1px solid #9b9b9b;
  color: #9b9b9b;
}

.userDetail .status_btn .dea:hover {
  background: #9b9b9b;
  color: #fff;
}

.userDetail .status_btn .act {
  border: 1px solid #7ED321;
  color: #7ED321;
}

.userDetail .status_btn .act:hover {
  background: #7ED321;
  color: #fff;
}

.userDetail .file-upload {
  position: relative;
}

.userDetail .file-upload .upload {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 300px;
  height: 40px;
  cursor: pointer;
}

.userDetail .file-upload .choose {
  color: #555;
  width: 300px;
  height: 40px;
  line-height: 40px;
  border: 2px solid #555;
  border-radius: 4px;
  padding: 0 10px;
}

.userDetail .file-upload .choose img {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.userDetail .file-upload .choose span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.userDetail .uploadBtn {
  margin: 10px 0 0 0;
  min-width: 84px;
  height: 28px;
  min-height: 28px;
  line-height: 28px;
  border-radius: 4px;
  font-size: 12px;
  border: 1px solid #555;
  color: #555;
}

.userDetail .uploadBtn:hover {
  background: #555;
  color: #fff;
}

.userDetail .coin {
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-left: 8px;
  height: 36px;
  text-indent: 5px;
  min-width: 50px;
}

.userDetail textarea.coin {
  width: 400px;
  line-height: 36px;
}

.userDetail .subbtn {
  background-color: #78caf1;
  color: white;
  background-image: linear-gradient(45deg, #439adf 0px, #78caf1 100%);
  border: 0px;
  border-radius: 3px;
  height: 36px;
  line-height: 36px;
  width: 80px;
  text-align: center;
  margin-left: 8px;
  cursor: pointer;
}

.md-panel-outer-wrapper {
  z-index: 110 !important;
}

.md-panel-outer-wrapper .md-panel {
  z-index: 111 !important;
}

.essay_info .close_btn, #popupDialog .close_btn {
  height: 32px;
  min-height: 32px;
  line-height: 32px;
  background: white !important;
  border-radius: 3px;
  border: 1px solid #4a4a4a;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  color: #4a4a4a;
}

.essay_info .close_btn:hover, #popupDialog .close_btn:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

md-event-calendar:not(._md) md-event-calendar-header {
  color: #666;
  background: #fff;
  border-color: #ddd;
}

md-event-calendar:not(._md) md-event-calendar-header md-event-calendar-next .md-arrow svg, md-event-calendar:not(._md) md-event-calendar-header md-event-calendar-prev .md-arrow svg {
  fill: #666;
}

md-event-calendar:not(._md) .md-button:not([disabled]) {
  color: #333;
}

md-event-calendar:not(._md) .md-button:not([disabled]):hover {
  background: rgba(158, 158, 158, 0.2);
}

md-event-calendar:not(._md) .md-button[disabled] {
  color: #ccc;
}

md-event-calendar:not(._md) md-event-calendar-month .md-event-calendar-month-row-header {
  color: #999;
  background: #fff;
  border-color: #ddd;
}

md-event-calendar:not(._md) md-event-calendar-month .md-event-calendar-month-row {
  background: #fff;
  border-color: #ddd;
}

md-event-calendar:not(._md) md-event-calendar-month .md-event-calendar-month-row .md-event-calendar-month-cell, md-event-calendar:not(._md) md-event-calendar-month .md-event-calendar-month-row .md-event-calendar-month-cell-divider {
  border-color: #ddd;
}

md-event-calendar:not(._md) md-event-calendar-month .md-event-calendar-month-row .md-event-calendar-month-cell .md-event-calendar-month-cell-content .md-event-calendar-create-link {
  color: #4189b8;
}

md-event-calendar:not(._md) md-event-calendar-month .md-event-calendar-month-row .md-event-calendar-month-cell .md-event-calendar-month-cell-content .md-event-calendar-cell-data-label {
  color: #999;
}

md-event-calendar:not(._md) md-event-calendar-month .md-event-calendar-month-row .md-event-calendar-month-cell .md-event-calendar-month-cell-content .md-event-calendar-cell-event-show-more-link {
  color: #4189b8;
}

md-event-calendar:not(._md) md-event-calendar-month .md-event-calendar-month-row .md-event-calendar-month-cell.different-month {
  background: #f5f5f5;
}

md-event-calendar:not(._md) md-event-calendar-month .md-event-calendar-month-row .md-event-calendar-month-cell.today {
  box-shadow: inset 0 0 0 1px #aaa;
}

md-event-calendar:not(._md) md-event-calendar-month .md-event-calendar-month-row .md-event-calendar-month-cell.today .md-event-calendar-month-cell-content .md-event-calendar-cell-data-label {
  color: #666;
}

md-event-calendar:not(._md) md-event-calendar-month .md-event-calendar-month-row .md-event-calendar-month-cell.today .md-event-calendar-month-cell-divider {
  border-color: #aaa;
}

md-event-calendar:not(._md) md-event-calendar-month .md-event-calendar-month-row .md-event-calendar-month-cell:last-child {
  border-color: #ddd;
}

md-event-calendar:not(._md) .md-event-calendar-cell-event {
  background: #ddd;
  color: #666;
}

md-event-calendar:not(._md) .md-event-calendar-cell-event.md-selected {
  color: #eee;
  background: #888;
}

md-event-calendar:not(._md) .md-event-calendar-cell-event.md-continue-left:after, md-event-calendar:not(._md) .md-event-calendar-cell-event.md-end-left:after {
  border-right-color: #ddd;
}

md-event-calendar:not(._md) .md-event-calendar-cell-event.md-continue-right:after, md-event-calendar:not(._md) .md-event-calendar-cell-event.md-start-right:after {
  border-left-color: #ddd;
}

md-event-calendar:not(._md) .md-event-calendar-cell-event.md-selected.md-continue-left:after, md-event-calendar:not(._md) .md-event-calendar-cell-event.md-selected.md-end-left:after {
  border-right-color: #888;
}

md-event-calendar:not(._md) .md-event-calendar-cell-event.md-selected.md-continue-right:after, md-event-calendar:not(._md) .md-event-calendar-cell-event.md-selected.md-start-right:after {
  border-left-color: #888;
}

md-event-calendar:not(._md) .md-event-calendar-show-more-container .md-event-calendar-show-more-date-label {
  color: #999;
}

md-event-calendar:not(._md) .md-event-calendar-show-more-container .md-event-calendar-show-more-close svg {
  fill: #999;
}

md-event-calendar {
  display: block;
}

md-event-calendar md-event-calendar-header {
  flex-direction: row;
  display: flex;
  line-height: 64px;
  align-items: center;
  border-style: solid;
  border-width: 1px 1px 0;
}

md-event-calendar md-event-calendar-header.md-center {
  justify-content: center;
}

md-event-calendar md-event-calendar-header md-event-calendar-title {
  display: block;
  min-width: 170px;
  text-align: center;
  font-size: 20px;
}

md-event-calendar md-event-calendar-header md-event-calendar-next, md-event-calendar md-event-calendar-header md-event-calendar-prev {
  display: block;
}

md-event-calendar md-event-calendar-header md-event-calendar-next .md-arrow, md-event-calendar md-event-calendar-header md-event-calendar-prev .md-arrow {
  cursor: pointer;
  height: 24px;
  width: 24px;
}

md-event-calendar md-event-calendar-header md-event-calendar-next .md-arrow.md-left-arrow, md-event-calendar md-event-calendar-header md-event-calendar-prev .md-arrow.md-left-arrow {
  transform: rotate(180deg);
}

md-event-calendar .md-event-calendar-month-cell-content .md-event-calendar-create-link {
  opacity: 0;
}

md-event-calendar .md-event-calendar-month-cell-content:hover .md-event-calendar-create-link {
  opacity: 1;
}

md-event-calendar md-event-calendar-month.md-event-hover .md-event-calendar-month-cell-content .md-event-calendar-create-link {
  opacity: 0;
}

md-event-calendar md-event-calendar-month.fitted {
  display: flex;
  flex-direction: column;
}

md-event-calendar md-event-calendar-month .md-event-calendar-month-row-header {
  display: flex;
  flex-direction: row;
  min-height: 36px;
  height: 36px;
  align-items: flex-end;
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 12px;
  border-style: solid;
  border-width: 0 1px 1px;
}

md-event-calendar md-event-calendar-month .md-event-calendar-month-row-header .md-event-calendar-month-cell-header {
  flex: 1;
  padding-left: 6px;
}

md-event-calendar md-event-calendar-month .md-event-calendar-month-row {
  flex: 1;
  display: flex;
  flex-direction: row;
  border-style: solid;
  border-width: 0 0 1px;
}

md-event-calendar md-event-calendar-month .md-event-calendar-month-row .md-event-calendar-month-cell {
  position: relative;
  flex: 1;
}

md-event-calendar md-event-calendar-month .md-event-calendar-month-row .md-event-calendar-month-cell .md-event-calendar-month-cell-spacer {
  margin-top: 100%;
}

md-event-calendar md-event-calendar-month .md-event-calendar-month-row .md-event-calendar-month-cell .md-event-calendar-month-cell-divider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-style: solid;
  border-width: 0 1px 0 0;
}

md-event-calendar md-event-calendar-month .md-event-calendar-month-row .md-event-calendar-month-cell .md-event-calendar-month-cell-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

md-event-calendar md-event-calendar-month .md-event-calendar-month-row .md-event-calendar-month-cell .md-event-calendar-month-cell-content .md-event-calendar-create-link {
  -ms-grid-row-align: center;
  align-self: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  padding-right: 12px;
  cursor: pointer;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

md-event-calendar md-event-calendar-month .md-event-calendar-month-row .md-event-calendar-month-cell .md-event-calendar-month-cell-content .md-event-calendar-cell-data-label {
  font-size: 13px;
  padding: 8px;
  flex: 1;
}

md-event-calendar md-event-calendar-month .md-event-calendar-month-row .md-event-calendar-month-cell .md-event-calendar-month-cell-content .md-event-calendar-cell-event-spacer {
  margin: 4px 0;
  height: 23px;
}

md-event-calendar md-event-calendar-month .md-event-calendar-month-row .md-event-calendar-month-cell .md-event-calendar-month-cell-content .md-event-calendar-cell-event-show-more-link {
  font-size: 13px;
  padding: 4px;
  padding-left: 8px;
  cursor: pointer;
}

md-event-calendar md-event-calendar-month .md-event-calendar-month-row .md-event-calendar-month-cell:last-child {
  border-style: solid;
  border-width: 0 1px 0 0;
}

md-event-calendar .md-event-calendar-cell-event {
  font-size: 12px;
  min-height: 15px;
  padding: 4px;
  cursor: pointer;
}

md-event-calendar .md-event-calendar-cell-event.md-single {
  margin: 4px;
  border-radius: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

md-event-calendar .md-event-calendar-cell-event.md-start {
  margin: 4px 0 4px 4px;
  border-radius: 2px 0 0 2px;
  white-space: nowrap;
  z-index: 1;
  position: relative;
}

md-event-calendar .md-event-calendar-cell-event.md-start-right {
  margin: 4px 13px 4px 4px;
  border-radius: 2px 0 0 2px;
}

md-event-calendar .md-event-calendar-cell-event.md-end {
  margin: 4px 4px 4px 0;
  border-radius: 0 2px 2px 0;
}

md-event-calendar .md-event-calendar-cell-event.md-end-left {
  margin: 4px 4px 4px 13px;
  border-radius: 0 2px 2px 0;
}

md-event-calendar .md-event-calendar-cell-event.md-continue, md-event-calendar .md-event-calendar-cell-event.md-continue-both {
  margin: 4px 0;
  border-radius: 0;
}

md-event-calendar .md-event-calendar-cell-event.md-continue-right {
  margin: 4px 13px 4px 0;
  border-radius: 0;
  white-space: nowrap;
}

md-event-calendar .md-event-calendar-cell-event.md-continue-left {
  margin: 4px 0 4px 13px;
  border-radius: 0;
  white-space: nowrap;
}

md-event-calendar .md-event-calendar-cell-event.md-continue-right:after, md-event-calendar .md-event-calendar-cell-event.md-start-right:after {
  content: '';
  position: absolute;
  height: 0;
  width: 0;
  right: 0;
  margin-top: -4px;
  border-top: 12px solid transparent;
  border-bottom: 11.5px solid transparent;
  border-left: 13px solid #eee;
}

md-event-calendar .md-event-calendar-cell-event.md-continue-left:after, md-event-calendar .md-event-calendar-cell-event.md-end-left:after {
  content: '';
  position: absolute;
  height: 0;
  width: 0;
  left: 0;
  margin-top: -4px;
  border-top: 12px solid transparent;
  border-bottom: 11.5px solid transparent;
  border-right: 13px solid #eee;
}

md-event-calendar .md-event-calendar-cell-event .md-event-calendar-cell-event-time {
  font-weight: 500;
  padding-right: 6px;
  pointer-events: none;
}

md-event-calendar .md-event-calendar-cell-event span {
  pointer-events: none;
}

md-event-calendar .md-event-calendar-show-more-content .md-event-calendar-cell-event.md-end, md-event-calendar .md-event-calendar-show-more-content .md-event-calendar-cell-event.md-single, md-event-calendar .md-event-calendar-show-more-content .md-event-calendar-cell-event.md-start-right {
  padding-left: 16px;
  margin-left: 0;
}

md-event-calendar .md-event-calendar-show-more-content .md-event-calendar-cell-event.md-end, md-event-calendar .md-event-calendar-show-more-content .md-event-calendar-cell-event.md-end-left, md-event-calendar .md-event-calendar-show-more-content .md-event-calendar-cell-event.md-single {
  margin-right: 0;
}

md-event-calendar .md-event-calendar-show-more-container {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 1px;
  width: 180px;
  background: #fff;
  padding: 12px;
  padding-top: 7px;
  border-radius: 2px;
  z-index: 2;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
}

md-event-calendar .md-event-calendar-show-more-container .md-event-calendar-show-more-content {
  position: relative;
}

md-event-calendar .md-event-calendar-show-more-container.show {
  opacity: 1;
}

md-event-calendar .md-event-calendar-show-more-container.show:not(.no-transition) {
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

md-event-calendar .md-event-calendar-show-more-container .md-event-calendar-show-more-close {
  display: flex;
  position: absolute;
  top: 6px;
  right: 7px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

md-event-calendar .md-event-calendar-show-more-container .md-event-calendar-show-more-close svg {
  pointer-events: none;
}

md-event-calendar .md-event-calendar-show-more-container .md-event-calendar-show-more-date-label {
  font-size: 13px;
  padding: 6px;
  margin-left: -11px;
  margin-top: -5px;
}

md-event-calendar.md-create-disabled md-event-calendar-month .md-event-calendar-month-row .md-event-calendar-month-cell .md-event-calendar-month-cell-content .md-event-calendar-create-link {
  display: none;
}

/* Generated by Font Squirrel (https://www.fontsquirrel.com) on June 25, 2016 */
@font-face {
  font-family: 'Lato';
  src: url("/fonts/lato-light-webfont.woff2") format("woff2"), url("/fonts/lato-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url("/fonts/lato-regular-webfont.woff2") format("woff2"), url("/fonts/lato-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/MaterialIcons-Regular.eot");
  /* For IE6-8 */
  src: local("/fonts/Material Icons"), local("/fonts/MaterialIcons-Regular"), url("/fonts/MaterialIcons-Regular.woff2") format("woff2"), url("/fonts/MaterialIcons-Regular.woff") format("woff"), url("/MaterialIcons-Regular.ttf") format("truetype");
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

#popupDialog pre {
  word-wrap: break-word;
  white-space: pre-wrap;
}

.simple-file-upload {
  border: 3px dashed #dee5e7;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.simple-file-upload input[type="file"] {
  height: inherit;
  width: inherit;
  box-sizing: inherit;
  opacity: 0;
  cursor: pointer;
}

td[tableedit].disabled {
  pointer-events: none;
  cursor: crosshair;
}

#addSchoolDialog md-tabs {
  height: 100%;
}

md-menu-item > md-input-container > label {
  padding-left: 16px !important;
  padding-right: 16px !important;
}


md-dialog.school-score-submit-dialog {
  max-width: 1000px;
  width: 1000px;
}

md-dialog.school-score-submit-dialog .score-list .score-subheader {
  font-size: 10px;
}

md-dialog.school-score-submit-dialog .score-list .score-item {
  text-align: center;
}

md-dialog.school-score-submit-dialog .score-list .score-item td {
  font-size: 10px;
}

md-dialog.school-score-submit-dialog .score-submitted .score-subheader {
  font-size: 10px;
}

md-dialog.school-score-submit-dialog .score-submitted .score-item {
  text-align: center;
}

md-dialog.school-score-submit-dialog .score-submitted .score-item td {
  font-size: 10px;
}

md-dialog.school-score-submit-dialog .send-button {
  top: 50%;
}

md-toast[md-theme=warn] .md-toast-content {
  background-color: #F44336;
}

[ui-view].ng-enter,
[ui-view].ng-leave {
  transition: opacity ease-in-out 200ms;
}

[ui-view].ng-enter,
[ui-view].ng-leave.ng-leave-active {
  opacity: 0;
}

[ui-view].ng-enter.ng-enter-active {
  opacity: 1;
}

.Page.ng-leave.ng-leave-active {
  display: none;
}

.u-center {
  text-align: center;
}

#acCommon {
  padding: 10px 20px 0 20px;
}

#acCommon .lineheight {
  line-height: 34px;
}

#acCommon .dateInput {
  margin-left: 10px;
}

#acCommon table {
  width: 100%;
  overflow-y: auto;
  max-height: 160px;
  display: block;
}

#acCommon table tr {
  width: 100%;
  height: 40px;
}

#acCommon table th {
  width: 250px;
  background: #fff;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 10;
}

#acCommon table tr td {
  border: 0;
}

#acCommon textarea {
  max-height: 108px;
  overflow-y: auto;
}

#acCommon .attachmentClass {
  height: 36px;
  border: none;
  max-width: 100%;
}

#acCommon .attachmentClass .ap-input-label {
  border: none;
  background: none;
  max-width: 25%;
  padding-left: 1px;
}

#acCommon .attachmentClass .attachment-container {
  max-width: 75%;
}

.ac_courier_content {
  padding: 10px;
  max-width: 1000px !important;
}

.ac_courier_content .courier {
  min-height: 180px;
  max-height: 400px;
  overflow-y: auto;
}

.ac_courier_content .courier .ap-input-label {
  display: none;
}

.ac_courier_content .courier ap-form-input:not(.no-label) {
  border: none;
}

.ac_courier_content .courier ap-form-input:not(.no-label) table {
  padding-right: 0;
}

.ac_courier_content .courier .ap-input {
  max-width: none !important;
  height: 400px;
}

.ac_courier_content ap-form-input.score_table table.md-table tbody.md-body tr.md-row .table-btn.delete-btn {
  right: -8px;
}

#dailyReport .title {
  background: #7986CB;
  color: #fff;
  min-height: 40px;
  padding: 0 16px;
  font-size: 20px;
  line-height: 40px;
}

#dailyReport .title md-icon {
  color: #fff;
  cursor: pointer;
}

#dailyReport .switchBtn {
  min-height: 50px;
  padding: 0 20px;
  font-size: 18px;
  color: #439ADF;
  font-weight: bold;
}

#dailyReport .out {
  height: 600px;
  overflow-y: auto;
}

#dailyReport .item {
  padding: 30px 20px;
  border-bottom: 1px solid #dcdcdc;
}

#dailyReport .item h1 {
  font-size: 18px;
  color: #439ADF;
}

#dailyReport .item ap-tag {
  margin-left: 10px;
}

#dailyReport .item .imgSrc {
  margin-top: 10px;
  overflow-y: auto;
  height: 200px;
}

#dailyReport .item .imgSrc img {
  width: 100%;
}

#dailyReport .item .note {
  font-size: 18px;
  color: #439ADF;
}

#dailyReport .item md-icon {
  margin: 0 0 0 5px;
  width: 24px;
  cursor: pointer;
}

.ac_essay {
  padding: 20px;
  color: #4a4a4a;
  height: 400px;
  overflow-y: auto;
}

.ac_essay * {
  font-size: 16px;
}

.ac_essay .marLeft {
  margin-left: 5px;
}

.ac_essay table.md-table {
  overflow-y: auto;
}

.ac_essay table.md-table th.md-column {
  background: #fff;
  z-index: 10;
  font-weight: 500;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  font-size: 14px;
}

.ac_essay table tr {
  height: 40px;
}

.ac_essay table.md-table td.md-cell {
  border: none;
}

.ac_essay .md-icon-button {
  padding: 0px;
  margin: 0px;
}

.ac_essay .icon {
  color: #4a4a4a;
  border-radius: 3px;
  border: 1px solid #4a4a4a;
  font-size: 20px;
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
}

#acFinance {
  max-height: 300px;
  min-height: 300px;
  overflow: auto;
}

#acFinance table.md-table th.md-column {
  font-size: 14px;
  font-weight: 500;
}

#acFinance .dateInput {
  margin-left: 10px;
}

#acFinance table tr {
  height: 40px;
}

#acFinance table tr td {
  border: 0;
}

#acFinance table tr td input {
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  border-left-width: 0px;
  cursor: pointer;
}

#acFinance .loading {
  display: flex;
  height: 300px;
  justify-content: center;
  align-items: center;
}

.ac_interview_content {
  margin: 10px;
  min-height: 256px;
  max-height: 256px;
}

.ac_interview_content div .star {
  float: left;
  width: 24px;
  height: 24px;
}

.ac_interview_content div .open_in_new {
  float: right;
}

.ac_interview_content div .line {
  display: flex;
  width: 100%;
  height: 210px;
  flex-direction: column;
  justify-content: space-around;
}

.ac_interview_content div .line md-input-container {
  padding: 0 100px;
}

.ac_interview_content .loading {
  display: flex;
  min-height: 256px;
  justify-content: center;
  align-items: center;
}

.completed1 {
  background: #7ED321;
  color: #fff;
}

.ongoing1 {
  background-color: #F5A623;
  color: #fff;
}

.hurry1 {
  background-color: #D0021B;
  color: #fff;
}

.nostart1 {
  background-color: #fff;
  color: #979797;
  border: 1px solid #979797 !important;
}

.ac_note {
  width: 150px;
}

.ac_note .status_span {
  height: 20px;
  line-height: 20px;
  border-radius: 2px;
  color: #fff;
  padding: 0 3px;
  white-space: nowrap;
}

.ac_note .time {
  color: #636364;
  margin-left: 5px;
}

.ac_note .completed {
  background: #7ED321;
}

.ac_note .ongoing {
  background: #F5A623;
}

.ac_note .hurry {
  background: #D0021B;
}

.ac_note .noneed {
  background: #948f8f;
  color: #fff;
}

.ac_note .nostart {
  border: 1px solid #979797;
  color: #979797;
}

.ac_note .noapply {
  border: 1px solid #945f5f;
  color: #945f5f;
}

.ac_note .note {
  position: relative;
  height: 40px;
}

#ac_post_admission {
  overflow: hidden;
}

#ac_post_admission .leftSide {
  background: #fff;
  padding: 20px;
  padding-left: 0;
}

#ac_post_admission .leftSide .acSelect {
  border: 1px solid #439adf;
  border-radius: 3px;
  margin-bottom: 10px;
  padding-left: 5px;
  margin-right: 15px;
  height: 30px;
  width: 25%;
}

#ac_post_admission .leftSide .acSelect md-select {
  margin: 0;
}

#ac_post_admission .leftSide .acSelect .md-select-value {
  height: 24px;
  min-height: 0;
  box-sizing: border-box;
}

#ac_post_admission .leftSide .acSelectActive {
  background: #439adf;
  color: #fff;
}

#ac_post_admission .leftSide .acSelectActive .md-select-value {
  color: #fff;
}

#ac_post_admission .leftSide .can_switch {
  width: 100%;
  padding: 0 15px;
}

#ac_post_admission .leftSide .can_switch .md-button {
  margin: 0;
  margin-bottom: 10px;
  width: 50%;
  min-width: 110px;
  height: 26px;
  min-height: 26px;
  line-height: 26px;
  background: #fff;
  font-size: 14px;
  border: 1px solid #439adf;
  color: #439adf;
}

#ac_post_admission .leftSide .can_switch .md-button:nth-child(1) {
  border-radius: 3px 0px 0px 3px;
}

#ac_post_admission .leftSide .can_switch .md-button:nth-child(2) {
  border-radius: 0px 3px 3px 0px;
  border-left: none;
}

#ac_post_admission .leftSide .can_switch .active {
  background-image: linear-gradient(225deg, #78caf1 0%, #439adf 100%);
  color: #fff;
}

#ac_post_admission .leftSide .container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 0px 0 0 10px;
}

#ac_post_admission .leftSide .container input {
  background-color: #f5f5f5;
  border-radius: 3px;
  border: none;
  height: 32px;
  padding-left: 8px;
  line-height: 32px;
  width: 50%;
}

#ac_post_admission .leftSide .container .md-button {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  padding: 0px;
  margin: 0px;
}

#ac_post_admission .leftSide .school_list {
  overflow: auto;
  padding: 0;
  margin-top: 10px;
  width: 100%;
  border: 1px solid #dcdcdc;
  border-left: none;
}

#ac_post_admission .leftSide .school_list .admit_round {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 3px;
  height: 16px;
  line-height: 16px;
  min-width: 25px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}

#ac_post_admission .leftSide .school_list .ed_round {
  background-color: #7bd500;
  color: #fff;
  border-radius: 2px;
}

#ac_post_admission .leftSide .school_list .not_ed_round {
  color: #7bd500;
}

#ac_post_admission .leftSide .school_list md-list-item {
  position: relative;
  border-bottom: 1px solid #dcdcdc;
}

#ac_post_admission .leftSide .school_list md-list-item.active {
  border: 2px solid #006dd5;
  background: #eee;
}

#ac_post_admission .leftSide .school_list md-list-item.active .stu_name {
  font-weight: 450;
  color: #4a3e3e;
}

#ac_post_admission .leftSide .school_list md-list-item div.c439adf {
  background-color: #439adf;
}

#ac_post_admission .leftSide .school_list md-list-item div.cdfc45a {
  background-color: #dfc45a;
}

#ac_post_admission .leftSide .school_list md-list-item div.c5db37e {
  background-color: #5db37e;
}

#ac_post_admission .leftSide .school_list md-list-item div.c7b87c6 {
  background-color: #7b87c6;
}

#ac_post_admission .table {
  margin: 10px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

#ac_post_admission .table table {
  overflow: inherit;
}

#ac_post_admission .table table th.md-column,
#ac_post_admission .table table td.md-cell {
  min-width: 80px;
  padding: 0 10px !important;
  max-width: 150px;
}

#ac_post_admission .table table th:nth-child(1) {
  width: 135px;
  max-width: 135px;
  min-width: 135px;
  left: 0px;
  z-index: 13;
}

#ac_post_admission .table table th:nth-child(2) {
  left: 155px;
  min-width: 80px;
  max-width: 80px;
  z-index: 13;
}

#ac_post_admission .table table tr td:nth-child(1), #ac_post_admission .table table tr td:nth-child(2) {
  position: sticky;
  position: -webkit-sticky;
  background: #f8f8f8;
}

#ac_post_admission .table table tr td:nth-child(1) {
  width: 135px;
  max-width: 135px;
  min-width: 135px;
  left: 0px;
  z-index: 12;
}

#ac_post_admission .table table tr td:nth-child(2) {
  left: 155px;
  min-width: 80px;
  max-width: 80px;
  z-index: 12;
}

#ac_post_admission .table table td.md-cell {
  height: 60px;
  text-align: center;
  border-bottom: 1px solid #dcdcdc;
}

#ac_post_admission .table table th .head {
  width: 100%;
  display: inline-block;
  text-align: center;
}

#ac_post_admission .table table th .head md-icon {
  color: #fff;
}

#ac_post_admission .table table th .more {
  height: 36px;
  line-height: 33px !important;
  font-size: 18px !important;
  color: white;
  cursor: pointer;
}

#ac_post_admission .table table .head {
  font-size: 16px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  color: #fff;
  text-align: center;
}

#ac_post_admission .table table .head md-icon {
  color: #439adf;
  cursor: pointer;
}

#ac_post_admission .table table .headStu {
  font-size: 16px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  color: #fff;
}

#ac_post_admission .table table .headStu md-icon {
  cursor: pointer;
}

#ac_post_admission .table table .tr_head {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #7986cb;
  height: 40px;
  z-index: 1;
}

#ac_post_admission .table table .tr_head .export {
  margin: 0;
  margin-top: 3px;
  color: #fff;
  transform: rotateY(180deg);
}

#ac_post_admission .table table .tr_head .create {
  margin: 0;
  font-size: 20px !important;
  color: #fff;
  padding-top: 5px;
  cursor: pointer;
}

#ac_post_admission .table table .fix {
  z-index: 3;
  background: #7986cb;
  font-size: 18px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  color: #fff;
}

#ac_post_admission .table table tbody .head {
  min-width: 80px;
  z-index: 2;
  color: #4a4a4a;
}

#ac_post_admission .table table tbody textarea {
  border: none;
  font-size: 14px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  color: #636364;
}

#ac_post_admission .table table .submit {
  min-height: 24px;
  border-radius: 2px;
  padding: 2px;
}

#ac_post_admission .table table .gray_color {
  border: 1px solid #979797;
  color: #979797;
}

#ac_post_admission .table table .blue_color {
  border: 1px solid #439adf;
  color: #439adf;
}

#ac_post_admission .table table .orange_color {
  border: 1px solid #f5a623;
  color: #f5a623;
}

#ac_post_admission .table table .red_color {
  border: 1px solid #d0021b;
  color: #d0021b;
}

#ac_post_admission .table table .green_color {
  border: 1px solid #7ed321;
  color: #7ed321;
}

#ac_post_admission .table .action td {
  text-align: center;
}

#ac_post_admission .table .action td md-icon {
  cursor: pointer;
  font-size: 26px;
}

#ac_post_admission .complete {
  padding: 0;
  margin: 0;
  margin-left: 10px;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  background: #fff;
  border-radius: 8px;
}

.admission_detail_apforminput {
  height: 400px;
  border-top: 1px #dcdcdc solid;
}

.admission_detail_apforminput .ap-input {
  overflow-y: auto;
}

.admission_detail_apforminput .ap-input .table-row {
  border-top: 1px #dcdcdc solid;
}

.ac_received {
  padding: 20px;
}

.ac_received .tab {
  height: 34px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
}

.ac_received .tab > .md-button {
  margin: 0;
  width: 25%;
  color: #4A4A4A;
}

.ac_received .tab > .md-button.active {
  color: #FFFFFF;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

.ac_received .ac_received_content {
  min-height: 200px;
  margin-top: 10px;
}

.ac_received .ac_received_content .stand h2 {
  font-size: 18px;
}

.ac_received .ac_received_content .stand table.md-table {
  border: 1px solid #ccc;
}

.ac_received .ac_received_content .stand table.md-table th.md-column {
  font-size: 16px;
}

.ac_received .ac_received_content .stand table.md-table td.md-cell {
  height: 30px;
}

.ac_received .ac_received_content .stand table.md-table tr td input {
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  border-left-width: 0px;
}

.ac_received .date {
  margin-top: 10px;
}

.ac_received .imgSrc {
  height: 200px;
  overflow-y: auto;
}

.ac_received .imgSrc img {
  width: 760px;
}


#acTranscript .dateInput {
  margin-left: 10px;
}

#acTranscript md-table-container {
  max-height: 400px;
}

#acTranscript table {
  overflow-y: auto;
}

#acTranscript table th {
  background: #fff;
  z-index: 10;
  font-weight: 500;
  font-size: 14px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

#acTranscript table tr {
  height: 40px;
}

#acTranscript table tr td {
  border: 0;
}

#acTranscript table tr td .create {
  padding-left: 0px;
  padding-top: 10px;
}

#acTranscript table tr td .calendar {
  font-size: 16px;
}

#acTranscript table tr td .close {
  margin-right: 20px;
  margin-top: -20px;
  cursor: pointer;
}

#acTranscript table tr td input {
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  border-left-width: 0px;
}

#acTranscript .content {
  margin-top: 5px;
  margin-left: 15px;
}

#acTranscript .date_content {
  margin-top: 25px;
}

#acTranscript .p_content {
  height: 30px;
}

#acTranscript .canClick {
  cursor: pointer;
}

#acTranscript .loading {
  display: flex;
  min-height: 250px;
  justify-content: center;
  align-items: center;
}

.btn_link {
  padding: 0px;
  margin: 0px;
  line-height: 30px;
  min-height: 0px;
  min-width: 0px;
  color: white;
  width: 84px;
}

md-tabs.all-task-list .md-tab.md-active md-icon {
  color: inherit;
}

md-tabs.all-task-list md-tabs-content-wrapper {
  width: 100%;
  overflow: hidden;
}

md-tabs.all-task-list .circle {
  width: 20px;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  background: red;
  border-radius: 50%;
  font-size: 14px;
  color: white;
}

.task_card_list {
  background: white;
}

.task_card_list:hover {
  background: #ccc;
  cursor: pointer;
}

.status-chip {
  cursor: default;
  border-radius: 16px;
  display: block;
  height: 32px;
  line-height: 32px;
  margin: 8px;
  padding: 0 12px;
  float: left;
  box-sizing: border-box;
  max-width: 100%;
  position: relative;
  color: white;
}

.task-subtitle {
  position: relative;
  right: 5px;
  margin-top: 0;
  border-radius: 2px;
  display: block;
  line-height: 20px;
  padding: 0 12px;
  box-sizing: border-box;
  max-width: 100%;
  font-size: 10px;
  height: 100%;
  color: rgba(0, 0, 0, 0.87);
}

#header .top {
  background-color: #fff;
  height: 78px;
}

#header .logo {
  width: 120px;
  height: 22px;
}

#header .top > .section {
  position: relative;
}

#header .top > .section .md-button {
  color: #757575;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 6px;
  padding-bottom: 6px;
  height: 100%;
}

#header .top > .section .md-button md-icon {
  color: #6d6d6d;
}

#header .top > .section .md-button:hover {
  background: inherit;
}

#header .top > .section .children-container .childRect {
  display: none;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 10px;
  padding-bottom: 10px;
}

#header .top > .section .children-container .child {
  padding-right: 15px;
  cursor: pointer;
  white-space: nowrap;
  display: table-cell;
}

#header .top > .section .children-container .child > p {
  position: relative;
}

#header .top > .section .children-container .child span.active-dot {
  visibility: hidden;
  width: 4px;
  height: 4px;
  background-color: #006DD5;
  border-radius: 50%;
  margin-right: 4px;
  position: absolute;
  top: 50%;
  margin-top: -2px;
}

#header .top > .section .children-container .child span.nav-text {
  margin-left: 10px;
}

#header .top > .section .children-container .child:hover {
  color: #006DD5;
}

#header .top > .section .children-container .child:hover span.active-dot {
  visibility: visible;
}

#header .top > .section .children-container .active {
  color: #006DD5;
  font-weight: bold;
}

#header .top > .section .children-container .active span.active-dot {
  visibility: visible;
}

#header .top > .section.btnActive {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.97), #f8f8f8);
}

#header .top > .section.btnActive .children-container .childRect {
  display: table-row;
}

#header .top > .section.btnActive .md-button span, #header .top > .section.btnActive .md-button md-icon {
  font-weight: bold;
}

#header md-menu .md-icon-button, #header md-menu .md-icon-button > md-icon, #header md-menu .md-icon-button > span {
  width: 20px;
  height: 20px;
  background: #6d6d6d;
  padding: 0;
  margin: 0;
  min-width: 20px;
  min-height: 20px;
  color: #fff;
  font-size: 10px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
}

#header md-menu md-icon {
  font-size: 14px;
}

#header .user_img {
  vertical-align: middle;
}

#header .second_menu {
  background: linear-gradient(180deg, rgba(248, 248, 248, 0.97), rgba(238, 238, 238, 0.97));
  height: 41px;
  color: #6D6D6D;
  font-size: 14px;
  position: relative;
  margin-top: -3px;
}

#header .second_menu .childRect {
  position: absolute;
  left: 145px;
}

#header .second_menu .child {
  margin-right: 15px;
  cursor: pointer;
}

#header .second_menu .child span {
  visibility: hidden;
  width: 4px;
  height: 4px;
  background-color: #006DD5;
  border-radius: 50%;
  margin-right: 4px;
}

#header .second_menu .child:hover {
  color: #006DD5;
}

#header .second_menu .child:hover span {
  visibility: visible;
}

#header .second_menu .active {
  color: #006DD5;
}

#header .second_menu .active span {
  visibility: visible;
}

#header .special_logout {
  margin-right: 20px;
  cursor: pointer;
}

#header .noteDiv {
  position: absolute;
  right: 0;
  background: #fff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  z-index: 500;
}

#header .todolistDiv {
  position: absolute;
  width: 400px;
  right: 0;
  background: #fff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  z-index: 500;
  height: 70vh;
  padding: 10px 20px 50px 0;
}

#header .notificationDiv {
  position: absolute;
  width: 700px;
  right: 0;
  background: #fff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  z-index: 500;
}

md-menu-content {
  padding: 5px 0px;
}

md-menu-content .toggle_btn_item {
  padding: 0px;
  margin: 0px;
  width: 100%;
  text-align: left;
  padding-left: 10px;
  color: #58666e;
}

md-menu-content .toggle_btn_item .toggle_language_btn {
  border: 1px solid #ccc;
  border-radius: 50%;
  font-size: 12px;
  width: 20px;
  height: 20px;
  display: inline-block;
  line-height: 20px;
  text-align: center;
}

md-menu-content.notification .noti_header {
  height: 40px;
  line-height: 40px;
  background: #d9e9ec;
  font-weight: 700;
  padding-left: 10px;
}

md-menu-content.notification .md-3-line {
  min-height: 50px;
}

md-menu-content.user .user_top {
  padding-top: 15px;
  background: #d9e9ec;
  font-size: 14px;
  color: #58666e;
  padding-left: 10px;
  margin-bottom: 8px;
}

@charset "UTF-8";
#headerNote {
  position: relative;
  width: 400px;
}

#headerNote .title {
  background: #7986CB;
  min-height: 50px;
  line-height: 50px;
  padding-left: 20px;
  color: white;
}

#headerNote .title h1 {
  font-size: 22px;
  color: white;
}

#headerNote .title .stu_select {
  color: white;
  margin: 0 10px;
}

#headerNote .title .stu_select span {
  color: white;
}

#headerNote .editStatus {
  font-size: 14px;
  color: #4A4A4A;
  position: absolute;
  right: 0;
}

#headerNote .content {
  height: 500px;
  overflow-y: scroll;
}

#headerNote .content .noteText {
  padding: 10px;
  min-height: 60px;
  max-height: 120px;
  overflow-y: auto;
  border-bottom: 1px dashed #439adf;
}

#headerNote .noteTitle {
  padding-left: 10px;
  height: 38px;
  line-height: 38px;
}

#headerNote .noteTitle .label {
  color: #439adf;
}

#headerNote .noteTitle md-icon {
  margin: 6px 0 0 5px;
  width: 24px;
  cursor: pointer;
}

#headerNote .noteTitle .editing {
  font-size: 12px;
  color: #4A4A4A;
}

#headerNote md-autocomplete {
  background: #7986CB;
  min-width: 180px;
  margin-top: 5px;
}

#headerNote md-autocomplete .md-whiteframe-z1 {
  box-shadow: none;
}

#headerNote md-autocomplete input {
  font-size: 18px;
  color: #fff;
  border: 0;
  padding: 0;
  background: #7986CB;
  font-weight: 500;
}

#headerNote md-autocomplete input::-webkit-input-placeholder {
  /* placeholder颜色  */
  color: #fff;
  /* placeholder字体大小  */
  font-size: 18px;
  /* placeholder位置  */
  text-align: left;
  font-weight: 500;
}

#headerNote md-autocomplete md-icon svg {
  margin-top: 5px;
  width: 10px;
  height: 10px;
}

#headerNote md-autocomplete md-icon svg path {
  fill: #fff;
  color: #fff;
}

.header_notification .title {
  background: #7986CB;
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  color: white;
  font-weight: bold;
}

.header_notification .student_list {
  border: 1px solid #DCDCDC;
  overflow-y: auto;
  margin: 5px 5px 0 0;
  height: 500px;
}

.header_notification .student_list .md-list-item-inner {
  white-space: nowrap !important;
  font-size: 14px;
  padding-left: 4px;
}

.header_notification .student_list .student_item {
  border-bottom: 1px solid #DCDCDC;
  height: 36px;
  min-height: 36px;
}

.header_notification .student_list .student_item.active {
  border-left: 5px solid #006dd5;
  background: #eee;
}

.header_notification .student_list .student_item .md-button {
  padding: 0;
  min-height: 36px;
  height: 36px;
}

.header_notification .student_list .student_item .md-button button {
  margin: 0;
  padding: 0;
  min-height: 36px;
  height: 36px;
  line-height: 36px;
}

.header_notification .content {
  height: 500px;
  overflow-y: auto;
}

.header_notification .content .tab {
  font-size: 0;
  background: rgba(255, 255, 255, 0.8);
  overflow-x: auto;
  margin-right: 10px;
  padding: 5px;
  padding-bottom: 20px;
}

.header_notification .content .tab > .md-button {
  min-width: 80px;
  min-height: 0px;
  padding: 0px;
  margin: 0px;
  height: 24px;
  color: #4A4A4A;
  border: 1px solid #dadada;
  border-left: none;
  line-height: 22px;
  border-radius: none;
  font-size: PingFang SC;
}

.header_notification .content .tab > .md-button.btnActive {
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  border-color: #78CAF1;
  color: white;
}

.header_notification .content .box {
  padding: 10px;
  overflow-y: auto;
}

.header_notification .content .box .notificationBox {
  border: 1px solid #439adf;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 5px 10px;
  color: #4a4a4a;
  position: relative;
  cursor: pointer;
  min-height: 70px;
}

.header_notification .content .box .notificationBox .contentP {
  color: #439adf;
}

.header_notification .content .box .notificationBox .dateP {
  color: #999;
  font-size: 12px;
}

.header_notification .content .box .notificationBox .md-button {
  position: absolute;
  right: 2px;
  top: 2px;
  margin: 0;
  min-width: 0;
  min-height: 0;
  width: auto;
  height: auto;
  padding: 0;
}

.header_notification .content .box .notificationBox .md-button md-icon {
  color: #439ADF;
}

.header_notification .content .box .notificationBox:hover {
  background: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

.header_notification .content .box .notificationBox:hover .contentP, .header_notification .content .box .notificationBox:hover .dateP, .header_notification .content .box .notificationBox:hover md-icon, .header_notification .content .box .notificationBox:hover .extraTable {
  color: #fff;
}

.header_notification .content .box .notificationBox .extraTable {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin-top: 5px;
}

.header_notification .content .box .notificationBox .extraTable th, .header_notification .content .box .notificationBox .extraTable td {
  border-right: 1px solid #ccc;
  text-align: center;
}

.header_notification .content .box .notificationBox .extraTable tr {
  border-bottom: 1px solid #ccc;
}

.header_notification .content .box .notificationBox .extraTable .change {
  color: #fff;
  border-radius: 3px;
  padding: 0 3px;
}

.header_notification .content .box .not_bac {
  background: #fff !important;
}

.header_notification .content .box .not_bac .contentP {
  color: #439adf !important;
}

.header_notification .content .box .not_bac .dateP {
  color: #999 !important;
}

.header_notification .content .box .not_bac .md-button md-icon {
  color: #439adf !important;
}

.header_notification .content .box .not_bac .extraTable {
  color: #4a4a4a !important;
}

.header_notification .content .message md-list .md-subheader button {
  min-width: 44px;
  min-height: 26px;
  line-height: 26px;
}

.header_notification .content .message md-list md-list-item {
  display: flex;
}

.header_notification .content .message md-list md-list-item .md-list-item-text {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  padding: 0 5px;
}

.header_notification .content .message md-list md-list-item md-icon {
  background: #7ED321 !important;
  -webkit-background-clip: text !important;
  color: transparent !important;
}

.app-header-notification-add {
  overflow-y: auto;
  min-height: 500px;
  max-height: 500px;
  padding: 0 15px;
}

.app-header-notification-add .md-block {
  margin-bottom: 15px;
}

.app-header-notification-add .timeRange {
  margin-top: 30px;
}

.app-header-notification-add .kinds {
  margin-top: 30px;
}

.app-header-notification-add .checkBox {
  margin-top: 30px;
}

.app-header-notification-add .checkBox .checkoutItem {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.app-header-notification-add .checkBox .checkoutItem .demo-select-all-checkboxes {
  margin-right: 15px;
}

.app-header-notification-change {
  overflow-y: auto;
  min-height: 500px;
  max-height: 500px;
  padding: 0 15px;
}

.app-header-notification-change .md-block {
  margin-bottom: 15px;
}

.app-header-notification-change .timeRange {
  margin-top: 30px;
}

.app-header-notification-change .kinds {
  margin-top: 30px;
}

.app-header-notification-change .checkBox {
  margin-top: 30px;
}

.app-header-notification-change .checkBox .checkoutItem {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.app-header-notification-change .checkBox .checkoutItem .demo-select-all-checkboxes {
  margin-right: 15px;
}

.app_notification_manager {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  height: 500px;
  overflow-y: auto;
}

.app_notification_manager .add {
  margin: 10px 0;
}

.app_notification_manager .add button {
  color: #fff;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  min-height: 26px;
  line-height: 26px;
  min-width: 70px;
}

.app_notification_manager .add button md-icon {
  color: #fff;
  position: relative;
  top: -1px;
}

.app_notification_manager md-content md-list md-list-item .md-avatar {
  margin-right: 0;
}

.app_notification_manager md-content md-list md-list-item md-icon {
  font-size: 24px;
  color: #c40000;
}

.app_notification_manager md-content md-list md-list-item .md-list-item-text p {
  padding: 0px;
}

.bg-sidenav {
  background: #6D6D6D;
}

.app-header-fixed {
  max-width: 100%;
  max-height: 100%;
  min-height: 100%;
}

md-sidenav {
  width: auto;
  transition: width 0.2s;
  overflow: visible;
}

.main {
  padding: 0;
  background: white;
  position: static;
}

.marquee {
  position: absolute;
  right: -800px;
  font-size: 20px;
  color: white;
  animation: scoll 15s linear 0s infinite;
}

.marquee a {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  color: white;
  margin-right: 10px;
}

@keyframes scoll {
  from {
    right: -800px;
  }
  to {
    right: 100%;
  }
}

#sider .main {
  width: 200px;
  position: relative;
  overflow: visible;
}

#sider .main .filter_stu {
  padding: 10px;
  padding-bottom: 16px;
}

#sider .main .filter_stu .can_switch .md-button {
  margin: 0;
  margin-bottom: 10px;
  min-width: 90px;
  height: 26px;
  min-height: 26px;
  line-height: 26px;
  background: #fff;
  font-size: 14px;
  border: 1px solid #439ADF;
  color: #439ADF;
}

#sider .main .filter_stu .can_switch .md-button:nth-of-type(1) {
  border-radius: 3px 0px 0px 3px;
}

#sider .main .filter_stu .can_switch .md-button:nth-of-type(2) {
  border-radius: 0px 3px 3px 0px;
  border-left: none;
}

#sider .main .filter_stu .can_switch .active {
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  color: #fff;
}

#sider .main .filter_stu .container {
  position: relative;
  background-color: #F5F5F5;
  border-radius: 3px;
  overflow: hidden;
}

#sider .main .filter_stu .container input {
  background-color: #F5F5F5;
  border: none;
  height: 32px;
  padding-left: 8px;
  line-height: 32px;
}

#sider .main .filter_stu .container .md-button {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  padding: 0px;
  margin: 0px;
}

#sider .main .stu_list {
  overflow: auto;
  border-top: 1px solid #dcdcdc;
}

#sider .main .stu_list .admit_round {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 3px;
  height: 16px;
  line-height: 16px;
  min-width: 25px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}

#sider .main .stu_list .ed_round {
  background-color: #7BD500;
  color: #fff;
  border-radius: 2px;
}

#sider .main .stu_list .not_ed_round {
  color: #7BD500;
}

#sider .main .stu_list md-list-item {
  position: relative;
  border-bottom: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
}

#sider .main .stu_list md-list-item.active {
  border: 2px solid #006dd5;
  background: #eee;
}

#sider .main .stu_list md-list-item.active .stu_name {
  font-weight: 450;
  color: #4a3e3e;
}

#sider .main .stu_list md-list-item div.c439adf {
  background-color: #439adf;
}

#sider .main .stu_list md-list-item div.cdfc45a {
  background-color: #dfc45a;
}

#sider .main .stu_list md-list-item div.c5db37e {
  background-color: #5db37e;
}

#sider .main .stu_list md-list-item div.c7b87c6 {
  background-color: #7b87c6;
}

#sider .main .stu_info {
  position: absolute;
  height: 98%;
  opacity: 0.99;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  left: 200px;
  top: 1%;
  z-index: 100;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-top: 70px;
  padding-left: 24px;
  padding-right: 8px;
  border-radius: 3px;
  background: #fff;
  width: 289px;
}

#sider .main .stu_info.ng-enter {
  transition: all 0.2s;
  opacity: 0;
  left: 100px;
}

#sider .main .stu_info.ng-enter-active {
  opacity: 1;
  left: 200px;
}

#sider .main .stu_info::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 200%;
  height: 100%;
  left: -40%;
  filter: blur(10px);
  z-index: -1;
  background: #d9d8d8;
}

#sider .main .attr_list p {
  min-height: 44px;
  font-family: PingFang SC;
  font-size: 14px;
}

#sider .main .side_simditor .simditor .simditor-toolbar > ul > li > .toolbar-item {
  width: 34px;
  height: 36px;
}

#sider .main .side_simditor .simditor-body {
  min-height: 81px;
}

.btn_list {
  display: block;
  width: 180px;
  padding-left: 10px;
  color: #666;
  height: 30px;
  line-height: 30px;
  text-decoration: none;
}

.btn_list:hover {
  background: gainsboro;
}

md-list-item.min_h_36 {
  min-height: 36px;
}

md-list-item.min_h_36::before {
  min-height: 40px;
}

md-list-item.min_h_36 div.md-button {
  height: 36px;
  min-height: 40px;
}

md-list-item.min_h_36 div.md-button button {
  z-index: 1;
}

md-list-item.min_h_36 div.md-button .md-list-item-inner {
  white-space: nowrap;
}

md-list-item.min_h_36 .md-icon-button {
  opacity: 0;
}

md-list-item.min_h_36:hover .md-icon-button {
  opacity: 1;
}

md-list {
  padding: 0;
}


.application_status {
  overflow: hidden;
}

.application_status .main {
  width: 210px;
  height: 100%;
  position: relative;
  overflow: visible;
}

.application_status .main .filter_stu {
  padding: 10px;
  padding-bottom: 16px;
}

.application_status .main .filter_stu .can_switch .md-button {
  margin: 0;
  margin-bottom: 10px;
  min-width: 90px;
  height: 26px;
  min-height: 26px;
  line-height: 26px;
  background: #fff;
  font-size: 14px;
  border: 1px solid #439ADF;
  color: #439ADF;
}

.application_status .main .filter_stu .can_switch .md-button:nth-of-type(1) {
  border-radius: 3px 0px 0px 3px;
}

.application_status .main .filter_stu .can_switch .md-button:nth-of-type(2) {
  border-radius: 0px 3px 3px 0px;
  border-left: none;
}

.application_status .main .filter_stu .can_switch .active {
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  color: #fff;
}

.application_status .main .filter_stu .container {
  position: relative;
  background-color: #F5F5F5;
  border-radius: 3px;
  overflow: hidden;
}

.application_status .main .filter_stu .container input {
  background-color: #F5F5F5;
  border: none;
  height: 32px;
  padding-left: 8px;
  line-height: 32px;
}

.application_status .main .filter_stu .container .md-button {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  padding: 0px;
  margin: 0px;
}

.application_status .main .stu_list {
  overflow: auto;
  border-top: 1px solid #dcdcdc;
  width: 100%;
  border-right: 1px solid #dcdcdc;
}

.application_status .main .stu_list md-list-item {
  position: relative;
  border-bottom: 1px solid #dcdcdc;
}

.application_status .main .stu_list md-list-item .stu_name {
  padding-left: 14px;
  font-family: PingFang SC;
}

.application_status .main .stu_list md-list-item.active {
  border: 2px solid #006dd5;
  background: #eee;
}

.application_status .main .stu_list md-list-item.active .stu_name {
  font-weight: 450;
  color: #4a3e3e;
}

.application_status .main .stu_list md-list-item div.c439adf {
  background-color: #439adf;
}

.application_status .main .stu_list md-list-item div.cdfc45a {
  background-color: #dfc45a;
}

.application_status .main .stu_list md-list-item div.c5db37e {
  background-color: #5db37e;
}

.application_status .main .stu_list md-list-item div.c7b87c6 {
  background-color: #7b87c6;
}

.application_status .content {
  height: 100%;
  padding: 10px 20px;
  position: relative;
}

.application_status .content .scroll_btn {
  position: absolute;
  z-index: 50;
  padding: 0px;
  margin: 0px;
  border-radius: 3px;
  width: 20px;
  top: 10px;
  background: #439adf;
}

.application_status .content .scroll_btn md-icon {
  color: #fff;
}

.application_status .content .scroll_btn:hover {
  background: #439adf;
}

.application_status .content .tab {
  overflow-x: auto;
  width: 100%;
}

.application_status .content .tab::-webkit-scrollbar {
  display: none;
}

.application_status .content .tabBox {
  padding: 10px;
  width: 260px;
  min-width: 260px;
  height: 150px;
  background: white;
  border-radius: 4px 4px 0px 0px;
  border: 2px solid rgba(0, 0, 0, 0.15);
  border-bottom: none;
  cursor: pointer;
  overflow-y: auto;
}

.application_status .content .tabBox .num span {
  margin-right: 10px;
}

.application_status .content .tabBox .datepicker {
  margin-left: -10px;
}

.application_status .content .tabBox .datepicker input {
  width: 100px;
  border: none;
  background: transparent;
  cursor: pointer;
}

.application_status .content .activeTabId {
  background: #7986CB;
  color: #fff;
  border-color: #7986CB;
}

.application_status .content .activeTabId .datepicker input {
  color: #fff;
}

.application_status .content .checklist {
  width: 100%;
  overflow-y: auto;
}

.application_status .content .checklist table {
  overflow: inherit;
}

.application_status .content .checklist thead tr th {
  height: 40px;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background: #7986CB;
  z-index: 1;
  color: #fff;
  font-size: 14px;
  text-align: center;
}

.application_status .content .checklist thead tr th md-icon {
  font-size: 24px !important;
  color: #fff;
  min-height: 0;
}

.application_status .content .checklist table.md-table td.md-cell {
  border-bottom: 1px rgba(0, 0, 0, 0.12) solid;
}

.application_status .content .checklist .type {
  font-size: 14px;
  font-weight: bold;
}

.application_status .content .checklist .row {
  min-height: 40px;
  border-bottom: 1px rgba(0, 0, 0, 0.12) solid;
}

.application_status .content .checklist .row > div {
  border-right: 1px rgba(0, 0, 0, 0.12) solid;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.application_status .content .checklist .row > div:nth-child(1) {
  border-left: 1px rgba(0, 0, 0, 0.12) solid;
}

.application_status .content .checklist .row > div:nth-last-child(1) {
  border-right: none;
}

.application_status .content .checklist .row:nth-last-child(1) {
  border-bottom: none;
}

.application_status .content .checklist .materialBox {
  position: relative;
}

.application_status .content .checklist .materialBox .name {
  height: 40px;
  width: 70% !important;
}

.application_status .content .checklist .materialBox .deleteBtn {
  visibility: hidden;
  position: absolute;
  right: 40px;
  width: 40px;
  height: 40px;
  top: 0;
}

.application_status .content .checklist .materialBox .infoBtn {
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
  top: 0;
}

.application_status .content .checklist .materialBox:hover .deleteBtn {
  visibility: visible;
}

.application_status .content .checklist .no_data {
  text-align: center;
  font-size: 14px;
  color: #666;
  border-left: 1px rgba(0, 0, 0, 0.12) solid;
}

.application_status .content .checklist .note {
  width: 90%;
  height: 36px;
  border-radius: 3px;
  text-indent: 5px;
  border: none;
}

.application_status .content .checklist .note:focus {
  border: 1px solid #439adf;
}

.application_status .content .checklist .addRow {
  color: #fff;
  border: 1px solid #439adf;
  border-radius: 3px;
  font-size: 20px;
  width: 20px;
  min-width: 0;
  min-height: 0;
  height: 20px;
  background: #439adf;
}

.application_status .content .checklist .attachmentClass {
  height: 36px;
  border: none;
  max-width: 100%;
}

.application_status .content .checklist .attachmentClass .ap-input-label {
  display: none;
}

.application_status .content .checklist .attachmentClass .attachment-container {
  max-width: 100%;
}

.application_status .content .checklist .attachmentClass .attachment-container .ap-input-btn {
  display: none;
}

.application_status .content .checklist .attachmentClass:hover .attachment-container .ap-input-btn {
  display: block;
}

.application_status_account_dialog {
  width: 1000px;
  height: 400px;
  padding: 20px;
  max-width: 1000px;
}

.application_status_account_dialog .content {
  border: 1px solid #ccc;
  border-right: none;
  overflow-y: auto;
}

.application_status_account_dialog ap-form-input.score_table > .ap-input-label {
  height: auto;
}




.calendar_management md-radio-button .md-label {
  margin-left: 24px;
}

.calendar_management .showing {
  background: #eee;
}

.square md-checkbox.md-default-theme.md-checked .md-icon:after, .square md-checkbox.md-checked .md-icon:after {
  margin-left: 2px;
  margin-top: 2px;
}

.md-event-calendar-cell-event-show-more-link {
  color: #666 !important;
}

#calendar md-event-calendar .md-event-calendar-create-link {
  color: #286bc8;
}

#calendar md-event-calendar .md-event-calendar-month-row-header {
  background: #106CC8;
  color: white;
  font-size: 18px;
}

#calendar md-event-calendar md-event-calendar-header .md-arrow svg {
  fill: white;
}

#calendar .md-event-calendar-cell-event-show-more-link {
  color: #106CC8;
}

#calendar .md-event-calendar-month-cell-content {
  overflow: auto;
}

#calendar .md-event-calendar-month-cell-content .md-event-calendar-cell-event {
  line-height: 14px;
}

#calendar md-event-calendar-month .md-event-calendar-month-row .md-event-calendar-month-cell.today .md-event-calendar-month-cell-content .md-event-calendar-cell-data-label {
  color: #106CC8;
}

#calendar .md-event-calendar-cell-event.md-selected span {
  color: black;
}

#calendar md-event-calendar-month .md-event-calendar-show-more-container {
  max-height: 50%;
  width: 30%;
  overflow: scroll;
  position: fixed;
  top: 40% !important;
  left: 45% !important;
}

.hover:hover {
  background: #ccc;
}

md-event-calendar:not(._md) md-event-calendar-header {
  color: #FFF;
  background: #1458b1;
}

md-event-calendar:not(._md) md-event-calendar-header md-event-calendar-next .md-arrow svg,
md-event-calendar:not(._md) md-event-calendar-header md-event-calendar-prev .md-arrow svg {
  fill: #FFF;
}

md-event-calendar:not(._md) md-event-calendar-month .md-event-calendar-month-row-header {
  color: #FFF;
  background: #1458b1;
}

md-event-calendar:not(._md) md-event-calendar-month .md-event-calendar-month-row .md-event-calendar-month-cell.today {
  box-shadow: inset 0px 0px 0px 1px #1458b1;
}

md-event-calendar:not(._md) md-event-calendar-month .md-event-calendar-month-row .md-event-calendar-month-cell.today .md-event-calendar-month-cell-content .md-event-calendar-cell-data-label {
  color: #1458b1;
}

md-event-calendar:not(._md) md-event-calendar-month .md-event-calendar-month-row .md-event-calendar-month-cell.today .md-event-calendar-month-cell-divider {
  border-color: #1458b1;
}

md-event-calendar:not(._md) md-event-calendar-month .md-event-calendar-cell-event.md-selected {
  color: #FFF;
  background: #1458b1;
}

md-event-calendar:not(._md) md-event-calendar-month .md-event-calendar-cell-event.md-selected.md-continue-left:after, md-event-calendar:not(._md) md-event-calendar-month .md-event-calendar-cell-event.md-selected.md-end-left:after {
  border-right-color: #1458b1;
}

md-event-calendar:not(._md) md-event-calendar-month .md-event-calendar-cell-event.md-selected.md-continue-right:after, md-event-calendar:not(._md) md-event-calendar-month .md-event-calendar-cell-event.md-selected.md-start-right:after {
  border-left-color: #1458b1;
}

.md-arrow svg {
  fill: white !important;
}



.video_progress .md-bar2 {
  background: #439adf !important;
}

.lay_date_picker:focus {
  border-bottom: 1px solid #4688f1 !important;
}

.daily-simple {
  overflow: hidden;
}

.daily-simple .date_pick {
  border: 1px solid #dadada;
  margin-bottom: 3px;
  position: relative;
  height: 80px;
}

.daily-simple .date_pick .md-button {
  padding: 0px;
  margin: 0px;
  color: white;
  background: #d8d8d8;
  min-width: 0px;
  min-height: 0px;
  width: 11px;
  height: 78px;
  border-radius: 0px;
  z-index: 3;
  position: relative;
}

.daily-simple .date_pick .md-button:hover {
  background-color: #ccc;
}

.daily-simple .date_pick .week_day_container {
  left: 0;
  transition: left 1s;
}

.daily-simple .date_pick .item:last-child {
  border-right: none;
}

.daily-simple .date_pick div.item {
  text-align: center;
  border-right: 1px solid #dadada;
  height: 79px;
  cursor: pointer;
}

.daily-simple .date_pick div.item.active {
  outline: 1px solid #439ADF;
  height: 78px;
}

.daily-simple .date_pick div.item.active span:first-child {
  color: #439ADF;
}

.daily-simple .date_pick div.item.active span:last-child {
  color: #439ADF;
}

.daily-simple .date_pick div.item span:first-child {
  line-height: 40px;
  color: #6D6D6D;
  font-size: 12px;
}

.daily-simple .date_pick div.item span:last-child {
  line-height: 30px;
  color: #DCDCDC;
  font-size: 24px;
}

.daily-simple .event_content {
  overflow: scroll;
  border: 1px solid #dadada;
  margin-bottom: 10px;
}

.daily-simple .event_content .all_day {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 0px;
}

.daily-simple .event_content .left {
  background: white;
  position: sticky;
  position: -webkit-sticky;
  left: 0px;
}

.daily-simple .event_content .hour_box {
  height: 48px;
  background: white;
  position: relative;
}

.daily-simple .event_content .hour_box .left {
  width: 48px;
  border-right: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
  text-align: center;
  padding-top: 4px;
  color: #6D6D6D;
}

.daily-simple .event_content .hour_box .event_box {
  height: 100%;
  position: relative;
}

.daily-simple .event_content .hour_box .event_box .event_one {
  padding: 3px;
  border: 1px solid #dadada;
  margin-bottom: 3px;
  margin-right: 1px;
  width: 120px;
  border-radius: 3px;
  cursor: pointer;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: 22px;
  min-height: 24px;
}



.dashboard_essay_content .process_block {
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-family: Helvetica-Bold;
  min-width: 40px;
}

.dashboard_essay_content .process_block:nth-of-type(1) {
  background: #B8E986;
}

.dashboard_essay_content .process_block:nth-of-type(2) {
  background: #F5A623;
}

.dashboard_essay_content .process_block:nth-of-type(3) {
  background: #D8D8D8;
}

.dashboard_essay_content md-radio-button .md-container:before {
  width: 0;
}

.dashboard_essay_content .school_section {
  padding-bottom: 20px;
}

.dashboard_essay_content .school_section .no_data {
  height: 200px;
  border: 1px solid #dadada;
  line-height: 200px;
  color: #ccc;
  font-size: 20px;
  text-align: center;
}

.dashboard_essay_content .school_section .top .sch_name {
  color: #4A4A4A;
  font-size: 18px;
  font-weight: 600;
}

.dashboard_essay_content .school_section .top .jindu {
  position: relative;
  border-bottom: 1px solid #979797;
  margin: 0px 12px;
}

.dashboard_essay_content .school_section .top .jindu div {
  position: absolute;
  border-bottom: 1px solid #439ADF;
}

.dashboard_essay_content .school_section .top .jindu span {
  width: 7px;
  position: absolute;
  color: #439ADF;
  font-size: 20px;
  top: -20px;
  background: white;
}

.dashboard_essay_content .school_section .content .essay_box {
  border: 1px solid #dadada;
  width: 49%;
  margin-bottom: 10px;
  padding: 8px;
  height: 94px;
  cursor: pointer;
}

.dashboard_essay_content .school_section .content .essay_box .btn_box {
  margin-top: 0px;
}

.dashboard_essay_content .school_section .content .essay_box .btn_box button {
  padding: 0px 3px;
}

.dashboard_essay_content .school_section .content .essay_box .btn_box button img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.dashboard_essay_content .school_section .content .essay_box .top {
  height: 39px;
  border-bottom: 1px solid #dadada;
}

.dashboard_essay_content .school_section .content .essay_box .top md-icon {
  color: black;
  font-size: 34px;
}

.dashboard_essay_content .school_section .content .essay_box .bot {
  height: 39px;
  padding-top: 6px;
}

.dashboard_school {
  height: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  overflow-y: scroll;
}

.dashboard_school .basic_info {
  border: 1px solid #DCDCDC;
}

.dashboard_school .basic_info p {
  padding: 0 10px;
  border: 1px solid #DCDCDC;
  height: 27px;
  line-height: 27px;
}

.dashboard_school md-table-container {
  margin-top: 10px;
  height: 88%;
  overflow-y: scroll;
}

.dashboard_school md-table-container table {
  border-collapse: initial;
  border: 2px solid #ccc;
  border-top: none;
  overflow: scroll;
}

.dashboard_school md-table-container table th {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background: white;
  border-top: 2px solid #ccc;
  border-bottom: 1px solid #ccc;
  z-index: 2;
  font-size: 18px;
  font-family: Helvetica-Bold;
  color: #4a4a4a;
}

.dashboard_school md-table-container table .text_container {
  min-height: 20px;
  line-height: 20px;
  max-height: 38px;
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all;
}

.dashboard_school md-table-container table .popup {
  position: absolute;
  top: 0;
  background: white;
  z-index: 50;
  left: 100%;
  width: 100px;
  padding: 5px;
}

.dashboard_school md-table-container table.md-table td.md-cell:last-child {
  white-space: nowrap;
}

.dashboard_school md-table-container table.md-table td.md-cell {
  position: relative;
}

.dashboard_school md-table-container table.md-table:not(.md-row-select) th.md-column:first-child,
.dashboard_school md-table-container table.md-table:not(.md-row-select) th.md-column:nth-child(n+2):nth-last-child(n+2),
.dashboard_school md-table-container table.md-table th.md-column:last-child,
.dashboard_school md-table-container table.md-table:not(.md-row-select) td.md-cell:first-child,
.dashboard_school md-table-container table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2),
.dashboard_school md-table-container table.md-table td.md-cell:last-child {
  padding: 0 5px;
  max-width: 100px;
}

.dashboard_school md-table-container table.md-table thead.md-head > tr.md-row, .dashboard_school md-table-container table.md-table tbody.md-body > tr.md-row {
  height: 27px;
}

.dashboard_sop {
  border: 1px solid #DCDCDC;
  border-top: none;
  padding-right: 8px;
}

.dashboard_sop .have_to_do_task {
  background: #e9f6fd;
}

.dashboard_sop .have_to_do_task .bg {
  background: #cbe9f6 !important;
}

.dashboard_sop md-table-container {
  position: relative;
}

.dashboard_sop md-table-container table {
  overflow: inherit;
}

.dashboard_sop md-table-container table thead tr th {
  padding: 0px !important;
  min-width: 80px;
  text-align: center !important;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 10;
}

.dashboard_sop md-table-container table thead tr th.corner {
  border: none;
  border-right: 1px solid #DCDCDC;
  padding-right: 8px !important;
  text-align: left !important;
  left: 0px;
  z-index: 100;
}

.dashboard_sop md-table-container table thead tr th.corner > div {
  width: 120px;
  height: 77px;
  color: #439adf;
  border-bottom: 1px solid #DCDCDC;
}

.dashboard_sop md-table-container table thead tr:first-child th {
  background: #7986CB;
}

.dashboard_sop md-table-container table thead tr:last-child th {
  top: 50px;
}

.dashboard_sop md-table-container table tbody td {
  border: 1px solid #DCDCDC;
  border-left: none;
  border-top: none;
  height: 28px;
}

.dashboard_sop md-table-container table tbody td:first-child {
  border: none;
  padding-right: 8px;
  border-right: 1px solid #DCDCDC;
  position: sticky;
  position: -webkit-sticky;
  left: 0px;
}

.dashboard_sop md-table-container table tbody td:first-child > div {
  border: 1px solid #DCDCDC;
  border-top: none;
  border-left: none;
  background: white;
}

.task_panel {
  min-width: 240px;
  max-width: 300px;
  background: white;
  position: absolute;
  padding: 6px;
  padding-right: 0px;
  display: none;
  z-index: 20;
  box-shadow: 0px 1px 8px 0px #cce9f8, 0px 3px 6px 0px #cce9f8, 0px 3px 10px -2px #cce9f8;
}

.task_panel .panel_sub_task_list {
  margin: 6px 0px;
}

.task_panel .panel_sub_task_list .panel_sub_task_list_div {
  border: 1px solid #439adf;
  padding: 3px;
  border-radius: 1px;
  color: #439adf;
}

.task_panel .panel_sub_task_list .panel_sub_task_list_div md-checkbox .md-icon {
  border-color: #439adf;
}

.task_panel .panel_sub_task_list.no_auth .panel_sub_task_list_div {
  border: 1px solid #dcdcdc;
  color: #4A4A4A;
}

.task_panel .panel_sub_task_list.no_auth .panel_sub_task_list_div md-checkbox[disabled] .md-icon {
  border-color: #dcdcdc;
}

.task_panel .panel_sub_task_list.finished .panel_sub_task_list_div {
  border: 1px solid #dcdcdc;
  background: #eee;
  color: #4A4A4A;
}

.task_panel .panel_sub_task_list.finished .panel_sub_task_list_div md-checkbox[disabled] .md-icon {
  border-color: #939393;
}

.task_panel .finish_btn {
  padding-bottom: 4px;
}

.task_panel .finish_btn .md-icon-button {
  padding: 0px;
  margin: 0px;
  min-height: 0px;
  min-width: 0px;
  height: 24px;
  width: 24px;
}

.task_panel .finish_btn .md-icon-button md-icon {
  color: #439adf;
}

.toggle_detail {
  min-width: 0px;
  min-height: 0px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  border: 1px solid #439adf;
  right: 10px;
  top: 5px;
  height: 22px;
  width: 22px;
  line-height: 20px;
  color: #439adf;
}

.dashboard-student {
  padding-bottom: 10px;
  margin-left: 20px;
  height: 100%;
  border-top: 1px solid #DCDCDC;
}

.dashboard-student .can_switch {
  margin-left: -6px;
}

.dashboard-student .can_switch .md-button {
  margin: 0;
  min-width: 84px;
  height: 24px;
  min-height: 24px;
  line-height: 24px;
  background: #fff;
  font-size: 14px;
  border: 1px solid #439ADF;
  color: #439ADF;
}

.dashboard-student .can_switch .md-button:nth-of-type(1) {
  border-radius: 3px 0px 0px 3px;
}

.dashboard-student .can_switch .md-button:nth-of-type(2) {
  border-radius: 0px 3px 3px 0px;
  border-left: none;
}

.dashboard-student .can_switch .btnActive {
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  color: #fff;
}

.dashboard-student .can_switch.has_back .md-button {
  background: white;
}

.dashboard-student .can_switch.has_back .md-button:hover {
  background: white;
}

.dashboard-student .can_switch.has_back .btnActive {
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  color: #fff;
}

.dashboard-student .can_switch.has_back .btnActive:hover {
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  color: #fff;
}

.dashboard-student .header {
  border-left: 1px solid #DCDCDC;
  border-right: 1px solid #DCDCDC;
  padding: 16px 8px 12px 13px;
  height: 42px;
}

.dashboard-student .header .title_text {
  font-size: 16px;
  font-family: PingFangSC-Medium;
  color: #439ADF;
}

.dashboard-student .header .fir_type {
  border-left: 1px solid #dadada;
}

.dashboard-student .header .appli_type, .dashboard-student .header .road_type {
  border-left: 1px solid #dadada;
}

.dashboard-student .header .header_btn .md-button {
  min-width: 80px;
  min-height: 0px;
  padding: 0px;
  margin: 0px;
  width: 69px;
  height: 24px;
  color: #4A4A4A;
  border: 1px solid #dadada;
  border-left: none;
  line-height: 22px;
  border-radius: none;
  font-size: PingFang SC;
}

.dashboard-student .header .header_btn .md-button.active {
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  border-color: #78CAF1;
  color: white;
}

.dashboard-student .num {
  color: #fff;
  width: 24px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  display: inline-block;
  border-radius: 3px;
  margin-right: 10px;
  margin-left: 10px;
}

.dashboard-student .color1 {
  background-color: #439ADF;
}

.dashboard-student .color2 {
  background-color: #DFC45A;
}

.dashboard-student .color3 {
  background-color: #5DB37E;
}

.dashboard-student .color4 {
  background-color: #7B87C6;
}

.dashboard-student .student_list {
  border: 1px solid #DCDCDC;
  overflow-y: auto;
  border-right: none;
}

.dashboard-student .student_list .active {
  border-left: 5px solid #006dd5;
  background: #eee;
}

.dashboard-student .student_list .md-list-item-inner {
  white-space: nowrap !important;
}

.dashboard-student .student_list .input_container {
  height: 32px;
  margin: 15px 10px 20px 10px;
  padding: 0 10px;
  background: #f5f5f5;
  color: #909090;
  border-radius: 3px;
  box-sizing: border-box;
}

.dashboard-student .student_list .input_container .md-select-value {
  border: none;
}

.dashboard-student .student_list .studentList {
  border: 1px solid #DCDCDC;
  overflow-y: auto;
  height: 100%;
  border-right: none;
  cursor: pointer;
}

.dashboard-student .student_list .studentList p {
  border-bottom: 1px solid #DCDCDC;
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
  font-size: 14px;
}

.dashboard-student .student_list .studentList p:last-child {
  border: none;
}

.dashboard-student .right_content {
  border-bottom: 1px solid #DCDCDC;
  border-right: 1px solid #DCDCDC;
  border-left: 1px solid #dcdcdc;
}

.dashboard-student .right_content .selectYear {
  margin-top: 15px;
  margin-left: 15px;
  color: #006DD5;
}

.dashboard-student .right_content .selectYear .md-select-value {
  border-bottom: 0;
  color: #006DD5;
}

.dashboard-student .right_content .selectYear .md-select-value .md-select-icon {
  color: #006DD5;
}

.dashboard-student .right_content md-slider {
  margin-right: 20px;
}

.dashboard-student .right_content .yearInput {
  height: 32px;
  margin: 10px;
  border: none;
  color: #006DD5;
}

.dashboard-student .right_content .monthArr {
  color: #6D6D6D;
  font-size: 10px;
}

.dashboard-student .right_content .active {
  color: #1589EE;
}

.dashboard-student .right_content md-content {
  overflow-x: auto;
}

.dashboard-student .right_content md-content md-card {
  width: 164px;
  height: 192px;
  min-width: 164px;
  min-height: 192px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
  margin-left: 10px;
  box-sizing: border-box;
  overflow-y: auto;
}

.dashboard-student .right_content md-content md-card md-card-title {
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  border-bottom: 1px solid #dcdcdc;
}

.dashboard-student .right_content md-content md-card md-card-content {
  padding-top: 8px;
}

.dashboard-student .right_content .appli_ec_task .process_block {
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-family: Helvetica-Bold;
  min-width: 40px;
}

.dashboard-student .right_content .appli_ec_task .process_block:nth-of-type(1) {
  background: #B8E986;
}

.dashboard-student .right_content .appli_ec_task .process_block:nth-of-type(2) {
  background: #F5A623;
}

.dashboard-student .right_content .appli_ec_task .process_block:nth-of-type(3) {
  background: #D8D8D8;
}

.dashboard-student .right_content .appli_ec_task .task_list_item {
  cursor: pointer;
}

.dashboard-student .right_content .appli_ec_task .task_list_item:hover {
  background-color: #f8f5f5;
}

.dashboard-student .right_content .appli_ec_task .task_list_item:hover md-icon {
  display: inline !important;
}

.dashboard_todo {
  padding-bottom: 10px;
  margin-left: 10px;
}

.dashboard_todo .left {
  border: 1px solid #DCDCDC;
  padding: 6px;
}

.dashboard_todo .right {
  border: 1px solid #DCDCDC;
  border-left: none;
}

.dashboard_todo .right .system_recommend {
  padding: 6px;
  height: 50%;
  border-bottom: 1px solid #DCDCDC;
}

.dashboard_todo .right .system_recommend .title_text {
  color: #439ADF;
}

.dashboard_todo .right .system_recommend .card {
  border: 1px solid #439ADF;
  font-size: 14px;
}

.dashboard_todo .right .system_recommend .card:hover {
  box-shadow: 0px 1px 2px 0px rgba(39, 104, 156, 0.4);
}

.dashboard_todo .right .next_todo {
  padding: 6px;
  height: 50%;
}

.dashboard_todo .right .next_todo .title_text {
  color: #5DB37E;
}

.dashboard_todo .right .next_todo .newEvent {
  border: 1px solid #5DB37E;
}

.dashboard_todo .right .next_todo .newEvent:hover {
  box-shadow: 0px 1px 2px 0px rgba(71, 158, 104, 0.6);
}

.dashboard_todo .right .next_todo .card {
  border: 1px solid #5DB37E;
}

.dashboard_todo .right .next_todo .card:hover {
  box-shadow: 0px 1px 2px 0px rgba(53, 141, 87, 0.6);
}

.dashboard_todo .content {
  overflow-y: auto;
  position: relative;
}

.dashboard_todo .title {
  padding: 6px 8px 12px 8px;
  height: 42px;
  min-height: 42px;
}

.dashboard_todo .title .title_text {
  font-size: 16px;
  font-family: PingFangSC-Medium;
  color: #dfc45a;
}

.dashboard_todo .title .newEvent {
  margin: 0;
  padding: 0;
  width: 69px;
  height: 24px;
  min-width: 69px;
  min-height: 24px;
  line-height: 24px;
  font-size: 12px;
  font-family: PingFangSC-Regular;
  color: #6d6d6d;
  border: 1px solid #DFC45A;
  background: white;
  border-radius: 2px;
}

.dashboard_todo .title .newEvent:hover {
  box-shadow: 0px 1px 2px 0px rgba(168, 147, 64, 0.6);
}

.dashboard_todo .card {
  border: 1px solid #DFC45A;
  height: 61px;
  border-radius: 4px;
  margin-bottom: 6px;
  font-size: 12px;
  color: #434343;
  padding: 6px 6px 16px 6px;
  box-sizing: border-box;
  position: relative;
}

.dashboard_todo .card:hover {
  box-shadow: 0px 1px 2px 0px rgba(168, 147, 64, 0.6);
}

.dashboard_todo .card:hover .cardBtn {
  display: block;
}

.dashboard_todo .card textarea {
  word-wrap: break-word;
  resize: none;
  width: 100%;
  height: 100%;
  border: none;
  display: none;
}

.dashboard_todo .card .displayDiv {
  word-wrap: break-word;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  font-size: 14px;
}

.dashboard_todo .card .line {
  color: #838c91;
  font-style: italic;
  text-decoration: line-through;
}

.dashboard_todo .card .time {
  font-size: 12px;
  color: #838c91;
}

.dashboard_todo .card .time md-icon {
  font-size: 12px;
  min-width: 12px;
  height: 12px;
  width: 12px;
  min-height: 12px;
  color: #999;
  margin: 0 3px 0 0;
}

.dashboard_todo .card .time input {
  font-size: 12px;
  color: #838c91;
  border: none;
  height: 14px;
}

.dashboard_todo .card .time input::-webkit-inner-spin-button {
  display: none;
}

.dashboard_todo .card .time input::-webkit-clear-button {
  display: none;
}

.dashboard_todo .card .time input::-webkit-calendar-picker-indicator {
  margin-left: -10px;
}

.dashboard_todo .card .cardBtn {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  background: linear-gradient(-149.4deg, #f1e493, #e9cc5c);
  border-radius: 0px 4px 0px 0px;
  display: none;
}

.dashboard_todo .card .cardBtn .md-button {
  margin: 0;
  padding: 0;
  width: 40px;
  height: 30px;
  min-width: 40px;
  min-height: 30px;
}

.dashboard_todo .card .cardBtn .md-button md-icon {
  width: 40px;
  height: 30px;
  line-height: 30px;
  margin-top: -8px;
  color: #fff;
  font-size: 21px;
}

.dashboard_todo .card .blue {
  background: linear-gradient(-149.4deg, #96c7ee, #439adf);
}

.dashboard_todo .card .green {
  background: linear-gradient(-149.4deg, #9eddb6, #5db37e);
}

.dashboard_todo ul[dnd-list] .dndDraggingSource {
  display: none;
}

.dashboard_todo ul[dnd-list] .dndPlaceholder {
  background-color: #ddd;
  display: block;
  min-height: 61px;
}

.dataset-selected {
  background: rgba(158, 158, 158, 0.2);
}


#evaluation_advisory_report .tab {
  height: 34px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
}

#evaluation_advisory_report .tab > .md-button {
  margin: 0;
  width: 176px;
  color: #4A4A4A;
}

#evaluation_advisory_report .tab > .md-button.btnActive {
  color: #FFFFFF;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

#evaluation_advisory_report .selectYear {
  margin-top: 15px;
  margin-left: 15px;
  color: #006DD5;
}

#evaluation_advisory_report .selectYear .md-select-value {
  border-bottom: 0;
  color: #006DD5;
}

#evaluation_advisory_report .selectYear .md-select-value .md-select-icon {
  color: #006DD5;
}

#evaluation_advisory_report md-slider {
  margin-right: 20px;
}

#evaluation_advisory_report .monthArr {
  color: #6D6D6D;
  font-size: 10px;
}

#evaluation_advisory_report .active {
  color: #1589EE;
}

#evaluation_advisory_report .consultant_list md-list-item.consultant-selected {
  background: white;
  box-shadow: -3px 1px 3px 0px rgba(0, 0, 0, 0.2);
}

#evaluation_advisory_report .consultant_list md-list-item .md-button:hover {
  background: rgba(255, 255, 255, 0.2);
}

#evaluation_advisory_report .consultant_list .advisoryReport_content {
  background: white;
  box-shadow: -1px 1px 3px 0px rgba(0, 0, 0, 0.2);
}

#evaluation_advisory_report .consultant_list .tip {
  width: 40px;
  height: 17px;
  display: block;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
}

#evaluation_advisory_report_content {
  font-size: 14px;
  color: #22201F;
}

#evaluation_advisory_report_content table.md-table thead.md-head td.md-cell {
  font-family: PingFangSC-Medium;
  color: #006DD5;
}

#evaluation_advisory_report_content table.md-table tbody.md-body td.md-cell {
  font-family: PingFangSC-Regular;
}

#evaluation_advisory_report_content table.md-table tr.md-row {
  height: 36px;
  border-left: 1px rgba(0, 0, 0, 0.12) solid;
}

#evaluation_advisory_report_content table.md-table td.md-cell {
  border-top: 1px rgba(0, 0, 0, 0.12) solid;
  border-right: 1px rgba(0, 0, 0, 0.12) solid;
  border-bottom: 1px rgba(0, 0, 0, 0.12) solid;
}

#evaluation_advisory_report_content table.md-table td.first_td {
  width: 192px;
}

#evaluation_advisory_report_content table.md-table td.second_td {
  padding: 5px 20px;
}

#evaluation_advisory_report_content .send_btn {
  margin-top: 37px;
  margin-bottom: 40px;
}

#evaluation {
  padding-top: 14px;
}

#evaluation .side_nav {
  border-top: 1px solid #dcdcdc;
}

#evaluation .side_nav md-list-item {
  border: 1px solid #dcdcdc;
  border-top: none;
  position: relative;
  height: 36px;
  min-height: 36px;
}

#evaluation .side_nav md-list-item.active {
  border-left: 5px solid #006dd5;
  background: #eee;
}

#evaluation .side_nav md-list-item::before {
  min-height: 36px;
}

#evaluation .side_nav md-list-item .md-button {
  height: 36px;
  min-height: 36px;
}

#evaluation_metaAnalysis .tab {
  height: 34px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
}

#evaluation_metaAnalysis .tab > .md-button {
  margin: 0;
  width: 176px;
  color: #4A4A4A;
}

#evaluation_metaAnalysis .tab > .md-button.btnActive {
  color: #FFFFFF;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

#evaluation_metaAnalysis .time md-select {
  height: 33px;
  font-size: 14px;
  font-family: PingFangSC-Medium;
  text-align: center;
  margin-top: 6px;
  margin-bottom: 12px;
  background-color: #f5f5f5;
  color: #000;
  transition: background-color 100ms linear;
}

#evaluation_metaAnalysis .time md-select .md-select-value {
  border: none;
}

#evaluation_metaAnalysis .time md-select .md-select-value .md-select-icon {
  color: #757575;
}

#evaluation_metaAnalysis .time md-select:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

#evaluation_metaAnalysis .time .md-button {
  padding: 0;
  margin: 0;
  background: #f5f5f5;
  min-width: 60px;
  min-height: 33px;
  height: 33px;
  margin-left: 15px;
  margin-top: -6px;
  line-height: 33px;
}

#evaluation_metaAnalysis .time .md-button:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

#evaluation_metaAnalysis .time md-select:nth-of-type(odd) {
  width: 128px;
}

#evaluation_metaAnalysis .time md-select:nth-of-type(even) {
  width: 96px;
  margin-left: 6px;
}

#evaluation_metaAnalysis .time .to {
  font-size: 14px;
  margin-left: 23px;
  margin-right: 23px;
  margin-top: -5px;
  color: #4A4A4A;
  font-family: PingFangSC-Medium;
}

#evaluation_metaAnalysis .buttonType {
  margin-right: 32px;
}

#evaluation_metaAnalysis .buttonType .md-button {
  width: 108px;
  height: 32px;
  background: #D8DDE6;
  border-radius: 4px;
  margin: 0;
  margin-left: 12px;
  color: #FFFFFF;
  min-height: 32px;
  padding: 0;
  line-height: 32px;
}

#evaluation_metaAnalysis .buttonType .active {
  background: #439adf;
  color: white;
}

#evaluation_metaAnalysis .consultant_table table {
  margin-top: 0px;
}

#evaluation_metaAnalysis .consultant_table table {
  border-left: 1px solid #dadada;
}

#evaluation_metaAnalysis .consultant_table table th {
  border-top: 1px solid #dadada;
  height: 46px;
  position: relative;
  min-width: 50px;
  color: #757575;
  background: #eee;
}

#evaluation_metaAnalysis .consultant_table table td div.cell {
  min-width: 150px;
  min-height: 28px;
  line-height: 28px;
  text-align: left;
  padding: 4px;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

#evaluation_metaAnalysis .consultant_table table td .cell_normal {
  min-height: 28px;
  line-height: 14px;
  text-align: left;
  padding: 4px;
}

#evaluation_metaAnalysis .consultant_table table th, #evaluation_metaAnalysis .consultant_table table td {
  border-bottom: 1px solid #dadada;
  border-right: 1px solid #dadada;
  text-align: center;
  font-size: 14px;
}

.ratingForm {
  font-family: Multiple;
  padding-right: 20px;
}

.ratingForm p {
  padding: 1px 0px;
}

.ratingForm .table .main .row_h {
  padding: 10px 0px;
  padding-top: 30px;
}

.ratingForm .table .main .row_h p {
  text-align: center;
}

.ratingForm .table .main .row {
  padding: 10px 0px;
}

.ratingForm .select_container:hover {
  background: #f0f1f1;
}

.ratingForm md-input-container:not(.md-input-invalid).md-input-focused .md-input {
  border-color: #a2c9ef;
}

.radio_btn:hover .md-off {
  border-color: rgba(3, 169, 244, 0.87);
  transition: none;
}

.slider .md-slider-content .md-track, .slider .md-slider-content .md-track.md-track-fill {
  background-color: #d0d7e6;
}

.slider .md-slider-content .md-thumb-container .md-thumb::after {
  border-width: 4px;
  border-color: #439adf;
  background-color: #fff;
}

.summary .tab {
  height: 34px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
}

.summary .tab > .md-button {
  margin: 0;
  width: 176px;
  color: #4A4A4A;
}

.summary .tab > .md-button.btnActive {
  color: #FFFFFF;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

.summary .selectYear {
  margin-top: 15px;
  margin-left: 15px;
  color: #006DD5;
}

.summary .selectYear .md-select-value {
  border-bottom: 0;
  color: #006DD5;
}

.summary .selectYear .md-select-value .md-select-icon {
  color: #006DD5;
}

.summary md-slider {
  margin-right: 20px;
}

.summary .monthArr {
  color: #6D6D6D;
  font-size: 10px;
}

.summary .active {
  color: #1589EE;
}

.summary .consultant_table table {
  margin-top: 0px;
}

.summary .consultant_table table {
  border-left: 1px solid #dadada;
}

.summary .consultant_table table th {
  border-top: 1px solid #dadada;
  height: 46px;
  position: relative;
  min-width: 50px;
  background: #eee;
}

.summary .consultant_table table td div.cell {
  min-height: 28px;
  line-height: 28px;
  text-align: left;
  padding: 4px;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.summary .consultant_table table td .cell_normal {
  min-height: 28px;
  line-height: 14px;
  text-align: left;
  padding: 4px;
}

.summary .consultant_table table th, .summary .consultant_table table td {
  border-bottom: 1px solid #dadada;
  border-right: 1px solid #dadada;
  text-align: center;
  font-size: 14px;
}

#f1_visa {
  margin: 10px;
  border: 1px #DCDCDC solid;
  overflow-y: auto;
  height: 100%;
}

#f1_visa .title {
  font-size: 20px;
  font-family: Helvetica-Bold;
  color: #439adf;
  height: 46px;
  border-bottom: 1px solid #DCDCDC;
  border-top: 1px solid #DCDCDC;
  padding-left: 15px;
  line-height: 46px;
}

#f1_visa .col {
  color: #000;
  border-bottom: 1px #DCDCDC solid;
}

#f1_visa .col .col_p {
  background-color: #EEEEEE;
  border-left: 1px solid #DCDCDC;
  border-right: 1px solid #DCDCDC;
  height: 36px;
  padding-left: 15px;
  transition: all 100ms;
  -webkit-transition: all 50ms;
  cursor: pointer;
}

#f1_visa .col .col_p:hover {
  background: -webkit-linear-gradient(left, #EEEEEE, #ccc);
}

#f1_visa .col input {
  border: none;
  height: 36px;
  text-indent: 17px;
}

#f1_visa .col input:focus {
  outline: 2px solid #0091ea;
}

#f1_visa .col select {
  border: none;
  height: 36px;
  background: #FFFFFF;
}

#f1_visa .col .tableDiv {
  border-top: 1px solid #DCDCDC;
  border-bottom: 1px solid #DCDCDC;
}

#f1_visa .col .tableDiv:nth-last-of-type(1) {
  border-bottom: none;
}

#f1_visa .col .grade .tableDiv {
  height: 100%;
}

#f1_visa .col .grade .tableDiv p, #f1_visa .col .grade .tableDiv input {
  height: 100%;
}

#f1_visa .col .bb .tableDiv:nth-last-of-type(2) {
  border-bottom: none;
}

#f1_visa .col .mt {
  margin-top: 10px;
}

#f1_visa .col .mt:nth-of-type(1) {
  margin-top: 0;
}

#f1_visa .showdesc {
  position: absolute;
  top: -30px;
  left: 300px;
  z-index: 1001;
  color: #333;
  background: #fff;
  padding: 15px;
  width: 300px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

#f1_visa .description {
  color: #9b9b9b;
  padding: 15px;
  border-left: 1px solid #DCDCDC;
  border-right: 1px solid #DCDCDC;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
}

#f1_visa .invent {
  position: absolute;
  top: 10px;
  right: 30px;
}

#table_content {
  margin: 10px;
  border: 1px #DCDCDC solid;
  overflow-y: auto;
  position: relative;
}

#table_content .title {
  font-size: 20px;
  font-family: Helvetica-Bold;
  color: #439adf;
  height: 46px;
  border-bottom: 1px solid #DCDCDC;
  border-top: 1px solid #DCDCDC;
  padding-left: 15px;
  line-height: 46px;
}

#table_content .col {
  color: #000;
  border-bottom: 1px #DCDCDC solid;
}

#table_content .col .col_p {
  background-color: #EEEEEE;
  border-left: 1px solid #DCDCDC;
  border-right: 1px solid #DCDCDC;
  height: 36px;
  padding-left: 15px;
  transition: all 100ms;
  -webkit-transition: all 50ms;
  cursor: pointer;
}

#table_content .col .col_p:hover {
  background: -webkit-linear-gradient(left, #EEEEEE, #ccc);
}

#table_content .col input {
  border: none;
  height: 36px;
  text-indent: 17px;
}

#table_content .col input:focus {
  outline: 2px solid #0091ea;
}

#table_content .col select {
  border: none;
  height: 36px;
  background: #FFFFFF;
}

#table_content .col .tableDiv {
  border-top: 1px solid #DCDCDC;
  border-bottom: 1px solid #DCDCDC;
}

#table_content .col .tableDiv:nth-last-of-type(1) {
  border-bottom: none;
}

#table_content .col .grade .tableDiv {
  height: 100%;
}

#table_content .col .grade .tableDiv p, #table_content .col .grade .tableDiv input {
  height: 100%;
}

#table_content .col .bb .tableDiv:nth-last-of-type(2) {
  border-bottom: none;
}

#table_content .col .mt {
  margin-top: 10px;
}

#table_content .col .mt:nth-of-type(1) {
  margin-top: 0;
}

#table_content .edited {
  border: 1px solid red !important;
}

#table_content input {
  cursor: pointer;
}

#table_content select {
  cursor: pointer;
}

#table_content select[readonly] option {
  display: none;
}

#table_content .multi {
  height: 36px;
  line-height: 36px;
  cursor: pointer;
  padding-left: 15px;
}

.grid-file-over {
  border: dotted 3px green;
}

.file-upload-progress {
  border-radius: 3px;
  position: absolute;
  bottom: 48px;
  width: 100%;
}

.file-upload-progress .md-bar2 {
  background: #23b7e5;
}

.file-icon {
  font-size: 48px;
  height: 48px;
  width: 48px;
}

.file-icon.ready {
  opacity: 0.1;
}

.file-footer {
  overflow: inherit;
}

.file-footer > figcaption {
  width: 100%;
}

.file-footer > figcaption .file-name {
  width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.file-footer > figcaption .file-actions {
  right: 0px;
  bottom: 0px;
}

.file-footer > figcaption .file-actions .md-fab {
  background-color: #c8c8c8;
}

.file-footer > figcaption .file-upload-actions .md-icon-button {
  width: 36px;
  height: 36px;
  padding: 6px;
  margin: 0px;
}

.file-upload-zone {
  border: 3px dashed #dee5e7;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.file-upload-zone input[type="file"] {
  height: inherit;
  width: inherit;
  box-sizing: inherit;
  opacity: 0;
  cursor: pointer;
}


.first-start p {
  line-height: 24px;
}

.first-start .icon_list {
  width: 90%;
  margin-left: 9%;
  margin-bottom: 11px;
}

.first-start .icon_list p {
  color: #6b261d;
}

.ForgotPassword-input {
  margin-bottom: 0;
}

.md-errors-spacer {
  display: none;
}

md-input-container {
  margin: 12px 0px;
}

md-input-container .md-input[disabled], md-select[disabled] .md-select-value {
  color: black;
}

.high-school-add {
  padding: 10px;
  position: relative;
}

.high-school-add .tab {
  position: fixed;
  left: 0;
  width: 88px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
  margin: 10px 10px 16px;
}

.high-school-add .tab > a {
  position: relative;
  margin: 0;
  color: #4A4A4A;
  width: 80px;
  padding: 4px;
  height: 3.9vh;
  line-height: 3.9vh;
  border-bottom: 1px #DCDCDC solid;
  cursor: pointer;
}

.high-school-add .tab > a span {
  width: 80px;
  text-align: center;
}

.high-school-add .tab > a.btnActive {
  color: #FFFFFF;
  background: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

.high-school-add .tab > a.btnActive div {
  color: #4A4A4A;
}

.high-school-add .redColor {
  border: 1px solid red !important;
}

.high-school-add .opt-col {
  border-left: 1px solid #DCDCDC;
}

.high-school-add .table-row {
  border-top: 1px solid #DCDCDC;
}

.high-school-add .status_btn {
  margin-left: 10px;
}

.high-school-add .status_btn .md-button {
  margin: 0;
  min-width: 84px;
  height: 28px;
  min-height: 28px;
  line-height: 28px;
  border-radius: 4px;
  font-size: 12px;
}

.high-school-add .status_btn .dea {
  border: 1px solid #9b9b9b;
  color: #9b9b9b;
}

.high-school-add .status_btn .dea:hover {
  background: #9b9b9b;
  color: #fff;
}

.high-school-add .status_btn .act {
  border: 1px solid #7ED321;
  color: #7ED321;
}

.high-school-add .status_btn .act:hover {
  background: #7ED321;
  color: #fff;
}

.high-school-add .score_table_fix {
  overflow-y: auto;
  height: 100%;
  border: 1px solid #DCDCDC;
  box-sizing: border-box;
}

.high-school-add .score_table_fix textarea {
  width: 100%;
  border: none;
}

.high-school-add .score_table_fix textarea:focus {
  outline: 2px solid #0091ea;
}

.high-school-add .score_table_fix table.md-table {
  overflow-y: auto;
}

.high-school-add .score_table_fix table.md-table thead.md-head > tr.md-row {
  height: 36px;
}

.high-school-add .score_table_fix table.md-table thead.md-head > tr.md-row th.md-column {
  padding: 0px 17px;
  background-color: #EEEEEE;
  text-indent: 0px;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  text-align: center;
  font-size: 16px;
}

.high-school-add .score_table_fix table.md-table tbody.md-body tr.md-row {
  position: relative;
  height: 36px;
}

.high-school-add .score_table_fix table.md-table tbody.md-body tr.md-row td.md-cell {
  position: relative;
  padding: 0 0 0 17px;
  text-indent: 0px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 2px solid #fff;
}

.high-school-add .toggle_tab {
  margin-bottom: 10px;
  min-height: 36px;
  margin-right: 30px;
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
}

.high-school-add .toggle_tab > .md-button {
  margin: 0;
  width: 176px;
  color: #4A4A4A;
}

.high-school-add .toggle_tab > .md-button.active {
  color: #FFFFFF;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

.high-school-add .program_list_content {
  overflow-y: auto;
  border-top: 1px solid #DCDCDC;
  margin: 10px 0 10px 99px;
  height: 600px;
}

.high-school-add .program_list_content .title {
  font-size: 20px;
  font-family: Helvetica-Bold;
  color: #439adf;
  height: 46px;
  border-bottom: 1px solid #DCDCDC;
  border-top: 1px solid #DCDCDC;
  padding-left: 15px;
  line-height: 46px;
}

.high-school-add .program_list_content .panelDiv {
  width: 500px;
  position: absolute;
  top: 20%;
  left: 50%;
  z-index: 1;
  margin-left: -250px;
  background: #fff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.high-school-add .program_list_content .panelDiv .ap-input-btn {
  background: #439ADF;
  width: 27px;
  height: 27px;
  border-radius: 3px;
  padding: 0px;
  min-width: 27px;
  min-height: 27px;
  line-height: 27px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  text-indent: 0px;
}

.high-school-add .program_list_content .panelDiv .ap-input-btn md-icon {
  color: #fff;
  font-size: 21px;
}

.high-school-add .program_list_content .panelDiv .add-btn {
  margin-left: 0;
}

.high-school-add .program_list_content .panelDiv .toolbar {
  height: 32px;
  min-height: 24px;
  border-bottom: 1px solid #DCDCDC;
}

.high-school-add .program_list_content .panelDiv md-toolbar {
  background: #fff;
}

.high-school-add .program_list_content .panelDiv .inputx {
  padding: 20px 10px;
  font-size: 20px;
}

.high-school-add .program_list_content .panelDiv .inputx .checkboxdemoSelectAll {
  padding-left: 30px;
}

.high-school-add .program_list_content .panelDiv .inputx .uploadList {
  padding-left: 40px;
}

.high-school-add .program_list_content .panelDiv .buttondiv {
  border-top: 1px solid #DCDCDC;
}

.high-school-add .program_list_content .panelDiv table th {
  font-weight: bold;
}

.high-school-add .program_list_content .panelDiv table tr {
  border-bottom: 1px solid #DCDCDC;
}

.high-school-add .program_list_content .panelDiv table th,
.high-school-add .program_list_content .panelDiv table td {
  padding: 0;
  padding: 5px 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.high-school-add .program_list_content .panelDiv table .chexiao {
  cursor: pointer;
}

.high-school-add .table-btn {
  background: #439ADF;
  width: 27px;
  height: 27px;
  border-radius: 3px;
  padding: 0;
  min-width: 27px;
  min-height: 27px;
  line-height: 27px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.high-school-add .table-btn:not([disabled]).md-focused, .high-school-add .table-btn:not([disabled]):hover {
  background-color: #439ADF;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.high-school-add .table-btn > md-icon {
  color: #fff;
  font-size: 21px;
}

.high-school-add .opt-col {
  border-right: 1px solid #DCDCDC;
  border-bottom: 1px solid #DCDCDC;
}

.high-school-add .material_list_content .title {
  font-size: 20px;
  color: #439adf;
  padding: 3px 0;
}

.high-school-add .appli_edit_list {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -112px;
}

.high-school-add .appli_edit_list .md-button {
  margin: 0;
  min-width: 30px;
  line-height: 18px;
  background: #fff;
  font-size: 14px;
  color: #fff;
  background-color: rgba(67, 154, 223, 0.8);
  font-family: PingFangSC-Regular;
  height: 224px;
  width: 30px;
  white-space: normal;
  z-index: 2;
  border-radius: 16px 0 0 16px;
}

.high-school-add .appli_edit_list .md-button md-icon {
  color: #fff;
  margin: 0;
}

.high-school-add .editListDiv {
  position: absolute;
  height: 100%;
  width: 90%;
  right: -90%;
  background: #fff;
  z-index: 1;
  transition: all 0.3s;
  border-left: 1px #DCDCDC solid;
  overflow-y: auto;
}

.high-school-add .editListDiv .title {
  font-family: Helvetica-Bold;
  color: #439adf;
  height: 46px;
  line-height: 46px;
  border-bottom: 1px solid #DCDCDC;
  border-top: 1px solid #DCDCDC;
  padding-left: 15px;
}

.high-school-add .editListDiv .title h1 {
  font-size: 20px;
}

.high-school-add .editListDiv .loadMore {
  color: #439adf;
  cursor: pointer;
}

.high-school-add .editListDiv .page_list {
  margin-bottom: 20px;
}

.high-school-add .editListDiv .page_list .md-button {
  padding: 0px;
  margin: 0px;
  color: #428bca;
  border: 1px solid #dee5e7;
  border-left: none;
  background: white;
  width: 30px;
  height: 30px;
  min-width: 0px;
  min-height: 0px;
  line-height: 28px;
}

.high-school-add .editListDiv .page_list .md-button span {
  font-size: 10px;
  font-weight: bold;
}

.high-school-add .editListDiv .page_list .md-button:first-child {
  border-left: 1px solid #dee5e7;
}

.high-school-add .editListDiv .page_list .md-button.active {
  background: #428bca;
  color: white;
}

.high-school-add .editListDiv .page_list .md-button:hover {
  background: #dee5e7;
}

.high-school-add .editListDiv .page_list .md-button.active:hover {
  background: #428bca;
}

.high-school-add .editListDiv table th {
  font-weight: bold;
}

.high-school-add .editListDiv table tr {
  height: 50px !important;
  border-bottom: 1px solid #DCDCDC;
}

.high-school-add .editListDiv table th,
.high-school-add .editListDiv table td {
  padding: 0;
  padding-left: 10px;
  height: 50px !important;
  line-height: 50px;
}

.high-school-add .editListDiv table .chexiao {
  cursor: pointer;
}

.high-school-add .editListDiv table .active {
  background: #428bca;
  color: white;
}

.high-school-add .editListDiv table .show {
  position: relative;
}

.high-school-add .warning {
  min-width: 40px;
  margin: 0;
  color: red;
}

.high-school-add .loading {
  display: flex;
  min-height: 150px;
  justify-content: center;
  align-items: center;
}

.high-school-add .tag {
  font-family: "Monospaced Number","Chinese Quote",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-variant: tabular-nums;
  box-sizing: border-box;
  margin: auto 8px;
  padding: 0 7px;
  list-style: none;
  display: inline-block;
  line-height: 22px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fafafa;
  font-size: 12px;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 1;
  cursor: pointer;
  white-space: nowrap;
}

.high-school-add .tag .anticon {
  display: inline-block;
  font-style: normal;
  vertical-align: -.125em;
  text-align: center;
  text-transform: none;
  line-height: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.high-school-add .tag .close {
  display: inline-block;
  line-height: 20px;
  font-size: 12px;
  font-size: 10px\9;
  transform: scale(0.83333333) rotate(0);
  cursor: pointer;
  margin-left: 3px;
  transition: all .3s;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
}

.high-school-add .subject_box {
  border-right: 1px #DCDCDC solid;
  border-bottom: 1px #DCDCDC solid;
}

.high-school-add .subject_box .ap-input-label {
  flex: 1 1 100%;
  max-width: 20%;
  min-width: 20%;
  background-color: #EEEEEE;
  border-left: 1px solid #DCDCDC;
  border-right: 1px solid #DCDCDC;
  min-height: 39px;
  padding-left: 15px;
  text-indent: 0px;
  transition: all 100ms;
  -webkit-transition: all 50ms;
  cursor: pointer;
  box-sizing: border-box;
}

.high-school-add .subject_box .ap-input-label:hover {
  background: -webkit-linear-gradient(left, #EEEEEE, #ccc);
}

.high-school-add .subject_box .ap-input {
  flex: 1 1 100%;
  border: none;
  min-height: 100%;
  text-indent: 8px;
  box-sizing: border-box;
  max-width: 100%;
  padding: 10px;
}

.newPromptPanel {
  background: white;
  padding: 10px;
  width: 1000px;
  height: 300px;
  margin-top: -150px;
  margin-left: -500px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 2px #ccc;
}

.newPromptPanel .title {
  padding: 5px 10px;
  color: #439adf;
  font-size: 20px;
}

.newPromptPanel .toggle_tab {
  margin-bottom: 10px;
  min-height: 36px;
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
}

.newPromptPanel .toggle_tab > .md-button {
  margin: 0;
  color: #4A4A4A;
}

.newPromptPanel .toggle_tab > .md-button.active {
  color: #FFFFFF;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

.newPromptPanel .can_switch {
  height: 30px;
}

.newPromptPanel .can_switch .md-button {
  margin: 0;
  min-width: 84px;
  height: 24px;
  min-height: 24px;
  line-height: 24px;
  background: #fff;
  font-size: 14px;
  border: 1px solid #439ADF;
  color: #439ADF;
}

.newPromptPanel .can_switch .md-button:nth-of-type(1) {
  border-radius: 3px 0px 0px 3px;
}

.newPromptPanel .can_switch .md-button:nth-of-type(2) {
  border-radius: 0px 3px 3px 0px;
  border-left: none;
}

.newPromptPanel .can_switch .btnActive {
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  color: #fff;
}

.newPromptPanel .tag {
  font-family: "Monospaced Number","Chinese Quote",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-variant: tabular-nums;
  box-sizing: border-box;
  margin: 5px;
  text-indent: 3px;
  padding: 0 7px;
  list-style: none;
  display: inline-block;
  line-height: 22px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fafafa;
  font-size: 12px;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 1;
  cursor: pointer;
  white-space: nowrap;
}

.newPromptPanel .tag .anticon {
  display: inline-block;
  font-style: normal;
  vertical-align: -.125em;
  text-align: center;
  text-transform: none;
  line-height: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.newPromptPanel .tag .close {
  display: inline-block;
  line-height: 20px;
  font-size: 12px;
  font-size: 10px\9;
  transform: scale(0.83333333) rotate(0);
  cursor: pointer;
  margin-left: 3px;
  transition: all .3s;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
}

.high-school-change {
  padding: 10px;
  position: relative;
}

.high-school-change .tab {
  position: fixed;
  left: 0;
  width: 88px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
  margin: 10px 10px 16px;
}

.high-school-change .tab > a {
  position: relative;
  margin: 0;
  color: #4A4A4A;
  width: 80px;
  padding: 4px;
  height: 3.9vh;
  line-height: 3.9vh;
  border-bottom: 1px #DCDCDC solid;
  cursor: pointer;
}

.high-school-change .tab > a span {
  width: 80px;
  text-align: center;
}

.high-school-change .tab > a.btnActive {
  color: #FFFFFF;
  background: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

.high-school-change .tab > a.btnActive div {
  color: #4A4A4A;
}

.high-school-change .redColor {
  border: 1px solid red !important;
}

.high-school-change .opt-col {
  border-left: 1px solid #DCDCDC;
}

.high-school-change .table-row {
  border-top: 1px solid #DCDCDC;
}

.high-school-change .status_btn {
  margin-left: 10px;
}

.high-school-change .status_btn .md-button {
  margin: 0;
  min-width: 84px;
  height: 28px;
  min-height: 28px;
  line-height: 28px;
  border-radius: 4px;
  font-size: 12px;
}

.high-school-change .status_btn .dea {
  border: 1px solid #9b9b9b;
  color: #9b9b9b;
}

.high-school-change .status_btn .dea:hover {
  background: #9b9b9b;
  color: #fff;
}

.high-school-change .status_btn .act {
  border: 1px solid #7ED321;
  color: #7ED321;
}

.high-school-change .status_btn .act:hover {
  background: #7ED321;
  color: #fff;
}

.high-school-change .score_table_fix {
  overflow-y: auto;
  height: 100%;
  border: 1px solid #DCDCDC;
  box-sizing: border-box;
}

.high-school-change .score_table_fix textarea {
  width: 100%;
  border: none;
}

.high-school-change .score_table_fix textarea:focus {
  outline: 2px solid #0091ea;
}

.high-school-change .score_table_fix table.md-table {
  overflow-y: auto;
}

.high-school-change .score_table_fix table.md-table thead.md-head > tr.md-row {
  height: 36px;
}

.high-school-change .score_table_fix table.md-table thead.md-head > tr.md-row th.md-column {
  padding: 0px 17px;
  background-color: #EEEEEE;
  text-indent: 0px;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  text-align: center;
  font-size: 16px;
}

.high-school-change .score_table_fix table.md-table tbody.md-body tr.md-row {
  position: relative;
  height: 36px;
}

.high-school-change .score_table_fix table.md-table tbody.md-body tr.md-row td.md-cell {
  position: relative;
  padding: 0 0 0 17px;
  text-indent: 0px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 2px solid #fff;
}

.high-school-change .toggle_tab {
  margin-bottom: 10px;
  min-height: 36px;
  margin-right: 30px;
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
}

.high-school-change .toggle_tab > .md-button {
  margin: 0;
  width: 176px;
  color: #4A4A4A;
}

.high-school-change .toggle_tab > .md-button.active {
  color: #FFFFFF;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

.high-school-change .program_list_content {
  overflow-y: auto;
  border-top: 1px solid #DCDCDC;
  margin: 10px 0 10px 99px;
  height: 600px;
}

.high-school-change .program_list_content .title {
  font-size: 20px;
  font-family: Helvetica-Bold;
  color: #439adf;
  height: 46px;
  border-bottom: 1px solid #DCDCDC;
  border-top: 1px solid #DCDCDC;
  padding-left: 15px;
  line-height: 46px;
}

.high-school-change .program_list_content .panelDiv {
  width: 500px;
  position: absolute;
  top: 20%;
  left: 50%;
  z-index: 1;
  margin-left: -250px;
  background: #fff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.high-school-change .program_list_content .panelDiv .ap-input-btn {
  background: #439ADF;
  width: 27px;
  height: 27px;
  border-radius: 3px;
  padding: 0px;
  min-width: 27px;
  min-height: 27px;
  line-height: 27px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  text-indent: 0px;
}

.high-school-change .program_list_content .panelDiv .ap-input-btn md-icon {
  color: #fff;
  font-size: 21px;
}

.high-school-change .program_list_content .panelDiv .add-btn {
  margin-left: 0;
}

.high-school-change .program_list_content .panelDiv .toolbar {
  height: 32px;
  min-height: 24px;
  border-bottom: 1px solid #DCDCDC;
}

.high-school-change .program_list_content .panelDiv md-toolbar {
  background: #fff;
}

.high-school-change .program_list_content .panelDiv .inputx {
  padding: 20px 10px;
  font-size: 20px;
}

.high-school-change .program_list_content .panelDiv .inputx .checkboxdemoSelectAll {
  padding-left: 30px;
}

.high-school-change .program_list_content .panelDiv .inputx .uploadList {
  padding-left: 40px;
}

.high-school-change .program_list_content .panelDiv .buttondiv {
  border-top: 1px solid #DCDCDC;
}

.high-school-change .program_list_content .panelDiv table th {
  font-weight: bold;
}

.high-school-change .program_list_content .panelDiv table tr {
  border-bottom: 1px solid #DCDCDC;
}

.high-school-change .program_list_content .panelDiv table th,
.high-school-change .program_list_content .panelDiv table td {
  padding: 0;
  padding: 5px 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.high-school-change .program_list_content .panelDiv table .chexiao {
  cursor: pointer;
}

.high-school-change .table-btn {
  background: #439ADF;
  width: 27px;
  height: 27px;
  border-radius: 3px;
  padding: 0;
  min-width: 27px;
  min-height: 27px;
  line-height: 27px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.high-school-change .table-btn:not([disabled]).md-focused, .high-school-change .table-btn:not([disabled]):hover {
  background-color: #439ADF;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.high-school-change .table-btn > md-icon {
  color: #fff;
  font-size: 21px;
}

.high-school-change .opt-col {
  border-right: 1px solid #DCDCDC;
  border-bottom: 1px solid #DCDCDC;
}

.high-school-change .material_list_content .title {
  font-size: 20px;
  color: #439adf;
  padding: 3px 0;
}

.high-school-change .appli_edit_list {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -112px;
}

.high-school-change .appli_edit_list .md-button {
  margin: 0;
  min-width: 30px;
  line-height: 18px;
  background: #fff;
  font-size: 14px;
  color: #fff;
  background-color: rgba(67, 154, 223, 0.8);
  font-family: PingFangSC-Regular;
  height: 224px;
  width: 30px;
  white-space: normal;
  z-index: 2;
  border-radius: 16px 0 0 16px;
}

.high-school-change .appli_edit_list .md-button md-icon {
  color: #fff;
  margin: 0;
}

.high-school-change .editListDiv {
  position: absolute;
  height: 100%;
  width: 90%;
  right: -90%;
  background: #fff;
  z-index: 1;
  transition: all 0.3s;
  border-left: 1px #DCDCDC solid;
  overflow-y: auto;
}

.high-school-change .editListDiv .title {
  font-family: Helvetica-Bold;
  color: #439adf;
  height: 46px;
  line-height: 46px;
  border-bottom: 1px solid #DCDCDC;
  border-top: 1px solid #DCDCDC;
  padding-left: 15px;
}

.high-school-change .editListDiv .title h1 {
  font-size: 20px;
}

.high-school-change .editListDiv .loadMore {
  color: #439adf;
  cursor: pointer;
}

.high-school-change .editListDiv .page_list {
  margin-bottom: 20px;
}

.high-school-change .editListDiv .page_list .md-button {
  padding: 0px;
  margin: 0px;
  color: #428bca;
  border: 1px solid #dee5e7;
  border-left: none;
  background: white;
  width: 30px;
  height: 30px;
  min-width: 0px;
  min-height: 0px;
  line-height: 28px;
}

.high-school-change .editListDiv .page_list .md-button span {
  font-size: 10px;
  font-weight: bold;
}

.high-school-change .editListDiv .page_list .md-button:first-child {
  border-left: 1px solid #dee5e7;
}

.high-school-change .editListDiv .page_list .md-button.active {
  background: #428bca;
  color: white;
}

.high-school-change .editListDiv .page_list .md-button:hover {
  background: #dee5e7;
}

.high-school-change .editListDiv .page_list .md-button.active:hover {
  background: #428bca;
}

.high-school-change .editListDiv a.downloadLink {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
  color: #0091ea;
  cursor: pointer;
}

.high-school-change .editListDiv a.downloadLink:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: initial;
  text-decoration-color: initial;
}

.high-school-change .editListDiv table th {
  font-weight: bold;
}

.high-school-change .editListDiv table tr {
  height: 50px !important;
  border-bottom: 1px solid #DCDCDC;
}

.high-school-change .editListDiv table th,
.high-school-change .editListDiv table td {
  padding: 0;
  padding-left: 10px;
  height: 50px !important;
  line-height: 50px;
}

.high-school-change .editListDiv table .chexiao {
  cursor: pointer;
}

.high-school-change .editListDiv table .active {
  background: #428bca;
  color: white;
}

.high-school-change .editListDiv table .show {
  position: relative;
}

.high-school-change .warning {
  min-width: 40px;
  margin: 0;
  color: red;
}

.high-school-change .loading {
  display: flex;
  min-height: 150px;
  justify-content: center;
  align-items: center;
}

.high-school-change .tag {
  font-family: "Monospaced Number","Chinese Quote",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-variant: tabular-nums;
  box-sizing: border-box;
  margin: auto 8px;
  padding: 0 7px;
  list-style: none;
  display: inline-block;
  line-height: 22px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fafafa;
  font-size: 12px;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 1;
  cursor: pointer;
  white-space: nowrap;
}

.high-school-change .tag .anticon {
  display: inline-block;
  font-style: normal;
  vertical-align: -.125em;
  text-align: center;
  text-transform: none;
  line-height: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.high-school-change .tag .close {
  display: inline-block;
  line-height: 20px;
  font-size: 12px;
  font-size: 10px\9;
  transform: scale(0.83333333) rotate(0);
  cursor: pointer;
  margin-left: 3px;
  transition: all .3s;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
}

.high-school-change .subject_box {
  border-right: 1px #DCDCDC solid;
  border-bottom: 1px #DCDCDC solid;
}

.high-school-change .subject_box .ap-input-label {
  flex: 1 1 100%;
  max-width: 20%;
  min-width: 20%;
  background-color: #EEEEEE;
  border-left: 1px solid #DCDCDC;
  border-right: 1px solid #DCDCDC;
  min-height: 39px;
  padding-left: 15px;
  text-indent: 0px;
  transition: all 100ms;
  -webkit-transition: all 50ms;
  cursor: pointer;
  box-sizing: border-box;
}

.high-school-change .subject_box .ap-input-label:hover {
  background: -webkit-linear-gradient(left, #EEEEEE, #ccc);
}

.high-school-change .subject_box .ap-input {
  flex: 1 1 100%;
  border: none;
  min-height: 100%;
  text-indent: 8px;
  box-sizing: border-box;
  max-width: 100%;
  padding: 10px;
}

.newPromptPanel {
  background: white;
  padding: 10px;
  width: 1000px;
  height: 300px;
  margin-top: -150px;
  margin-left: -500px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 2px #ccc;
}

.newPromptPanel .title {
  padding: 5px 10px;
  color: #439adf;
  font-size: 20px;
}

.newPromptPanel .toggle_tab {
  margin-bottom: 10px;
  min-height: 36px;
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
}

.newPromptPanel .toggle_tab > .md-button {
  margin: 0;
  color: #4A4A4A;
}

.newPromptPanel .toggle_tab > .md-button.active {
  color: #FFFFFF;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

.newPromptPanel .can_switch {
  height: 30px;
}

.newPromptPanel .can_switch .md-button {
  margin: 0;
  min-width: 84px;
  height: 24px;
  min-height: 24px;
  line-height: 24px;
  background: #fff;
  font-size: 14px;
  border: 1px solid #439ADF;
  color: #439ADF;
}

.newPromptPanel .can_switch .md-button:nth-of-type(1) {
  border-radius: 3px 0px 0px 3px;
}

.newPromptPanel .can_switch .md-button:nth-of-type(2) {
  border-radius: 0px 3px 3px 0px;
  border-left: none;
}

.newPromptPanel .can_switch .btnActive {
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  color: #fff;
}

.newPromptPanel .tag {
  font-family: "Monospaced Number","Chinese Quote",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-variant: tabular-nums;
  box-sizing: border-box;
  margin: 5px;
  text-indent: 3px;
  padding: 0 7px;
  list-style: none;
  display: inline-block;
  line-height: 22px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fafafa;
  font-size: 12px;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 1;
  cursor: pointer;
  white-space: nowrap;
}

.newPromptPanel .tag .anticon {
  display: inline-block;
  font-style: normal;
  vertical-align: -.125em;
  text-align: center;
  text-transform: none;
  line-height: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.newPromptPanel .tag .close {
  display: inline-block;
  line-height: 20px;
  font-size: 12px;
  font-size: 10px\9;
  transform: scale(0.83333333) rotate(0);
  cursor: pointer;
  margin-left: 3px;
  transition: all .3s;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
}

#high-school-library {
  padding: 20px;
}

#high-school-library .status_btn {
  margin-bottom: 10px;
}

#high-school-library .status_btn .md-button {
  margin: 0;
  min-width: 84px;
  height: 28px;
  min-height: 28px;
  line-height: 28px;
  border-radius: 4px;
  font-size: 12px;
}

#high-school-library .status_btn .dea {
  border: 1px solid #9b9b9b;
  color: #9b9b9b;
}

#high-school-library .status_btn .dea:hover {
  background: #9b9b9b;
  color: #fff;
}

#high-school-library .status_btn .act {
  border: 1px solid #7ED321;
  color: #7ED321;
}

#high-school-library .status_btn .act:hover {
  background: #7ED321;
  color: #fff;
}

#high-school-library .mid {
  padding-bottom: 16px;
}

#high-school-library .mid .select_title {
  color: #439ADF;
  padding-bottom: 8px;
  font-size: 18px;
}

#high-school-library .mid .search_box {
  padding-right: 16px;
  position: relative;
}

#high-school-library .mid .search_box md-autocomplete {
  background: none;
  height: 32px;
}

#high-school-library .mid .search_box md-autocomplete md-autocomplete-wrap {
  height: 32px;
  box-shadow: none;
  border: 1px solid #9B9B9B;
}

#high-school-library .mid .search_box md-autocomplete md-autocomplete-wrap input {
  padding: 0 10px;
  height: 32px;
  line-height: 32px;
}

#high-school-library .mid .add_btn {
  margin: 0;
  min-width: 78px;
  height: 32px;
  min-height: 32px;
  line-height: 32px;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  color: white;
  box-shadow: 0px 1px 2px 0px rgba(67, 154, 223, 0.6);
}

#high-school-library .mid .add_btn:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

#high-school-library .mid .add_btn md-icon {
  font-size: 18px;
  height: 20px;
  width: 20px;
  min-width: 20px;
  min-height: 20px;
  color: #fff;
}

#high-school-library .mid .add_paper {
  background-image: linear-gradient(45deg, #5EE1BF 0, #439ADF 100%);
  padding: 0 2px;
  margin: 0px;
  color: white;
}

#high-school-library .mid .paperIcon {
  color: white;
  font-size: 18px;
  margin-top: 4px;
  margin-right: -4px;
}

#high-school-library .add-btn {
  width: 24px;
  text-align: center;
  height: 22px;
  border-radius: 3px;
  padding: 0px;
  min-width: 24px;
  min-height: 22px;
  line-height: 22px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  text-indent: 0px;
}

#high-school-library .add-btn md-icon {
  color: #439ADF;
  font-size: 21px;
}

#high-school-library .page_list, #high-school-library .edit_list {
  padding-bottom: 0;
  padding-top: 10px;
}

#high-school-library .page_list .md-button, #high-school-library .edit_list .md-button {
  padding: 0px;
  margin: 0px;
  color: #428bca;
  border: 1px solid #dee5e7;
  border-left: none;
  background: white;
  width: 30px;
  height: 30px;
  min-width: 0px;
  min-height: 0px;
  line-height: 28px;
}

#high-school-library .page_list .md-button span, #high-school-library .edit_list .md-button span {
  font-size: 10px;
  font-weight: bold;
}

#high-school-library .page_list .md-button:first-child, #high-school-library .page_list .md-button:nth-of-type(3), #high-school-library .edit_list .md-button:first-child, #high-school-library .edit_list .md-button:nth-of-type(3) {
  border-left: 1px solid #dee5e7;
}

#high-school-library .page_list .md-button.active, #high-school-library .edit_list .md-button.active {
  background: #428bca;
  color: white;
}

#high-school-library .page_list .md-button:hover, #high-school-library .edit_list .md-button:hover {
  background: #dee5e7;
}

#high-school-library .page_list .md-button.active:hover, #high-school-library .edit_list .md-button.active:hover {
  background: #428bca;
}

#high-school-library .scroll_btn {
  position: absolute;
  z-index: 50;
  padding: 0px;
  margin: 0px;
  width: 20px;
  height: 40px;
  background: #7986CB;
  border-radius: 0;
}

#high-school-library .scroll_btn md-icon {
  color: #fff;
}

#high-school-library .scroll_btn_left {
  right: 0px;
  top: 0px;
}

#high-school-library .scroll_btn_right {
  right: 0px;
  top: 0px;
}

#high-school-library .table_container {
  padding-right: 10px;
  height: 100%;
  overflow: auto;
}

#high-school-library .table_container table {
  overflow: inherit;
}

#high-school-library .table_container table thead th {
  padding: 10px 3px;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background: #7986CB;
  z-index: 10;
  white-space: nowrap;
}

#high-school-library .table_container table thead th:first-child > div,
#high-school-library .table_container table thead th:nth-child(2) > div {
  padding-left: 10px !important;
}

#high-school-library .table_container table tbody tr:first-child td {
  padding-top: 0px;
}

#high-school-library .table_container table tbody tr {
  cursor: pointer;
  border: 1px solid #9B9B9B;
}

#high-school-library .table_container table tbody tr td .box {
  border: none;
}

#high-school-library .table_container table tbody tr:hover {
  border: 1px solid #9B9B9B;
  box-sizing: border-box;
  background: rgba(238, 238, 238, 0.97);
}

#high-school-library .table_container table tbody tr:hover td .box {
  border: none;
}

#high-school-library .table_container table tbody td {
  padding: 3px 0px;
}

#high-school-library .table_container table tbody td .box {
  padding: 0px 8px;
  padding-left: 23px;
  min-height: 43px;
  border-bottom: 1px solid #9B9B9B;
  border-top: 1px solid #9B9B9B;
  position: relative;
}

#high-school-library .table_container table tbody td .box div.md-whiteframe-5dp {
  white-space: pre-wrap;
}

#high-school-library .table_container table tbody td .box a {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
  color: #0091ea;
}

#high-school-library .table_container table tbody td .box a:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: initial;
  text-decoration-color: initial;
}

#high-school-library .table_container table tbody td:first-child .box {
  padding-left: 8px;
  font-weight: bold;
}

#high-school-library .table_container table tbody td:nth-child(2) .box {
  padding-left: 8px;
}

#high-school-library .table_container table tbody td:nth-child(3) .box {
  padding-left: 8px;
}

#high-school-library .table_container table tbody td:nth-child(4) {
  max-width: 380px;
}

#high-school-library .table_container table tbody td:nth-child(5) {
  max-width: 380px;
}

#high-school-library .add_school {
  background-image: linear-gradient(45deg, #5EE1BF 0, #439ADF 100%);
  padding: 0 2px;
  margin: 0px;
  color: white;
}

#high-school-library .add_school:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

#high-school-library .shoolIcon {
  color: white;
  font-size: 18px;
  margin-top: 4px;
  margin-right: -4px;
}

#high-school-library .question_title {
  position: absolute;
  right: 0;
  top: 50%;
  width: 25px;
  z-index: 101;
}

#high-school-library .question_title .md-button {
  margin: 0;
  min-width: 25px;
  line-height: 18px;
  background: #fff;
  font-size: 14px;
  color: #fff;
  background-color: rgba(67, 154, 223, 0.8);
  font-family: PingFangSC-Regular;
  height: 224px;
  width: 30px;
  white-space: normal;
  z-index: 2;
  border-radius: 16px 0 0 16px;
}

#high-school-library .question_title .md-button md-icon {
  color: #fff;
  margin: 0;
}

#high-school-library .list_mask {
  position: absolute;
  width: 98%;
  top: 18%;
  height: 82%;
  z-index: 100;
}

#high-school-library .app_list {
  position: relative;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  width: 90%;
  height: 90%;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

#high-school-library .app_list .application_container {
  overflow: auto;
  height: 85%;
  overflow: auto;
}

#high-school-library .app_list .application_container table {
  overflow: inherit;
}

#high-school-library .app_list .application_container table thead th {
  padding: 10px 3px;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background: #7986CB;
  z-index: 10;
  white-space: nowrap;
}

#high-school-library .app_list .application_container table thead th:first-child > div,
#high-school-library .app_list .application_container table thead th:nth-child(2) > div {
  padding-left: 10px !important;
}

#high-school-library .app_list .application_container table tbody tr:first-child td {
  padding-top: 0px;
}

#high-school-library .app_list .application_container table tbody td {
  padding: 3px 0px;
  border-bottom: 1px solid #9B9B9B;
  border-top: 1px solid #9B9B9B;
}

#high-school-library .app_list .application_container table tbody td .box {
  padding: 0px 8px;
  padding-left: 23px;
  min-height: 43px;
  position: relative;
}

#high-school-library .app_list .application_container table tbody td .box .loadMore {
  color: #439adf;
  cursor: pointer;
}

#high-school-library .app_list .application_container table tbody td .box a.downloadLink {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
  color: #0091ea;
  cursor: pointer;
}

#high-school-library .app_list .application_container table tbody td .box a.downloadLink:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: initial;
  text-decoration-color: initial;
}

#high-school-library .app_list .application_container table tbody td:first-child {
  border-left: 1px solid #9B9B9B;
}

#high-school-library .app_list .application_container table tbody td:first-child .box {
  padding-left: 12px;
  font-weight: bold;
}

#high-school-library .app_list .application_container table tbody td:nth-child(2) .box {
  padding-left: 8px;
  font-weight: bold;
}

#high-school-library .app_list .application_container table tbody td:nth-child(3) .box {
  padding-left: 8px;
  font-weight: bold;
  text-align: center;
}

#high-school-library .app_list .application_container table tbody td:nth-last-child(1) {
  border-right: 2px solid #9B9B9B;
}

#high-school-library .app_list .application_container table tbody td .goDetail a {
  cursor: pointer;
  color: #72ACE3;
}

#high-school-library .app_list .application_container table tbody tr td .action span {
  cursor: pointer;
}

#high-school-library .app_list .closebtn {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #000;
}

#high-school-library .app_list .chexiao {
  margin-right: 5px;
}

#high-school-library .app_list .action {
  padding-left: 10px !important;
}

#high-school-library .app_list .edit_list {
  position: absolute;
  bottom: 5px;
  left: 50%;
  margin-left: -140px;
}

#high-school-library .downloadAttr {
  margin-left: 5px;
  color: blue;
  cursor: pointer;
}

.loadMoreForm .subject_box {
  border-right: 1px #DCDCDC solid;
  border-bottom: 1px #DCDCDC solid;
}

.loadMoreForm .subject_box .ap-input-label {
  flex: 1 1 100%;
  max-width: 20%;
  background-color: #EEEEEE;
  border-left: 1px solid #DCDCDC;
  border-right: 1px solid #DCDCDC;
  min-height: 36px;
  padding-left: 15px;
  text-indent: 0px;
  transition: all 100ms;
  -webkit-transition: all 50ms;
  cursor: pointer;
  box-sizing: border-box;
  line-height: 36px;
}

.loadMoreForm .subject_box .ap-input-label:hover {
  background: -webkit-linear-gradient(left, #EEEEEE, #ccc);
}

.loadMoreForm .subject_box .ap-input {
  flex: 1 1 100%;
  border: none;
  min-height: 36px;
  text-indent: 17px;
  box-sizing: border-box;
  max-width: 100%;
}

.loadMoreForm .subject_box .ap-input:focus {
  outline: 2px solid #0091ea;
}


#intelligent_school input {
  border-bottom: none;
}

#intelligent_school md-grid-list {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

#intelligent_school md-grid-list md-grid-tile {
  border-left: 1px solid #ccc;
  text-align: center;
}

#intelligent_school md-grid-list md-grid-tile figure {
  padding: 0px;
  text-align: center;
}

#intelligent_school md-grid-list md-grid-tile figure p {
  width: 100%;
}

.difficulty-match {
  background-color: #ebdc61;
}

.difficulty-safety {
  background-color: #93eb93;
}

.difficulty-reach {
  background-color: #e34b4b;
}

button.filter_button {
  background-color: white;
  color: #007eb9;
  border: 1px solid #007eb9;
  min-height: 30px;
  min-width: 0px;
  line-height: 30px;
  height: 30px;
  padding: 0px 20px;
  border-radius: 2px;
}

button.filter_button p {
  margin-top: -4px;
}

button.filter_button span {
  margin-top: -4px;
}

button.filter_button md-icon {
  color: #cecbcb;
}

button.filter_button:hover, button.filter_button.active {
  background-color: #fe8800 !important;
  color: white;
  border: 1px solid #fe8800;
}

button.filter_button.active p md-icon:last-child {
  color: #cecbcb;
}

button.filter_button.active p md-icon:first-child {
  color: white;
}

button.filter_button.active p.bottom md-icon:last-child {
  color: white;
}

button.filter_button.active p.bottom md-icon:first-child {
  color: #cecbcb;
}

.filter_btn {
  line-height: 30px;
  cursor: pointer;
  margin-right: 20px;
}

.filter_btn.active {
  color: #fe8800;
}

.filter_btn:hover {
  color: #fe8800;
}

#interview .top .tab {
  height: 34px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
  margin: 10px 10px 16px;
}

#interview .top .tab > .md-button {
  margin: 0;
  width: 176px;
  color: #4A4A4A;
}

#interview .top .tab > .md-button.active {
  color: #FFFFFF;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

#interview .md-button {
  margin: 0;
  padding: 0;
}

#interview .add {
  position: relative;
  left: -18px;
  height: 44px;
  width: 2px;
  background: #dcdcdc;
  top: 20px;
}

#interview .add .md-button {
  top: -31px;
  position: absolute;
  left: -19px;
  min-width: 40px;
}

#interview .add .md-button md-icon {
  font-size: 32px;
  color: #439ADF;
  position: relative;
  left: -4px;
  top: -4px;
}

#interview .video_info {
  max-width: 545px;
  height: 284px;
  border-radius: 3px;
  border: 2px solid #439adf;
  position: relative;
  margin-bottom: 20px;
}

#interview .video_info .bor_div {
  height: 100%;
  position: absolute;
  top: 0px;
  border: 1px solid #DCDCDC;
  left: -20px;
}

#interview .video_info .bor_div md-icon {
  color: #439ADF;
  top: -5px;
  position: absolute;
  font-size: 20px;
  left: -10px;
}

#interview .video_info .bor_div .gray {
  color: #9B9B9B;
}

#interview .video_info .bor_bottom {
  height: 100px;
  position: absolute;
  border: 1px solid #DCDCDC;
  left: -20px;
  bottom: -100px;
}

#interview .video_info .title {
  padding: 0 8px 0 19px;
  height: 38px;
  color: #4a4a4a;
  border-bottom: 1px solid #439adf;
}

#interview .video_info .title h2 {
  font-size: 20px;
  font-family: Helvetica-Bold;
}

#interview .video_info .title span {
  font-size: 14px;
  font-family: PingFangSC-Regular;
}

#interview .video_info .title span b {
  font-size: 20px;
}

#interview .video_info .title span:last-of-type {
  margin-left: 12px;
}

#interview .video_info .content {
  padding: 0 19px;
}

#interview .video_info .content > div {
  margin-top: 10px;
}

#interview .video_info .content h3 {
  font-size: 14px;
  font-family: Helvetica;
  color: #439adf;
  min-width: 110px;
  text-align: left;
}

#interview .video_info .content md-input-container {
  margin: 0;
  padding: 0;
}

#interview .video_info .content md-input-container md-select {
  width: 96px;
  height: 28px;
  border-radius: 3px;
  border: 1px solid gainsboro;
}

#interview .video_info .content md-input-container md-select md-select-value {
  border: none;
}

#interview .video_info .content md-input-container.md-input-has-value .md-select-value > span:not(.md-select-icon) {
  text-align: center;
  height: 100%;
}

#interview .video_info .content .md-select-value > span:not(.md-select-icon) .md-text {
  font-size: 14px;
}

#interview .video_info .content .invite, #interview .video_info .content .confirm {
  min-width: 58px;
  height: 30px;
  background: linear-gradient(-135deg, #78caf1, #439adf);
  border-radius: 4px;
  min-height: 30px;
  line-height: 30px;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  color: white;
  margin-left: 5px;
  padding: 0 5px;
}

#interview .video_info .content .online {
  border-radius: 3px 0px 0px 3px;
  border: 1px solid #439adf;
  color: #4a4a4a;
  font-size: 14px;
  background: #fff;
}

#interview .video_info .content .onsite {
  border-radius: 0px 3px 3px 0px;
  border: 1px solid #439adf;
  color: #4a4a4a;
  font-size: 14px;
  border-left: 0;
  margin-left: 0;
  background: #fff;
}

#interview .video_info .content .onActive {
  background: linear-gradient(-135deg, #78caf1, #439adf);
  color: #fff;
}

#interview .video_info .content .ondisActive {
  background: linear-gradient(-120deg, #a5a5a5, #696868);
  color: #fff;
}

#interview .video_info .content .disableClass {
  background: linear-gradient(-120deg, #a5a5a5, #696868);
}

#interview .video_info .content .dateInput {
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  height: 30px;
  text-indent: 5px;
  cursor: pointer;
}

#interview .video_info .content md-divider {
  margin-top: 10px;
  border-color: #439adf;
}

#interview .video_info .content .operation {
  width: 30px;
  height: 30px;
  background: linear-gradient(-135deg, #78caf1, #439adf);
  border-radius: 4px;
  min-height: 30px;
  min-width: 30px;
  color: #fff;
  line-height: 30px;
  margin-right: 5px;
}

#interview .video_info .content .operation md-icon {
  color: #fff;
}

#interview .video_info .content textarea {
  border-radius: 3px;
  text-indent: 5px;
  border: none;
  background: #ecf4fb;
  min-height: 40px;
}

#interview .video_info .content .file-upload {
  position: relative;
  margin-right: 5px;
}

#interview .video_info .content .file-upload input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 200px;
  height: 30px;
  cursor: pointer;
}

#interview .video_info .content .file-upload .choose {
  width: 200px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  padding: 0 10px;
}

#interview .video_info .content .file-upload .choose span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-family: Helvetica;
  color: #439adf;
}

#interview .video_player_box {
  height: 100%;
  padding: 10px;
  margin-left: 20px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  border: 1px solid #439adf;
  position: relative;
}

#interview .video_player_box .add_comment {
  height: 30px;
  background: linear-gradient(-135deg, #78caf1, #439adf);
  border-radius: 4px;
  min-height: 30px;
  color: #fff;
  line-height: 30px;
  padding: 0 5px;
}

#interview .video_player_box .md-icon-button md-icon {
  min-width: 32px;
  min-height: 32px;
  width: 32px;
  height: 32px;
  font-size: 32px;
  transform: rotate(45deg);
  color: #439ADF;
}

#interview .form {
  padding-left: 10px;
  padding-top: 10px;
  position: relative;
  right: -1000px;
  transition: right 0.3s;
  -webkit-transition: right 0.3s;
}

#interview .form .tab {
  height: 34px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
}

#interview .form .tab p {
  font-family: Helvetica-Bold;
  color: #6d6d6d;
  text-align: center;
  height: 34px;
  line-height: 34px;
  font-size: 16px;
  cursor: pointer;
}

#interview .form .tab p.btnActive {
  font-family: PingFangSC-Medium;
  color: white;
  background: linear-gradient(-180deg, #78caf1, #439adf);
}

#interview .form .container {
  border-radius: 0px 3px 3px 3px;
  border: 1px solid gainsboro;
  margin-top: 10px;
}

#interview .form .container .question, #interview .form .container .formA, #interview .form .container .formB {
  padding: 30px;
  padding-top: 14px;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

#interview .form .container .border_red {
  border-color: #EF6C00 !important;
}

#interview .form .container .border_red span {
  color: #EF6C00 !important;
}

#interview .form .container .border_red md-icon {
  color: #EF6C00 !important;
}

#interview .form .container .formB {
  padding: 0;
  position: relative;
  overflow: hidden;
}

#interview .form .container .formB .md-icon-button {
  margin-top: -10px;
  margin-right: -10px;
}

#interview .form .container .formB .md-icon-button md-icon {
  min-width: 32px;
  min-height: 32px;
  width: 32px;
  height: 32px;
  font-size: 32px;
  transform: rotate(45deg);
  color: #439ADF;
}

#interview .form .container p {
  font-size: 16px;
  font-family: PingFangSC-Medium;
  color: #4a4a4a;
  line-height: 22px;
  margin-top: 16px;
  padding: 5px 0;
}

#interview .form .container input {
  border: none;
  border-bottom: 1px solid #dcdcdc;
  width: 100%;
}

#interview .form .container .submit {
  min-width: 74px;
  height: 40px;
  background: #439ADF;
  border-radius: 4px;
  min-height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  color: white;
  margin-top: 10px;
}

#interview .form .container .bor {
  border: 1px solid #439ADF;
  border-radius: 3px;
  padding: 10px;
  margin-top: 16px;
}

#interview .form .container .bor span {
  color: #439ADF;
  margin: 5px 0;
}

#interview .form .container .bor .inputClass {
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  height: 28px;
  text-indent: 5px;
}

#interview .ivDiv {
  padding-left: 10px;
}

#interview .ivDiv .note .md-icon-button {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  padding: 0;
  background: white;
  border-radius: 3px;
  border: 2px solid #439adf;
  margin: 0 6px;
}

#interview .ivDiv .note .md-icon-button md-icon {
  color: #439adf;
  font-size: 20px;
  margin-left: -2px;
}

#interview .ivDiv .note .simiditor_note .simditor {
  height: 100%;
}

#interview .ivDiv .note .simiditor_note .simditor .simditor-wrapper {
  height: 100%;
}

#interview .ivDiv .note .simiditor_note .simditor .simditor-wrapper .simditor-toolbar {
  position: absolute;
  z-index: 2;
  background: white;
  left: 0px !important;
}

#interview .ivDiv .note .simiditor_note .simditor .simditor-wrapper .simditor-body {
  padding-top: 80px !important;
  height: 65%;
  overflow: auto;
  max-height: 110px;
}

#interview .ivDiv .note .simiditor_note .simditor .simditor-toolbar > ul li:nth-of-type(7), #interview .ivDiv .note .simiditor_note .simditor .simditor-toolbar > ul li:nth-of-type(13), #interview .ivDiv .note .simiditor_note .simditor .simditor-toolbar > ul li:nth-of-type(15), #interview .ivDiv .note .simiditor_note .simditor .simditor-toolbar > ul li:nth-of-type(17), #interview .ivDiv .note .simiditor_note .simditor .simditor-toolbar > ul li:nth-of-type(18), #interview .ivDiv .note .simiditor_note .simditor .simditor-toolbar > ul li:nth-of-type(19), #interview .ivDiv .note .simiditor_note .simditor .simditor-toolbar > ul li:nth-of-type(20), #interview .ivDiv .note .simiditor_note .simditor .simditor-toolbar > ul li:nth-of-type(21), #interview .ivDiv .note .simiditor_note .simditor .simditor-toolbar > ul li:nth-of-type(22) {
  display: none;
}

#interview .ivDiv .note .simiditor_note .simditor .simditor-wrapper .simditor-placeholder {
  display: none !important;
}

#interview .ivDiv .note .simiditor_note .simditor .simditor-toolbar {
  width: 100% !important;
}

#interview .ivDiv .note .simiditor_note .simditor .simditor-body {
  min-height: 100px;
  padding: 10px;
  height: 100%;
  overflow-y: auto;
}

#interview .ivDiv .note .simiditor_note .simditor .simditor-body ol > li {
  list-style: decimal;
  list-style-position: inside;
}

#interview .ivDiv .note .simiditor_note .simditor .simditor-body ul > li {
  list-style: disc;
  list-style-position: inside;
}

#interview .ivDiv .note .simiditor_note .simditor .simditor-toolbar .toolbar-menu ul > li .menu-item {
  line-height: 1.5em;
}

#interview .ivDiv .note .simiditor_note .simditor .simditor-toolbar > ul > li > .toolbar-item {
  width: 45px;
}

#interview .ivDiv .note .simiditor_note .simditor .simditor-toolbar > ul > li > span.separator {
  margin: 0;
}

#interview .ivDiv .note .simiditor_note .simditor .simditor-body h1, #interview .ivDiv .note .simiditor_note .simditor .simditor-body h2, #interview .ivDiv .note .simiditor_note .simditor .simditor-body h3, #interview .ivDiv .note .simiditor_note .simditor .simditor-body h4, #interview .ivDiv .note .simiditor_note .simditor .simditor-body h5, #interview .ivDiv .note .simiditor_note .simditor .simditor-body h6, #interview .ivDiv .note .simiditor_note .editor-style h1, #interview .ivDiv .note .simiditor_note .editor-style h2, #interview .ivDiv .note .simiditor_note .editor-style h3, #interview .ivDiv .note .simiditor_note .editor-style h4, #interview .ivDiv .note .simiditor_note .editor-style h5, #interview .ivDiv .note .simiditor_note .editor-style h6 {
  margin: 0;
}

#interview .ivDiv .note .simiditor_note .simditor .simditor-body p, #interview .ivDiv .note .simiditor_note .simditor .simditor-body div, #interview .ivDiv .note .simiditor_note .editor-style p, #interview .ivDiv .note .simiditor_note .editor-style div {
  margin: 0;
}

#interview .ivDiv .note .simiditor_note .simditor .simditor-body ul, #interview .ivDiv .note .simiditor_note .simditor .simditor-body ol, #interview .ivDiv .note .simiditor_note .editor-style ul, #interview .ivDiv .note .simiditor_note .editor-style ol {
  margin: 0;
  padding: 0;
}

#interview .ivDiv .note .simiditor_note .simditor .simditor-body blockquote, #interview .ivDiv .note .simiditor_note .editor-style blockquote {
  margin: 0;
}

#interview .ivDiv .note .simiditor_note .simditor .simditor-body code, #interview .ivDiv .note .simiditor_note .editor-style code {
  margin: 0;
}

#interview .ivDiv .note .simiditor_note .simditor .simditor-body pre, #interview .ivDiv .note .simiditor_note .editor-style pre {
  margin: 0;
}

#interview .ivDiv .note .simiditor_note .simditor .simditor-body hr, #interview .ivDiv .note .simiditor_note .editor-style hr {
  margin: 0;
}

#interview .ivDiv .note .simiditor_note .simditor .simditor-body table, #interview .ivDiv .note .simiditor_note .editor-style table {
  margin: 0;
}

#interview .ivDiv .interv_info {
  margin-left: 10px;
  min-height: 190px;
  padding: 5px;
  width: 100%;
  border: 2px solid #439adf;
}

#interview .ivDiv .interv_info .website {
  margin-left: 20px;
}

#interview .ivDiv .interv_info .website a {
  color: blue;
}

#interview .ivDiv .interv_info .md-icon-button {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  padding: 0;
  background: white;
  border-radius: 3px;
  border: 2px solid #439adf;
  margin: 0 6px;
}

#interview .ivDiv .interv_info .type_date {
  line-height: 32px;
  margin-top: 5px;
}

#interview .ivDiv .interv_info .type_date span {
  margin-left: 15px;
}

#interview .ivDiv .interv_info .type_date .actpwd md-input-container {
  margin: 0 8px;
}

#interview .ivDiv .interv_info .online {
  border-radius: 3px 0px 0px 3px;
  border: 1px solid #439adf;
  color: #4a4a4a;
  font-size: 14px;
  background: #fff;
}

#interview .ivDiv .interv_info .onsite {
  border-radius: 0px 3px 3px 0px;
  border: 1px solid #439adf;
  color: #4a4a4a;
  font-size: 14px;
  border-left: 0;
  margin-left: 0;
  background: #fff;
}

#interview .ivDiv .interv_info .onActive {
  background: linear-gradient(-135deg, #78caf1, #439adf);
  color: #fff;
}

#interview .ivDiv .interv_info .dateInput {
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  height: 30px;
  text-indent: 5px;
  width: 130px;
}

#interview .ivDiv .interv_info .confirm {
  width: 65px;
  min-width: 50px;
  height: 32px;
  min-height: 32px;
  line-height: 32px;
}

#interview .ivDiv .interv_info .account {
  padding-left: 15px;
  margin-top: 5px;
}

#interview .ivDiv .interv_info .account md-input-container {
  width: 24%;
  margin-right: 1%;
}

#interview .ivDiv .star {
  box-sizing: border-box;
  width: 96%;
  height: 96%;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  font-weight: bold;
  color: #fff;
  border: 2px solid #fff;
  background-color: rgba(0, 0, 0, 0.7);
  line-height: 100%;
  text-align: center;
}

#interview .ivDiv .star img {
  height: 24px;
  width: 24px;
  margin-top: 12px;
}

#interview .ivDiv .star_img {
  height: 24px;
  width: 24px;
}

#interview .table_container {
  overflow: auto;
}

#interview .table_container table {
  overflow: inherit;
}

#interview .table_container table thead th {
  padding: 10px 3px;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background: #7986CB;
  z-index: 10;
  white-space: nowrap;
}

#interview .table_container table thead th:first-child > div, #interview .table_container table thead th:nth-child(2) > div {
  padding-left: 8px !important;
}

#interview .table_container table thead th:first-child > div {
  text-align: left;
}

#interview .table_container table thead th:nth-child(3) > div {
  text-align: center;
}

#interview .table_container table thead tr th:nth-child(1) {
  width: 105px;
  max-width: 105px;
  min-width: 105px;
  left: 0px;
  z-index: 12;
}

#interview .table_container table thead tr th:nth-child(2) {
  left: 111px;
  min-width: 80px;
  max-width: 80px;
  z-index: 12;
}

#interview .table_container table thead tr th:nth-child(3) {
  left: 197px;
  min-width: 80px;
  max-width: 80px;
  z-index: 12;
}

#interview .table_container table thead tr th:nth-child(4) {
  left: 377px;
  min-width: 100px;
  max-width: 100px;
  z-index: 12;
}

#interview .table_container table thead tr th:nth-child(5) {
  left: 483px;
  min-width: 100px;
  z-index: 12;
}

#interview .table_container table thead tr th:nth-last-child(1) div {
  margin-right: 20px;
}

#interview .table_container table tbody tr:first-child td {
  padding-top: 0px;
}

#interview .table_container table tbody tr td:nth-child(1), #interview .table_container table tbody tr td:nth-child(2), #interview .table_container table tbody tr td:nth-child(3), #interview .table_container table tbody tr td:nth-child(4), #interview .table_container table tbody tr td:nth-child(5) {
  font-size: 14px;
  color: #4a4a4a;
  position: sticky;
  position: -webkit-sticky;
  background: #f8f8f8;
}

#interview .table_container table tbody tr td:nth-child(1) {
  font-size: 16px;
  font-family: PingFangSC-Semibold;
  color: #439ADF;
  width: 105px;
  max-width: 105px;
  min-width: 105px;
  left: 0px;
  z-index: 11;
}

#interview .table_container table tbody tr td:nth-child(2) {
  left: 111px;
  min-width: 80px;
  max-width: 80px;
  z-index: 11;
}

#interview .table_container table tbody tr td:nth-child(3) {
  left: 197px;
  min-width: 180px;
  max-width: 180px;
  z-index: 11;
}

#interview .table_container table tbody tr td:nth-child(4) {
  left: 377px;
  min-width: 100px;
  max-width: 100px;
  z-index: 11;
}

#interview .table_container table tbody tr td:nth-child(5) {
  left: 483px;
  min-width: 100px;
  z-index: 11;
}

#interview .table_container table tbody td .box {
  padding: 0px 8px;
  padding-left: 12px;
  height: 56px;
  border-bottom: 1px solid gainsboro;
  position: relative;
  text-align: center;
}

#interview .table_container table tbody td .box .feebackClick {
  width: 60px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

#interview .table_container table tbody td .box .more {
  font-size: 14px;
  max-width: 100%;
  min-height: 16px;
  overflow: hidden;
  /* autoprefixer: off */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* autoprefixer: on */
  width: 100%;
}

#interview .table_container table tbody td .tags p {
  margin-top: 2px;
}

#interview .table_container table tbody td .btn_box {
  margin-top: 0px;
}

#interview .table_container table tbody td .btn_box button {
  padding: 0px 3px;
}

#interview .table_container table tbody td .btn_box button img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

#interview .table_container table tbody td:first-child .box {
  padding-left: 8px;
  font-weight: bold;
}

#interview .table_container table tbody td:nth-child(2) .box {
  padding-left: 8px;
}

#interview .table_container table tbody td:nth-last-child(1) .box {
  border-right: 1px solid #9B9B9B;
}

#interview .table_container table tbody td .manage {
  color: white;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

#interview .toggleBtn {
  background-color: rgba(67, 154, 223, 0.8);
  color: #fff;
  position: absolute;
  bottom: 0;
  right: 0;
}

#interview .toggleBtn md-icon {
  color: #fff;
}

#interview .link {
  width: 100px !important;
  color: blue;
  cursor: pointer;
}

#interview .bottom {
  height: 200px;
}

.layui-laydate .layui-this {
  background-color: #439ADF !important;
}

.layui-laydate-content td.laydate-selected {
  background-color: #78caf1 !important;
}

.interview_feedback .title {
  font-size: 20px;
  font-family: PingFangSC-Semibold;
  color: #439adf;
  text-align: center;
}

.interview_feedback .width {
  width: 50%;
}


div.Login-formContainer {
  margin: 0px;
  padding-top: 80px;
}

.LoginForm-inputContainer {
  margin-top: 10px;
  margin-bottom: 20;
  min-width: 260px;
}

.LoginForm-submit {
  margin-bottom: 40px;
  margin-left: 0;
  width: 100%;
  padding: 5px 0;
}

#major-library {
  padding: 20px;
}

#major-library .status_btn {
  margin-bottom: 10px;
}

#major-library .status_btn .md-button {
  margin: 0;
  min-width: 84px;
  height: 28px;
  min-height: 28px;
  line-height: 28px;
  border-radius: 4px;
  font-size: 12px;
}

#major-library .status_btn .dea {
  border: 1px solid #9b9b9b;
  color: #9b9b9b;
}

#major-library .status_btn .dea:hover {
  background: #9b9b9b;
  color: #fff;
}

#major-library .status_btn .act {
  border: 1px solid #7ED321;
  color: #7ED321;
}

#major-library .status_btn .act:hover {
  background: #7ED321;
  color: #fff;
}

#major-library .mid {
  padding-bottom: 16px;
}

#major-library .mid .select_title {
  color: #439ADF;
  padding-bottom: 8px;
  font-size: 18px;
}

#major-library .mid .search_box {
  padding-right: 16px;
  position: relative;
}

#major-library .mid .search_box md-autocomplete {
  background: none;
  height: 32px;
}

#major-library .mid .search_box md-autocomplete md-autocomplete-wrap {
  height: 32px;
  box-shadow: none;
  border: 1px solid #9B9B9B;
}

#major-library .mid .search_box md-autocomplete md-autocomplete-wrap input {
  padding: 0 10px;
  height: 32px;
  line-height: 32px;
}

#major-library .mid .add_btn {
  margin: 0;
  min-width: 78px;
  height: 32px;
  min-height: 32px;
  line-height: 32px;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  color: white;
  box-shadow: 0px 1px 2px 0px rgba(67, 154, 223, 0.6);
}

#major-library .mid .add_btn:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

#major-library .mid .add_btn md-icon {
  font-size: 18px;
  height: 20px;
  width: 20px;
  min-width: 20px;
  min-height: 20px;
  color: #fff;
}

#major-library .mid .add_paper {
  background-image: linear-gradient(45deg, #5EE1BF 0, #439ADF 100%);
  padding: 0 2px;
  margin: 0px;
  color: white;
}

#major-library .mid .paperIcon {
  color: white;
  font-size: 18px;
  margin-top: 4px;
  margin-right: -4px;
}

#major-library .add-btn {
  width: 24px;
  text-align: center;
  height: 22px;
  border-radius: 3px;
  padding: 0px;
  min-width: 24px;
  min-height: 22px;
  line-height: 22px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  text-indent: 0px;
}

#major-library .add-btn md-icon {
  color: #439ADF;
  font-size: 21px;
}

#major-library .page_list, #major-library .edit_list {
  padding-bottom: 0;
  padding-top: 10px;
}

#major-library .page_list .md-button, #major-library .edit_list .md-button {
  padding: 0px;
  margin: 0px;
  color: #428bca;
  border: 1px solid #dee5e7;
  border-left: none;
  background: white;
  width: 30px;
  height: 30px;
  min-width: 0px;
  min-height: 0px;
  line-height: 28px;
}

#major-library .page_list .md-button span, #major-library .edit_list .md-button span {
  font-size: 10px;
  font-weight: bold;
}

#major-library .page_list .md-button:first-child, #major-library .page_list .md-button:nth-of-type(3), #major-library .edit_list .md-button:first-child, #major-library .edit_list .md-button:nth-of-type(3) {
  border-left: 1px solid #dee5e7;
}

#major-library .page_list .md-button.active, #major-library .edit_list .md-button.active {
  background: #428bca;
  color: white;
}

#major-library .page_list .md-button:hover, #major-library .edit_list .md-button:hover {
  background: #dee5e7;
}

#major-library .page_list .md-button.active:hover, #major-library .edit_list .md-button.active:hover {
  background: #428bca;
}

#major-library .scroll_btn {
  position: absolute;
  z-index: 50;
  padding: 0px;
  margin: 0px;
  width: 20px;
  height: 40px;
  background: #7986CB;
  border-radius: 0;
}

#major-library .scroll_btn md-icon {
  color: #fff;
}

#major-library .scroll_btn_left {
  right: 0px;
  top: 0px;
}

#major-library .scroll_btn_right {
  right: 0px;
  top: 0px;
}

#major-library .table_container {
  padding-right: 10px;
  height: 100%;
  overflow: auto;
}

#major-library .table_container table {
  overflow: inherit;
}

#major-library .table_container table thead th {
  padding: 10px 3px;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background: #7986CB;
  z-index: 10;
  white-space: nowrap;
}

#major-library .table_container table thead th:first-child > div,
#major-library .table_container table thead th:nth-child(2) > div {
  padding-left: 10px !important;
}

#major-library .table_container table tbody tr:first-child td {
  padding-top: 0px;
}

#major-library .table_container table tbody tr {
  cursor: pointer;
  border: 1px solid #9B9B9B;
}

#major-library .table_container table tbody tr td .box {
  border: none;
}

#major-library .table_container table tbody tr td .box div.md-whiteframe-5dp {
  white-space: pre-wrap;
}

#major-library .table_container table tbody tr:hover {
  border: 1px solid #9B9B9B;
  box-sizing: border-box;
  background: rgba(238, 238, 238, 0.97);
}

#major-library .table_container table tbody tr:hover td .box {
  border: none;
}

#major-library .table_container table tbody td {
  padding: 3px 0px;
}

#major-library .table_container table tbody td .box {
  padding: 0px 8px;
  padding-left: 23px;
  min-height: 43px;
  border-bottom: 1px solid #9B9B9B;
  border-top: 1px solid #9B9B9B;
  position: relative;
}

#major-library .table_container table tbody td .box a {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
  color: #0091ea;
}

#major-library .table_container table tbody td .box a:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: initial;
  text-decoration-color: initial;
}

#major-library .table_container table tbody td:first-child .box {
  padding-left: 8px;
  font-weight: bold;
}

#major-library .table_container table tbody td:nth-child(2) .box {
  padding-left: 8px;
}

#major-library .table_container table tbody td:nth-child(3) .box {
  padding-left: 8px;
}

#major-library .table_container table tbody td:nth-child(4) {
  max-width: 380px;
}

#major-library .table_container table tbody td:nth-child(5) {
  max-width: 380px;
}

#major-library .add_school {
  background-image: linear-gradient(45deg, #5EE1BF 0, #439ADF 100%);
  padding: 0 2px;
  margin: 0px;
  color: white;
}

#major-library .add_school:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

#major-library .shoolIcon {
  color: white;
  font-size: 18px;
  margin-top: 4px;
  margin-right: -4px;
}

#major-library .question_title {
  position: absolute;
  right: 0;
  top: 50%;
  width: 25px;
  z-index: 101;
}

#major-library .question_title .md-button {
  margin: 0;
  min-width: 25px;
  line-height: 18px;
  background: #fff;
  font-size: 14px;
  color: #fff;
  background-color: rgba(67, 154, 223, 0.8);
  font-family: PingFangSC-Regular;
  height: 224px;
  width: 30px;
  white-space: normal;
  z-index: 2;
  border-radius: 16px 0 0 16px;
}

#major-library .question_title .md-button md-icon {
  color: #fff;
  margin: 0;
}

#major-library .list_mask {
  position: absolute;
  width: 98%;
  top: 18%;
  height: 82%;
  z-index: 100;
}

#major-library .app_list {
  position: relative;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  width: 90%;
  height: 90%;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

#major-library .app_list .application_container {
  overflow: auto;
  height: 85%;
  overflow: auto;
}

#major-library .app_list .application_container table {
  overflow: inherit;
}

#major-library .app_list .application_container table thead th {
  padding: 10px 3px;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background: #7986CB;
  z-index: 10;
  white-space: nowrap;
}

#major-library .app_list .application_container table thead th:first-child > div,
#major-library .app_list .application_container table thead th:nth-child(2) > div {
  padding-left: 10px !important;
}

#major-library .app_list .application_container table tbody tr:first-child td {
  padding-top: 0px;
}

#major-library .app_list .application_container table tbody td {
  padding: 3px 0px;
  border-bottom: 1px solid #9B9B9B;
  border-top: 1px solid #9B9B9B;
}

#major-library .app_list .application_container table tbody td .box {
  padding: 0px 8px;
  padding-left: 23px;
  min-height: 43px;
  position: relative;
}

#major-library .app_list .application_container table tbody td .box .loadMore {
  color: #439adf;
  cursor: pointer;
}

#major-library .app_list .application_container table tbody td .box a.downloadLink {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
  color: #0091ea;
  cursor: pointer;
}

#major-library .app_list .application_container table tbody td .box a.downloadLink:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: initial;
  text-decoration-color: initial;
}

#major-library .app_list .application_container table tbody td:first-child {
  border-left: 1px solid #9B9B9B;
}

#major-library .app_list .application_container table tbody td:first-child .box {
  padding-left: 12px;
  font-weight: bold;
}

#major-library .app_list .application_container table tbody td:nth-child(2) .box {
  padding-left: 8px;
  font-weight: bold;
}

#major-library .app_list .application_container table tbody td:nth-child(3) .box {
  padding-left: 8px;
  font-weight: bold;
  text-align: center;
}

#major-library .app_list .application_container table tbody td:nth-last-child(1) {
  border-right: 2px solid #9B9B9B;
}

#major-library .app_list .application_container table tbody td .goDetail a {
  cursor: pointer;
  color: #72ACE3;
}

#major-library .app_list .application_container table tbody tr td .action span {
  cursor: pointer;
}

#major-library .app_list .closebtn {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #000;
}

#major-library .app_list .chexiao {
  margin-right: 5px;
}

#major-library .app_list .action {
  padding-left: 10px !important;
}

#major-library .app_list .edit_list {
  position: absolute;
  bottom: 5px;
  left: 50%;
  margin-left: -140px;
}

#major-library .downloadAttr {
  margin-left: 5px;
  color: blue;
  cursor: pointer;
}

.loadMoreForm .subject_box {
  border-right: 1px #DCDCDC solid;
  border-bottom: 1px #DCDCDC solid;
}

.loadMoreForm .subject_box .ap-input-label {
  flex: 1 1 100%;
  max-width: 20%;
  background-color: #EEEEEE;
  border-left: 1px solid #DCDCDC;
  border-right: 1px solid #DCDCDC;
  min-height: 36px;
  padding-left: 15px;
  text-indent: 0px;
  transition: all 100ms;
  -webkit-transition: all 50ms;
  cursor: pointer;
  box-sizing: border-box;
  line-height: 36px;
}

.loadMoreForm .subject_box .ap-input-label:hover {
  background: -webkit-linear-gradient(left, #EEEEEE, #ccc);
}

.loadMoreForm .subject_box .ap-input {
  flex: 1 1 100%;
  border: none;
  min-height: 36px;
  text-indent: 17px;
  box-sizing: border-box;
  max-width: 100%;
}

.loadMoreForm .subject_box .ap-input:focus {
  outline: 2px solid #0091ea;
}

.major-library-add {
  padding: 10px;
  position: relative;
}

.major-library-add .redColor {
  border: 1px solid red !important;
}

.major-library-add .opt-col {
  border-left: 1px solid #DCDCDC;
}

.major-library-add .table-row {
  border-top: 1px solid #DCDCDC;
}

.major-library-add .status_btn {
  margin-left: 10px;
}

.major-library-add .status_btn .md-button {
  margin: 0;
  min-width: 84px;
  height: 28px;
  min-height: 28px;
  line-height: 28px;
  border-radius: 4px;
  font-size: 12px;
}

.major-library-add .status_btn .dea {
  border: 1px solid #9b9b9b;
  color: #9b9b9b;
}

.major-library-add .status_btn .dea:hover {
  background: #9b9b9b;
  color: #fff;
}

.major-library-add .status_btn .act {
  border: 1px solid #7ED321;
  color: #7ED321;
}

.major-library-add .status_btn .act:hover {
  background: #7ED321;
  color: #fff;
}

.major-library-add .score_table_fix {
  overflow-y: auto;
  height: 100%;
  border: 1px solid #DCDCDC;
  box-sizing: border-box;
}

.major-library-add .score_table_fix textarea {
  width: 100%;
  border: none;
}

.major-library-add .score_table_fix textarea:focus {
  outline: 2px solid #0091ea;
}

.major-library-add .score_table_fix table.md-table {
  overflow-y: auto;
}

.major-library-add .score_table_fix table.md-table thead.md-head > tr.md-row {
  height: 36px;
}

.major-library-add .score_table_fix table.md-table thead.md-head > tr.md-row th.md-column {
  padding: 0px 17px;
  background-color: #EEEEEE;
  text-indent: 0px;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  text-align: center;
  font-size: 16px;
}

.major-library-add .score_table_fix table.md-table tbody.md-body tr.md-row {
  position: relative;
  height: 36px;
}

.major-library-add .score_table_fix table.md-table tbody.md-body tr.md-row td.md-cell {
  position: relative;
  padding: 0 0 0 17px;
  text-indent: 0px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 2px solid #fff;
}

.major-library-add .toggle_tab {
  margin-bottom: 10px;
  min-height: 36px;
  margin-right: 30px;
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
}

.major-library-add .toggle_tab > .md-button {
  margin: 0;
  width: 176px;
  color: #4A4A4A;
}

.major-library-add .toggle_tab > .md-button.active {
  color: #FFFFFF;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

.major-library-add .program_list_content {
  overflow-y: auto;
  border-top: 1px solid #DCDCDC;
}

.major-library-add .program_list_content, .major-library-add .prompt_list_content, .major-library-add .material_list_content {
  height: 600px;
  margin-right: 30px;
}

.major-library-add .table-btn {
  background: #439ADF;
  width: 27px;
  height: 27px;
  border-radius: 3px;
  padding: 0;
  min-width: 27px;
  min-height: 27px;
  line-height: 27px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.major-library-add .table-btn:not([disabled]).md-focused, .major-library-add .table-btn:not([disabled]):hover {
  background-color: #439ADF;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.major-library-add .table-btn > md-icon {
  color: #fff;
  font-size: 21px;
}

.major-library-add .opt-col {
  border-right: 1px solid #DCDCDC;
  border-bottom: 1px solid #DCDCDC;
}

.major-library-add .material_list_content .title {
  font-size: 20px;
  color: #439adf;
  padding: 3px 0;
}

.major-library-add .appli_edit_list {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -112px;
}

.major-library-add .appli_edit_list .md-button {
  margin: 0;
  min-width: 30px;
  line-height: 18px;
  background: #fff;
  font-size: 14px;
  color: #fff;
  background-color: rgba(67, 154, 223, 0.8);
  font-family: PingFangSC-Regular;
  height: 224px;
  width: 30px;
  white-space: normal;
  z-index: 2;
  border-radius: 16px 0 0 16px;
}

.major-library-add .appli_edit_list .md-button md-icon {
  color: #fff;
  margin: 0;
}

.major-library-add .editListDiv {
  position: absolute;
  height: 100%;
  width: 90%;
  right: -90%;
  background: #fff;
  z-index: 1;
  transition: all 0.3s;
  border-left: 1px #DCDCDC solid;
  overflow-y: auto;
}

.major-library-add .editListDiv .title {
  font-family: Helvetica-Bold;
  color: #439adf;
  height: 46px;
  line-height: 46px;
  border-bottom: 1px solid #DCDCDC;
  border-top: 1px solid #DCDCDC;
  padding-left: 15px;
}

.major-library-add .editListDiv .title h1 {
  font-size: 20px;
}

.major-library-add .editListDiv .loadMore {
  color: #439adf;
  cursor: pointer;
}

.major-library-add .editListDiv .page_list {
  margin-bottom: 20px;
}

.major-library-add .editListDiv .page_list .md-button {
  padding: 0px;
  margin: 0px;
  color: #428bca;
  border: 1px solid #dee5e7;
  border-left: none;
  background: white;
  width: 30px;
  height: 30px;
  min-width: 0px;
  min-height: 0px;
  line-height: 28px;
}

.major-library-add .editListDiv .page_list .md-button span {
  font-size: 10px;
  font-weight: bold;
}

.major-library-add .editListDiv .page_list .md-button:first-child {
  border-left: 1px solid #dee5e7;
}

.major-library-add .editListDiv .page_list .md-button.active {
  background: #428bca;
  color: white;
}

.major-library-add .editListDiv .page_list .md-button:hover {
  background: #dee5e7;
}

.major-library-add .editListDiv .page_list .md-button.active:hover {
  background: #428bca;
}

.major-library-add .editListDiv table th {
  font-weight: bold;
}

.major-library-add .editListDiv table tr {
  height: 50px !important;
  border-bottom: 1px solid #DCDCDC;
}

.major-library-add .editListDiv table th,
.major-library-add .editListDiv table td {
  padding: 0;
  padding-left: 10px;
  height: 50px !important;
  line-height: 50px;
}

.major-library-add .editListDiv table .chexiao {
  cursor: pointer;
}

.major-library-add .editListDiv table .active {
  background: #428bca;
  color: white;
}

.major-library-add .editListDiv table .show {
  position: relative;
}

.major-library-add .warning {
  min-width: 40px;
  margin: 0;
  color: red;
}

.major-library-add .loading {
  display: flex;
  min-height: 150px;
  justify-content: center;
  align-items: center;
}

.major-library-add .tag {
  font-family: "Monospaced Number","Chinese Quote",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-variant: tabular-nums;
  box-sizing: border-box;
  margin: auto 8px;
  padding: 0 7px;
  list-style: none;
  display: inline-block;
  line-height: 22px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fafafa;
  font-size: 12px;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 1;
  cursor: pointer;
  white-space: nowrap;
}

.major-library-add .tag .anticon {
  display: inline-block;
  font-style: normal;
  vertical-align: -.125em;
  text-align: center;
  text-transform: none;
  line-height: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.major-library-add .tag .close {
  display: inline-block;
  line-height: 20px;
  font-size: 12px;
  font-size: 10px\9;
  transform: scale(0.83333333) rotate(0);
  cursor: pointer;
  margin-left: 3px;
  transition: all .3s;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
}

.major-library-add .subject_box {
  border-right: 1px #DCDCDC solid;
  border-bottom: 1px #DCDCDC solid;
}

.major-library-add .subject_box .ap-input-label {
  flex: 1 1 100%;
  max-width: 20%;
  min-width: 40%;
  background-color: #EEEEEE;
  border-left: 1px solid #DCDCDC;
  border-right: 1px solid #DCDCDC;
  min-height: 92px;
  padding-left: 15px;
  text-indent: 0px;
  transition: all 100ms;
  -webkit-transition: all 50ms;
  cursor: pointer;
  box-sizing: border-box;
}

.major-library-add .subject_box .ap-input-label:hover {
  background: -webkit-linear-gradient(left, #EEEEEE, #ccc);
}

.major-library-add .subject_box .ap-input {
  flex: 1 1 100%;
  border: none;
  min-height: 100%;
  text-indent: 8px;
  box-sizing: border-box;
  max-width: 100%;
  padding: 10px;
}

.newPromptPanel {
  background: white;
  padding: 10px;
  width: 1000px;
  height: 300px;
  margin-top: -150px;
  margin-left: -500px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 2px #ccc;
}

.newPromptPanel .title {
  padding: 5px 10px;
  color: #439adf;
  font-size: 20px;
}

.newPromptPanel .toggle_tab {
  margin-bottom: 10px;
  min-height: 36px;
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
}

.newPromptPanel .toggle_tab > .md-button {
  margin: 0;
  color: #4A4A4A;
}

.newPromptPanel .toggle_tab > .md-button.active {
  color: #FFFFFF;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

.newPromptPanel .can_switch {
  height: 30px;
}

.newPromptPanel .can_switch .md-button {
  margin: 0;
  min-width: 84px;
  height: 24px;
  min-height: 24px;
  line-height: 24px;
  background: #fff;
  font-size: 14px;
  border: 1px solid #439ADF;
  color: #439ADF;
}

.newPromptPanel .can_switch .md-button:nth-of-type(1) {
  border-radius: 3px 0px 0px 3px;
}

.newPromptPanel .can_switch .md-button:nth-of-type(2) {
  border-radius: 0px 3px 3px 0px;
  border-left: none;
}

.newPromptPanel .can_switch .btnActive {
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  color: #fff;
}

.newPromptPanel .tag {
  font-family: "Monospaced Number","Chinese Quote",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-variant: tabular-nums;
  box-sizing: border-box;
  margin: 5px;
  text-indent: 3px;
  padding: 0 7px;
  list-style: none;
  display: inline-block;
  line-height: 22px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fafafa;
  font-size: 12px;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 1;
  cursor: pointer;
  white-space: nowrap;
}

.newPromptPanel .tag .anticon {
  display: inline-block;
  font-style: normal;
  vertical-align: -.125em;
  text-align: center;
  text-transform: none;
  line-height: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.newPromptPanel .tag .close {
  display: inline-block;
  line-height: 20px;
  font-size: 12px;
  font-size: 10px\9;
  transform: scale(0.83333333) rotate(0);
  cursor: pointer;
  margin-left: 3px;
  transition: all .3s;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
}

.major-library-change {
  padding: 10px;
  position: relative;
}

.major-library-change .redColor {
  border: 1px solid red !important;
}

.major-library-change .opt-col {
  border-left: 1px solid #DCDCDC;
}

.major-library-change .table-row {
  border-top: 1px solid #DCDCDC;
}

.major-library-change .status_btn {
  margin-left: 10px;
}

.major-library-change .status_btn .md-button {
  margin: 0;
  min-width: 84px;
  height: 28px;
  min-height: 28px;
  line-height: 28px;
  border-radius: 4px;
  font-size: 12px;
}

.major-library-change .status_btn .dea {
  border: 1px solid #9b9b9b;
  color: #9b9b9b;
}

.major-library-change .status_btn .dea:hover {
  background: #9b9b9b;
  color: #fff;
}

.major-library-change .status_btn .act {
  border: 1px solid #7ED321;
  color: #7ED321;
}

.major-library-change .status_btn .act:hover {
  background: #7ED321;
  color: #fff;
}

.major-library-change .score_table_fix {
  overflow-y: auto;
  height: 100%;
  border: 1px solid #DCDCDC;
  box-sizing: border-box;
}

.major-library-change .score_table_fix textarea {
  width: 100%;
  border: none;
}

.major-library-change .score_table_fix textarea:focus {
  outline: 2px solid #0091ea;
}

.major-library-change .score_table_fix table.md-table {
  overflow-y: auto;
}

.major-library-change .score_table_fix table.md-table thead.md-head > tr.md-row {
  height: 36px;
}

.major-library-change .score_table_fix table.md-table thead.md-head > tr.md-row th.md-column {
  padding: 0px 17px;
  background-color: #EEEEEE;
  text-indent: 0px;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  text-align: center;
  font-size: 16px;
}

.major-library-change .score_table_fix table.md-table tbody.md-body tr.md-row {
  position: relative;
  height: 36px;
}

.major-library-change .score_table_fix table.md-table tbody.md-body tr.md-row td.md-cell {
  position: relative;
  padding: 0 0 0 17px;
  text-indent: 0px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 2px solid #fff;
}

.major-library-change .toggle_tab {
  margin-bottom: 10px;
  min-height: 36px;
  margin-right: 30px;
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
}

.major-library-change .toggle_tab > .md-button {
  margin: 0;
  width: 176px;
  color: #4A4A4A;
}

.major-library-change .toggle_tab > .md-button.active {
  color: #FFFFFF;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

.major-library-change .program_list_content {
  overflow-y: auto;
  border-top: 1px solid #DCDCDC;
}

.major-library-change .program_list_content .panelDiv {
  width: 500px;
  position: absolute;
  top: 20%;
  left: 50%;
  z-index: 1;
  margin-left: -250px;
  background: #fff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.major-library-change .program_list_content .panelDiv .ap-input-btn {
  background: #439ADF;
  width: 27px;
  height: 27px;
  border-radius: 3px;
  padding: 0px;
  min-width: 27px;
  min-height: 27px;
  line-height: 27px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  text-indent: 0px;
}

.major-library-change .program_list_content .panelDiv .ap-input-btn md-icon {
  color: #fff;
  font-size: 21px;
}

.major-library-change .program_list_content .panelDiv .add-btn {
  margin-left: 0;
}

.major-library-change .program_list_content .panelDiv .toolbar {
  height: 32px;
  min-height: 24px;
  border-bottom: 1px solid #DCDCDC;
}

.major-library-change .program_list_content .panelDiv md-toolbar {
  background: #fff;
}

.major-library-change .program_list_content .panelDiv .inputx {
  padding: 20px 10px;
  font-size: 20px;
}

.major-library-change .program_list_content .panelDiv .inputx .checkboxdemoSelectAll {
  padding-left: 30px;
}

.major-library-change .program_list_content .panelDiv .inputx .uploadList {
  padding-left: 40px;
}

.major-library-change .program_list_content .panelDiv .buttondiv {
  border-top: 1px solid #DCDCDC;
}

.major-library-change .program_list_content .panelDiv table th {
  font-weight: bold;
}

.major-library-change .program_list_content .panelDiv table tr {
  border-bottom: 1px solid #DCDCDC;
}

.major-library-change .program_list_content .panelDiv table th,
.major-library-change .program_list_content .panelDiv table td {
  padding: 0;
  padding: 5px 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.major-library-change .program_list_content .panelDiv table .chexiao {
  cursor: pointer;
}

.major-library-change .program_list_content, .major-library-change .prompt_list_content, .major-library-change .material_list_content {
  height: 600px;
  margin-right: 30px;
}

.major-library-change .table-btn {
  background: #439ADF;
  width: 27px;
  height: 27px;
  border-radius: 3px;
  padding: 0;
  min-width: 27px;
  min-height: 27px;
  line-height: 27px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.major-library-change .table-btn:not([disabled]).md-focused, .major-library-change .table-btn:not([disabled]):hover {
  background-color: #439ADF;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.major-library-change .table-btn > md-icon {
  color: #fff;
  font-size: 21px;
}

.major-library-change .opt-col {
  border-right: 1px solid #DCDCDC;
  border-bottom: 1px solid #DCDCDC;
}

.major-library-change .material_list_content .title {
  font-size: 20px;
  color: #439adf;
  padding: 3px 0;
}

.major-library-change .appli_edit_list {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -112px;
}

.major-library-change .appli_edit_list .md-button {
  margin: 0;
  min-width: 30px;
  line-height: 18px;
  background: #fff;
  font-size: 14px;
  color: #fff;
  background-color: rgba(67, 154, 223, 0.8);
  font-family: PingFangSC-Regular;
  height: 224px;
  width: 30px;
  white-space: normal;
  z-index: 2;
  border-radius: 16px 0 0 16px;
}

.major-library-change .appli_edit_list .md-button md-icon {
  color: #fff;
  margin: 0;
}

.major-library-change .editListDiv {
  position: absolute;
  height: 100%;
  width: 90%;
  right: -90%;
  background: #fff;
  z-index: 1;
  transition: all 0.3s;
  border-left: 1px #DCDCDC solid;
  overflow-y: auto;
}

.major-library-change .editListDiv .title {
  font-family: Helvetica-Bold;
  color: #439adf;
  height: 46px;
  line-height: 46px;
  border-bottom: 1px solid #DCDCDC;
  border-top: 1px solid #DCDCDC;
  padding-left: 15px;
}

.major-library-change .editListDiv .title h1 {
  font-size: 20px;
}

.major-library-change .editListDiv .loadMore {
  color: #439adf;
  cursor: pointer;
}

.major-library-change .editListDiv a.downloadLink {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
  color: #0091ea;
  cursor: pointer;
}

.major-library-change .editListDiv a.downloadLink:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: initial;
  text-decoration-color: initial;
}

.major-library-change .editListDiv .page_list {
  margin-bottom: 20px;
}

.major-library-change .editListDiv .page_list .md-button {
  padding: 0px;
  margin: 0px;
  color: #428bca;
  border: 1px solid #dee5e7;
  border-left: none;
  background: white;
  width: 30px;
  height: 30px;
  min-width: 0px;
  min-height: 0px;
  line-height: 28px;
}

.major-library-change .editListDiv .page_list .md-button span {
  font-size: 10px;
  font-weight: bold;
}

.major-library-change .editListDiv .page_list .md-button:first-child {
  border-left: 1px solid #dee5e7;
}

.major-library-change .editListDiv .page_list .md-button.active {
  background: #428bca;
  color: white;
}

.major-library-change .editListDiv .page_list .md-button:hover {
  background: #dee5e7;
}

.major-library-change .editListDiv .page_list .md-button.active:hover {
  background: #428bca;
}

.major-library-change .editListDiv table th {
  font-weight: bold;
}

.major-library-change .editListDiv table tr {
  height: 50px !important;
  border-bottom: 1px solid #DCDCDC;
}

.major-library-change .editListDiv table th,
.major-library-change .editListDiv table td {
  padding: 0;
  padding-left: 10px;
  height: 50px !important;
  line-height: 50px;
}

.major-library-change .editListDiv table .chexiao {
  cursor: pointer;
}

.major-library-change .editListDiv table .active {
  background: #428bca;
  color: white;
}

.major-library-change .editListDiv table .show {
  position: relative;
}

.major-library-change .warning {
  min-width: 40px;
  margin: 0;
  color: red;
}

.major-library-change .loading {
  display: flex;
  min-height: 150px;
  justify-content: center;
  align-items: center;
}

.major-library-change .tag {
  font-family: "Monospaced Number","Chinese Quote",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-variant: tabular-nums;
  box-sizing: border-box;
  margin: auto 8px;
  padding: 0 7px;
  list-style: none;
  display: inline-block;
  line-height: 22px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fafafa;
  font-size: 12px;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 1;
  cursor: pointer;
  white-space: nowrap;
}

.major-library-change .tag .anticon {
  display: inline-block;
  font-style: normal;
  vertical-align: -.125em;
  text-align: center;
  text-transform: none;
  line-height: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.major-library-change .tag .close {
  display: inline-block;
  line-height: 20px;
  font-size: 12px;
  font-size: 10px\9;
  transform: scale(0.83333333) rotate(0);
  cursor: pointer;
  margin-left: 3px;
  transition: all .3s;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
}

.major-library-change .subject_box {
  border-right: 1px #DCDCDC solid;
  border-bottom: 1px #DCDCDC solid;
}

.major-library-change .subject_box .ap-input-label {
  flex: 1 1 100%;
  max-width: 20%;
  background-color: #EEEEEE;
  border-left: 1px solid #DCDCDC;
  border-right: 1px solid #DCDCDC;
  min-height: 36px;
  padding-left: 15px;
  text-indent: 0px;
  transition: all 100ms;
  -webkit-transition: all 50ms;
  cursor: pointer;
  box-sizing: border-box;
}

.major-library-change .subject_box .ap-input-label:hover {
  background: -webkit-linear-gradient(left, #EEEEEE, #ccc);
}

.major-library-change .subject_box .ap-input {
  flex: 1 1 100%;
  border: none;
  min-height: 36px;
  text-indent: 17px;
  box-sizing: border-box;
  max-width: 100%;
}

.major-library-change .subject_box .ap-input:focus {
  outline: 2px solid #0091ea;
}

.major-library-change .subject_box1 {
  border-right: 1px #DCDCDC solid;
  border-bottom: 1px #DCDCDC solid;
}

.major-library-change .subject_box1 .ap-input-label {
  flex: 1 1 100%;
  max-width: 20%;
  min-width: 40%;
  background-color: #EEEEEE;
  border-left: 1px solid #DCDCDC;
  border-right: 1px solid #DCDCDC;
  min-height: 92px;
  padding-left: 15px;
  text-indent: 0px;
  transition: all 100ms;
  -webkit-transition: all 50ms;
  cursor: pointer;
  box-sizing: border-box;
}

.major-library-change .subject_box1 .ap-input-label:hover {
  background: -webkit-linear-gradient(left, #EEEEEE, #ccc);
}

.major-library-change .subject_box1 .ap-input {
  flex: 1 1 100%;
  border: none;
  min-height: 100%;
  text-indent: 8px;
  box-sizing: border-box;
  max-width: 100%;
  padding: 10px;
}

.newPromptPanel {
  background: white;
  padding: 10px;
  width: 1000px;
  height: 300px;
  margin-top: -150px;
  margin-left: -500px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 2px #ccc;
}

.newPromptPanel .title {
  padding: 5px 10px;
  color: #439adf;
  font-size: 20px;
}

.newPromptPanel .toggle_tab {
  margin-bottom: 10px;
  min-height: 36px;
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
}

.newPromptPanel .toggle_tab > .md-button {
  margin: 0;
  color: #4A4A4A;
}

.newPromptPanel .toggle_tab > .md-button.active {
  color: #FFFFFF;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

.newPromptPanel .can_switch {
  height: 30px;
}

.newPromptPanel .can_switch .md-button {
  margin: 0;
  min-width: 84px;
  height: 24px;
  min-height: 24px;
  line-height: 24px;
  background: #fff;
  font-size: 14px;
  border: 1px solid #439ADF;
  color: #439ADF;
}

.newPromptPanel .can_switch .md-button:nth-of-type(1) {
  border-radius: 3px 0px 0px 3px;
}

.newPromptPanel .can_switch .md-button:nth-of-type(2) {
  border-radius: 0px 3px 3px 0px;
  border-left: none;
}

.newPromptPanel .can_switch .btnActive {
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  color: #fff;
}

.newPromptPanel .tag {
  font-family: "Monospaced Number","Chinese Quote",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-variant: tabular-nums;
  box-sizing: border-box;
  margin: 5px;
  text-indent: 3px;
  padding: 0 7px;
  list-style: none;
  display: inline-block;
  line-height: 22px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fafafa;
  font-size: 12px;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 1;
  cursor: pointer;
  white-space: nowrap;
}

.newPromptPanel .tag .anticon {
  display: inline-block;
  font-style: normal;
  vertical-align: -.125em;
  text-align: center;
  text-transform: none;
  line-height: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.newPromptPanel .tag .close {
  display: inline-block;
  line-height: 20px;
  font-size: 12px;
  font-size: 10px\9;
  transform: scale(0.83333333) rotate(0);
  cursor: pointer;
  margin-left: 3px;
  transition: all .3s;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
}

.myCourseDetail .description {
  margin-top: 10px;
}


#paperwork {
  padding: 30px;
  padding-top: 16px;
  padding-right: 40px;
  padding-bottom: 0px;
}

#paperwork .mid {
  padding-bottom: 16px;
}

#paperwork .mid .select_title {
  color: #439ADF;
  padding-bottom: 8px;
  font-size: 18px;
}

#paperwork .mid .search_box {
  padding-right: 16px;
  position: relative;
}

#paperwork .mid .search_box md-autocomplete {
  background: none;
  height: 32px;
}

#paperwork .mid .search_box md-autocomplete md-autocomplete-wrap {
  height: 32px;
  box-shadow: none;
  border: 1px solid #9B9B9B;
}

#paperwork .mid .search_box md-autocomplete md-autocomplete-wrap input {
  padding: 0 10px;
  height: 32px;
  line-height: 32px;
}

#paperwork .mid .add_btn {
  margin: 0;
  min-width: 78px;
  height: 32px;
  min-height: 32px;
  line-height: 32px;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  color: white;
  box-shadow: 0px 1px 2px 0px rgba(67, 154, 223, 0.6);
}

#paperwork .mid .add_btn:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

#paperwork .mid .add_btn md-icon {
  font-size: 18px;
  height: 20px;
  width: 20px;
  min-width: 20px;
  min-height: 20px;
  color: #fff;
}

#paperwork .mid .add_paper {
  background-image: linear-gradient(45deg, #5EE1BF 0, #439ADF 100%);
  padding: 0 2px;
  margin: 0px;
  color: white;
}

#paperwork .mid .paperIcon {
  color: white;
  font-size: 18px;
  margin-top: 4px;
  margin-right: -4px;
}

#paperwork .content .md-icon-button {
  z-index: 50;
  padding: 0px;
  margin: 0px;
  width: 20px;
  height: 40px;
  border-radius: 0;
  background: #7986CB;
}

#paperwork .content .md-icon-button md-icon {
  color: #fff;
  margin-top: 1px;
}

#paperwork .content .scroll_btn_left {
  position: absolute;
  left: 292px;
  top: 0px;
}

#paperwork .content .scroll_btn_right {
  position: absolute;
  right: 0px;
  top: 0px;
}

#paperwork .content md-table-container {
  padding-bottom: 20px;
}

#paperwork .content md-table-container table {
  overflow: inherit;
}

#paperwork .content md-table-container table thead th {
  padding: 10px 3px;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background: #7986CB;
  z-index: 10;
  white-space: nowrap;
}

#paperwork .content md-table-container table thead th:first-child > div, #paperwork .content md-table-container table thead th:nth-child(2) > div {
  padding-left: 8px !important;
}

#paperwork .content md-table-container table thead tr th:nth-child(1) {
  width: 53px;
  max-width: 53px;
  left: 0;
  z-index: 11;
}

#paperwork .content md-table-container table thead tr th:nth-child(2) {
  width: 105px;
  max-width: 105px;
  min-width: 105px;
  left: 53px;
  z-index: 11;
}

#paperwork .content md-table-container table thead tr th:nth-child(3) {
  left: 164px;
  z-index: 11;
}

#paperwork .content md-table-container table thead tr th:nth-child(3) div {
  margin-right: 20px;
}

#paperwork .content md-table-container table thead tr th:nth-last-child(1) div {
  margin-right: 20px;
}

#paperwork .content md-table-container table tbody tr:first-child td {
  padding-top: 0px;
}

#paperwork .content md-table-container table tbody tr {
  cursor: pointer;
}

#paperwork .content md-table-container table tbody tr td:nth-child(1), #paperwork .content md-table-container table tbody tr td:nth-child(2), #paperwork .content md-table-container table tbody tr td:nth-child(3) {
  position: sticky;
  position: -webkit-sticky;
  background: #fff;
}

#paperwork .content md-table-container table tbody tr td:nth-child(1) {
  width: 53px;
  max-width: 53px;
  left: 0;
  z-index: 3;
}

#paperwork .content md-table-container table tbody tr td:nth-child(2) {
  width: 105px;
  max-width: 105px;
  min-width: 105px;
  left: 53px;
  z-index: 2;
}

#paperwork .content md-table-container table tbody tr td:nth-child(3) {
  left: 164px;
  z-index: 1;
}

#paperwork .content md-table-container table tbody tr td:nth-child(3) .box {
  border-right: 1px solid #9B9B9B;
}

#paperwork .content md-table-container table tbody td {
  padding: 3px 0px;
}

#paperwork .content md-table-container table tbody td .box {
  padding: 0px 8px;
  padding-left: 12px;
  height: 43px;
  border-bottom: 1px solid #9B9B9B;
  border-top: 1px solid #9B9B9B;
  position: relative;
}

#paperwork .content md-table-container table tbody td .btn_box {
  margin-top: 0px;
}

#paperwork .content md-table-container table tbody td .btn_box button {
  padding: 0px 3px;
}

#paperwork .content md-table-container table tbody td .btn_box button img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

#paperwork .content md-table-container table tbody td:first-child .box {
  padding-left: 8px;
  border-left: 1px solid #9B9B9B;
  font-weight: bold;
}

#paperwork .content md-table-container table tbody td:nth-child(2) .box {
  padding-left: 8px;
  font-weight: bold;
}

#paperwork .content md-table-container table tbody td:nth-last-child(1) .box {
  border-right: 1px solid #9B9B9B;
}

#paperwork .content md-table-container table tbody td .manage {
  color: white;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

#paperwork .content .page_list {
  padding-bottom: 20px;
  padding-top: 10px;
}

#paperwork .content .page_list .md-button {
  padding: 0px;
  margin: 0px;
  color: #428bca;
  border: 1px solid #dee5e7;
  border-left: none;
  background: white;
  width: 30px;
  height: 30px;
  min-width: 0px;
  min-height: 0px;
  line-height: 28px;
}

#paperwork .content .page_list .md-button span {
  font-size: 10px;
  font-weight: bold;
}

#paperwork .content .page_list .md-button:first-child, #paperwork .content .page_list .md-button:nth-of-type(3) {
  border-left: 1px solid #dee5e7;
}

#paperwork .content .page_list .md-button.active {
  background: #428bca;
  color: white;
}

#paperwork .content .page_list .md-button:hover {
  background: #dee5e7;
}

#paperwork .content .page_list .md-button.active:hover {
  background: #428bca;
}

#paperwork .content .question_title {
  position: absolute;
  right: 0;
  top: 50%;
  width: 25px;
  z-index: 99;
}

#paperwork .content .question_title .md-button {
  margin: 0;
  min-width: 25px;
  line-height: 18px;
  background: #fff;
  font-size: 14px;
  color: #fff;
  background-color: rgba(67, 154, 223, 0.8);
  font-family: PingFangSC-Regular;
  height: 224px;
  width: 30px;
  white-space: normal;
  z-index: 2;
  border-radius: 16px 0 0 16px;
}

#paperwork .content .question_title .md-button md-icon {
  color: #fff;
  margin: 0;
}

#paperwork .list_mask {
  position: absolute;
  width: 96%;
  top: 18%;
  height: 82%;
  z-index: 100;
}

#paperwork .app_list {
  position: relative;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  width: 90%;
  height: 90%;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

#paperwork .app_list .status_btn {
  margin-left: 10px;
}

#paperwork .app_list .status_btn .md-button {
  margin: 0;
  min-width: 84px;
  height: 28px;
  min-height: 28px;
  line-height: 28px;
  border-radius: 4px;
  font-size: 12px;
}

#paperwork .app_list .status_btn .dea {
  border: 1px solid #9b9b9b;
  color: #9b9b9b;
}

#paperwork .app_list .status_btn .dea:hover {
  background: #9b9b9b;
  color: #fff;
}

#paperwork .app_list .status_btn .act {
  border: 1px solid #7ED321;
  color: #7ED321;
}

#paperwork .app_list .status_btn .act:hover {
  background: #7ED321;
  color: #fff;
}

#paperwork .app_list .application_container {
  overflow: auto;
  height: 85%;
  overflow: hidden;
  overflow-y: auto;
}

#paperwork .app_list .application_container table {
  overflow: inherit;
}

#paperwork .app_list .application_container table thead th {
  padding: 10px 3px;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background: #7986CB;
  z-index: 10;
  white-space: nowrap;
}

#paperwork .app_list .application_container table thead th:first-child > div,
#paperwork .app_list .application_container table thead th:nth-child(2) > div {
  padding-left: 10px !important;
}

#paperwork .app_list .application_container table thead tr th:nth-child(1) {
  width: 55px;
  max-width: 55px;
  left: 0;
  z-index: 11;
}

#paperwork .app_list .application_container table thead tr th:nth-child(2) {
  width: 100px;
  max-width: 100px;
  min-width: 100px;
  left: 61px;
  z-index: 11;
}

#paperwork .app_list .application_container table thead tr th:nth-child(3) {
  left: 167px;
  z-index: 11;
}

#paperwork .app_list .application_container table tbody tr:first-child td {
  padding-top: 0px;
}

#paperwork .app_list .application_container table tbody td {
  padding: 3px 0px;
}

#paperwork .app_list .application_container table tbody td .box {
  padding: 0px 8px;
  padding-left: 23px;
  height: 43px;
  border-bottom: 1px solid #9B9B9B;
  border-top: 1px solid #9B9B9B;
  position: relative;
}

#paperwork .app_list .application_container table tbody td:first-child .box {
  width: 100px;
  padding-left: 12px;
  border-left: 1px solid #9B9B9B;
  font-weight: bold;
}

#paperwork .app_list .application_container table tbody td:nth-child(2) .box {
  padding-left: 8px;
  font-weight: bold;
}

#paperwork .app_list .application_container table tbody td:nth-child(3) .box {
  padding-left: 8px;
  font-weight: bold;
}

#paperwork .app_list .application_container table tbody td:nth-last-child(1) .box {
  border-right: 1px solid #9B9B9B;
}

#paperwork .app_list .application_container table tbody td .goDetail a {
  cursor: pointer;
  color: #72ACE3;
}

#paperwork .app_list .application_container table tbody tr td:nth-child(1), #paperwork .app_list .application_container table tbody tr td:nth-child(2), #paperwork .app_list .application_container table tbody tr td:nth-child(3) {
  position: sticky;
  position: -webkit-sticky;
  background: #fff;
}

#paperwork .app_list .application_container table tbody tr td:nth-child(1) {
  width: 100px;
  max-width: 100px;
  left: 0;
  z-index: 3;
}

#paperwork .app_list .application_container table tbody tr td:nth-child(2) {
  width: 100px;
  max-width: 100px;
  min-width: 100px;
  left: 61px;
  z-index: 2;
}

#paperwork .app_list .application_container table tbody tr td:nth-child(3) {
  left: 167px;
  z-index: 1;
}

#paperwork .app_list .application_container table tbody tr td .action span {
  cursor: pointer;
}

#paperwork .app_list .closebtn {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #000;
}

#paperwork .app_list .chexiao {
  margin-right: 5px;
}

#paperwork .app_list .action {
  padding-left: 10px !important;
}

#paper_info {
  display: flex;
  justify-content: center;
  padding-right: 15px;
  position: relative;
}

#paper_info .tab {
  position: fixed;
  left: 0;
  width: 88px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
  margin: 10px 10px 16px;
}

#paper_info .tab > a {
  position: relative;
  margin: 0;
  color: #4A4A4A;
  width: 80px;
  padding: 4px;
  height: 30px;
  line-height: 30px;
  border-bottom: 1px #DCDCDC solid;
  cursor: pointer;
}

#paper_info .tab > a span {
  width: 80px;
  text-align: center;
}

#paper_info .tab > a.btnActive {
  color: #FFFFFF;
  background: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

#paper_info .tab > a.btnActive div {
  color: #4A4A4A;
}

#paper_info .paper_content {
  margin: 10px;
  border: 1px #DCDCDC solid;
  overflow-y: auto;
}

#paper_info .paper_content .title {
  font-size: 20px;
  font-family: Helvetica-Bold;
  color: #439adf;
  height: 46px;
  border-bottom: 1px solid #DCDCDC;
  border-top: 1px solid #DCDCDC;
  padding-left: 15px;
  line-height: 46px;
}

#paper_info .paper_content .col {
  color: #000;
  border-bottom: 1px #DCDCDC solid;
}

#paper_info .paper_content .col .col_p {
  background-color: #EEEEEE;
  border-left: 1px solid #DCDCDC;
  border-right: 1px solid #DCDCDC;
  height: 36px;
  padding-left: 15px;
  transition: all 100ms;
  -webkit-transition: all 50ms;
  cursor: pointer;
}

#paper_info .paper_content .col .col_p:hover {
  background: -webkit-linear-gradient(left, #EEEEEE, #ccc);
}

#paper_info .paper_content .col input {
  border: none;
  height: 36px;
  text-indent: 17px;
}

#paper_info .paper_content .col input:focus {
  outline: 2px solid #0091ea;
}

#paper_info .paper_content .col select {
  border: none;
  height: 36px;
  background: #FFFFFF;
}

#paper_info .paper_content .col .tableDiv {
  border-top: 1px solid #DCDCDC;
  border-bottom: 1px solid #DCDCDC;
}

#paper_info .paper_content .col .tableDiv:nth-last-of-type(1) {
  border-bottom: none;
}

#paper_info .paper_content .col .grade .tableDiv {
  height: 100%;
}

#paper_info .paper_content .col .grade .tableDiv p, #paper_info .paper_content .col .grade .tableDiv input {
  height: 100%;
}

#paper_info .paper_content .col .bb .tableDiv:nth-last-of-type(2) {
  border-bottom: none;
}

#paper_info .paper_content .col .mt {
  margin-top: 10px;
}

#paper_info .paper_content .col .mt:nth-of-type(1) {
  margin-top: 0;
}

#paper_info .paper_content .col textarea {
  height: 150px;
}

#paper_info .paper_content .col .textareaLabel {
  height: 150px;
  background: #EEEEEE;
  line-height: 150px;
}

#paper_info .paper_content .edited {
  border: 1px solid red !important;
}

#paper_info .paper_content input {
  cursor: pointer;
}

#paper_info .paper_content select {
  cursor: pointer;
}

#paper_info .paper_content textarea {
  cursor: pointer;
}

#paper_info .paper_content select[readonly] option {
  display: none;
}

#paper_info .paper_content .multi {
  height: 36px;
  line-height: 36px;
  cursor: pointer;
  padding-left: 15px;
}

#paper_info .panelDiv {
  width: 600px;
  position: absolute;
  left: 50%;
  z-index: 1;
  margin-left: -300px;
  background: #fff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

#paper_info .panelDiv .toolbar {
  height: 32px;
  min-height: 24px;
  border-bottom: 1px solid #DCDCDC;
}

#paper_info .panelDiv md-toolbar {
  background: #fff;
}

#paper_info .panelDiv .inputx {
  padding: 20px 10px;
  font-size: 20px;
}

#paper_info .panelDiv .inputx .checkboxdemoSelectAll {
  padding-left: 30px;
}

#paper_info .panelDiv .inputx textarea {
  height: 240px;
  overflow-y: auto;
  max-height: 240px;
}

#paper_info .panelDiv .buttondiv {
  border-top: 1px solid #DCDCDC;
}

#paper_info .panelDiv table th {
  font-weight: bold;
}

#paper_info .panelDiv table tr {
  border-bottom: 1px solid #DCDCDC;
}

#paper_info .panelDiv table th,
#paper_info .panelDiv table td {
  padding: 0;
  padding: 5px 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#paper_info .panelDiv table .chexiao {
  cursor: pointer;
}

#paper_info .editListDiv {
  position: absolute;
  height: 100%;
  width: 900px;
  right: -900px;
  background: #fff;
  z-index: 1;
  transition: all 0.3s;
  border-left: 1px #DCDCDC solid;
  overflow-y: auto;
}

#paper_info .editListDiv .title {
  font-size: 20px;
  font-family: Helvetica-Bold;
  color: #439adf;
  height: 46px;
  border-bottom: 1px solid #DCDCDC;
  border-top: 1px solid #DCDCDC;
  padding-left: 15px;
  line-height: 46px;
}

#paper_info .editListDiv .page_list {
  position: absolute;
  left: 50%;
  margin-left: -60px;
  bottom: 0;
}

#paper_info .editListDiv .page_list .md-button {
  padding: 0px;
  margin: 0px;
  color: #428bca;
  border: 1px solid #dee5e7;
  border-left: none;
  background: white;
  width: 30px;
  height: 30px;
  min-width: 0px;
  min-height: 0px;
  line-height: 28px;
}

#paper_info .editListDiv .page_list .md-button span {
  font-size: 10px;
  font-weight: bold;
}

#paper_info .editListDiv .page_list .md-button:first-child {
  border-left: 1px solid #dee5e7;
}

#paper_info .editListDiv .page_list .md-button.active {
  background: #428bca;
  color: white;
}

#paper_info .editListDiv .page_list .md-button:hover {
  background: #dee5e7;
}

#paper_info .editListDiv .page_list .md-button.active:hover {
  background: #428bca;
}

#paper_info .editListDiv table th {
  font-weight: bold;
}

#paper_info .editListDiv table tr {
  height: 50px !important;
  border-bottom: 1px solid #DCDCDC;
}

#paper_info .editListDiv table th, #paper_info .editListDiv table td {
  padding: 0;
  padding-left: 10px;
  height: 50px !important;
  line-height: 50px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#paper_info .editListDiv table .chexiao {
  cursor: pointer;
}

#paper_info .editListDiv table .active {
  background: #428bca;
  color: white;
}

#paper_info .editListDiv table .show {
  position: relative;
}

#paper_info .question_title {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -112px;
}

#paper_info .question_title .md-button {
  margin: 0;
  min-width: 30px;
  line-height: 18px;
  background: #fff;
  font-size: 14px;
  color: #fff;
  background-color: rgba(67, 154, 223, 0.8);
  font-family: PingFangSC-Regular;
  height: 224px;
  width: 30px;
  white-space: normal;
  z-index: 2;
  border-radius: 16px 0 0 16px;
}

#paper_info .question_title .md-button md-icon {
  color: #fff;
  margin: 0;
}

.parent-tel {
  padding: 20px;
}

.parent-tel .title {
  font-size: 18px;
  color: #4a4a4a;
  padding-bottom: 10px;
}

.parent-tel .file-upload {
  position: relative;
}

.parent-tel .file-upload .upload {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 300px;
  height: 40px;
  cursor: pointer;
}

.parent-tel .file-upload .choose {
  color: #555;
  width: 300px;
  height: 40px;
  border: 2px solid #555;
  border-radius: 4px;
  padding: 0 10px;
}

.parent-tel .file-upload .choose #fileuploadurl {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
}

.parent-tel .download {
  color: #555;
  width: 150px;
  height: 40px;
  border: 2px solid #555;
  border-radius: 4px;
  padding: 0 10px;
  margin-left: 20px;
}

.parent-tel .icon {
  color: #4a4a4a;
  font-size: 20px;
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
  margin: 0;
}

.parent-tel .iconBor {
  border-radius: 3px;
  border: 1px solid #4a4a4a;
  width: 22px;
  height: 22px;
  min-width: 22px;
  min-height: 22px;
  padding: 0;
}

.parent-tel .container {
  margin: 10px 0;
  width: 100%;
}

.parent-tel .container table.md-table {
  overflow: inherit;
}

.parent-tel .container table.md-table .thead_tr {
  height: 40px;
}

.parent-tel .container table.md-table .thead_tr th {
  font-size: 16px;
  color: #fff;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background: #7986CB;
  z-index: 10;
}

.parent-tel .container table.md-table .tbody_tr {
  border-bottom: 1px rgba(0, 0, 0, 0.12) solid;
}

#pilot-roadmap .nav {
  width: 200px;
  height: 100%;
  box-sizing: border-box;
  border-left: 10px solid whitesmoke;
  border-top: 29px solid whitesmoke;
  border-radius: 3px;
  border-bottom: 10px solid whitesmoke;
  padding-bottom: 10px;
  position: relative;
}

#pilot-roadmap .nav .btn {
  min-width: 50px;
  min-height: auto;
  padding: 0 5px;
  height: 20px;
  line-height: 20px;
  background-color: #78caf1;
  color: white;
  background-image: linear-gradient(45deg, #439adf 0px, #78caf1 100%);
  border: 0px;
  border-radius: 3px;
  margin-left: 10px;
  margin-top: 5px;
}

#pilot-roadmap .nav .tagpink {
  background: #fff1f0;
  border: 1px solid #ffa39e;
  color: #F5222D;
}

#pilot-roadmap .nav .tagblue {
  background: rgba(60, 150, 243, 0.1);
  border: 1px solid rgba(60, 150, 243, 0.6);
  color: #3C96F3;
}

#pilot-roadmap .nav .tagorange {
  background: rgba(254, 147, 31, 0.1);
  border: 1px solid #FE931F;
  color: #FE931F;
}

#pilot-roadmap .nav .taggreen {
  background: #f5ffed;
  border: 1px solid #b7eb8f;
  color: #7ED321;
}

#pilot-roadmap .nav .status {
  padding: 0 3px;
  height: 20px;
  line-height: 20px;
  border-radius: 2px;
  width: 50px;
  font-size: 12px;
  text-align: center;
  position: absolute;
  top: -26px;
}

#pilot-roadmap .nav .tab {
  width: 100%;
  height: 90%;
  overflow-y: auto;
  list-style-type: none;
}

#pilot-roadmap .nav .tab li {
  list-style-type: none;
  width: 100%;
}

#pilot-roadmap .nav .tab li h3 {
  width: 100%;
  box-sizing: border-box;
  padding-left: 10px;
  height: 45px;
  line-height: 45px;
  color: #757575;
  font-family: "PingFang SC";
}

#pilot-roadmap .nav .tab li h3 .material-icons {
  font-size: 20px;
  color: #757575;
}

#pilot-roadmap .nav .tab li md-icon {
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  margin-right: 5px;
  padding-bottom: 3px;
}

#pilot-roadmap .nav .tab li .tabItem {
  width: 100%;
  box-sizing: border-box;
  padding-left: 16px;
  height: 35px;
  line-height: 35px;
  cursor: pointer;
  color: #757575;
  font-family: "PingFang SC";
  transition: background 0.3s ease 0s;
}

#pilot-roadmap .nav .tab li .tabItem:hover {
  background: rgba(158, 158, 158, 0.3);
}

#pilot-roadmap .nav .tab li .tabItem.btnAction {
  background: rgba(158, 158, 158, 0.2);
  color: #006DD5;
}

#pilot-roadmap .nav .tab li.allBtn .md-button {
  min-height: 20px !important;
  min-width: 72px !important;
  line-height: 30px !important;
  margin: 6px 0 0 3px !important;
}

#pilot-roadmap .content {
  height: 100%;
  overflow-x: auto;
  background: whitesmoke;
}

#pilot-roadmap .content .leftContent {
  height: 100%;
  min-width: 400px;
  box-sizing: border-box;
  padding: 0 10px 10px 10px;
}

#pilot-roadmap .content .leftContent h3 {
  height: 29px;
  line-height: 29px;
  color: #9A9A9A;
}

#pilot-roadmap .content .leftContent .leftInner {
  width: 100%;
  background: #fff;
  border-radius: 3px;
  overflow-y: auto;
}

#pilot-roadmap .content .rightContent {
  height: 100%;
  min-width: 500px;
  box-sizing: border-box;
  padding: 0 10px 10px 0;
}

#pilot-roadmap .content .rightContent h3 {
  height: 29px;
  line-height: 29px;
  color: #9A9A9A;
}

#pilot-roadmap .content .rightContent .rightInner {
  width: 100%;
  background: #fff;
  border-radius: 3px;
  overflow-y: auto;
}

#pilot-roadmap .fmsDiv {
  position: relative;
  display: none;
  background: #fff;
  height: 80%;
  position: absolute;
  left: 0px;
  width: 40%;
  z-index: 100;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

#pilot-roadmap .close {
  position: absolute;
  top: 15px;
  right: 5px;
  width: 24px;
  height: 24px;
  margin: 0;
  z-index: 10;
  cursor: pointer;
}


.close {
  font-size: 18px;
  color: #8c8c8c;
}

.close:hover {
  color: red;
}

.md-virtual-repeat-container.md-autocomplete-suggestions-container {
  z-index: 110;
  max-height: auto;
  height: auto;
}

.md-virtual-repeat-container.md-autocomplete-suggestions-container .md-virtual-repeat-offsetter {
  padding: 5px 0px;
}

popup-menu {
  width: 100%;
}

.popup_menu md-autocomplete {
  min-width: 0px;
  width: 100%;
}

.popup_menu md-autocomplete input {
  padding: 0px 0px;
  height: 36px;
  line-height: 36px;
}

.popup_menu md-autocomplete ul li {
  padding: 0px 3px;
}

.post_admission_manage {
  padding: 20px;
}

.post_admission_manage .title {
  color: #439ADF;
  padding-bottom: 8px;
  font-size: 18px;
}

.post_admission_manage .search_item {
  padding-right: 10px;
  position: relative;
  height: 36px;
  line-height: 36px;
  font-size: 16px;
}

.post_admission_manage .search_item select {
  height: 36px;
}

.post_admission_manage .students_count {
  font-size: 16px;
}

.post_admission_manage .students_count span {
  margin-left: 10px;
}

.post_admission_manage md-autocomplete md-autocomplete-wrap {
  height: 36px;
  box-shadow: none;
  border: 1px solid #9B9B9B;
}

.post_admission_manage md-autocomplete md-autocomplete-wrap input {
  padding: 0 10px;
  height: 36px;
  line-height: 36px;
}

.post_admission_manage .add_btn {
  margin: 0 10px 0 0;
  min-width: 78px;
  height: 36px;
  min-height: 36px;
  line-height: 36px;
  background: #439adf;
  font-size: 16px;
  font-family: PingFangSC-Regular;
  color: white;
  box-shadow: 0px 1px 2px 0px rgba(67, 154, 223, 0.6);
}

.post_admission_manage .add_btn:hover {
  background: #439adf;
}

.post_admission_manage .add_btn md-icon {
  font-size: 20px;
  height: 22px;
  width: 20px;
  min-width: 20px;
  min-height: 22px;
  color: #fff;
}

.post_admission_manage .show_ed_students {
  position: absolute;
  width: 150px;
  min-height: 50px;
  box-shadow: 0 0 3px 0 #999;
  left: 100%;
  top: 50%;
  background: #fff;
  border-radius: 3px;
  padding: 10px;
  z-index: 1;
  font-size: 16px;
  color: #4a4a4a;
}

.post_admission_manage .container {
  background-color: white;
  padding-bottom: 5px;
  margin: 10px 0px;
  border: 1px solid #ccc;
}

.post_admission_manage table.md-table th.md-column,
.post_admission_manage table.md-table td.md-cell {
  text-align: center;
}

.post_admission_manage table {
  overflow: auto;
  border-collapse: inherit;
  border-bottom: 1px solid #ccc;
}

.post_admission_manage table th,
.post_admission_manage table td {
  padding: 0px !important;
}

.post_admission_manage table th {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background: white;
  border-bottom: 1px solid #ccc;
  z-index: 2;
  text-align: center;
  min-width: 60px;
  border-right: 1px solid #ccc;
  line-height: 40px;
}

.post_admission_manage table tbody td {
  border-right: 1px solid #ccc;
}

.post_admission_manage table tbody .state-1 {
  background: #fff;
}

.post_admission_manage table tbody .state-2 {
  background: #f7f7f7;
}

.post_admission_manage table tbody .state-3 {
  background: #EEEEEE;
}

.post_admission_manage table tbody .icon {
  position: absolute;
  right: 10px;
}

.post_admission_manage table tbody .icon md-icon {
  cursor: pointer;
}

.post_admission_manage .containerleft table tbody tr:nth-child(2n-1) {
  background: #f7f7f7;
}

.post_admission_manage .containerleft table tbody tr:nth-child(2n-1) td {
  background: #f7f7f7;
}

.post_admission_manage .student {
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #ccc;
}

.post_admission_manage .student .name, .post_admission_manage .student .ac {
  width: 50%;
}

.post_admission_manage .student:nth-last-child(1) {
  border-bottom: none;
}

.post_admission_manage .studentTr td.md-cell {
  background: #f7f7f7;
  height: 30px;
}

.post_admission_manage .toggleBtn {
  position: absolute;
  left: 0;
  top: 4px;
}

.post_admission_manage .acSelect {
  margin: 0;
  width: 80%;
  height: 40px;
}

.post_admission_manage .testAc {
  background: #f7f7f7;
}

.post_admission_manage .testAc td {
  background: #f7f7f7;
}


.task_card_list {
  background: white;
}

.task_card_list:hover {
  background: #ccc;
  cursor: pointer;
}

#alltask .md-button {
  padding: 8px;
}

#alltask md-list-item .md-button {
  padding: 0px 16px;
}

.jindu_done path {
  stroke: #60c360;
}

.jindu_audit path {
  stroke: #60c360;
}

.jindu_all path {
  stroke: #abd0ab;
}

.status-chip {
  cursor: default;
  border-radius: 16px;
  display: block;
  height: 32px;
  line-height: 32px;
  margin: 8px;
  padding: 0 12px 0 12px;
  float: right;
  box-sizing: border-box;
  max-width: 100%;
  position: relative;
  color: white;
}

#process a {
  margin: 0;
}

#profile {
  padding: 0px;
  padding-left: 16px;
}

#profile .md-datepicker-input-container {
  border-bottom: 1px solid #ccc;
}

.RegisterForm-inputContainer {
  margin-top: 10px;
  margin-bottom: 0px;
  width: 80%;
  min-width: 300px;
}

.RegisterForm-submit {
  margin-bottom: 40px;
  margin-left: 0;
  width: 100%;
  padding: 5px 0;
}



.reporting_school .btn_link.active {
  background: #546e7a;
}

.reporting_school table.md-table td.md-cell {
  height: 0;
}

.reporting_school .page_list {
  padding-bottom: 10px;
  padding-top: 10px;
}

.reporting_school .page_list .md-button {
  padding: 0px;
  margin: 0px;
  color: #428bca;
  border: 1px solid #dee5e7;
  border-left: none;
  background: white;
  width: 30px;
  height: 30px;
  min-width: 0px;
  min-height: 0px;
  line-height: 28px;
}

.reporting_school .page_list .md-button span {
  font-size: 10px;
  font-weight: bold;
}

.reporting_school .page_list .md-button:first-child, .reporting_school .page_list .md-button:nth-of-type(3) {
  border-left: 1px solid #dee5e7;
}

.reporting_school .page_list .md-button.active {
  background: #428bca;
  color: white;
}

.reporting_school .page_list .md-button:hover {
  background: #dee5e7;
}

.reporting_school .page_list .md-button.active:hover {
  background: #428bca;
}

.reporting_school table {
  border-collapse: inherit;
  border-bottom: 1px solid #ccc;
}

.reporting_school table .top_fix {
  background: white;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.reporting_school table .left_fix {
  border-right: 1px solid #ccc;
}

.reporting_school table th, .reporting_school table td {
  padding: 0px !important;
}

.reporting_school table th {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background: white;
  border-bottom: 1px solid #ccc;
  z-index: 2;
}

.reporting_school table th:first-child, .reporting_school table th:nth-child(2) {
  left: 0px;
  z-index: 51;
}

.reporting_school table td:first-child, .reporting_school table td:nth-child(2) {
  position: sticky;
  position: -webkit-sticky;
  left: 0px;
}

.reporting_school table .min_wid:first-child {
  min-width: 100px;
}

.reporting_school table .no_border:last-child {
  border: none !important;
}

.reporting_school table tbody .min_row {
  border-bottom: 1px solid #ccc;
  line-height: 20px;
  height: 0px;
}

.reporting_school table tbody p.min_col {
  border-right: 1px solid #ccc;
  font-size: 12px;
}

.reporting_school table tbody p.min_col:first-child {
  min-width: 100px;
}

.reporting_school table tbody td {
  background: white;
}

.reporting_school table tbody tr:nth-child(2n-1) {
  background: #f7f7f7;
}

.reporting_school table tbody tr:nth-child(2n-1) td {
  background: #f7f7f7;
}

.reporting_school .marg {
  margin-left: 20px;
}

.can_toggle_mine {
  margin-right: 10px;
}

.can_toggle_mine .md-button {
  margin: 0;
  min-width: 90px;
  height: 26px;
  min-height: 26px;
  line-height: 26px;
  background: #fff;
  font-size: 14px;
  border: 1px solid #439ADF;
  color: #439ADF;
}

.can_toggle_mine .md-button:nth-of-type(1) {
  border-radius: 3px 0px 0px 3px;
}

.can_toggle_mine .md-button:nth-of-type(2) {
  border-radius: 0px 3px 3px 0px;
  border-left: none;
}

.can_toggle_mine .mine_active {
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  color: #fff;
}

#reporting_school_specific .btn_link.active {
  background: #546e7a;
}

#reporting_school_specific .school_selected {
  background: white;
}

#reporting_school_specific md-list md-list-item .md-button:hover {
  background: rgba(255, 255, 255, 0.2);
}

#reporting_school_specific .project_progress .md-container.md-mode-determinate {
  height: 10px;
}

#reporting_school_specific table td {
  border: none;
  padding-left: 3px;
  line-height: 20px;
  color: #666;
}

#reporting_school_specific table th:first-child,
#reporting_school_specific table td:first-child {
  padding-left: 0px;
}

#reporting_school_specific .stu_table thead {
  background-color: #556e7a;
}

#reporting_school_specific .stu_table th:first-child,
#reporting_school_specific .stu_table td:first-child {
  padding-left: 10px;
}

#reporting_school_specific .stu_table th {
  line-height: 50px;
  color: white;
  font-size: 14px;
}

#reporting_school_specific .stu_table td {
  line-height: 30px;
}

#reporting_school_specific .stu_table td > div.max_w {
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

#reporting_school_specific .stu_table td md-icon {
  font-size: 20px;
  cursor: pointer;
}

#reporting_school_specific .stu_table td md-icon:hover {
  color: #29b6e5;
}

#reporting_school_specific .stu_table .stu_list:nth-child(2n) {
  background: #F5F5F5;
}

#reporting_school_specific .stu_table .no_border:last-child {
  border: none !important;
}

.reporting_student_specific_component .btn_link.active {
  background: #546e7a;
}

.reporting_student_specific_component .is_new {
  background: #F4511E;
  position: absolute;
  border-radius: 2px;
  padding: 3px;
  font-size: 11px;
  color: #fff;
  display: inline-block;
  line-height: 12px;
  font-family: PingFangSC-Semibold;
  right: 0;
  top: 0;
}

.reporting_student_specific_component table {
  border-collapse: inherit;
  border-bottom: 1px solid #ccc;
}

.reporting_student_specific_component table .top_fix {
  background: white;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.reporting_student_specific_component table .left_fix {
  border-right: 1px solid #ccc;
}

.reporting_student_specific_component table th, .reporting_student_specific_component table td {
  padding: 0px !important;
}

.reporting_student_specific_component table th {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background: white;
  border-bottom: 1px solid #ccc;
  z-index: 2;
}

.reporting_student_specific_component table th:first-child, .reporting_student_specific_component table th:nth-child(2) {
  left: 0px;
  z-index: 51;
}

.reporting_student_specific_component table td:first-child, .reporting_student_specific_component table td:nth-child(2) {
  position: sticky;
  position: -webkit-sticky;
  left: 0px;
}

.reporting_student_specific_component table .min_wid:first-child {
  min-width: 100px;
}

.reporting_student_specific_component table .no_border {
  height: 50px;
  overflow-y: auto;
}

.reporting_student_specific_component table .no_border:last-child {
  border: none !important;
}

.reporting_student_specific_component table tbody td {
  background: white;
}

.reporting_student_specific_component table tbody tr:nth-child(2n-1) {
  background: #f7f7f7;
}

.reporting_student_specific_component table tbody tr:nth-child(2n-1) td {
  background: #f7f7f7;
}

body {
  height: 100%;
  overflow: hidden;
}

#plan_table {
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0px;
}

#plan_table .md-checkbox.md-default-theme:not(.md-checked) .md-icon, #plan_table md-checkbox:not(.md-checked) .md-icon {
  border-color: white;
}

#plan_table .hover_red:hover {
  cursor: pointer;
  border: 1px dashed red;
}

#plan_table .event_box {
  background: #eee;
}

#plan_table .has_event {
  background: transparent !important;
  border: 1px dashed red;
}

#plan_table .no_left_border {
  border-left: 1px dashed #ccc !important;
}

#plan_table .hide {
  display: none;
}

#plan_table table {
  border-bottom: 1px solid #ccc;
  border-collapse: inherit;
  overflow: inherit;
}

#plan_table table thead th {
  position: sticky;
  position: -webkit-sticky;
  height: 36px;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  padding: 4px;
  text-align: center;
  background: #eeeeee;
  z-index: 20;
}

#plan_table table thead th span {
  font-size: 24px;
}

#plan_table table tbody td {
  max-width: 120px;
  min-width: 100px;
}

#plan_table table tbody td:first-child, #plan_table table tbody td:nth-child(2), #plan_table table tbody td:nth-child(3) {
  position: sticky;
  position: -webkit-sticky;
}

#plan_table table tbody td.first_col {
  width: 100px;
  min-width: 100px;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 4px;
  text-align: center;
  padding-bottom: 0px;
  z-index: 39;
  left: 0px;
  background: white;
}

#plan_table table tbody td.first_col h4 {
  font-size: 16px;
}

#plan_table table tbody td.second_col > span, #plan_table table tbody td.third_col > span {
  max-height: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-wrap;
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 4;
  word-break: break-all;
}

#plan_table table tbody td.second_col {
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 4px;
  text-align: center;
  z-index: 39;
  min-width: 120px;
  width: 120px;
  background: white;
  left: 110px;
}

#plan_table table tbody td.second_col > span {
  font-size: 16px;
}

#plan_table table tbody td.third_col {
  border: 1px solid #ccc;
  border-top: none;
  padding: 6px;
  text-align: center;
  z-index: 39;
  background: white;
  width: 240px;
  min-width: 240px;
  left: 240px;
}

#plan_table table tbody td.third_col > span {
  position: relative;
}

#plan_table table tbody td.third_col > .popup {
  position: absolute;
  left: 100%;
  width: 100%;
  top: 6px;
  margin-left: 10px;
  background: white;
  white-space: pre-wrap;
  padding: 6px;
  visibility: hidden;
  transition: all .3s;
  transform: translateX(-10%);
  opacity: 0;
}

#plan_table table tbody td.third_col > .popup.in {
  visibility: visible;
  transform: translateX(0%);
  opacity: 1;
}

#plan_table table tbody td .table-edit {
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  overflow: hidden;
}

#plan_table table tbody td.md-cell-import {
  z-index: 39;
}

#plan_table table tbody td.md-cell-hover:hover {
  box-shadow: 0px 0px 2px rgba(3, 169, 244, 0.87);
  z-index: 38;
}

#plan_table table tbody td.md-cell-import:hover {
  z-index: 40;
}

#plan_table table tbody td.md-cell {
  vertical-align: top;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 6px;
  padding-left: 2px;
  padding-top: 2px;
  position: relative;
  background: #fff;
  min-width: 160px;
  height: 90px;
  overflow: visible;
}

#plan_table table tbody td.md-cell div.plan_container {
  cursor: pointer;
  overflow: hidden;
  height: 92px;
  border-radius: 4px;
  padding: 8px 4px;
  padding-top: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  position: relative;
  z-index: 2;
  background: white;
}

#plan_table table tbody td.md-cell div.plan_container div.priority {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid white;
  color: #737272;
  right: 8px;
  text-align: center;
  line-height: 20px;
  font-size: 20px;
  border-radius: 4px;
  top: 4px;
}

#plan_table table tbody td.md-cell div.plan_container p.text_p {
  color: #332a2a;
  text-align: center;
  width: 100%;
}

#plan_table table tbody td .md-icon-button {
  padding: 0px;
  margin: 0px;
  width: 28px;
  height: 28px;
}

#plan_table table tbody td .bind_html {
  font-size: 20px;
  line-height: 20px;
}

#plan_table table tbody td .bind_html p {
  font-size: 20px;
  line-height: 20px;
}

#plan_table table tbody td .moren {
  background: #eeeeee;
}

#plan_table table tbody td .moren span {
  max-width: 100%;
  display: block;
}

#plan_table table tbody td.c_#b996be {
  background: #b996be;
}

#plan_table table tbody td.c_#69c8b4 {
  background: #69c8b4;
}

#plan_table table tbody td.c_#66afd5 {
  background: #66afd5;
}

#plan_table table tbody td.c_#e17069 {
  background: #e17069;
}

#plan_table table tbody td.c_#d9896e {
  background: #d9896e;
}

#plan_table table tbody td.c_#7c909d {
  background: #7c909d;
}

#plan_table table tbody td.c_#ced3d3 {
  background: #ced3d3;
}

#plan_table table tbody td.c_#eccb4f {
  background: #eccb4f;
}

#plan_table table tbody td .panel-menu-container {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

#plan_table table tbody td .panel-menu-container .md-panel.project-menu {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.color-picker md-checkbox {
  margin: 0px;
}

.color-picker md-checkbox:first-child {
  margin-left: 10px;
}

.color-picker md-checkbox:not(.md-checked).c_b996be .md-icon, .color-picker .c_b996be.md-checked .md-icon {
  background-color: #b996be;
  border-radius: 50%;
  border-color: #ccc;
  border-width: 1px;
}

.color-picker md-checkbox:not(.md-checked).c_69c8b4 .md-icon, .color-picker .c_69c8b4.md-checked .md-icon {
  background-color: #69c8b4;
  border-radius: 50%;
  border-color: #ccc;
  border-width: 1px;
}

.color-picker md-checkbox:not(.md-checked).c_66afd5 .md-icon, .color-picker .c_66afd5.md-checked .md-icon {
  background-color: #66afd5;
  border-radius: 50%;
  border-color: #ccc;
  border-width: 1px;
}

.color-picker md-checkbox:not(.md-checked).c_e17069 .md-icon, .color-picker .c_e17069.md-checked .md-icon {
  background-color: #e17069;
  border-radius: 50%;
  border-color: #ccc;
  border-width: 1px;
}

.color-picker md-checkbox:not(.md-checked).c_d9896e .md-icon, .color-picker .c_d9896e.md-checked .md-icon {
  background-color: #d9896e;
  border-radius: 50%;
  border-color: #ccc;
  border-width: 1px;
}

.color-picker md-checkbox:not(.md-checked).c_7c909d .md-icon, .color-picker .c_7c909d.md-checked .md-icon {
  background-color: #7c909d;
  border-radius: 50%;
  border-color: #ccc;
  border-width: 1px;
}

.color-picker md-checkbox:not(.md-checked).c_ced3d3 .md-icon, .color-picker .c_ced3d3.md-checked .md-icon {
  background-color: #ced3d3;
  border-radius: 50%;
  border-color: #ccc;
  border-width: 1px;
}

.color-picker md-checkbox:not(.md-checked).c_eccb4f .md-icon, .color-picker .c_eccb4f.md-checked .md-icon {
  background-color: #eccb4f;
  border-radius: 50%;
  border-color: #ccc;
  border-width: 1px;
}

.color-picker md-checkbox:not(.md-checked).c_ffffff .md-icon, .color-picker .c_ffffff.md-checked .md-icon {
  background-color: #ffffff;
  border-radius: 50%;
  border-color: #ccc;
  border-width: 1px;
}

.roadmap_simditor {
  padding-top: 10px;
  width: 340px;
  height: 200px;
}

.roadmap_simditor .simditor .simditor-toolbar > ul li:nth-of-type(7), .roadmap_simditor .simditor .simditor-toolbar > ul li:nth-of-type(13), .roadmap_simditor .simditor .simditor-toolbar > ul li:nth-of-type(15), .roadmap_simditor .simditor .simditor-toolbar > ul li:nth-of-type(17), .roadmap_simditor .simditor .simditor-toolbar > ul li:nth-of-type(18), .roadmap_simditor .simditor .simditor-toolbar > ul li:nth-of-type(19), .roadmap_simditor .simditor .simditor-toolbar > ul li:nth-of-type(20), .roadmap_simditor .simditor .simditor-toolbar > ul li:nth-of-type(21), .roadmap_simditor .simditor .simditor-toolbar > ul li:nth-of-type(22) {
  display: none;
}

.roadmap_simditor .simditor .simditor-wrapper .simditor-placeholder {
  display: none !important;
}

.roadmap_simditor .simditor .simditor-toolbar {
  width: 100% !important;
}

.roadmap_simditor .simditor .simditor-body {
  min-height: 100px;
  padding: 10px;
  height: 100%;
  overflow-y: auto;
}

.roadmap_simditor .simditor .simditor-body ol > li {
  list-style: decimal;
  list-style-position: inside;
}

.roadmap_simditor .simditor .simditor-body ul > li {
  list-style: disc;
  list-style-position: inside;
}

.roadmap_simditor .simditor .simditor-toolbar .toolbar-menu ul > li .menu-item {
  line-height: 1.5em;
}

.roadmap_simditor .simditor .simditor-toolbar > ul > li > .toolbar-item {
  width: 45px;
}

.roadmap_simditor .simditor .simditor-toolbar > ul > li > span.separator {
  margin: 0;
}

.roadmap_simditor .simditor .simditor-body h1, .roadmap_simditor .simditor .simditor-body h2, .roadmap_simditor .simditor .simditor-body h3, .roadmap_simditor .simditor .simditor-body h4, .roadmap_simditor .simditor .simditor-body h5, .roadmap_simditor .simditor .simditor-body h6, .roadmap_simditor .editor-style h1, .roadmap_simditor .editor-style h2, .roadmap_simditor .editor-style h3, .roadmap_simditor .editor-style h4, .roadmap_simditor .editor-style h5, .roadmap_simditor .editor-style h6 {
  margin: 0;
}

.roadmap_simditor .simditor .simditor-body p, .roadmap_simditor .simditor .simditor-body div, .roadmap_simditor .editor-style p, .roadmap_simditor .editor-style div {
  margin: 0;
}

.roadmap_simditor .simditor .simditor-body ul, .roadmap_simditor .simditor .simditor-body ol, .roadmap_simditor .editor-style ul, .roadmap_simditor .editor-style ol {
  margin: 0;
  padding: 0;
}

.roadmap_simditor .simditor .simditor-body blockquote, .roadmap_simditor .editor-style blockquote {
  margin: 0;
}

.roadmap_simditor .simditor .simditor-body code, .roadmap_simditor .editor-style code {
  margin: 0;
}

.roadmap_simditor .simditor .simditor-body pre, .roadmap_simditor .editor-style pre {
  margin: 0;
}

.roadmap_simditor .simditor .simditor-body hr, .roadmap_simditor .editor-style hr {
  margin: 0;
}

.roadmap_simditor .simditor .simditor-body table, .roadmap_simditor .editor-style table {
  margin: 0;
}

#monthly_plan_ng_simditor .simditor-toolbar {
  display: none;
}

#monthly_plan_ng_simditor .simditor-body {
  padding: 0px;
  min-height: 30px;
}

#monthly_plan_ng_simditor .simditor-wrapper {
  height: 30px !important;
}

.no_table ul li:nth-of-type(12) {
  display: none;
}

.scale_select_container {
  position: absolute;
  top: 86px;
  right: 18px;
  z-index: 100;
  background: #fff;
  font-size: 12px;
  border-radius: 3px;
}

.scale_select_container select {
  border: 1px solid #f44336;
  color: #f44336;
}

.scale_select_container select:hover {
  background: #f44336;
  color: #fff;
}

.notes_records {
  width: 0;
  height: calc(100% - 116px);
  position: absolute;
  right: 0;
  top: 116px;
  z-index: 100;
  background: #efefef;
  color: #4A4A4A;
  transition: width 0.5s;
  -webkit-transition: width 0.5s;
}

.notes_records .title {
  margin-top: 8px;
}

.notes_records .title .md-button {
  margin: 0;
  width: 120px;
  height: 28px;
  min-height: 28px;
  line-height: 28px;
  background: #fff;
  font-size: 14px;
}

.notes_records .title .md-button:nth-of-type(1) {
  border-radius: 3px 0px 0px 3px;
}

.notes_records .title .md-button:nth-of-type(2) {
  border-radius: 0px 3px 3px 0px;
}

.notes_records .title .active {
  background: #439ADF;
  color: #fff;
}

.notes_records .new {
  margin: 18px 17px 30px 17px;
  overflow-y: auto;
  height: calc(100% - 86px);
}

.notes_records .new .simiditor_note {
  height: 80%;
  overflow-y: hidden;
}

.notes_records .new .simditor {
  height: 100%;
}

.notes_records .new .tip {
  margin-bottom: 10px;
}

.notes_records .new .tip h2 {
  font-size: 18px;
}

.notes_records .new .tip span {
  font-size: 14px;
  color: #777;
}

.notes_records .new .simditor .simditor-toolbar {
  width: 100% !important;
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
}

.notes_records .new .simditor .simditor-wrapper {
  height: 100%;
}

.notes_records .new .simditor .simditor-body {
  min-height: 400px;
}

.notes_records .new .simditor .simditor-wrapper .simditor-placeholder {
  display: none !important;
}

.notes_records .his {
  margin: 0 17px 30px 17px;
  overflow-y: auto;
  height: calc(100% - 116px);
}

.notes_records .his .simditor .simditor-toolbar {
  width: 100% !important;
}

.notes_records .his .noteDiv {
  margin-top: 24px;
  word-wrap: break-word;
  word-break: break-all;
}

.notes_records .his .noteDiv h4 {
  font-size: 18px;
  font-family: PingFangSC-Medium;
  color: #439adf;
  padding-bottom: 6px;
}

.notes_records .meeting_btn {
  background: #439ADF;
  color: #fff;
}

.notes_records .md-button.md-raised:not([disabled]):hover {
  background: #439ADF;
  color: #fff;
}

.roadmap_month_view {
  background-color: #fff;
}

.roadmap_month_view .num {
  color: #fff;
  width: 24px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  display: inline-block;
  border-radius: 3px;
  margin-right: 10px;
  margin-left: 10px;
}

.roadmap_month_view .color1 {
  background-color: #439ADF;
}

.roadmap_month_view .color2 {
  background-color: #DFC45A;
}

.roadmap_month_view .color3 {
  background-color: #5DB37E;
}

.roadmap_month_view .color4 {
  background-color: #7B87C6;
}

.roadmap_month_view .student_list {
  width: 211px;
  overflow-y: auto;
}

.roadmap_month_view .student_list .input_container {
  width: 190px;
  height: 32px;
  margin: 15px 10px 20px 10px;
  padding: 0 10px;
  background: #f5f5f5;
  color: #909090;
  border-radius: 3px;
  box-sizing: border-box;
}

.roadmap_month_view .student_list .input_container .md-select-value {
  border: none;
}

.roadmap_month_view .student_list .studentList {
  border: 1px solid #DCDCDC;
  overflow-y: auto;
  height: 100%;
  cursor: pointer;
}

.roadmap_month_view .student_list .studentList p {
  border-bottom: 1px solid #DCDCDC;
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
  font-size: 14px;
}

.roadmap_month_view .student_list .studentList p:last-child {
  border: none;
}

.roadmap_month_view .student_list .studentList .stu_bor {
  border-left: 5px solid #006dd5;
  background: #eee;
}

.roadmap_month_view .right_content .selectYear {
  margin-top: 15px;
  margin-left: 15px;
  color: #006DD5;
}

.roadmap_month_view .right_content .selectYear .md-select-value {
  border-bottom: 0;
  color: #006DD5;
}

.roadmap_month_view .right_content .selectYear .md-select-value .md-select-icon {
  color: #006DD5;
}

.roadmap_month_view .right_content md-slider {
  margin-right: 20px;
}

.roadmap_month_view .right_content .yearInput {
  height: 32px;
  margin: 10px;
  border: none;
  color: #006DD5;
}

.roadmap_month_view .right_content .monthArr {
  color: #6D6D6D;
  font-size: 10px;
}

.roadmap_month_view .right_content .active {
  color: #1589EE;
}

.roadmap_month_view .right_content md-content {
  overflow-x: auto;
}

.roadmap_month_view .right_content md-content md-card {
  width: 164px;
  height: 192px;
  min-width: 164px;
  min-height: 192px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
  margin-left: 10px;
  box-sizing: border-box;
  overflow-y: auto;
  cursor: pointer;
}

.roadmap_month_view .right_content md-content md-card md-card-title {
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  border-bottom: 1px solid #dcdcdc;
}

.roadmap_month_view .right_content md-content md-card md-card-content {
  padding-top: 8px;
}

#school {
  padding: 20px;
}

#school .scroll_btn {
  position: absolute;
  z-index: 50;
  padding: 0px;
  margin: 0px;
  width: 20px;
  height: 40px;
  background: #7986CB;
  border-radius: 0;
}

#school .scroll_btn md-icon {
  color: #fff;
}

#school .scroll_btn_left {
  right: 0px;
  top: 0px;
}

#school .scroll_btn_right {
  right: 0px;
  top: 0px;
}

#school .table_container {
  padding-right: 10px;
  height: 100%;
  overflow: auto;
}

#school .table_container table {
  overflow: inherit;
}

#school .table_container table thead th {
  padding: 10px 3px;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background: #7986CB;
  z-index: 1;
  white-space: nowrap;
}

#school .table_container table thead th:first-child > div,
#school .table_container table thead th:nth-child(2) > div {
  padding-left: 10px !important;
}

#school .table_container table tbody tr:first-child td {
  padding-top: 0px;
}

#school .table_container table tbody tr {
  cursor: pointer;
}

#school .table_container table tbody tr:hover {
  border: 1px solid #9B9B9B;
  box-sizing: border-box;
  background: rgba(238, 238, 238, 0.97);
}

#school .table_container table tbody tr:hover td .box {
  border: none;
}

#school .table_container table tbody td {
  padding: 3px 0px;
}

#school .table_container table tbody td .box {
  padding: 0px 8px;
  padding-left: 23px;
  height: 43px;
  border-bottom: 1px solid #9B9B9B;
  border-top: 1px solid #9B9B9B;
  position: relative;
}

#school .table_container table tbody td:first-child .box {
  padding-left: 8px;
  border-left: 1px solid #9B9B9B;
  font-weight: bold;
}

#school .table_container table tbody td:nth-child(2) .box {
  padding-left: 8px;
  font-weight: bold;
}

#school .table_container table tbody td:nth-child(3) .box {
  padding-left: 8px;
  font-weight: bold;
}

#school .table_container table tbody td:nth-last-child(1) .box {
  border-right: 1px solid #9B9B9B;
}

#school .select_btn .title {
  color: #439ADF;
  padding-bottom: 8px;
  font-size: 18px;
}

#school .select_btn .search_item {
  border: 2px solid #439adf;
  border-radius: 3px;
  margin-left: 5px;
  height: 24px;
  line-height: 24px;
  padding: 0 5px;
  color: #439adf;
  font-weight: bold;
}

#school .select_btn .search_item .close {
  color: #439adf;
  cursor: pointer;
}

#school .select_btn .add {
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  padding: 0;
  margin: 0;
  margin-left: 10px;
  background: white;
  border-radius: 3px;
  border: 2px solid #439adf;
}

#school .select_btn .add md-icon {
  color: #439adf;
  font-size: 24px;
  font-weight: bold;
}

#school .select_btn .search_list {
  height: 28px;
  color: #4a4a4a;
  border: 1px solid #4a4a4a;
  background: transparent;
  border-radius: 3px;
  appearance: none;
  margin-left: 10px;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding: 0 30px 0 5px;
  font-size: 16px;
  z-index: 3;
  position: relative;
  max-width: 300px;
}

#school .select_btn .search_icon {
  position: absolute;
  top: 0;
  right: 0;
  height: 28px;
  line-height: 28px;
  width: 28px;
  z-index: 2;
  color: #439adf;
  cursor: pointer;
}

#school .select_btn .textInput {
  margin-left: 10px;
  border-radius: 3px;
  border: 1px solid #4a4a4a;
  text-indent: 5px;
  color: #4a4a4a;
  height: 28px;
}

#school .select_btn .search_btn {
  margin: 0;
  min-width: 78px;
  height: 28px;
  min-height: 28px;
  line-height: 28px;
  background: linear-gradient(45deg, #439ADF 0, #78CAF1 100%);
  font-size: 16px;
  font-family: PingFangSC-Regular;
  color: white;
  margin-left: 10px;
  box-shadow: 0px 1px 2px 0px rgba(67, 154, 223, 0.6);
}

#school .select_btn .search_btn md-icon {
  font-size: 18px;
  height: 20px;
  width: 20px;
  min-width: 20px;
  min-height: 20px;
  color: #fff;
}

#school .add_school {
  background-image: linear-gradient(45deg, #5EE1BF 0, #439ADF 100%);
  padding: 0 2px;
  margin: 0px;
  color: white;
}

#school .add_school:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

#school .search_alias {
  margin-bottom: 5px;
  border: 1px solid #4a4a4a;
  border-radius: 3px;
  height: 28px;
  line-height: 28px;
  padding-left: 5px;
}

#school .search_alias #search_alias_input {
  height: 26px;
  line-height: 26px;
  font-size: 16px;
}

#school .shoolIcon {
  color: white;
  font-size: 18px;
  margin-top: 4px;
  margin-right: -4px;
}

#school .question_title {
  position: absolute;
  right: 0;
  top: 50%;
  width: 25px;
  z-index: 99;
}

#school .question_title .md-button {
  margin: 0;
  min-width: 25px;
  line-height: 18px;
  background: #fff;
  font-size: 14px;
  color: #fff;
  background-color: rgba(67, 154, 223, 0.8);
  font-family: PingFangSC-Regular;
  height: 224px;
  width: 30px;
  white-space: normal;
  z-index: 2;
  border-radius: 16px 0 0 16px;
}

#school .question_title .md-button md-icon {
  color: #fff;
  margin: 0;
}

#school .list_mask {
  position: absolute;
  width: 98%;
  top: 18%;
  height: 82%;
  z-index: 100;
}

#school .app_list {
  position: relative;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  width: 90%;
  height: 90%;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

#school .app_list .application_container {
  overflow: auto;
  height: 85%;
  overflow: hidden;
  overflow-y: auto;
}

#school .app_list .application_container table {
  overflow: inherit;
}

#school .app_list .application_container table thead th {
  padding: 10px 3px;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background: #7986CB;
  z-index: 10;
  white-space: nowrap;
}

#school .app_list .application_container table thead th:first-child > div,
#school .app_list .application_container table thead th:nth-child(2) > div {
  padding-left: 10px !important;
}

#school .app_list .application_container table thead tr th:nth-child(1) {
  width: 55px;
  max-width: 55px;
  left: 0;
  z-index: 11;
}

#school .app_list .application_container table thead tr th:nth-child(2) {
  width: 100px;
  max-width: 100px;
  min-width: 100px;
  left: 61px;
  z-index: 11;
}

#school .app_list .application_container table thead tr th:nth-child(3) {
  left: 167px;
  z-index: 11;
}

#school .app_list .application_container table tbody tr:first-child td {
  padding-top: 0px;
}

#school .app_list .application_container table tbody td {
  padding: 3px 0px;
}

#school .app_list .application_container table tbody td .box {
  padding: 0px 8px;
  padding-left: 23px;
  height: 43px;
  border-bottom: 1px solid #9B9B9B;
  border-top: 1px solid #9B9B9B;
  position: relative;
}

#school .app_list .application_container table tbody td:first-child .box {
  padding-left: 12px;
  border-left: 1px solid #9B9B9B;
  font-weight: bold;
}

#school .app_list .application_container table tbody td:nth-child(2) .box {
  padding-left: 8px;
  font-weight: bold;
}

#school .app_list .application_container table tbody td:nth-child(3) .box {
  padding-left: 8px;
  font-weight: bold;
}

#school .app_list .application_container table tbody td:nth-last-child(1) .box {
  border-right: 1px solid #9B9B9B;
}

#school .app_list .application_container table tbody td .goDetail a {
  cursor: pointer;
  color: #72ACE3;
}

#school .app_list .application_container table tbody tr td:nth-child(1), #school .app_list .application_container table tbody tr td:nth-child(2), #school .app_list .application_container table tbody tr td:nth-child(3) {
  position: sticky;
  position: -webkit-sticky;
  background: #fff;
}

#school .app_list .application_container table tbody tr td:nth-child(1) {
  width: 55px;
  max-width: 55px;
  left: 0;
  z-index: 3;
}

#school .app_list .application_container table tbody tr td:nth-child(2) {
  width: 100px;
  max-width: 100px;
  min-width: 100px;
  left: 61px;
  z-index: 2;
}

#school .app_list .application_container table tbody tr td:nth-child(3) {
  left: 167px;
  z-index: 1;
}

#school .app_list .application_container table tbody tr td .action span {
  cursor: pointer;
}

#school .app_list .closebtn {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #000;
}

#school .app_list .chexiao {
  margin-right: 5px;
}

#school .app_list .action {
  padding-left: 10px !important;
}

#school .downloadAttr {
  margin-left: 5px;
  color: blue;
  cursor: pointer;
}

.status_btn {
  margin-left: 10px;
}

.status_btn .md-button {
  margin: 0;
  min-width: 84px;
  height: 28px;
  min-height: 28px;
  line-height: 28px;
  border-radius: 4px;
  font-size: 12px;
}

.status_btn .dea {
  border: 1px solid #9b9b9b;
  color: #9b9b9b;
}

.status_btn .dea:hover {
  background: #9b9b9b;
  color: #fff;
}

.status_btn .act {
  border: 1px solid #7ED321;
  color: #7ED321;
}

.status_btn .act:hover {
  background: #7ED321;
  color: #fff;
}

.school_application_ac_board .container {
  margin: 10px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.school_application_ac_board .container table {
  overflow: auto;
}

.school_application_ac_board .container table th.md-column, .school_application_ac_board .container table td.md-cell {
  min-width: 80px;
  padding: 0 10px !important;
  box-sizing: border-box;
  max-width: 150px;
}

.school_application_ac_board .container table th.md-column:nth-of-type(2) {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  position: -webkit-sticky;
  position: sticky;
  left: 125px;
  z-index: 3;
}

.school_application_ac_board .container table th.md-column:nth-of-type(3) {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  position: -webkit-sticky;
  position: sticky;
  left: 225px;
  z-index: 3;
}

.school_application_ac_board .container table th.md-column:nth-of-type(4) {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  position: -webkit-sticky;
  position: sticky;
  left: 325px;
  z-index: 3;
}

.school_application_ac_board .container table th.md-column:nth-of-type(5) {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  position: -webkit-sticky;
  position: sticky;
  left: 425px;
  z-index: 3;
}

.school_application_ac_board .container table td.md-cell:nth-of-type(2) {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  position: -webkit-sticky;
  position: sticky;
  left: 125px;
  z-index: 2;
}

.school_application_ac_board .container table td.md-cell:nth-of-type(3) {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  position: -webkit-sticky;
  position: sticky;
  left: 225px;
  z-index: 2;
}

.school_application_ac_board .container table td.md-cell:nth-of-type(4) {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  position: -webkit-sticky;
  position: sticky;
  left: 325px;
  z-index: 2;
}

.school_application_ac_board .container table td.md-cell:nth-of-type(5) {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  position: -webkit-sticky;
  position: sticky;
  left: 425px;
  z-index: 2;
}

.school_application_ac_board .container table td.md-cell {
  height: 60px;
}

.school_application_ac_board .container table th .head {
  width: 100%;
  display: inline-block;
  text-align: center;
}

.school_application_ac_board .container table th .more {
  height: 36px;
  line-height: 33px !important;
  font-size: 18px !important;
  color: white;
  cursor: pointer;
}

.school_application_ac_board .container table .head {
  font-size: 18px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  color: #fff;
}

.school_application_ac_board .container table .tr_head {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #7986CB;
  height: 40px;
  z-index: 1;
}

.school_application_ac_board .container table .fix {
  z-index: 3;
  background: #7986CB;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  font-size: 18px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  color: #fff;
  width: 125px;
  min-width: 125px;
  max-width: 125px;
}

.school_application_ac_board .container table tbody .head {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  min-width: 125px;
  max-width: 125px;
  width: 125px;
  z-index: 2;
  color: #4a4a4a;
}

.school_application_ac_board .container table tbody .head .ranking {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 12px;
  background: #989898;
  min-width: 15px;
  text-align: center;
}

.school_application_ac_board .container table tbody textarea {
  border: none;
  font-size: 14px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  color: #636364;
}

.school_application_ac_board .container table .submit {
  min-height: 24px;
  border-radius: 2px;
  padding: 2px;
}

.school_application_ac_board .container table .gray_color {
  border: 1px solid #979797;
  color: #979797;
}

.school_application_ac_board .container table .blue_color {
  border: 1px solid #439adf;
  color: #439adf;
}

.school_application_ac_board .container table .orange_color {
  border: 1px solid #F5A623;
  color: #F5A623;
}

.school_application_ac_board .container table .red_color {
  border: 1px solid #D0021B;
  color: #D0021B;
}

.school_application_ac_board .container table .green_color {
  border: 1px solid #7ED321;
  color: #7ED321;
}

.school_application_ac_board .container table .noapply_color {
  border: 1px solid #64a70c;
  color: #64a70c;
}

.completed_theme {
  background: #7ED321;
  color: #fff;
  border: none;
  cursor: pointer;
}

.ongoing_theme {
  background: #F5A623;
  color: #fff;
  border: none;
  cursor: pointer;
}

.hurry_theme {
  background: #D0021B;
  color: #fff;
  border: none;
  cursor: pointer;
}

.nostart_theme {
  border: 1px solid #979797;
  color: #979797;
  cursor: pointer;
}

.noneed_theme {
  background: #979797;
  color: #fff;
  border: none;
  cursor: pointer;
}

.fix_bottom {
  padding: 10px;
  padding-bottom: 0px;
  min-height: 80px;
}

.fix_bottom .note {
  margin: 0 10px;
}

.fix_bottom .note textarea {
  min-width: 400px;
  min-height: 80px;
  border-radius: 3px;
  border: 1px solid #cccccc;
  padding: 5px;
  box-sizing: border-box;
}

.fix_bottom select option {
  background: #fff;
  color: #4a4a4a;
}

#school_account webform-input button {
  color: #0091ea;
}

#school_account webform-input button md-icon {
  color: #0091ea;
}

#school_application .nav_list a {
  padding: 6px 16px;
  margin: 0px;
  color: white;
  font-size: 16px;
}

#school_application .nav_list a:hover, #school_application .nav_list a.active {
  background: #0091EA;
}

.school_list {
  padding: 10px 20px;
  overflow: hidden;
}

.school_list .choose_school_title {
  font-size: 16px;
  font-family: PingFangSC-Semibold;
  color: #439adf;
}

.school_list .btnWidth {
  min-width: 40% !important;
}

.school_list .toggle_btn {
  margin: 0;
  border: 2px solid #439adf;
  border-radius: 3px;
  padding: 0;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  margin-left: 5px;
}

.school_list .toggle_btn md-icon {
  color: #439adf;
  margin: 0;
  margin-left: -4px;
  margin-top: -6px;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
}

.school_list .small_score_container {
  padding: 10px 0;
  overflow-x: auto;
}

.school_list .small_score_container .scoreDiv {
  border: 1px solid #DCDCDC;
  margin-right: 10px;
  padding: 10px;
}

.school_list .small_score_container .scoreDiv .span1 {
  font-size: 18px;
  color: #4A4A4A;
  font-family: PingFangSC-Medium;
}

.school_list .small_score_container .scoreDiv .span2 {
  font-size: 36px;
  font-family: Helvetica-Bold;
  color: #439ADF;
}

.school_list .small_score_container .scoreDiv .span3 {
  font-size: 12px;
  font-family: PingFangSC-Regular;
  color: #4A4A4A;
  white-space: nowrap;
  margin-right: 5px;
}

.school_list .small_score_container .scoreDiv .span4 {
  font-size: 24px;
  font-family: Helvetica-Bold;
  color: #4A4A4A;
  text-align: center;
}

.school_list .small_score_container .scoreDiv:last-child {
  margin: 0;
}

.school_list form {
  padding: 10px 0;
}

.school_list md-autocomplete {
  height: 32px;
}

.school_list md-autocomplete md-autocomplete-wrap {
  height: 32px;
  box-shadow: none;
  border: 1px solid #989898;
}

.school_list md-autocomplete input:not(.md-input) {
  font-size: 12px;
  line-height: 32px;
  height: 32px;
}

.school_list .md-select-value {
  border: none;
}

.school_list md-select[disabled] .md-select-value {
  background: none;
}

.school_list .add_btn {
  margin: 0;
  min-width: 78px;
  height: 32px;
  min-height: 32px;
  line-height: 32px;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  color: white;
  box-shadow: 0px 1px 2px 0px rgba(67, 154, 223, 0.6);
}

.school_list .add_btn:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.school_list .add_btn md-icon {
  font-size: 18px;
  height: 20px;
  width: 20px;
  min-width: 20px;
  min-height: 20px;
  color: #fff;
}

.school_list .table1 td.md-cell div.disabled {
  pointer-events: none;
  cursor: crosshair;
}

.school_list .fix {
  background: #7986CB;
}

.school_list .fix span {
  text-indent: 24px;
}

.school_list .left_fix {
  border-right: 1px solid #ccc;
}

.school_list .top_fix {
  border-bottom: 1px solid #ccc;
}

.school_list .table1 table th {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background: #7986CB;
  border-bottom: 1px solid #979797;
  z-index: 2;
}

.school_list .table1 table th span {
  font-size: 14px;
  font-family: Helvetica-Bold;
  color: #fff;
}

.school_list .table1 table th:first-child {
  left: 0px;
  z-index: 3;
}

.school_list .table1 table td span {
  font-size: 14px;
  color: #4a4a4a;
  line-height: 14px;
}

.school_list .table1 table td:first-child {
  z-index: 2;
  position: sticky;
  position: -webkit-sticky;
  left: 0px;
}

.school_list .table1 table td:first-child span {
  font-size: 16px;
  font-family: PingFangSC-Semibold;
  color: #439ADF;
}

.school_list .table1 table td:first-child .text_container {
  max-width: 249px;
}

.school_list .table1 table td:first-child, .school_list .table1 table th:first-child {
  min-width: 160px;
}

.school_list .table1 table td:nth-of-type(2) span {
  white-space: nowrap;
}

.school_list .table1 table .arrow_left {
  position: absolute;
  left: 249px;
  top: 19px;
  background: #fff;
  color: #439ADF;
  cursor: pointer;
}

.school_list .table1 table .three_btn {
  display: none;
  position: absolute;
  width: 100%;
  height: 43px;
  top: 4px;
  left: 0;
  background: rgba(74, 74, 74, 0.6);
}

.school_list .table1 table .three_btn .md-button {
  padding: 0;
  margin: 0;
  border: 1px solid #fff;
  min-width: 30%;
  color: #fff;
}

.school_list .table1 table .td_container:hover .three_btn {
  display: block;
}

.school_list .table1 table.md-table td.md-cell {
  border: none;
  height: 40px;
  padding: 8px 0;
}

.school_list .text_container {
  padding-left: 24px;
  position: absolute;
  left: 0;
  min-width: 100px;
  display: -webkit-box;
  font-size: 10px;
  height: 40px;
  width: 100%;
}

.school_list .text_container span {
  max-height: 29px;
}

.school_list .bor:hover {
  background-color: #f8f8f8;
  cursor: pointer;
}

.school_list .popup {
  position: absolute;
  top: 6px;
  background: white;
  z-index: 50;
  left: 100%;
  width: 164px;
}

.school_list .school_library {
  position: absolute;
  height: 98%;
  right: -515px;
  top: 1%;
  z-index: 100;
  overflow-x: hidden;
  overflow-y: hidden;
  border-radius: 3px;
  background: #fff;
  width: 515px;
  color: #4A4A4A;
  transition: right 0.3s;
  -webkit-transition: right 0.3s;
}

.school_list .school_library .md-virtual-repeat-container .md-virtual-repeat-scroller {
  padding-bottom: 200px;
}

.school_list .school_library .title {
  margin-top: 8px;
}

.school_list .school_library .title .md-button {
  margin: 0;
  min-width: 120px;
  height: 28px;
  min-height: 28px;
  line-height: 28px;
  background: #fff;
  font-size: 14px;
  border: 1px solid #439ADF;
  color: #439ADF;
}

.school_list .school_library .title .md-button:nth-of-type(1) {
  border-radius: 3px 0px 0px 3px;
}

.school_list .school_library .title .md-button:nth-of-type(2) {
  border-radius: 0px 3px 3px 0px;
}

.school_list .school_library .title .active {
  background: #439ADF;
  color: #fff;
}

.school_list .school_library .question_title {
  position: absolute;
  right: 1px;
  top: 50%;
  margin-top: -112px;
}

.school_list .school_library .question_title .md-button {
  margin: 0;
  min-width: 30px;
  line-height: 18px;
  background: #fff;
  font-size: 14px;
  color: #fff;
  background-color: rgba(67, 154, 223, 0.8);
  font-family: PingFangSC-Regular;
  height: 224px;
  width: 30px;
  white-space: normal;
  z-index: 1;
  border-radius: 16px 0 0 16px;
}

.school_list .school_library .question_title .md-button md-icon {
  color: #fff;
  margin: 0;
}

.school_list .school_library .all {
  font-size: 12px;
  font-family: PingFangSC-Regular;
  color: #4a4a4a;
  padding: 20px;
}

.school_list .school_library .all .select_h2 {
  font-size: 14px;
  font-family: PingFangSC-Semibold;
  padding: 10px 0;
}

.school_list .school_library .all .filter_btn {
  cursor: pointer;
  margin-right: 10px;
  line-height: 18px;
}

.school_list .school_library .all .filter_btn.active {
  color: #fe8800;
}

.school_list .school_library .all .filter_btn:hover {
  color: #fe8800;
}

.school_list .school_library .all .stu_body p, .school_list .school_library .all .stu_body div span {
  color: #4ac1fc !important;
}

.school_list .question_content {
  position: absolute;
  height: 98%;
  right: -500px;
  top: 1%;
  z-index: 100;
  overflow-x: hidden;
  overflow-y: hidden;
  border-radius: 3px;
  background: #fff;
  width: 500px;
  color: #4A4A4A;
  transition: right 0.3s;
  -webkit-transition: right 0.3s;
}

.school_list .question_content .table2 {
  border-collapse: initial;
  overflow: scroll;
  padding: 0 10px 10px 10px;
}

.school_list .question_content .table2 thead.md-head > tr.md-row {
  height: 36px;
}

.school_list .question_content .table2 td.md-cell:first-child, .school_list .question_content .table2 td.md-cell:nth-child(n+2):nth-last-child(n+2), .school_list .question_content .table2 td.md-cell:last-child {
  padding: 0;
  padding-left: 8px;
}

.school_list .question_content .table2 th.md-column:nth-child(n+2):nth-last-child(n+2), .school_list .question_content .table2 th.md-column:last-child {
  padding: 0;
  text-align: center;
  font-size: 18px;
}

.school_list .question_content .table2 th {
  position: sticky;
  position: -webkit-sticky;
  top: 0px !important;
  background: #7986CB;
  z-index: 2;
  font-size: 18px;
  font-family: PingFangSC-Medium;
  color: #fff;
  height: 36px;
}

.school_list .question_content .table2 .star-rating {
  unicode-bidi: bidi-override;
  color: #ddd;
  font-size: 0;
  height: 25px;
  margin: 0 auto;
  position: relative;
  display: table;
  padding: 0;
  text-shadow: 0px 1px 0 #a2a2a2;
}

.school_list .question_content .table2 .star-rating span {
  padding: 1px;
  font-size: 14px;
}

.school_list .question_content .table2 .star-rating-top {
  color: #DFC45A;
  padding: 0;
  position: absolute;
  z-index: 1;
  display: block;
  top: 0;
  left: 0;
  overflow: hidden;
  white-space: nowrap;
}

.school_list .question_content .table2 .star-rating-bottom {
  padding: 0;
  display: block;
  z-index: 0;
}

.school_list_custom_column md-dialog-content {
  overflow: scroll;
}

.school_list_custom_column .md-title {
  height: 42px;
  background: #439adf;
  font-size: 20px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  color: white;
  line-height: 42px;
  text-indent: 10px;
}

.school_list_custom_column .custom_title {
  height: 30px;
  font-size: 18px;
  font-family: Helvetica-Bold;
  font-weight: bold;
  color: #439adf;
  padding-left: 10px;
  padding-top: 10px;
}

.school_list_custom_column .child_btn {
  min-width: 148px;
  margin: 0;
  box-sizing: border-box;
  padding: 0px 5px;
  height: 48px;
  background: white;
  position: relative;
  overflow: inherit;
  padding: 5px;
}

.school_list_custom_column .child_btn span {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  border: 1px solid #979797;
  display: block;
  line-height: 40px;
}

.school_list_custom_column .child_btn span:hover {
  background: #439ADF;
  color: #fff;
  border-color: #439ADF;
}

.school_list_custom_column .child_btn .child_active {
  background: #439ADF;
  color: #fff;
  border-color: #439ADF;
}

.school_list_custom_column .child_btn:hover {
  background-color: #fff;
}

.major_college_panel {
  background: white;
  width: 800px;
  height: 400px;
  margin-top: -200px;
  margin-left: -400px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 2px #ccc;
}

.major_college_panel .title {
  padding: 5px 10px;
  color: #439adf;
  font-size: 20px;
}

.major_college_panel .tip {
  padding: 5px 10px 0 10px;
}

.major_college_panel md-autocomplete {
  background: none;
  height: 28px;
}

.major_college_panel md-autocomplete md-autocomplete-wrap {
  height: 28px;
  box-shadow: none;
  border: 1px solid #ccc;
}

.major_college_panel md-autocomplete md-autocomplete-wrap input {
  padding: 0 10px;
  height: 26px;
  line-height: 26px;
}

.major_college_panel md-autocomplete .md-show-clear-button button {
  width: 26px;
  height: 26px;
}

.major_college_panel .add_btn {
  margin: 0;
  margin-left: 10px;
  min-width: 60px;
  height: 28px;
  min-height: 28px;
  line-height: 28px;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  color: white;
  box-shadow: 0px 1px 2px 0px rgba(67, 154, 223, 0.6);
}

.major_college_panel .add_btn:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.major_college_panel .add_btn md-icon {
  font-size: 18px;
  height: 20px;
  width: 20px;
  min-width: 20px;
  min-height: 20px;
  color: #fff;
}

.major_college_panel .content {
  padding: 5px 10px;
  padding-top: 0;
  overflow-y: auto;
  height: 250px;
}

.major_college_panel .content table {
  border: 1px solid #ccc;
  border-bottom: none;
  width: 100%;
}

.major_college_panel .content table thead tr {
  background: #7986CB;
  color: #fff;
  border-bottom: 1px solid #979797;
  height: 30px;
  line-height: 30px;
}

.major_college_panel .content table td {
  border-bottom: 1px solid #ccc;
}

.major_college_panel .content table th {
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  background: #7986CB;
}

.major_college_panel .content table td, .major_college_panel .content table th {
  font-size: 16px;
  text-align: center;
}

.major_college_panel .content table .major_list {
  padding: 0;
  height: 1px;
}

.major_college_panel .content table .major_list .major_box {
  border-bottom: 1px solid #ccc;
}

.major_college_panel .content table .major_list .major_box:hover .delete_icon, .major_college_panel .content table .major_list .major_box:hover .primary_btn {
  visibility: visible;
}

.major_college_panel .content table .major_list .major_box:nth-last-child(1) {
  border-bottom: none;
}

.major_college_panel .content table .delete_icon {
  cursor: pointer;
  visibility: hidden;
}

.major_college_panel .content table .primary_btn {
  padding: 0;
  width: 24px;
  height: 24px;
  min-height: 24px;
  visibility: hidden;
}


.sch_appli_mater_tab {
  background: white;
  padding-top: 10px;
  padding-left: 10px;
}

.sch_appli_mater_tab .tab {
  margin-top: 44px;
  margin-bottom: 3px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
  margin-left: 50px;
  font-size: 0;
}

.sch_appli_mater_tab .tab > .md-button {
  margin: 0;
  width: 176px;
  color: #4A4A4A;
}

.sch_appli_mater_tab .tab > .md-button.btnActive {
  color: #FFFFFF;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

.sch_appli_mater_tab .rotate_container {
  width: 80px;
  height: 86px;
  position: relative;
  transform-style: preserve-3d;
  transform-origin: center center -48px;
  transform: rotateX(0deg);
}

.sch_appli_mater_tab .rotate_container .transform {
  transform: rotateX(0deg);
  transition: all 0.2s linear;
}

.sch_appli_mater_tab .rotate_container .top {
  position: absolute;
  width: 100%;
  height: 36px;
  text-align: center;
  background: white;
  line-height: 14px;
  transform-origin: bottom;
  transform: rotateX(30deg);
  font-size: 14px;
  color: #666;
  z-index: 2;
}

.sch_appli_mater_tab .rotate_container .top.active {
  font-size: 22px;
  color: #439ADF;
}

.sch_appli_mater_tab .rotate_container .mid {
  position: absolute;
  width: 100%;
  height: 30px;
  top: 30px;
  text-align: center;
  background: white;
  line-height: 30px;
  font-size: 22px;
  color: #439ADF;
  z-index: 2;
}

.sch_appli_mater_tab .rotate_container .mid.common {
  color: #666;
  font-size: 14px;
}

.sch_appli_mater_tab .rotate_container .bot {
  position: absolute;
  width: 100%;
  height: 36px;
  top: 57px;
  text-align: center;
  background: white;
  line-height: 14px;
  transform-origin: top;
  transform: rotateX(-30deg);
  padding-top: 20px;
  font-size: 14px;
  color: #666;
  z-index: 2;
}

.sch_appli_mater_tab .rotate_container .bot.active {
  font-size: 22px;
  color: #439ADF;
}

#school_application_material_content {
  padding-top: 16px;
  padding-left: 10px;
  position: relative;
}

#school_application_material_content md-table-container {
  padding: 0px;
  padding-bottom: 20px;
}

#school_application_material_content md-table-container table {
  overflow: inherit;
}

#school_application_material_content md-table-container table .border_right .border_box {
  overflow: hidden;
  border-right: 1px solid #979797;
}

#school_application_material_content md-table-container table th {
  padding: 0 5px 0 5px !important;
  font-weight: 700;
  color: white;
  font-size: 14px;
  /* autoprefixer: off */
  position: sticky;
  /* autoprefixer: on */
  line-height: 47px;
  top: 0px;
  background: #7986CB;
  z-index: 40;
}

#school_application_material_content md-table-container table th .bor {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  border-bottom: 1px solid #979797;
}

#school_application_material_content md-table-container table th.border_right .bor {
  border-right: 1px solid #fff;
  left: -1px;
}

#school_application_material_content md-table-container table th:nth-child(1) {
  z-index: 41;
  left: 0px;
  width: 27px;
  min-width: 27px;
  max-width: 27px;
}

#school_application_material_content md-table-container table th:nth-child(1) .bor {
  border-right: 1px solid #fff;
  left: 0px;
}

#school_application_material_content md-table-container table th:nth-child(2) {
  z-index: 41;
  width: 149px;
  min-width: 149px;
  max-width: 149px;
  left: 38px;
}

#school_application_material_content md-table-container table th:nth-child(2) .bor {
  border-right: 1px solid #fff;
  left: 0px;
}

#school_application_material_content md-table-container table th:nth-child(3) {
  z-index: 41;
  left: 198px;
  width: 60px;
  min-width: 60px;
  max-width: 60px;
}

#school_application_material_content md-table-container table th:nth-child(3) .bor {
  border-right: 1px solid #fff;
  left: -1px;
}

#school_application_material_content md-table-container table th:nth-child(4) {
  padding-left: 30px !important;
}

#school_application_material_content md-table-container table th:last-child {
  padding-right: 20px !important;
}

#school_application_material_content md-table-container table td {
  padding: 5px 0px;
  padding-bottom: 0px;
  position: relative;
}

#school_application_material_content md-table-container table td .border_box {
  border: 1px solid #979797;
  border-left: none;
  border-right: none;
  height: 48px;
}

#school_application_material_content md-table-container table td .border_box .text_container {
  width: 100%;
  min-width: 50px;
  display: -webkit-box;
  font-size: 10px;
  line-height: 14px;
  height: 100%;
  position: absolute;
}

#school_application_material_content md-table-container table td .can_fix {
  cursor: pointer;
}

#school_application_material_content md-table-container table td .can_fix:hover {
  background-color: #f8f8f8;
  box-shadow: 2px 2px 3px #979797 inset;
}

#school_application_material_content md-table-container table td .popup {
  position: absolute;
  top: 6px;
  background: white;
  z-index: 50;
  left: 100%;
  width: 164px;
}

#school_application_material_content md-table-container table td:nth-child(1), #school_application_material_content md-table-container table td:nth-child(2), #school_application_material_content md-table-container table td:nth-child(3) {
  position: -webkit-sticky;
  position: sticky;
  z-index: 12;
}

#school_application_material_content md-table-container table td:nth-child(1) .border_box, #school_application_material_content md-table-container table td:nth-child(2) .border_box, #school_application_material_content md-table-container table td:nth-child(3) .border_box {
  overflow: hidden;
  border-left: 1px solid #979797;
  background: #f8f8f8;
  color: #439ADF;
  padding-right: 0px;
  cursor: pointer;
}

#school_application_material_content md-table-container table td:nth-child(4) .border_box {
  overflow: hidden;
  padding-left: 20px;
}

#school_application_material_content md-table-container table td:nth-child(1) {
  left: 0px;
}

#school_application_material_content md-table-container table td:nth-child(2) {
  left: 37px;
}

#school_application_material_content md-table-container table td:nth-child(3) {
  left: 196px;
}

#school_application_material_content md-table-container table td:nth-child(3) .border_box {
  overflow: hidden;
  border-right: 1px solid #979797;
}

#school_application_material_content .apScore {
  min-height: 20px;
  width: 100%;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  -webkit-box-pack: center;
  -webkit-line-clamp: 2;
  /* autoprefixer: on */
  padding-left: 10px;
  width: 100px;
}

#school_application_material_content .apScore span {
  white-space: inherit !important;
}

.school-application-overview .ap-multiple-select {
  border-bottom: solid 1px #439ADF;
}

.school-application-overview .top .tab {
  height: 34px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
  margin: 15px 0px 5px 10px !important;
}

.school-application-overview .top .tab > .md-button {
  margin: 0;
  width: 176px;
  color: #4A4A4A;
}

.school-application-overview .top .tab > .md-button.active {
  color: #FFFFFF;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

.school-application-overview .showtip {
  box-sizing: border-box;
  width: 96%;
  height: 96%;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  font-weight: bold;
  color: #fff;
  border: 2px solid #fff;
  background-color: rgba(0, 0, 0, 0.7);
  line-height: 100%;
}

.school-application-overview .showtip span {
  font-size: 14px;
}

.school-application-overview .showtip .sec {
  margin-left: 10px;
}

.school-application-overview .page_list {
  padding-bottom: 10px;
  padding-top: 10px;
}

.school-application-overview .page_list .md-button {
  padding: 0px;
  margin: 0px;
  color: #428bca;
  border: 1px solid #dee5e7;
  border-left: none;
  background: white;
  width: 30px;
  height: 30px;
  min-width: 0px;
  min-height: 0px;
  line-height: 28px;
}

.school-application-overview .page_list .md-button span {
  font-size: 10px;
  font-weight: bold;
}

.school-application-overview .page_list .md-button:first-child, .school-application-overview .page_list .md-button:nth-of-type(3) {
  border-left: 1px solid #dee5e7;
}

.school-application-overview .page_list .md-button.active {
  background: #428bca;
  color: white;
}

.school-application-overview .page_list .md-button:hover {
  background: #dee5e7;
}

.school-application-overview .page_list .md-button.active:hover {
  background: #428bca;
}

.school-application-overview .add_btn {
  margin: 0;
  min-width: 78px;
  height: 32px;
  min-height: 32px;
  line-height: 32px;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  color: white;
  box-shadow: 0px 1px 2px 0px rgba(67, 154, 223, 0.6);
}

.school-application-overview .add_btn:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.school-application-overview .add_btn md-icon {
  font-size: 18px;
  height: 20px;
  width: 20px;
  min-width: 20px;
  min-height: 20px;
  color: #fff;
}

.school-application-overview .strat_school_reporting {
  padding: 0px 10px 10px 10px;
}

.school-application-overview .strat_school_reporting .top {
  background: white;
  margin-top: 10px;
}

.school-application-overview .strat_school_reporting .top .formTab {
  display: flex;
  align-items: center;
}

.school-application-overview .strat_school_reporting .top .formTab .itemFilter {
  margin-left: 15px;
}

.school-application-overview .strat_school_reporting .top .search_box {
  position: relative;
}

.school-application-overview .strat_school_reporting .top .search_box md-autocomplete {
  background: none;
  height: 32px;
}

.school-application-overview .strat_school_reporting .top .search_box md-autocomplete md-autocomplete-wrap {
  height: 32px;
  box-shadow: none;
  border: 1px solid #9B9B9B;
}

.school-application-overview .strat_school_reporting .top .search_box md-autocomplete md-autocomplete-wrap input {
  padding: 0 10px;
  height: 32px;
  line-height: 32px;
  min-width: 150px;
}

.school-application-overview .strat_school_reporting .container {
  background-color: white;
  padding-bottom: 5px;
  margin: 10px 0px;
  padding-right: 10px;
  border: 1px solid #ccc;
}

.school-application-overview .strat_school_reporting .container .notSend div {
  border-radius: 5px;
  margin: 0px auto;
  height: 30px;
  line-height: 30px;
  width: 75px;
  color: rgba(255, 255, 255, 0.87);
  background-color: #F5A623;
  font-weight: bold;
}

.school-application-overview .strat_school_reporting .container .nothing {
  cursor: not-allowed !important;
  background-color: gainsboro;
}

.school-application-overview .strat_school_reporting .container .notSelect div {
  border-radius: 5px;
  margin: 0px auto;
  height: 30px;
  line-height: 30px;
  width: 75px;
  color: rgba(255, 255, 255, 0.87);
  background-color: #D0021B;
  font-weight: bold;
}

.school-application-overview .strat_school_reporting .container .send div {
  display: inline-block;
  padding: 0 10px;
  white-space: nowrap;
  border-radius: 5px;
  margin: 0px auto;
  height: 30px;
  line-height: 30px;
  color: rgba(255, 255, 255, 0.87);
  background-color: #7ED321;
  font-weight: bold;
}

.school-application-overview .strat_school_reporting .container .waive div {
  border-radius: 5px;
  margin: 0px auto;
  height: 30px;
  line-height: 30px;
  width: 110px;
  color: rgba(255, 255, 255, 0.87);
  background-color: #439ADF;
  font-weight: bold;
}

.school-application-overview .strat_school_reporting .container .selectColor {
  display: flex;
  justify-content: center;
  height: 48px;
  align-items: center;
}

.school-application-overview .strat_school_reporting .container .selectColor .md-icon-button {
  padding: 0px;
  margin: 0px;
  width: 32px;
  height: 24px;
}

.school-application-overview .strat_school_reporting .container .white {
  background-color: transparent;
}

.school-application-overview .strat_school_reporting .container .red {
  background-color: #e17069;
}

.school-application-overview .strat_school_reporting .container .green {
  background-color: #69c8b4;
}

.school-application-overview .strat_school_reporting .container .yellow {
  background-color: #eccb4f;
}

.school-application-overview .strat_school_reporting .container.first_col {
  width: 100px;
  min-width: 100px;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 4px;
  text-align: center;
  padding-bottom: 0px;
  z-index: 39;
  left: 0px;
  background: white;
}

.school-application-overview .strat_school_reporting .container.first_col h4 {
  font-size: 16px;
}

.school-application-overview .strat_school_reporting .container.second_col > span, .school-application-overview .strat_school_reporting .container.third_col > span {
  max-height: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-wrap;
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 4;
  word-break: break-all;
}

.school-application-overview .strat_school_reporting .container.second_col {
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 4px;
  text-align: center;
  z-index: 39;
  min-width: 120px;
  width: 120px;
  background: white;
  left: 110px;
}

.school-application-overview .strat_school_reporting .container.second_col > span {
  font-size: 16px;
}

.school-application-overview .strat_school_reporting .container.third_col {
  border: 1px solid #ccc;
  border-top: none;
  padding: 6px;
  text-align: center;
  z-index: 39;
  background: white;
  width: 240px;
  min-width: 240px;
  left: 240px;
}

.school-application-overview .strat_school_reporting .container.third_col > span {
  position: relative;
}

.school-application-overview .strat_school_reporting .container.third_col > .popup {
  position: absolute;
  left: 100%;
  width: 100%;
  top: 6px;
  margin-left: 10px;
  background: white;
  white-space: pre-wrap;
  padding: 6px;
  visibility: hidden;
  transition: all .3s;
  transform: translateX(-10%);
  opacity: 0;
}

.school-application-overview .strat_school_reporting .container.third_col > .popup.in {
  visibility: visible;
  transform: translateX(0%);
  opacity: 1;
}

.school-application-overview .strat_school_reporting .container.c_#e17069 {
  background: #e17069;
}

.school-application-overview .strat_school_reporting .container.c_#69c8b4 {
  background: #69c8b4;
}

.school-application-overview .strat_school_reporting .container.c_#eccb4f {
  background: #eccb4f;
}

.school-application-overview .strat_school_reporting .container.c_#00FFFFF {
  background: transparent;
}

.school-application-overview .strat_school_reporting table.md-table th.md-column,
.school-application-overview .strat_school_reporting table.md-table td.md-cell {
  text-align: center;
}

.school-application-overview .strat_school_reporting table {
  border-right: 1px solid #ccc;
  overflow: auto;
  border-collapse: inherit;
  border-bottom: 1px solid #ccc;
}

.school-application-overview .strat_school_reporting table th,
.school-application-overview .strat_school_reporting table td {
  position: relative;
  padding: 0px !important;
}

.school-application-overview .strat_school_reporting table th {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background: white;
  border-bottom: 1px solid #ccc;
  z-index: 2;
  text-align: center;
  min-width: 60px;
  border-right: 1px solid #ccc;
  line-height: 40px;
}

.school-application-overview .strat_school_reporting table tbody td {
  background: white;
  border-right: 1px solid #ccc;
}

.school-application-overview .strat_school_reporting table tbody .bac_incomplete {
  background: #fff;
}

.school-application-overview .strat_school_reporting table tbody .bac_submitted {
  background: #ECF3FC;
}

.school-application-overview .strat_school_reporting table tbody .bac_reject {
  background: #FAE5E8;
}

.school-application-overview .strat_school_reporting table tbody .bac_admit {
  background: #F2FAE8;
}

.school-application-overview .strat_school_reporting table tbody .bac_other {
  background: #FEF6E9;
}

.school-application-overview .strat_school_reporting table tbody .bac_noapply {
  background: #e2e2e2;
}

.color-picker md-checkbox {
  margin: 0px;
}

.color-picker md-checkbox:first-child {
  margin-left: 10px;
}

.color-picker md-checkbox:not(.md-checked).c_69c8b4 .md-icon,
.color-picker .c_69c8b4.md-checked .md-icon {
  background-color: #69c8b4;
  border-radius: 50%;
  border-color: #ccc;
  border-width: 1px;
}

.color-picker md-checkbox:not(.md-checked).c_e17069 .md-icon,
.color-picker .c_e17069.md-checked .md-icon {
  background-color: #e17069;
  border-radius: 50%;
  border-color: #ccc;
  border-width: 1px;
}

.color-picker md-checkbox:not(.md-checked).c_eccb4f .md-icon,
.color-picker .c_eccb4f.md-checked .md-icon {
  background-color: #eccb4f;
  border-radius: 50%;
  border-color: #ccc;
  border-width: 1px;
}

.color-picker md-checkbox:not(.md-checked).c_00FFFFF .md-icon,
.color-picker .c_00FFFFF.md-checked .md-icon {
  background-color: transparent;
  border-radius: 50%;
  border-color: #ccc;
  border-width: 1px;
}

.school_appli_score .tab {
  margin: 16px 16px 3px 16px;
  font-size: 0;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
}

.school_appli_score .tab > .md-button {
  margin: 0;
  width: 150px;
  color: #4A4A4A;
}

.school_appli_score .tab > .md-button.btnActive {
  color: #FFFFFF;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

.school_appli_score .add {
  width: auto;
  height: auto;
  min-height: auto;
  background: #439ADF;
  border-radius: 0;
  color: #fff;
}

.school_appli_score .delete_icon {
  position: absolute;
  right: 0;
  top: 0;
  display: none;
  cursor: pointer;
}

.school_appli_score .scroll_icon {
  position: absolute;
  top: 10px;
  color: #7986CB;
  cursor: pointer;
}

.school_appli_score .width120 {
  width: 120px;
}

.school_appli_score .tip {
  color: #999;
}

.school_appli_score .tip_sent {
  color: #fff;
  padding: 3px 8px;
  border-radius: 3px;
  font-size: 14px;
}

.school_appli_score .redColor {
  color: #db4d4d;
  font-weight: 600;
}

.school_appli_score .redColor span {
  font-weight: 600;
}

.school_appli_score .is_add_or_delete {
  color: #db4d4d;
}

.school_appli_score .is_add_or_delete md-icon {
  font-size: 20px;
  color: #db4d4d;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  margin-bottom: 2px;
}

.school_appli_score .has_no_condition {
  color: #ccc;
  text-align: center;
}

.school_appli_score .maxWidth {
  max-width: 120px;
}

.school_appli_score .waive_box {
  position: relative;
}

.school_appli_score .waive_box .waive_i {
  width: 18px;
  height: 18px;
  border: 1px solid #909090;
  border-radius: 50%;
  margin: 0 5px;
  text-align: center;
  line-height: 18px;
  font-size: 18px;
  color: #909090;
}

.school_appli_score .waive_box .waive_con {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  margin-bottom: 10px;
  z-index: 5;
  line-height: 18px;
  width: 600px;
  min-width: 600px;
  max-height: 300px;
  overflow: auto;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px #9c9c9c;
}

.school_appli_score .waive_box .waive_con .first_td {
  color: #4a4a4a;
  font-size: 16px;
  max-width: 150px;
  text-align: left;
  font-weight: bold;
}

.school_appli_score .waive_box .waive_con .third_td {
  border-bottom: 1px dashed;
  color: #439ADF;
  font-weight: bold;
  font-size: 16px;
  max-width: 100px;
  text-align: left;
}

.school_appli_score .can_switch {
  min-height: 38px;
}

.school_appli_score .can_switch .md-button {
  margin: 0;
  min-width: 50px;
  width: 50px;
  height: 24px;
  min-height: 24px;
  line-height: 24px;
  background: #ccc;
  font-size: 14px;
  border: 1px solid #ccc;
  color: #fff;
}

.school_appli_score .can_switch .md-button:nth-of-type(1) {
  border-radius: 3px 0px 0px 3px;
}

.school_appli_score .can_switch .md-button:nth-of-type(2) {
  border-radius: 0px 3px 3px 0px;
  border-left: none;
}

.school_appli_score .can_switch .active {
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  color: #fff;
  border-color: #78CAF1;
}

.school_appli_score .content {
  overflow-y: auto;
  padding: 16px;
}

.school_appli_score .content .subject_container_top {
  margin-top: 10px;
}

.school_appli_score .content .subject_container_top .score_info_td .score_info_bor {
  height: 38px;
  line-height: 38px;
}

.school_appli_score .content .subject_container_top .back_highlight {
  font-weight: bold;
  font-size: 16px;
  color: #439ADF;
  background: rgba(220, 220, 220, 0.2);
  padding: 0 5px;
}

.school_appli_score .content .subject_container_top table.md-table:not(.md-row-select) th.md-column {
  padding: 0;
}

.school_appli_score .content .subject_container_top .thead_tr {
  background: #7986CB;
  height: 24px;
}

.school_appli_score .content .subject_container_top .thead_tr th {
  font-size: 16px;
  color: #fff;
  min-width: 80px;
}

.school_appli_score .content .subject_container_top .borBottomTr {
  border-bottom: 3px solid #ccc;
}

.school_appli_score .content .subject_container_top .borBottomTr:nth-last-child(1) {
  border-bottom: none;
}

.school_appli_score .content .subject_container_top .tbody_tr td {
  height: 1px;
  width: 1px;
}

.school_appli_score .content .subject_container_top .tbody_tr .bor {
  border-bottom: 1px solid #ccc;
  border-right: none;
  text-align: center;
  height: 100%;
}

.school_appli_score .content .subject_container_top .tbody_tr td:nth-last-of-type(1) .bor {
  box-sizing: border-box;
}

.school_appli_score .content .subject_container_top .tbody_tr .score_row {
  position: relative;
}

.school_appli_score .content .subject_container_top .tbody_tr .score_row .delete_icon {
  top: 50%;
  margin-top: -12px;
}

.school_appli_score .content .subject_container_top .tbody_tr .score_row:hover .delete_icon {
  display: block;
}

.school_appli_score .content .subject_container_top .tbody_tr .score_row_box {
  min-height: 40px;
  padding: 0 4px;
  box-sizing: border-box;
  border-right: 1px solid #ccc;
  min-width: 60px;
}

.school_appli_score .content .subject_container_top .tbody_tr .score_row_box span {
  word-break: break-word;
}

.school_appli_score .content .subject_container_top .tbody_tr .minWidth {
  min-width: 90px;
}

.school_appli_score .content .subject_container_top .tbody_tr .borBottom {
  border-bottom: 1px solid #ccc;
}

.school_appli_score .content .subject_container_top .tbody_tr .borRight {
  border-right: 1px solid #ccc;
}

.school_appli_score .content .subject_container_top .tbody_tr .borLeft {
  border-left: 1px solid #ccc;
}

.school_appli_score .content .subject_container_top .tbody_tr .send_score {
  background-image: linear-gradient(225deg, #78CAF1 0, #439ADF 100%);
  color: #fff;
  height: 24px;
  min-height: 24px;
  line-height: 24px;
  font-size: 12px;
}

.school_appli_score .content .advice {
  padding: 10px 0;
}

.school_appli_score .content .advice h5 {
  color: #439ADF;
}

.school_appli_score .content .advice .advice_table {
  border: 1px solid #ccc;
  min-width: 240px;
  margin-right: 20px;
}

.school_appli_score .content .advice .advice_table .th_left {
  text-align: left;
  padding-left: 10px;
  font-weight: 400;
}

.school_appli_score .content .advice .advice_table .th_right {
  text-align: right;
}

.school_appli_score .content .advice .advice_table .repeat_tr {
  border-top: 1px solid #ccc;
  height: 26px;
  line-height: 26px;
}

.school_appli_score .content .advice .advice_table .repeat_tr .repeat_tr_left {
  padding-left: 10px;
  border-right: 1px solid #ccc;
  background: rgba(220, 220, 220, 0.2);
  min-width: 120px;
}

.school_appli_score .content .advice .advice_table .repeat_tr .repeat_tr_right {
  text-align: center;
  color: #439ADF;
  position: relative;
  min-width: 50px;
  border-right: 1px solid #ccc;
}

.school_appli_score .content .advice .advice_table .repeat_tr:hover .delete_icon {
  display: block;
}

.add_policy_send_entries {
  background: white;
  padding: 10px;
  border: 1px solid #ccc;
  padding-top: 0px;
  min-width: 300px;
}

.add_policy_send_entries .title {
  font-size: 16px;
  font-weight: 500;
  max-width: 280px;
}

.add_policy_send_entries .close {
  position: relative;
  left: 12px;
}

.school_appli_score_ac .tab {
  margin: 8px 20px 3px 20px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
  display: inline-block;
  font-size: 0;
}

.school_appli_score_ac .tab > .md-button {
  margin: 0;
  width: 300px;
  font-size: 16px;
  font-family: Helvetica-Bold;
  color: #6d6d6d;
}

.school_appli_score_ac .tab > .md-button.btnActive {
  color: #FFFFFF;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

.school_appli_score_ac .leftdiv {
  width: 126px;
}

.school_appli_score_ac .btn {
  background: white;
  box-shadow: 0px 2px 4px 0px rgba(67, 154, 223, 0.6);
  border-radius: 3px;
  border: 1px solid #439adf;
  font-size: 18px;
  font-family: PingFangSC-Medium;
  color: #439adf;
  height: 40px;
}

.school_appli_score_ac .active {
  padding: 0px;
  margin: 0px;
  background: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%) !important;
  box-shadow: 0px 2px 4px 0px rgba(67, 154, 223, 0.6) !important;
  border-radius: 3px !important;
  font-size: 18px !important;
  font-family: PingFangSC-Medium !important;
  border: 0 !important;
  color: #fff !important;
}

.school_appli_score_ac .mr10 {
  margin-right: 10px;
}

.school_appli_score_ac .height100 {
  height: 100% !important;
}

.school_appli_score_ac .info {
  margin: 8px 20px 3px 20px;
}

.school_appli_score_ac .tip_no_sent {
  color: #fff;
  padding: 2px;
  border-radius: 2px;
  font-size: 14px;
  margin-right: 10px;
  background: #D0021B;
}

.school_appli_score_ac .text-title {
  font-size: 18px;
  font-family: PingFangSC-Medium;
  color: #439adf;
  margin-right: 10px;
}

.school_appli_score_ac .toggle_btn {
  margin: 0;
  border: 2px solid #439adf;
  border-radius: 3px;
  padding: 0;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  margin-left: 5px;
}

.school_appli_score_ac .toggle_btn md-icon {
  color: #439adf;
  margin: 0;
  margin-left: -4px;
  margin-top: -6px;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
}

.school_appli_score_ac .credit_card {
  color: #000;
  border: 1px #DCDCDC solid;
  border-bottom: none;
  border-left: none;
}

.school_appli_score_ac .credit_card .card_row {
  border-bottom: 1px solid #DCDCDC;
  height: 30px;
  line-height: 30px;
}

.school_appli_score_ac .credit_card .card_row p {
  text-indent: 15px;
}

.school_appli_score_ac .credit_card .card_row .card_row_title {
  background-color: #EEEEEE;
  border-left: 1px solid #DCDCDC;
  border-right: 1px solid #DCDCDC;
  cursor: pointer;
}

.school_appli_score_ac .focus {
  outline: 2px solid #0091ea;
  outline-offset: -2px;
}

.school_appli_score_ac .content {
  margin: 20px;
  overflow-y: auto;
}

.school_appli_score_ac .content .line {
  border-bottom: 1px solid #439adf;
}

.school_appli_score_ac .content .red_color {
  color: #db4d4d;
  font-weight: 600;
}

.school_appli_score_ac .content .md_list {
  border: 1px solid #dcdcdc;
}

.school_appli_score_ac .content .md_list .header {
  position: relative;
  min-height: 64px;
}

.school_appli_score_ac .content .md_list .md-subheader {
  position: absolute;
  left: 0;
  background: none;
}

.school_appli_score_ac .content .md_list .md-subheader .md-subheader-inner {
  padding: 10px;
}

.school_appli_score_ac .content .md_list .total {
  padding: 5px 5px 0 5px;
}

.school_appli_score_ac .content .md_list .total .span1 {
  white-space: nowrap;
  font-size: 12px;
  font-family: PingFangSC-Regular;
  color: #439ADF;
}

.school_appli_score_ac .content .md_list .total .span2 {
  font-size: 36px;
  font-family: PingFangSC-Medium;
  color: #439ADF;
}

.school_appli_score_ac .content .md_list .total .span3 {
  font-size: 10px;
  font-family: PingFangSC-Medium;
  color: #439adf;
}

.school_appli_score_ac .content .md_list .total .span4 {
  width: 0;
  height: 0;
  border-width: 0 8px 8px;
  border-style: solid;
  border-color: #fff #439ADF #fff #fff;
  border-style: solid;
  border-width: 0 10px 10px 0;
}

.school_appli_score_ac .content .md_list .total1 {
  position: absolute;
  right: 0;
  bottom: 0;
}

.school_appli_score_ac .content .md_list .rlsw {
  padding: 6px;
  text-align: center;
  color: #4a4a4a;
}

.school_appli_score_ac .content .md_list .rlsw span:nth-of-type(1) {
  font-size: 24px;
  font-family: PingFangSC-Medium;
}

.school_appli_score_ac .content .md_list .rlsw span:nth-of-type(2) {
  white-space: nowrap;
  font-size: 12px;
  font-family: PingFangSC-Regular;
}

.school_appli_score_ac .content md-table-container {
  border: 1px solid #dcdcdc;
  border-left: none;
}

.school_appli_score_ac .content md-table-container table.md-table {
  height: 1px;
}

.school_appli_score_ac .content md-table-container table.md-table .tr_title {
  height: 44px;
  background: #7986CB;
}

.school_appli_score_ac .content md-table-container table.md-table .tr_title th.md-column:first-child, .school_appli_score_ac .content md-table-container table.md-table .tr_title th.md-column:nth-child(n+2):nth-last-child(n+2), .school_appli_score_ac .content md-table-container table.md-table .tr_title th.md-column:last-child {
  font-size: 16px;
  font-family: Helvetica-Bold;
  color: white;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #7986CB;
  padding: 8px;
}

.school_appli_score_ac .content md-table-container table.md-table td {
  border-top: 1px solid #dcdcdc;
  font-size: 16px;
  font-family: Helvetica;
  color: #4a4a4a;
  padding: 8px;
  height: 100%;
}

.school_appli_score_ac .content md-table-container table.md-table td:first-child {
  font-family: PingFangSC-Semibold;
  color: #439adf;
}

.school_appli_score_ac .content md-table-container table.md-table td input {
  border: none;
  outline: none;
  width: 100px;
  cursor: pointer;
  height: 40px;
}

.school_appli_score_ac .content md-table-container table.md-table td.send_time {
  border-left: 1px solid #dcdcdc;
  text-align: center;
}

.school_appli_score_ac .content md-table-container table.md-table td.send_time .md-button {
  background: white;
  box-shadow: 0px 2px 4px 0px rgba(67, 154, 223, 0.6);
  border-radius: 3px;
  border: 1px solid #439adf;
  font-size: 18px;
  font-family: PingFangSC-Medium;
  color: #439adf;
  height: 40px;
}

.school_appli_score_ac .content md-table-container table.md-table td.send_time .sent_at_icon {
  font-size: 18px;
  min-width: 18px;
  height: 18px;
  width: 18px;
  min-height: 18px;
  color: #999;
}

.school_appli_score_ac .content md-table-container table.md-table td.send_time .sent_at_input {
  border: none;
  outline: none;
  width: 100px;
  cursor: pointer;
}

.school_appli_score_ac .content md-table-container table.md-table .small_arr {
  height: 100%;
  border-right: 1px solid #ccc;
}

.school_appli_score_ac .content md-table-container table.md-table .deadline {
  display: flex;
  align-items: center;
}

.school_appli_score_ac .content md-table-container table.md-table .deadline .waive_box .waive_i {
  width: 18px;
  height: 18px;
  border: 1px solid #909090;
  border-radius: 50%;
  margin: 0 5px;
  text-align: center;
  line-height: 18px;
  font-size: 18px;
  color: #909090;
}

.school_appli_score_ac .content md-table-container table.md-table .deadline .waive_box .waive_con {
  position: absolute;
  background: white;
  margin-bottom: 10px;
  z-index: 5;
  line-height: 18px;
  width: 600px;
  min-width: 600px;
  max-height: 300px;
  overflow: auto;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px #9c9c9c;
}

.school_appli_score_ac .content md-table-container table.md-table .deadline .waive_box .waive_con .first_td {
  color: #4a4a4a;
  font-size: 16px;
  max-width: 150px;
  text-align: left;
  font-weight: bold;
}

.school_appli_score_ac .content md-table-container table.md-table .deadline .waive_box .waive_con .third_td {
  color: #439ADF;
  font-weight: bold;
  font-size: 16px;
  max-width: 100px;
  text-align: left;
}


.school_specific {
  font-weight: 200;
}

.school_specific th, .school_specific td {
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  text-align: center;
  min-width: 100px;
  position: relative;
  padding: 0px 10px;
}

.school_specific th p, .school_specific th span, .school_specific th div, .school_specific td p, .school_specific td span, .school_specific td div {
  font-size: 12px;
}

.school_specific td.col_active {
  background: rgba(255, 255, 255, 0.3);
}

.school_specific md-table-container .left_fix {
  border-right: 1px solid #ccc;
}

.school_specific md-table-container .top_fix {
  background: #55608f;
  border-bottom: 1px solid #ccc;
}

.school_specific md-table-container table {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.school_specific md-table-container table thead th {
  color: #ffffff;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 2;
  background: #55608f;
}

.school_specific md-table-container table thead th:first-child {
  left: 0px;
  z-index: 3;
}

.school_specific md-table-container table tbody tr {
  color: #000;
}

.school_specific md-table-container table tbody tr td:first-child {
  position: sticky;
  position: -webkit-sticky;
  left: 0px;
  z-index: 2;
  background: white;
}

.school_specific md-table-container table tbody tr:nth-of-type(odd) {
  background-color: lightgray;
}

.school_specific md-table-container table tbody tr:nth-of-type(odd) td {
  background: lightgray;
}

#school_cof_fa {
  padding: 10px 20px;
}

#school_cof_fa .tab {
  position: fixed;
  left: 0;
  width: 88px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
  margin: 10px 10px 16px;
}

#school_cof_fa .tab > a {
  position: relative;
  margin: 0;
  color: #4A4A4A;
  width: 80px;
  padding: 4px;
  height: 3.9vh;
  line-height: 3.9vh;
  border-bottom: 1px #DCDCDC solid;
  cursor: pointer;
}

#school_cof_fa .tab > a span {
  width: 80px;
  text-align: center;
}

#school_cof_fa .tab > a.btnActive {
  color: #FFFFFF;
  background: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

#school_cof_fa .tab > a.btnActive div {
  color: #4A4A4A;
}

#school_cof_fa .school_cof_fa_content {
  margin: 10px;
  overflow-y: auto;
}

#school_cof_fa .school_cof_fa_content .title {
  font-size: 20px;
  font-family: Helvetica-Bold;
  color: #439adf;
  height: 46px;
  border-bottom: 1px solid #DCDCDC;
  padding-left: 15px;
  line-height: 46px;
}

#school_cof_fa .school_cof_fa_content .ap-form-input {
  border-top: 1px solid #DCDCDC;
}

#school_cof_fa .school_cof_fa_content .col {
  color: #000;
  border-bottom: 1px #DCDCDC solid;
}

#school_cof_fa .school_cof_fa_content .col .col_p {
  background-color: #EEEEEE;
  border-left: 1px solid #DCDCDC;
  border-right: 1px solid #DCDCDC;
  height: 36px;
  padding-left: 15px;
  transition: all 100ms;
  -webkit-transition: all 50ms;
  cursor: pointer;
}

#school_cof_fa .school_cof_fa_content .col .col_p:hover {
  background: -webkit-linear-gradient(left, #EEEEEE, #ccc);
}

#school_cof_fa .school_cof_fa_content .col input {
  border: none;
  height: 36px;
  text-indent: 17px;
}

#school_cof_fa .school_cof_fa_content .col input:focus {
  outline: 2px solid #0091ea;
}

#school_cof_fa .school_cof_fa_content .col select {
  border: none;
  height: 36px;
  background: #FFFFFF;
}

#school_cof_fa .school_cof_fa_content .col .tableDiv {
  border-top: 1px solid #DCDCDC;
  border-bottom: 1px solid #DCDCDC;
}

#school_cof_fa .school_cof_fa_content .col .tableDiv:nth-last-of-type(1) {
  border-bottom: none;
}

#school_cof_fa .school_cof_fa_content .col .grade .tableDiv {
  height: 100%;
}

#school_cof_fa .school_cof_fa_content .col .grade .tableDiv p,
#school_cof_fa .school_cof_fa_content .col .grade .tableDiv input {
  height: 100%;
}

#school_cof_fa .school_cof_fa_content .col .bb .tableDiv:nth-last-of-type(2) {
  border-bottom: none;
}

#school_cof_fa .school_cof_fa_content .col .mt {
  margin-top: 10px;
}

#school_cof_fa .school_cof_fa_content .col .mt:nth-of-type(1) {
  margin-top: 0;
}

#school_cof_fa .school_cof_fa_content .edited {
  border: 1px solid red !important;
}

#school_cof_fa .school_cof_fa_content input {
  cursor: pointer;
}

#school_cof_fa .school_cof_fa_content select {
  cursor: pointer;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

#school_cof_fa .school_cof_fa_content select[readonly] option {
  display: none;
}

#school_cof_fa .school_cof_fa_content .multi {
  height: 36px;
  line-height: 36px;
  cursor: pointer;
  padding-left: 18px;
}

#school_cof_fa .panelDiv {
  width: 500px;
  position: absolute;
  top: 20%;
  left: 50%;
  z-index: 1;
  margin-left: -250px;
  background: #fff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

#school_cof_fa .panelDiv .toolbar {
  height: 32px;
  min-height: 24px;
  border-bottom: 1px solid #DCDCDC;
}

#school_cof_fa .panelDiv md-toolbar {
  background: #fff;
}

#school_cof_fa .panelDiv .inputx {
  padding: 20px 10px;
  font-size: 20px;
}

#school_cof_fa .panelDiv .inputx .checkboxdemoSelectAll {
  padding-left: 30px;
}

#school_cof_fa .panelDiv .inputx .uploadList {
  padding-left: 40px;
}

#school_cof_fa .panelDiv .buttondiv {
  border-top: 1px solid #DCDCDC;
}

#school_cof_fa .panelDiv table th {
  font-weight: bold;
}

#school_cof_fa .panelDiv table tr {
  border-bottom: 1px solid #DCDCDC;
}

#school_cof_fa .panelDiv table th,
#school_cof_fa .panelDiv table td {
  padding: 0;
  padding: 5px 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#school_cof_fa .panelDiv table .chexiao {
  cursor: pointer;
}

#school_cof_fa .editListDiv {
  position: absolute;
  height: 100%;
  width: 900px;
  right: -900px;
  background: #fff;
  z-index: 1;
  transition: all 0.3s;
  border-left: 1px #DCDCDC solid;
  overflow-y: auto;
}

#school_cof_fa .editListDiv .title {
  font-size: 20px;
  font-family: Helvetica-Bold;
  color: #439adf;
  height: 46px;
  border-bottom: 1px solid #DCDCDC;
  border-top: 1px solid #DCDCDC;
  padding-left: 15px;
  line-height: 46px;
}

#school_cof_fa .editListDiv .page_list {
  position: absolute;
  left: 50%;
  margin-left: -60px;
  bottom: 0;
}

#school_cof_fa .editListDiv .page_list .md-button {
  padding: 0px;
  margin: 0px;
  color: #428bca;
  border: 1px solid #dee5e7;
  border-left: none;
  background: white;
  width: 30px;
  height: 30px;
  min-width: 0px;
  min-height: 0px;
  line-height: 28px;
}

#school_cof_fa .editListDiv .page_list .md-button span {
  font-size: 10px;
  font-weight: bold;
}

#school_cof_fa .editListDiv .page_list .md-button:first-child {
  border-left: 1px solid #dee5e7;
}

#school_cof_fa .editListDiv .page_list .md-button.active {
  background: #428bca;
  color: white;
}

#school_cof_fa .editListDiv .page_list .md-button:hover {
  background: #dee5e7;
}

#school_cof_fa .editListDiv .page_list .md-button.active:hover {
  background: #428bca;
}

#school_cof_fa .editListDiv table th {
  font-weight: bold;
}

#school_cof_fa .editListDiv table tr {
  height: 50px !important;
  border-bottom: 1px solid #DCDCDC;
}

#school_cof_fa .editListDiv table th,
#school_cof_fa .editListDiv table td {
  padding: 0;
  padding-left: 10px;
  height: 50px !important;
  line-height: 50px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#school_cof_fa .editListDiv table .chexiao {
  cursor: pointer;
}

#school_cof_fa .editListDiv table .active {
  background: #428bca;
  color: white;
}

#school_cof_fa .editListDiv table .show {
  position: relative;
}

#school_cof_fa .question_title {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -112px;
}

#school_cof_fa .question_title .md-button {
  margin: 0;
  min-width: 30px;
  line-height: 18px;
  background: #fff;
  font-size: 14px;
  color: #fff;
  background-color: rgba(67, 154, 223, 0.8);
  font-family: PingFangSC-Regular;
  height: 224px;
  width: 30px;
  white-space: normal;
  z-index: 2;
  border-radius: 16px 0 0 16px;
}

#school_cof_fa .question_title .md-button md-icon {
  color: #fff;
  margin: 0;
}

#school_cof_fa .ap-input-btn {
  background: #439ADF;
  width: 27px;
  height: 27px;
  border-radius: 3px;
  padding: 0px;
  min-width: 27px;
  min-height: 27px;
  line-height: 27px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  text-indent: 0px;
}

#school_cof_fa .ap-input-btn md-icon {
  color: #fff;
  font-size: 21px;
}

#school_cof_fa .add-btn {
  margin-left: 0;
}

#school_cof_fa .cof-input {
  border: none;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  text-indent: 17px;
}

#school_cof_fa .cof-input:focus-within {
  border: 2px solid #0091ea;
}

#school_cof_fa .attachment-container {
  position: relative;
  flex: 1 1 100%;
  display: flex;
}

#school_cof_fa .attachment-container .uploadDoc {
  display: flex;
  flex-wrap: wrap;
}

#school_cof_fa .attachment-container .uploadDoc .uploadList {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

#school_cof_fa .attachment-container .file-list {
  text-indent: 0px;
  z-index: 1000;
  position: absolute;
  left: 0px;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  padding: 16px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

#school_cof_fa .attachment-container .file-list .close-btn {
  position: absolute;
  right: 2px;
  top: 2px;
  font-size: 14px;
  display: block;
  cursor: pointer;
  color: #DCDCDC;
  width: 16px;
  height: 16px;
  line-height: 14px;
  text-align: center;
}

#school_cof_fa .attachment-container .file-list.collapse {
  text-indent: 17px;
  height: 36px;
  overflow: hidden;
  z-index: inherit;
  box-shadow: none;
  padding: 0px;
  position: relative;
}

#school_cof_fa .attachment-container .file-list.collapse .file {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

#school_cof_fa .attachment-container .file-list.collapse .file .file-upload-progress {
  bottom: 7px;
  left: 17px;
}

#school_cof_fa .attachment-container .file-list.collapse .close-btn {
  display: none;
}

#school_cof_fa .attachment-container .file-list .file {
  position: relative;
  min-height: 36px;
  line-height: 36px;
  padding-right: 37px;
  white-space: nowrap;
}

#school_cof_fa .attachment-container .file-list .file > a {
  cursor: pointer;
}

#school_cof_fa .attachment-container .file-list .file > a:hover {
  text-decoration: underline;
}

#school_cof_fa .attachment-container .file-list .file.in-queue {
  cursor: pointer;
}

#school_cof_fa .attachment-container .file-list .file .file-upload-progress {
  bottom: 7px;
  left: 0px;
}

#school_cof_fa .attachment-container .file-list .file .remove-file {
  position: absolute;
  right: 0px;
  top: 0px;
  display: none;
}

#school_cof_fa .attachment-container .file-list .file:hover .remove-file {
  display: block;
}

#school_cof_fa .attachment-container .more-btn {
  position: absolute;
  right: 37px;
}

#school_cof_fa .attachment-container .add-btn {
  position: absolute;
  right: 2px;
}

#school_cof_fa .subject_container_top {
  margin-top: 10px;
}

#school_cof_fa .subject_container_top .score_info_td .score_info_bor {
  height: 38px;
  line-height: 38px;
}

#school_cof_fa .subject_container_top .back_highlight {
  font-weight: bold;
  font-size: 16px;
  color: #439ADF;
  background: rgba(220, 220, 220, 0.2);
  padding: 0 5px;
}

#school_cof_fa .subject_container_top table.md-table:not(.md-row-select) th.md-column {
  padding: 0;
}

#school_cof_fa .subject_container_top .thead_tr {
  background: #7986CB;
  height: 40px;
}

#school_cof_fa .subject_container_top .thead_tr .maxwidth {
  min-width: 400px;
}

#school_cof_fa .subject_container_top .thead_tr .minwidths {
  max-width: 165px;
  min-width: 160px;
}

#school_cof_fa .subject_container_top .thead_tr .maxwidths {
  min-width: 300px;
}

#school_cof_fa .subject_container_top .thead_tr th {
  font-size: 16px;
  color: #fff;
}

#school_cof_fa .subject_container_top .tbody_tr td {
  height: 1px;
  width: 1px;
}

#school_cof_fa .subject_container_top .tbody_tr .maxwidth {
  max-width: 33%;
}

#school_cof_fa .subject_container_top .tbody_tr .maxwidth .can_switch {
  min-height: 38px;
}

#school_cof_fa .subject_container_top .tbody_tr .maxwidth .can_switch .md-button {
  margin: 0;
  min-width: 50px;
  width: 50px;
  height: 24px;
  min-height: 24px;
  line-height: 24px;
  background: #ccc;
  font-size: 14px;
  border: 1px solid #ccc;
  color: #fff;
}

#school_cof_fa .subject_container_top .tbody_tr .maxwidth .can_switch .md-button:nth-of-type(1) {
  border-radius: 3px 0px 0px 3px;
}

#school_cof_fa .subject_container_top .tbody_tr .maxwidth .can_switch .md-button:nth-of-type(2) {
  border-radius: 0px 3px 3px 0px;
  border-left: none;
}

#school_cof_fa .subject_container_top .tbody_tr .maxwidth .can_switch .active {
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  color: #fff;
  border-color: #78CAF1;
}

#school_cof_fa .subject_container_top .tbody_tr .finalCof .finalIcon {
  display: flex;
  width: 40%;
  justify-content: center;
  align-items: center;
}

#school_cof_fa .subject_container_top .tbody_tr .finalCof .finalCofTime {
  text-align: left;
  width: 60%;
}

#school_cof_fa .subject_container_top .tbody_tr .finalCof .finalCofTime .uploadList {
  overflow: hidden;
  text-overflow: ellipsis;
}

#school_cof_fa .subject_container_top .tbody_tr .bor {
  border-bottom: 1px solid #ccc;
  border-right: none;
  text-align: center;
  height: 100%;
}

#school_cof_fa .subject_container_top .tbody_tr td:nth-last-of-type(1) .bor {
  box-sizing: border-box;
}

#school_cof_fa .subject_container_top .tbody_tr .score_row {
  position: relative;
}

#school_cof_fa .subject_container_top .tbody_tr .score_row .delete_icon {
  top: 50%;
  margin-top: -12px;
}

#school_cof_fa .subject_container_top .tbody_tr .score_row:hover .delete_icon {
  display: block;
}

#school_cof_fa .subject_container_top .tbody_tr .score_row_box {
  min-height: 40px;
  padding: 0 4px;
  box-sizing: border-box;
  border-right: 1px solid #ccc;
  min-width: 60px;
}

#school_cof_fa .subject_container_top .tbody_tr .score_row_box span {
  word-break: break-word;
}

#school_cof_fa .subject_container_top .tbody_tr .minWidth {
  min-width: 90px;
}

#school_cof_fa .subject_container_top .tbody_tr .borBottom {
  border-bottom: 1px solid #ccc;
}

#school_cof_fa .subject_container_top .tbody_tr .borRight {
  border-right: 1px solid #ccc;
}

#school_cof_fa .subject_container_top .tbody_tr .borLeft {
  border-left: 1px solid #ccc;
}

#school_cof_fa .subject_container_top .tbody_tr .send_score {
  background-image: linear-gradient(225deg, #78CAF1 0, #439ADF 100%);
  color: #fff;
  height: 24px;
  min-height: 24px;
  line-height: 24px;
  font-size: 12px;
}

.school-essay-base {
  padding: 16px;
  color: #4A4A4A;
}

.school-essay-base md-select[disabled] .md-select-value {
  background: none;
}

.school-essay-base .md-select-placeholder span {
  margin-left: 24px;
  letter-spacing: 2px;
}

.school-essay-base md-switch[disabled] .md-bar, .school-essay-base md-switch[disabled] .md-thumb {
  background: #ccc;
}

.school-essay-base ul[dnd-list] .dndDraggingSource {
  display: none;
}

.school-essay-base ul[dnd-list] .dndPlaceholder {
  background-color: #ddd;
  display: block;
  min-height: 61px;
}

.school-essay-base .school-essay-content {
  padding: 6px 0px;
  padding-top: 0px;
  overflow: auto;
  padding-bottom: 20px;
}

.school-essay-base .school-essay-content .header {
  background: #7986CB;
}

.school-essay-base .school-essay-content .header h4 {
  color: #fff;
  line-height: 44px;
  text-align: center;
  font-size: 16px;
  font-family: Helvetica-Bold;
}

.school-essay-base .school-essay-content .school_list_item {
  padding-bottom: 4px;
}

.school-essay-base .school-essay-content .school_list_item .school_list_item_top {
  height: 44px;
  background: white;
  margin-top: 1px;
  margin-bottom: 2px;
  border: 1px solid #ccc;
}

.school-essay-base .school-essay-content .school_list_item .school_list_item_top:first-child:hover {
  cursor: pointer;
}

.school-essay-base .school-essay-content .school_list_item .school_list_item_top:hover {
  cursor: move;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.school-essay-base .school-essay-content .school_list_item .focus_item {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.school-essay-base .school-essay-content .school_list_item .no_essay {
  cursor: not-allowed !important;
  background: #dcdcdc !important;
}

.school-essay-base .school-essay-content .school_list_item .table_container {
  position: relative;
  transition: all 0.2s;
}

.school-essay-base .school-essay-content .school_list_item .table_container.ng-hide {
  height: 0 !important;
}

.school-essay-base .school-essay-content .school_list_item .table_container .mask {
  position: relative;
  width: 4px;
  background: white;
  height: 100%;
  z-index: 2;
}

.school-essay-base .school-essay-content .school_list_item .table_container .mask .mask_bor {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 0px;
  width: 0px;
  border-style: solid;
  border-width: 15px 7px 15px 7px;
  border-color: #d9ebf9 #d9ebf9 #fff #fff;
}

.school-essay-base .school-essay-content .school_list_item .table_container table thead th {
  text-align: center;
  font-family: Helvetica-Bold;
}

.school-essay-base .school-essay-content .school_list_item .table_container table tbody tr:hover td > div {
  background: #d9ebf9 !important;
}

.school-essay-base .school-essay-content .school_list_item .table_container table tbody tr.final_eassy:hover td > div {
  background: #c0e4ce !important;
}

.school-essay-base .school-essay-content .school_list_item .table_container table tbody td:first-child {
  padding-left: 10px;
}

.school-essay-base .school-essay-content .school_list_item .table_container table tbody td div {
  text-align: center;
}

.school-essay-base .school-essay-content .school_list_item .table_container table tbody td div span {
  font-weight: bold;
  color: #4A4A4A;
}

.school-essay-base .school-essay-content .school_list_item .table_container table tbody .md-select-icon {
  display: none;
}

.school-essay-base .note .md-icon-button {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  padding: 0;
  background: white;
  border-radius: 3px;
  border: 2px solid #439adf;
}

.school-essay-base .note .md-icon-button md-icon {
  color: #439adf;
  font-size: 20px;
  margin-left: -2px;
}

.school-essay-base .note .simiditor_note .simditor {
  height: 100%;
}

.school-essay-base .note .simiditor_note .simditor .simditor-wrapper {
  height: 100%;
}

.school-essay-base .note .simiditor_note .simditor .simditor-wrapper .simditor-toolbar {
  position: absolute;
  z-index: 2;
  background: white;
  left: 0px !important;
}

.school-essay-base .note .simiditor_note .simditor .simditor-wrapper .simditor-body {
  padding-top: 40px !important;
  height: 65%;
  overflow: auto;
  max-height: 110px;
}

.school-essay-base .note .simiditor_note .simditor .simditor-toolbar > ul li:nth-of-type(7), .school-essay-base .note .simiditor_note .simditor .simditor-toolbar > ul li:nth-of-type(13), .school-essay-base .note .simiditor_note .simditor .simditor-toolbar > ul li:nth-of-type(15), .school-essay-base .note .simiditor_note .simditor .simditor-toolbar > ul li:nth-of-type(17), .school-essay-base .note .simiditor_note .simditor .simditor-toolbar > ul li:nth-of-type(18), .school-essay-base .note .simiditor_note .simditor .simditor-toolbar > ul li:nth-of-type(19), .school-essay-base .note .simiditor_note .simditor .simditor-toolbar > ul li:nth-of-type(20), .school-essay-base .note .simiditor_note .simditor .simditor-toolbar > ul li:nth-of-type(21), .school-essay-base .note .simiditor_note .simditor .simditor-toolbar > ul li:nth-of-type(22) {
  display: none;
}

.school-essay-base .note .simiditor_note .simditor .simditor-wrapper .simditor-placeholder {
  display: none !important;
}

.school-essay-base .note .simiditor_note .simditor .simditor-toolbar {
  width: 100% !important;
}

.school-essay-base .note .simiditor_note .simditor .simditor-body {
  min-height: 100px;
  padding: 10px;
  height: 100%;
  overflow-y: auto;
}

.school-essay-base .note .simiditor_note .simditor .simditor-body ol > li {
  list-style: decimal;
  list-style-position: inside;
}

.school-essay-base .note .simiditor_note .simditor .simditor-body ul > li {
  list-style: disc;
  list-style-position: inside;
}

.school-essay-base .note .simiditor_note .simditor .simditor-toolbar .toolbar-menu ul > li .menu-item {
  line-height: 1.5em;
}

.school-essay-base .note .simiditor_note .simditor .simditor-toolbar > ul > li > .toolbar-item {
  width: 45px;
}

.school-essay-base .note .simiditor_note .simditor .simditor-toolbar > ul > li > span.separator {
  margin: 0;
}

.school-essay-base .note .simiditor_note .simditor .simditor-body h1, .school-essay-base .note .simiditor_note .simditor .simditor-body h2, .school-essay-base .note .simiditor_note .simditor .simditor-body h3, .school-essay-base .note .simiditor_note .simditor .simditor-body h4, .school-essay-base .note .simiditor_note .simditor .simditor-body h5, .school-essay-base .note .simiditor_note .simditor .simditor-body h6, .school-essay-base .note .simiditor_note .editor-style h1, .school-essay-base .note .simiditor_note .editor-style h2, .school-essay-base .note .simiditor_note .editor-style h3, .school-essay-base .note .simiditor_note .editor-style h4, .school-essay-base .note .simiditor_note .editor-style h5, .school-essay-base .note .simiditor_note .editor-style h6 {
  margin: 0;
}

.school-essay-base .note .simiditor_note .simditor .simditor-body p, .school-essay-base .note .simiditor_note .simditor .simditor-body div, .school-essay-base .note .simiditor_note .editor-style p, .school-essay-base .note .simiditor_note .editor-style div {
  margin: 0;
}

.school-essay-base .note .simiditor_note .simditor .simditor-body ul, .school-essay-base .note .simiditor_note .simditor .simditor-body ol, .school-essay-base .note .simiditor_note .editor-style ul, .school-essay-base .note .simiditor_note .editor-style ol {
  margin: 0;
  padding: 0;
}

.school-essay-base .note .simiditor_note .simditor .simditor-body blockquote, .school-essay-base .note .simiditor_note .editor-style blockquote {
  margin: 0;
}

.school-essay-base .note .simiditor_note .simditor .simditor-body code, .school-essay-base .note .simiditor_note .editor-style code {
  margin: 0;
}

.school-essay-base .note .simiditor_note .simditor .simditor-body pre, .school-essay-base .note .simiditor_note .editor-style pre {
  margin: 0;
}

.school-essay-base .note .simiditor_note .simditor .simditor-body hr, .school-essay-base .note .simiditor_note .editor-style hr {
  margin: 0;
}

.school-essay-base .note .simiditor_note .simditor .simditor-body table, .school-essay-base .note .simiditor_note .editor-style table {
  margin: 0;
}

.school-essay-base .bottom_button {
  font-size: 18px;
  font-family: Helvetica-Bold;
  color: #439adf;
  width: 160px;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  border: 1px solid #439adf;
}

.school-essay-base .borderLeft {
  border-left: 2px solid #979797;
  border-bottom: none;
}

.school-essay-base .borderRight {
  border-right: 2px solid #979797;
}

.school-essay-base .final_eassy td > div {
  background: #D9F0E2 !important;
}

#school_info {
  padding-right: 15px;
  padding-left: 140px;
  position: relative;
}

#school_info .tab {
  position: fixed;
  left: 0;
  width: 177px;
  height: 499px;
  overflow-y: auto;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
  margin: 0px 10px 16px;
}

#school_info .tab > a {
  position: relative;
  margin: 0;
  color: #4A4A4A;
  width: 90%;
  padding: 4px;
  height: 3.9vh;
  line-height: 3.9vh;
  border-bottom: 1px #DCDCDC solid;
  cursor: pointer;
}

#school_info .tab > a span {
  width: 80px;
  text-align: center;
}

#school_info .tab > a.btnActive {
  color: #FFFFFF;
  background: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

#school_info .tab > a.btnActive div {
  color: #4A4A4A;
}

#school_info .school_content {
  margin: 0 auto;
  border: 1px #DCDCDC solid;
  overflow-y: auto;
  height: 500px;
  width: 90%;
}

#school_info .school_content table {
  margin: 0 10px 20px 38px;
}

#school_info .school_content table thead th {
  height: 40px;
}

#school_info .school_content table tbody tr td {
  white-space: pre-line;
}

.school-essay-content .radiusfix md-checkbox {
  margin: 0 0 0 5px;
}

.school-essay-content .radiusfix md-checkbox .md-icon {
  border-radius: 6px;
  border-width: 1px;
}

.school-essay-content-list .md-button.md-accent {
  background-color: #439ADF;
  color: #fff;
  border-radius: 3px;
}

.school-essay-content-list md-nav-bar .md-button._md-nav-button.md-unselected {
  color: #4a4a4a;
  background: #fff;
}

.school-essay-content-list .md-button._md-nav-button {
  padding: 0 5px;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  min-height: 28px;
}

.school-essay-content-list .md-nav-bar {
  border: none;
  height: 28px;
  padding: 10px 0;
}

.school-essay-content-list md-nav-ink-bar {
  height: 0;
}

@charset "UTF-8";
.school-essay-supplement {
  overflow: hidden;
  padding-top: 20px;
}

.school-essay-supplement .toggle_outline_type {
  margin: 0px;
  position: relative;
  z-index: 3;
}

.school-essay-supplement .toggle_outline_type .md-button {
  margin: 0;
  min-width: auto;
  height: 22px;
  min-height: 22px;
  line-height: 22px;
  background: #fff;
  font-size: 12px;
  border: 1px solid #439ADF;
  color: #439ADF;
}

.school-essay-supplement .toggle_outline_type .md-button:nth-of-type(1) {
  border-radius: 3px 0px 0px 3px;
}

.school-essay-supplement .toggle_outline_type .md-button:nth-of-type(2) {
  border-radius: 0px 3px 3px 0px;
  border-left: none;
}

.school-essay-supplement .toggle_outline_type .active {
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  color: #fff;
}

.school-essay-supplement .pass_btn {
  border-radius: 3px;
  border: 2px solid #439adf;
  background: white;
  min-width: 20px;
  width: 40px;
  min-height: 26px;
  height: 26px;
  line-height: 22px;
  color: #439adf;
  font-size: 14px;
  font-weight: 600;
  margin: 0 6px;
  padding: 0;
  z-index: 3;
  position: absolute;
  top: 8px;
  right: 8px;
}

.school-essay-supplement .main_container {
  padding-left: 22px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 25px;
  min-width: 280px;
}

.school-essay-supplement .main_container .md-select-icon:after, .school-essay-supplement .main_container .md-select-value {
  color: #439adf !important;
}

.school-essay-supplement .main_container .bor_div {
  height: 100%;
  position: absolute;
  top: 0px;
  border: 1px solid #DCDCDC;
  left: -16px;
}

.school-essay-supplement .main_container .bor_div md-icon {
  position: absolute;
  font-size: 20px;
  left: -10px;
  top: -2px;
  color: #4A4A4A;
}

.school-essay-supplement .main_container .bor_div span {
  position: absolute;
  border: 1px solid #439ADF;
  border-radius: 50%;
  color: #439ADF;
  top: 30px;
  left: -7px;
  background: white;
  line-height: 11px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  width: 15px;
  height: 15px;
}

.school-essay-supplement .main_container .bor_div.deadline_change md-icon {
  color: #DD6023;
  top: -2px;
}

.school-essay-supplement .main_container .file-upload-zone {
  position: absolute;
  border: none;
  top: -3px;
  left: -3px;
}

.school-essay-supplement .main_container .box {
  padding: 8px;
  border-radius: 3px;
  position: relative;
  width: 260px;
}

.school-essay-supplement .main_container .box h4 {
  font-size: 16px;
  padding-left: 3px;
}

.school-essay-supplement .main_container .box:after {
  content: '';
  position: absolute;
  right: 100%;
  top: 9px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-color: transparent;
  border-width: 8px;
  border-right-color: #57a2dd;
}

.school-essay-supplement .main_container .box.is_right:after {
  left: 100%;
  top: 9px;
  border-style: solid;
  border-color: transparent;
  border-width: 8px;
  border-left-color: #57a2dd;
  border-right: none;
}

.school-essay-supplement .main_container .box .menu_one_container {
  padding-bottom: 50px;
}

.school-essay-supplement .main_container .box .menu_one_container .md-icon-button {
  border-radius: 3px;
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
}

.school-essay-supplement .main_container .box .menu_one_container .md-icon-button md-icon {
  color: white;
}

.school-essay-supplement .main_container .box .box_left {
  padding-top: 6px;
}

.school-essay-supplement .main_container .box .box_left .md-button {
  padding: 0px;
  margin: 0px;
  margin-top: 4px;
  min-height: 0px;
  min-width: 0px;
  line-height: 22px;
  border-radius: 18px;
  padding-left: 2px;
  padding-right: 8px;
  background: #d9ebf9;
}

.school-essay-supplement .main_container .box .box_left .md-button md-icon {
  color: #439ADF;
  position: relative;
  top: 3px;
  font-size: 18px;
}

.school-essay-supplement .main_container .box .box_left .md-button input {
  color: #439ADF;
  font-size: 12px;
}

.school-essay-supplement .main_container .box .box_left p {
  padding-top: 8px;
  padding-left: 3px;
}

.school-essay-supplement .main_container .box .box_right {
  border-radius: 3px;
  border: 2px solid #439adf;
  background: white;
}

.school-essay-supplement .main_container .box .box_right md-icon {
  color: #439ADF;
  margin-left: -2px;
  margin-top: -2px;
}

.school-essay-supplement .main_container .box.to_do_box {
  border: 2px solid #439ADF;
}

.school-essay-supplement .main_container .box.to_do_box .bor_div {
  left: -18px;
}

.school-essay-supplement .main_container .box.to_do_box .bor_div md-icon {
  color: #439ADF;
  top: -5px;
}

.school-essay-supplement .main_container .box.to_do_box h4 {
  color: #439ADF;
}

.school-essay-supplement .main_container .box.to_do_box .box_left {
  color: #439ADF;
}

.school-essay-supplement .main_container .box.to_do_box .box_left span {
  color: #439ADF;
}

.school-essay-supplement .main_container .box.to_do_box .version_span {
  color: #439adf;
  font-weight: bold;
  font-size: 12px;
}

.school-essay-supplement .main_container .box.to_do_box .version {
  margin: 0 3px;
  border-radius: 3px;
  border: 2px solid #439adf;
  z-index: 1;
}

.school-essay-supplement .main_container .box.to_do_box.no_auth {
  border: 2px solid #ccc;
}

.school-essay-supplement .main_container .box.to_do_box.no_auth .bor_div md-icon {
  color: #ccc;
}

.school-essay-supplement .main_container .box.to_do_box.no_auth h4 {
  color: #666;
}

.school-essay-supplement .main_container .box.to_do_box.no_auth .box_left {
  color: #666;
}

.school-essay-supplement .main_container .box.to_do_box.no_auth .box_left span {
  color: #439ADF;
}

.school-essay-supplement .main_container .box.to_do_box.no_auth .box_right {
  border-color: #ccc;
}

.school-essay-supplement .main_container .box.to_do_box.no_auth .box_right md-icon {
  color: #666;
}

.school-essay-supplement .main_container .box.to_do_box.no_auth:after {
  border-color: #ccc;
}

.school-essay-supplement .main_container .flow_container {
  max-width: 300px;
}

.school-essay-supplement .main_container .flow_container .flow_item {
  position: relative;
  padding-bottom: 20px;
}

.school-essay-supplement .main_container .flow_container .flow_item:last-child .bor_div {
  height: 0px;
}

.school-essay-supplement .main_container .flow_container .flow_item.is_right .box:after {
  left: 100%;
  top: 9px;
  border-style: solid;
  border-color: transparent;
  border-width: 8px;
  border-left-color: #ebebeb;
  color: #dddddd;
  border-right: none;
}

.school-essay-supplement .main_container .flow_container .flow_item.is_right .box.deadline_change:after {
  border-left-color: #ec8942 !important;
}

.school-essay-supplement .main_container .flow_container .flow_item.is_right .box.meeting:after {
  border-left-color: #59b0e8 !important;
}

.school-essay-supplement .main_container .flow_container .flow_item.is_right .box.overtime:after {
  border-left-color: #ec8942 !important;
}

.school-essay-supplement .main_container .flow_container .flow_item.is_right .box.overtime.is_open:after {
  border-left-color: #ebebeb !important;
}

.school-essay-supplement .main_container .flow_container .date {
  padding-bottom: 10px;
  font-weight: bold;
  width: 100%;
}

.school-essay-supplement .main_container .flow_container .date span {
  color: #ccc;
}

.school-essay-supplement .main_container .flow_container .box {
  background-image: linear-gradient(0deg, #DCDCDC 0, #F0F0F0 100%);
  color: #343030;
  font-weight: bold;
  max-width: 85%;
}

.school-essay-supplement .main_container .flow_container .box:after {
  content: '';
  position: absolute;
  right: 100%;
  top: 9px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-color: transparent;
  border-width: 8px;
  border-right-color: #ebebeb;
  color: #dddddd;
}

.school-essay-supplement .main_container .flow_container .box.deadline_change {
  background-image: linear-gradient(0deg, #DD6023 0, #F09249 100%);
  color: white;
}

.school-essay-supplement .main_container .flow_container .box.deadline_change:after {
  border-right-color: #ec8942 !important;
}

.school-essay-supplement .main_container .flow_container .box.meeting {
  background-image: linear-gradient(225deg, #78CAF1 0%, #439adf 100%);
}

.school-essay-supplement .main_container .flow_container .box.meeting:after {
  border-right-color: #59b0e8 !important;
}

.school-essay-supplement .main_container .flow_container .box.overtime:after {
  border-right-color: #ec8942 !important;
}

.school-essay-supplement .main_container .flow_container .box.overtime.is_open:after {
  border-right-color: #ebebeb !important;
}

.school-essay-supplement .main_container .flow_container .box .box_left .md-button {
  background: white;
}

.school-essay-supplement .main_container .flow_container .box .box_left .md-button .timer, .school-essay-supplement .main_container .flow_container .box .box_left .md-button input {
  color: #888686;
}

.school-essay-supplement .main_container .flow_container .box .box_left p {
  color: #666;
}

.school-essay-supplement .main_container .flow_container .box .box_right {
  border-color: #ccc;
}

.school-essay-supplement .main_container .flow_container .box .box_right md-icon {
  color: #888686;
}

.school-essay-supplement .main_container .committee .committee_date, .school-essay-supplement .main_container .committee .committee_type, .school-essay-supplement .main_container .committee .committee_rate {
  padding-left: 12px;
}

.school-essay-supplement .main_container .committee .committee_date > span, .school-essay-supplement .main_container .committee .committee_type > span, .school-essay-supplement .main_container .committee .committee_rate > span {
  margin-right: 12px;
  color: #439ADF;
  font-weight: bold;
}

.school-essay-supplement .main_container .committee .committee_date .md-button, .school-essay-supplement .main_container .committee .committee_type .md-button, .school-essay-supplement .main_container .committee .committee_rate .md-button {
  padding: 0px;
  margin: 0px;
  min-height: 0px;
  min-width: 0px;
  line-height: 22px;
  border-radius: 18px;
  width: 120px;
  background: #fff;
  position: relative;
}

.school-essay-supplement .main_container .committee .committee_date .md-button .box_s, .school-essay-supplement .main_container .committee .committee_type .md-button .box_s, .school-essay-supplement .main_container .committee .committee_rate .md-button .box_s {
  position: absolute;
  width: 100%;
  height: 1px;
  top: -6px;
  left: 0px;
  border-radius: 3px;
}

.school-essay-supplement .main_container .committee .committee_date .md-button span, .school-essay-supplement .main_container .committee .committee_type .md-button span, .school-essay-supplement .main_container .committee .committee_rate .md-button span {
  color: #439ADF;
  font-weight: bold;
}

.school-essay-supplement .main_container .menu_container {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
}

.school-essay-supplement .main_container .menu_container .menu_list {
  padding-bottom: 10px;
}

.school-essay-supplement .main_container .menu_container .menu_list .box_right {
  border-radius: 3px;
  border: 2px solid #439adf;
  background: white;
  min-height: 0px;
  height: 28px;
  width: 28px;
}

.school-essay-supplement .main_container .menu_container .menu_list .box_right md-icon {
  color: #439ADF;
  margin-left: -8px;
  margin-top: -7px;
}

.school-essay-supplement .main_container .menu_container .menu_one_container {
  width: 100%;
  height: 109px;
  background: #ecf4fb;
  border-radius: 3px;
}

.school-essay-supplement .main_container .menu_container .menu_one_container.meeting_note {
  position: relative;
  padding: 10px;
  padding-bottom: 24px;
  height: 90px;
}

.school-essay-supplement .main_container .menu_container .menu_one_container.meeting_note textarea {
  border: none;
  background: none;
  height: 100%;
  width: 100%;
}

.school-essay-supplement .main_container .menu_container .menu_one_container.meeting_note .md-icon-button {
  border-radius: 3px;
  border: 2px solid #439adf;
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
}

.school-essay-supplement .main_container .menu_container .menu_one_container.meeting_note .md-icon-button md-icon {
  color: #439ADF;
}

.school-essay-supplement .main_container .set_as_final {
  min-height: 0px;
  height: 24px;
  line-height: 24px;
  width: 90px;
  right: 0px;
  top: 4px;
  color: white;
  border-radius: 30px;
  position: absolute;
  background-image: linear-gradient(45deg, #439ADF 0, #C86DD7 100%);
}

.school-essay-supplement .side_container {
  padding-left: 28px;
  padding-bottom: 40px;
  overflow-y: auto;
}

.school-essay-supplement .side_container .header {
  width: 246px;
}

.school-essay-supplement .side_container .header .title {
  padding-bottom: 12px;
}

.school-essay-supplement .side_container .header .title p {
  color: #439ADF;
}

.school-essay-supplement .side_container .header .des {
  padding-top: 4px;
  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
  /*定义滚动条轨道 内阴影+圆角*/
  /*定义滑块 内阴影+圆角*/
}

.school-essay-supplement .side_container .header .des p:first-child {
  padding-bottom: 6px;
}

.school-essay-supplement .side_container .header .des p:first-child span:first-child {
  font-weight: 500;
  font-size: 18px;
}

.school-essay-supplement .side_container .header .des p:first-child .md-button {
  min-height: 0px;
  height: 24px;
  line-height: 24px;
  margin: 0px;
  padding: 0px;
}

.school-essay-supplement .side_container .header .des p:last-child {
  overflow: auto;
  max-height: 200px;
}

.school-essay-supplement .side_container .header .des ::-webkit-scrollbar {
  width: 5px;
  height: 16px;
  background-color: #F5F5F5;
}

.school-essay-supplement .side_container .header .des ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.school-essay-supplement .side_container .header .des ::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #bbb;
}

.school-essay-supplement .side_container .container {
  width: 246px;
  padding-top: 12px;
}

.school-essay-supplement .side_container .container .final_box {
  margin-top: 10px;
  border: 1px solid #979797;
}

.school-essay-supplement .side_container .container .final_box .top {
  width: 94%;
  margin-left: 3%;
  border-bottom: 1px solid #4A4A4A;
}

.school-essay-supplement .side_container .container .final_box .top span {
  color: #439adf;
}

.school-essay-supplement .side_container .container .final_box .top .final_edit {
  cursor: pointer;
  color: white;
  margin: 0;
}

.school-essay-supplement .side_container .container .final_box .top span:first-child {
  margin-left: 7px;
  margin-right: 10px;
  font-weight: bold;
  font-size: 18px;
}

.school-essay-supplement .side_container .container .final_box .top .md-button {
  height: 36px;
  margin-right: 0px;
}

.school-essay-supplement .side_container .container .final_box .top .md-button md-icon {
  border: 1px solid #4A4A4A;
  border-radius: 6px;
  color: #4A4A4A;
  margin-top: -3px;
}

.school-essay-supplement .side_container .container .final_box .main {
  padding: 6px;
  padding-left: 12px;
}

.school-essay-supplement .side_container .container .final_box .main .prompt_no {
  color: #439ADF;
  font-weight: bold;
}

.school-essay-supplement .side_container .container .final_box .main .prompt_time {
  color: #439ADF;
  font-weight: normal;
}

.school-essay-supplement .side_container .container .final_box .main .final_textra_note {
  height: 120px;
}

.school-essay-supplement .side_container .container .final_box .main .text_area {
  background: transparent;
  max-height: 100px;
  padding: 3px;
  padding-left: 0px;
}

.school-essay-supplement .side_container .container .final_box .main .text_area:focus {
  background: white;
}

.school-essay-supplement .side_container .container .final_box .main .text_area textarea {
  background: transparent;
  width: 95%;
  height: 100%;
  border: none;
  font-size: 14px;
  padding: 1px;
}

.school-essay-supplement .side_container .container .final_box .main .text_area textarea:focus {
  background: white;
}

.school-essay-supplement .side_container .container .final_box .main .bot .md-button {
  border: 1px solid #4A4A4A;
  border-radius: 30px;
  margin-top: 12px;
  margin-right: 0px;
}

.school-essay-supplement .side_container .container .final_box .main .bot .md-button md-icon, .school-essay-supplement .side_container .container .final_box .main .bot .md-button span {
  color: #4A4A4A;
}

.school-essay-supplement .side_container .t_title {
  font-size: 18px;
  color: #439ADF;
  font-weight: bold;
}

.school-essay-supplement .side_container .tagList {
  margin-top: 20px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.school-essay-supplement .side_container .tag {
  width: 140px;
  height: 30px;
  font-size: 10px;
  line-height: 12px;
  border: 1px solid #979797;
  border-radius: 3px;
  margin-bottom: 10px;
  color: #4A4A4A;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  display: -webkit-box;
  padding: 3px 0px;
}

.school-essay-supplement .side_container .choosed {
  background: #439ADF;
  color: #fff;
}

.md-datepicker-input-mask {
  display: none;
}

.committee_note_div {
  margin-top: 15px;
}

.committee_note_div .committee_notes {
  padding-left: 12px;
}

.committee_note_div .committee_notes span {
  color: #439ADF;
  font-size: 16px;
  font-weight: 500;
}

.committee_note_div .committee_notes .note_icon {
  font-size: 20px;
  color: #439ADF;
  margin-top: 5px;
}

#textarea_notes {
  background: #ecf4fb;
  margin-left: 25px;
  font-size: 14px;
  border: none;
}

.flow_textarea_notes {
  background: none;
  margin-left: 25px;
  font-size: 14px;
  border: none;
  min-height: 100px;
}

.flow_textarea_notes:focus {
  background: white;
}

#outline_datepick {
  box-shadow: 0 1px 2px -1px rgba(9, 45, 66, 0.25), 0 0 0 1px rgba(9, 45, 66, 0.08);
}

#outline_datepick:focus {
  box-shadow: 0 4px 8px -2px rgba(9, 45, 66, 0.25), 0 0 0 1px rgba(9, 45, 66, 0.08);
}

.schoolCollegeInfoDialog {
  position: relative;
}

.schoolCollegeInfoDialog .toggle_tab {
  margin: 10px;
  min-height: 36px;
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
}

.schoolCollegeInfoDialog .toggle_tab > .md-button {
  margin: 0;
  width: 176px;
  color: #4A4A4A;
}

.schoolCollegeInfoDialog .toggle_tab > .md-button.active {
  color: #FFFFFF;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

.schoolCollegeInfoDialog .college_info {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.schoolCollegeInfoDialog .college_info .major_input {
  height: 28px;
  margin-left: 10px;
  text-indent: 10px;
  border: 1px solid #ccc;
}

.schoolCollegeInfoDialog .college_info .status_btn {
  cursor: pointer;
}

.schoolCollegeInfoDialog .college_info .status_btn .md-button {
  margin: 5px;
  min-width: 84px;
  height: 24px;
  min-height: 24px;
  line-height: 24px;
  border-radius: 4px;
  font-size: 12px;
}

.schoolCollegeInfoDialog .college_info .status_btn .dea {
  border: 1px solid #9b9b9b;
  color: #9b9b9b;
}

.schoolCollegeInfoDialog .college_info .status_btn .dea:hover {
  background: #9b9b9b;
  color: #fff;
}

.schoolCollegeInfoDialog .college_info .status_btn .act {
  border: 1px solid #7ED321;
  color: #7ED321;
}

.schoolCollegeInfoDialog .college_info .status_btn .act:hover {
  background: #7ED321;
  color: #fff;
}

.schoolCollegeInfoDialog .introduction_info {
  width: 97%;
  padding: 10px 20px;
  box-sizing: border-box;
  max-height: 520px;
  overflow-y: auto;
}

.schoolCollegeInfoDialog .introduction_info .title {
  border: 1px solid #DCDCDC;
  font-size: 18px;
  font-family: Helvetica-Bold;
  color: #439adf;
  height: 46px;
  padding-left: 15px;
  line-height: 46px;
}

.schoolCollegeInfoDialog .panelDiv {
  width: 500px;
  position: absolute;
  top: 20%;
  left: 50%;
  z-index: 1;
  margin-left: -250px;
  background: #fff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.schoolCollegeInfoDialog .panelDiv .toolbar {
  height: 32px;
  min-height: 24px;
  border-bottom: 1px solid #DCDCDC;
}

.schoolCollegeInfoDialog .panelDiv md-toolbar {
  background: #fff;
}

.schoolCollegeInfoDialog .panelDiv .inputx {
  padding: 20px 10px;
  font-size: 20px;
}

.schoolCollegeInfoDialog .panelDiv .inputx .checkboxdemoSelectAll {
  padding-left: 30px;
}

.schoolCollegeInfoDialog .panelDiv .inputx .uploadList {
  padding-left: 40px;
}

.schoolCollegeInfoDialog .panelDiv .buttondiv {
  border-top: 1px solid #DCDCDC;
}

.schoolCollegeInfoDialog .panelDiv table th {
  font-weight: bold;
}

.schoolCollegeInfoDialog .panelDiv table tr {
  border-bottom: 1px solid #DCDCDC;
}

.schoolCollegeInfoDialog .panelDiv table th,
.schoolCollegeInfoDialog .panelDiv table td {
  padding: 0;
  padding: 5px 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.schoolCollegeInfoDialog .panelDiv table .chexiao {
  cursor: pointer;
}

.schoolCollegeInfoDialog .editListDiv {
  position: absolute;
  height: 100%;
  width: 900px;
  right: -900px;
  background: #fff;
  z-index: 1;
  transition: all 0.3s;
  border-left: 1px #DCDCDC solid;
  overflow-y: auto;
}

.schoolCollegeInfoDialog .editListDiv .title {
  font-size: 20px;
  font-family: Helvetica-Bold;
  color: #439adf;
  height: 46px;
  border-bottom: 1px solid #DCDCDC;
  border-top: 1px solid #DCDCDC;
  padding-left: 15px;
  line-height: 46px;
}

.schoolCollegeInfoDialog .editListDiv .page_list {
  position: absolute;
  left: 50%;
  margin-left: -60px;
  bottom: 0;
}

.schoolCollegeInfoDialog .editListDiv .page_list .md-button {
  padding: 0px;
  margin: 0px;
  color: #428bca;
  border: 1px solid #dee5e7;
  border-left: none;
  background: white;
  width: 30px;
  height: 30px;
  min-width: 0px;
  min-height: 0px;
  line-height: 28px;
}

.schoolCollegeInfoDialog .editListDiv .page_list .md-button span {
  font-size: 10px;
  font-weight: bold;
}

.schoolCollegeInfoDialog .editListDiv .page_list .md-button:first-child {
  border-left: 1px solid #dee5e7;
}

.schoolCollegeInfoDialog .editListDiv .page_list .md-button.active {
  background: #428bca;
  color: white;
}

.schoolCollegeInfoDialog .editListDiv .page_list .md-button:hover {
  background: #dee5e7;
}

.schoolCollegeInfoDialog .editListDiv .page_list .md-button.active:hover {
  background: #428bca;
}

.schoolCollegeInfoDialog .editListDiv table th {
  font-weight: bold;
}

.schoolCollegeInfoDialog .editListDiv table tr {
  height: 50px !important;
  border-bottom: 1px solid #DCDCDC;
}

.schoolCollegeInfoDialog .editListDiv table th,
.schoolCollegeInfoDialog .editListDiv table td {
  padding: 0;
  padding-left: 10px;
  height: 50px !important;
  line-height: 50px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.schoolCollegeInfoDialog .editListDiv table .chexiao {
  cursor: pointer;
}

.schoolCollegeInfoDialog .editListDiv table .active {
  background: #428bca;
  color: white;
}

.schoolCollegeInfoDialog .editListDiv table .show {
  position: relative;
}

.schoolCollegeInfoDialog .question_title {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -112px;
}

.schoolCollegeInfoDialog .question_title .md-button {
  margin: 0;
  min-width: 30px;
  line-height: 18px;
  background: #fff;
  font-size: 14px;
  color: #fff;
  background-color: rgba(67, 154, 223, 0.8);
  font-family: PingFangSC-Regular;
  height: 224px;
  width: 30px;
  white-space: normal;
  z-index: 2;
  border-radius: 16px 0 0 16px;
}

.schoolCollegeInfoDialog .question_title .md-button md-icon {
  color: #fff;
  margin: 0;
}

.schoolCollegeInfoDialog #school_info {
  padding-right: 15px;
  padding-left: 108px;
  position: relative;
}

.schoolCollegeInfoDialog #school_info .tab {
  position: fixed;
  left: 0;
  width: 88px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
  margin: 10px 10px 16px;
}

.schoolCollegeInfoDialog #school_info .tab > a {
  position: relative;
  margin: 0;
  color: #4A4A4A;
  width: 80px;
  padding: 4px;
  height: 3.9vh;
  line-height: 3.9vh;
  border-bottom: 1px #DCDCDC solid;
  cursor: pointer;
}

.schoolCollegeInfoDialog #school_info .tab > a span {
  width: 80px;
  text-align: center;
}

.schoolCollegeInfoDialog #school_info .tab > a.btnActive {
  color: #FFFFFF;
  background: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

.schoolCollegeInfoDialog #school_info .tab > a.btnActive div {
  color: #4A4A4A;
}

.schoolCollegeInfoDialog #school_info .school_content {
  margin: 10px;
  border: 1px #DCDCDC solid;
  overflow-y: auto;
}

.schoolCollegeInfoDialog #school_info .school_content .title {
  font-size: 20px;
  font-family: Helvetica-Bold;
  color: #439adf;
  height: 46px;
  border-bottom: 1px solid #DCDCDC;
  border-top: 1px solid #DCDCDC;
  padding-left: 15px;
  line-height: 46px;
}

.schoolCollegeInfoDialog #school_info .school_content textarea {
  border: 0;
}

.schoolCollegeInfoDialog #school_info .school_content .textareaLabel {
  height: 150px;
  background: #EEEEEE;
  line-height: 150px;
}

.schoolCollegeInfoDialog #school_info .school_content .col {
  color: #000;
  border-bottom: 1px #DCDCDC solid;
}

.schoolCollegeInfoDialog #school_info .school_content .col .col_p {
  background-color: #EEEEEE;
  border-left: 1px solid #DCDCDC;
  border-right: 1px solid #DCDCDC;
  height: 36px;
  padding-left: 15px;
  transition: all 100ms;
  -webkit-transition: all 50ms;
  cursor: pointer;
}

.schoolCollegeInfoDialog #school_info .school_content .col .col_p:hover {
  background: -webkit-linear-gradient(left, #EEEEEE, #ccc);
}

.schoolCollegeInfoDialog #school_info .school_content .col input {
  border: none;
  height: 36px;
  text-indent: 17px;
}

.schoolCollegeInfoDialog #school_info .school_content .col input:focus {
  outline: 2px solid #0091ea;
}

.schoolCollegeInfoDialog #school_info .school_content .col select {
  border: none;
  height: 36px;
  background: #FFFFFF;
}

.schoolCollegeInfoDialog #school_info .school_content .col .tableDiv {
  border-top: 1px solid #DCDCDC;
  border-bottom: 1px solid #DCDCDC;
}

.schoolCollegeInfoDialog #school_info .school_content .col .tableDiv:nth-last-of-type(1) {
  border-bottom: none;
}

.schoolCollegeInfoDialog #school_info .school_content .col .grade .tableDiv {
  height: 100%;
}

.schoolCollegeInfoDialog #school_info .school_content .col .grade .tableDiv p,
.schoolCollegeInfoDialog #school_info .school_content .col .grade .tableDiv input {
  height: 100%;
}

.schoolCollegeInfoDialog #school_info .school_content .col .bb .tableDiv:nth-last-of-type(2) {
  border-bottom: none;
}

.schoolCollegeInfoDialog #school_info .school_content .col .mt {
  margin-top: 10px;
}

.schoolCollegeInfoDialog #school_info .school_content .col .mt:nth-of-type(1) {
  margin-top: 0;
}

.schoolCollegeInfoDialog #school_info .school_content .edited {
  border: 1px solid red !important;
}

.schoolCollegeInfoDialog #school_info .school_content input {
  cursor: pointer;
}

.schoolCollegeInfoDialog #school_info .school_content select {
  cursor: pointer;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.schoolCollegeInfoDialog #school_info .school_content select[readonly] option {
  display: none;
}

.schoolCollegeInfoDialog #school_info .school_content .multi {
  height: 36px;
  line-height: 36px;
  cursor: pointer;
  padding-left: 18px;
}

.schoolCollegeInfoDialog #school_info .ap-input-btn {
  background: #439ADF;
  width: 27px;
  height: 27px;
  border-radius: 3px;
  padding: 0px;
  min-width: 27px;
  min-height: 27px;
  line-height: 27px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  text-indent: 0px;
}

.schoolCollegeInfoDialog #school_info .ap-input-btn md-icon {
  color: #fff;
  font-size: 21px;
}

.schoolCollegeInfoDialog #school_info .add-btn {
  margin-left: 0;
}

.schoolCollegeInfoDialog #school_info .downloadAttr {
  margin-left: 5px;
  color: blue;
  cursor: pointer;
}

.schoolCollegeInfoDialog .material_list {
  border: 1px solid #ccc;
  margin: 10px 50px 10px 10px;
  height: 500px;
  overflow-y: auto;
}

.schoolCollegeInfoDialog .material_list .title {
  font-size: 20px;
  font-family: Helvetica-Bold;
  color: #439adf;
  height: 46px;
  border-bottom: 1px solid #DCDCDC;
  border-top: 1px solid #DCDCDC;
  padding-left: 15px;
  line-height: 46px;
}

.change_college_or_major_info {
  background: white;
  width: 500px;
  margin-top: -100px;
  margin-left: -250px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.change_college_or_major_info .toolbar {
  height: 32px;
  min-height: 24px;
  border-bottom: 1px solid #DCDCDC;
}

.change_college_or_major_info md-toolbar {
  background: #fff;
}

.change_college_or_major_info .inputx {
  padding: 20px 10px;
  font-size: 20px;
}

.change_college_or_major_info .buttondiv {
  border-top: 1px solid #DCDCDC;
}

.strat_school_reporting .top {
  background: white;
  margin-top: 10px;
}

.strat_school_reporting .container {
  background-color: white;
  padding-bottom: 5px;
  margin: 10px 0px;
  padding-right: 10px;
}

.strat_school_reporting table.md-table th.md-column,
.strat_school_reporting table.md-table td.md-cell {
  text-align: center;
}

.strat_school_reporting table {
  border-right: 1px solid #ccc;
  overflow: auto;
  border-collapse: inherit;
  border-bottom: 1px solid #ccc;
}

.strat_school_reporting table th,
.strat_school_reporting table td {
  padding: 0px !important;
}

.strat_school_reporting table th {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background: white;
  border-bottom: 1px solid #ccc;
  z-index: 2;
  text-align: center;
  min-width: 60px;
  border-right: 1px solid #ccc;
  line-height: 40px;
}

.strat_school_reporting table tbody td {
  background: white;
  border-right: 1px solid #ccc;
}

.strat_school_reporting table tbody tr:nth-child(2n-1) {
  background: #f7f7f7;
}

.strat_school_reporting table tbody tr:nth-child(2n-1) td {
  background: #f7f7f7;
}

.strat_send_score_panel {
  background: white;
  width: 800px;
  height: 400px;
  margin-top: -200px;
  margin-left: -400px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 2px #ccc;
}

.strat_send_score_panel .title {
  padding: 5px 10px;
  color: #439adf;
  font-size: 20px;
}

.strat_send_score_panel .content {
  padding: 10px;
  overflow: auto;
}

.strat_send_score_panel .content md-table-container {
  margin-bottom: 10px;
}

.strat_send_score_panel .content md-table-container .tip {
  color: #fff;
  padding: 0 3px;
  border-radius: 3px;
  margin-left: 5px;
  font-size: 12px;
  white-space: nowrap;
}

.strat_send_score_panel .content table.md-table .thead_tr {
  height: 24px;
  background: #7986cb;
}

.strat_send_score_panel .content table.md-table .thead_tr th {
  font-size: 16px;
  color: #fff;
}

.strat_send_score_panel .content table.md-table .tbody_tr {
  border-bottom: 1px rgba(0, 0, 0, 0.12) solid;
}

.strat_send_score_panel .content table.md-table .tbody_tr .waive_box {
  position: relative;
  display: inline-block;
}

.strat_send_score_panel .content table.md-table .tbody_tr .waive_box .waive_i {
  width: 14px;
  height: 14px;
  border: 1px solid #909090;
  border-radius: 50%;
  margin: 0 5px;
  text-align: center;
  line-height: 14px;
  font-size: 14px;
  color: #909090;
}

.strat_send_score_panel .content table.md-table .tbody_tr .waive_box .waive_con {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  margin-bottom: 10px;
  z-index: 5;
  line-height: 14px;
  width: 600px;
  min-width: 600px;
  max-height: 300px;
  overflow: auto;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px #9c9c9c;
}

.strat_send_score_panel .content table.md-table .tbody_tr .waive_box .waive_con .first_td {
  color: #4a4a4a;
  font-size: 14px;
  max-width: 150px;
  text-align: left;
  font-weight: bold;
}

.strat_send_score_panel .content table.md-table .tbody_tr .waive_box .waive_con .third_td {
  border-bottom: 1px dashed;
  color: #439ADF;
  font-weight: bold;
  font-size: 14px;
  max-width: 100px;
  text-align: left;
}

.strat_send_score_panel .none {
  font-size: 30px;
  color: #999;
}

.autocomplete-custom-template li {
  border-bottom: 1px solid #ccc;
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: normal;
}

.autocomplete-custom-template li:last-child {
  border-bottom-width: 0;
}

.autocomplete-custom-template .school-alias, .autocomplete-custom-template .school-name {
  display: block;
  line-height: 2;
}

#school_select thead tr .top_fix {
  background: #7c96be;
  border-right: 1px solid #ccc;
}

#school_select thead tr th {
  font-size: 14px;
}

#school_select thead tr:first-child .top_fix {
  background: #4174be;
}

#school_select thead tr:first-child th {
  font-size: 16px;
}

#school_select tbody tr .left_fix {
  background: #fafafa;
  border-right: 1px solid #ccc;
}

#school_select .first-col {
  min-width: 200px;
}

#school_select td.md-cell md-checkbox .md-container {
  margin: 0 16px;
}

#school_select md-grid-list {
  border-bottom: 1px solid #ccc;
}

#school_select md-grid-list.grid-list-process figure {
  justify-content: center;
  font-size: 16px;
}

#school_select md-grid-list.grid-list-center figure {
  justify-content: center;
}

#school_select md-grid-list.grid-list-header md-grid-tile {
  border-right: none;
}

#school_select md-grid-list md-grid-tile {
  border-right: 1px solid #ccc;
}

#school_select md-grid-list md-grid-tile figure {
  padding-left: 10px;
  font-size: 12px;
}

#school_select md-grid-list md-grid-tile.grid-list-start figure {
  justify-content: flex-start;
}

#school_select md-grid-list md-grid-tile.gird-list-condition figure {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#school_select md-grid-list md-grid-tile .sub-task-disabled {
  background: #e7e6e6;
}

.self-setting .password, .self-setting .time_zone, .self-setting .google_account {
  background-color: white;
  width: 50%;
  margin: 10px 0px;
  padding: 16px;
}

.wechat_bot {
  overflow: hidden;
  height: 100%;
}

.wechat_bot .changeBackColor {
  background: #fafafa;
}

.wechat_bot .login_btn {
  background: #589e58;
  color: #fff;
  margin-top: 40px;
}

#student {
  padding: 30px;
  padding-top: 16px;
  padding-right: 40px;
  padding-bottom: 0px;
}

#student .status_btn .md-button {
  margin: 0;
  min-width: 84px;
  height: 28px;
  min-height: 28px;
  line-height: 28px;
  border-radius: 4px;
  font-size: 12px;
}

#student .status_btn .dea {
  border: 1px solid #9b9b9b;
  color: #9b9b9b;
}

#student .status_btn .dea:hover {
  background: #9b9b9b;
  color: #fff;
}

#student .status_btn .act {
  border: 1px solid #7ED321;
  color: #7ED321;
}

#student .status_btn .act:hover {
  background: #7ED321;
  color: #fff;
}

#student .status_btn .all {
  border: 1px solid #439ADF;
  color: #439ADF;
}

#student .status_btn .all:hover {
  background: #439ADF;
  color: #fff;
}

#student .status_btn .deaActive {
  background: #9b9b9b;
  color: #fff;
}

#student .status_btn .actActive {
  background: #7ED321;
  color: #fff;
}

#student .status_btn .allActive {
  background: #439ADF;
  color: #fff;
}

#student .top .tab {
  height: 34px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
  margin: 10px 10px 16px;
}

#student .top .tab > .md-button {
  margin: 0;
  width: 176px;
  color: #4A4A4A;
}

#student .top .tab > .md-button.active {
  color: #FFFFFF;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

#student .mid {
  padding-bottom: 16px;
}

#student .mid .stu_name_input {
  border: 1px solid #9B9B9B;
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
  margin-right: 10px;
  text-indent: 10px;
}

#student .mid md-autocomplete md-autocomplete-wrap {
  height: 36px;
  box-shadow: none;
  border: 1px solid #9B9B9B;
}

#student .mid md-autocomplete md-autocomplete-wrap input {
  padding: 0 10px;
  height: 36px;
  line-height: 36px;
}

#student .mid .add_btn {
  margin: 0;
  min-width: 78px;
  height: 36px;
  min-height: 36px;
  line-height: 36px;
  background: #439adf;
  font-size: 16px;
  font-family: PingFangSC-Regular;
  color: white;
  box-shadow: 0px 1px 2px 0px rgba(67, 154, 223, 0.6);
}

#student .mid .add_btn:hover {
  background: #439adf;
}

#student .mid .add_btn md-icon {
  font-size: 20px;
  height: 22px;
  width: 20px;
  min-width: 20px;
  min-height: 22px;
  color: #fff;
}

#student .content md-table-container .page_list .md-button {
  padding: 0px;
  margin: 0px;
  color: #428bca;
  border: 1px solid #dee5e7;
  border-left: none;
  background: white;
  width: 30px;
  height: 30px;
  min-width: 0px;
  min-height: 0px;
  line-height: 28px;
}

#student .content md-table-container .page_list .md-button span {
  font-size: 10px;
  font-weight: bold;
}

#student .content md-table-container .page_list .md-button:first-child {
  border-left: 1px solid #dee5e7;
}

#student .content md-table-container .page_list .md-button.active {
  background: #428bca;
  color: white;
}

#student .content md-table-container .page_list .md-button:hover {
  background: #dee5e7;
}

#student .content md-table-container .page_list .md-button.active:hover {
  background: #428bca;
}

#student .content md-table-container table {
  overflow: inherit;
}

#student .content md-table-container table thead th {
  padding: 10px 3px;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background: #7986CB;
  z-index: 10;
}

#student .content md-table-container table thead th:first-child > div, #student .content md-table-container table thead th:nth-child(2) > div {
  padding-left: 8px !important;
}

#student .content md-table-container table tbody tr:first-child td {
  padding-top: 0px;
}

#student .content md-table-container table tbody td {
  padding: 3px 0px;
}

#student .content md-table-container table tbody td .box {
  padding: 0px 8px;
  padding-left: 20px;
  height: 43px;
  border-bottom: 1px solid #9B9B9B;
  border-top: 1px solid #9B9B9B;
}

#student .content md-table-container table tbody td:first-child .box {
  padding-left: 8px;
  border-left: 1px solid #9B9B9B;
  font-weight: bold;
}

#student .content md-table-container table tbody td:nth-child(2) .box {
  padding-left: 8px;
  font-weight: bold;
}

#student .content md-table-container table tbody td:nth-last-child(2) .box {
  border-right: 1px solid #9B9B9B;
}

#student .content md-table-container table tbody td .manage {
  color: white;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

#student_account {
  margin: 10px;
  border: 1px #DCDCDC solid;
  overflow-y: auto;
  height: 100%;
}

#student_account .title {
  font-size: 20px;
  font-family: Helvetica-Bold;
  color: #439adf;
  height: 46px;
  border-bottom: 1px solid #DCDCDC;
  padding-left: 15px;
  line-height: 46px;
}

#student_account .title:nth-last-of-type(1) {
  border-bottom: none;
}

#student_account .mt {
  margin-top: 10px;
}

#student_account .mt:nth-of-type(1) {
  margin-top: 0;
}

#student_account .tableDiv {
  border-top: 1px solid #DCDCDC;
  border-bottom: 1px solid #DCDCDC;
}

#student_account .tableDiv .col_p {
  background-color: #EEEEEE;
  border-left: 1px solid #DCDCDC;
  border-right: 1px solid #DCDCDC;
  height: 36px;
  padding-left: 15px;
  transition: all 100ms;
  -webkit-transition: all 50ms;
  cursor: pointer;
}

#student_account .tableDiv .col_p:hover {
  background: -webkit-linear-gradient(left, #EEEEEE, #ccc);
}

#student_account .tableDiv input {
  border: none;
  height: 36px;
  text-indent: 17px;
}

#student_account .tableDiv input:focus {
  outline: 2px solid #0091ea;
}

#student_account .tableDiv select {
  border: none;
  height: 36px;
  background: #FFFFFF;
}

#student_account .other .tableDiv {
  height: 100%;
}

#student_account .other .tableDiv p, #student_account .other .tableDiv input {
  height: 100%;
}

#student_account .reco .tableDiv {
  border-bottom: none;
}

#student_account .reco .tableDiv:nth-last-of-type(1), #student_account .reco .tableDiv:nth-last-of-type(2), #student_account .reco .tableDiv:nth-last-of-type(3) {
  border-bottom: 1px solid #DCDCDC;
}

.student_basic_info .card_btn {
  color: white;
  background: #0098d8;
  min-height: 0px;
  line-height: 26px;
  margin: 0px;
  padding: 0px;
}


#student_info {
  position: relative;
}

#student_info .tab {
  height: 34px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
  margin: 10px 10px 16px;
}

#student_info .tab > .md-button {
  margin: 0;
  width: 176px;
  color: #4A4A4A;
}

#student_info .tab > .md-button.btnActive {
  color: #FFFFFF;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

#student_info .student_content {
  margin: 10px;
  border: 1px #DCDCDC solid;
  overflow-y: auto;
  height: 100%;
}

#student_info .student_content .title {
  font-size: 20px;
  font-family: Helvetica-Bold;
  color: #439adf;
  height: 46px;
  border-bottom: 1px solid #DCDCDC;
  border-top: 1px solid #DCDCDC;
  padding-left: 15px;
  line-height: 46px;
}

#student_info .student_content .col {
  color: #000;
  border-bottom: 1px #DCDCDC solid;
}

#student_info .student_content .col .col_p {
  background-color: #EEEEEE;
  border-left: 1px solid #DCDCDC;
  border-right: 1px solid #DCDCDC;
  height: 36px;
  padding-left: 15px;
  transition: all 100ms;
  -webkit-transition: all 50ms;
  cursor: pointer;
}

#student_info .student_content .col .col_p:hover {
  background: -webkit-linear-gradient(left, #EEEEEE, #ccc);
}

#student_info .student_content .col input {
  border: none;
  height: 36px;
  text-indent: 17px;
}

#student_info .student_content .col input:focus {
  outline: 2px solid #0091ea;
}

#student_info .student_content .col select {
  border: none;
  height: 36px;
  background: #FFFFFF;
}

#student_info .student_content .col .tableDiv {
  border-top: 1px solid #DCDCDC;
  border-bottom: 1px solid #DCDCDC;
}

#student_info .student_content .col .tableDiv:nth-last-of-type(1) {
  border-bottom: none;
}

#student_info .student_content .col .grade .tableDiv {
  height: 100%;
}

#student_info .student_content .col .grade .tableDiv p, #student_info .student_content .col .grade .tableDiv input {
  height: 100%;
}

#student_info .student_content .col .bb .tableDiv:nth-last-of-type(2) {
  border-bottom: none;
}

#student_info .student_content .col .mt {
  margin-top: 10px;
}

#student_info .student_content .col .mt:nth-of-type(1) {
  margin-top: 0;
}

#student_info .invent {
  position: absolute;
  top: 10px;
  right: 30px;
}

.student-parents {
  height: 100%;
  padding: 30px;
  padding-top: 16px;
  padding-right: 40px;
  padding-bottom: 16px;
}

.student-parents .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
}

.student-parents .header .left .md-button {
  margin: 0;
  padding: 0;
}

.student-parents .header .left > .md-button.active {
  color: #FFFFFF;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

.student-parents .header .right {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.student-parents .header .right md-select {
  margin: 0 !important;
}

.student-parents .student-content {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.student-parents .student-content .table {
  width: 100%;
  flex: auto;
  overflow: auto;
}

.student-parents .student-content .table table thead tr th {
  min-width: 100px;
  text-align: left;
  background-color: #7986CB;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  height: 40px;
  padding-right: 24px;
  padding-left: 24px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
}

.student-parents .student-content .table table thead tr th:first-child {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 109;
}

.student-parents .student-content .table table tbody tr td {
  margin: 3px 0;
  border-bottom: 1px solid #9B9B9B;
}

.student-parents .student-content .table table tbody tr td > div {
  min-height: 39px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.student-parents .student-content .table table tbody tr td:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 108;
  background: #fff;
  font-weight: bold;
}

.student-parents .student-content .table table tbody tr td:first-child div {
  height: 100%;
}

.student-parents .student-content .table table tbody tr td:first-child .line {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  background: #9B9B9B;
  padding: 0 !important;
}

.student-parents .student-content .table table tbody tr td:last-child > div {
  border-right: 1px solid #9B9B9B;
}

.student-parents .student-content .page_list {
  padding-bottom: 0;
  padding-top: 10px;
}

.student-parents .student-content .page_list .md-button {
  padding: 0px;
  margin: 0px;
  color: #428bca;
  border: 1px solid #dee5e7;
  border-left: none;
  background: white;
  width: 30px;
  height: 30px;
  min-width: 0px;
  min-height: 0px;
  line-height: 28px;
}

.student-parents .student-content .page_list .md-button span {
  font-size: 10px;
  font-weight: bold;
}

.student-parents .student-content .page_list .md-button:first-child, .student-parents .student-content .page_list .md-button:nth-of-type(3) {
  border-left: 1px solid #dee5e7;
}

.student-parents .student-content .page_list .md-button.active {
  background: #428bca;
  color: white;
}

.student-parents .student-content .page_list .md-button:hover {
  background: #dee5e7;
}

.student-parents .student-content .page_list .md-button.active:hover {
  background: #428bca;
}


.summer-ec-manage {
  padding: 20px;
}

.summer-ec-manage .title {
  color: #439ADF;
  padding-bottom: 8px;
  font-size: 18px;
}

.summer-ec-manage .search_item {
  padding-right: 10px;
  position: relative;
}

.summer-ec-manage md-autocomplete md-autocomplete-wrap {
  height: 36px;
  box-shadow: none;
  border: 1px solid #9B9B9B;
}

.summer-ec-manage md-autocomplete md-autocomplete-wrap input {
  padding: 0 10px;
  height: 36px;
  line-height: 36px;
}

.summer-ec-manage .add_btn {
  margin: 0 10px 0 0;
  min-width: 78px;
  height: 36px;
  min-height: 36px;
  line-height: 36px;
  background: #439adf;
  font-size: 16px;
  font-family: PingFangSC-Regular;
  color: white;
  box-shadow: 0px 1px 2px 0px rgba(67, 154, 223, 0.6);
}

.summer-ec-manage .add_btn:hover {
  background: #439adf;
}

.summer-ec-manage .add_btn md-icon {
  font-size: 20px;
  height: 22px;
  width: 20px;
  min-width: 20px;
  min-height: 22px;
  color: #fff;
}

.summer-ec-manage .container {
  background-color: white;
  padding-bottom: 5px;
  margin: 10px 0px;
  border: 1px solid #ccc;
}

.summer-ec-manage table.md-table th.md-column,
.summer-ec-manage table.md-table td.md-cell {
  text-align: center;
}

.summer-ec-manage table {
  overflow: auto;
  border-collapse: inherit;
  border-bottom: 1px solid #ccc;
}

.summer-ec-manage table th,
.summer-ec-manage table td {
  padding: 0px !important;
}

.summer-ec-manage table th {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background: white;
  border-bottom: 1px solid #ccc;
  z-index: 2;
  text-align: center;
  min-width: 60px;
  border-right: 1px solid #ccc;
  line-height: 40px;
}

.summer-ec-manage table tbody td {
  background: white;
  border-right: 1px solid #ccc;
}

.summer-ec-manage .containerleft table tbody tr:nth-child(2n-1) {
  background: #f7f7f7;
}

.summer-ec-manage .containerleft table tbody tr:nth-child(2n-1) td {
  background: #f7f7f7;
}

.summer-ec-manage .program {
  height: 40px;
  border-bottom: 1px solid #ccc;
}

.summer-ec-manage .program .program_name {
  font-weight: bolder;
  font-size: 14px;
}

.summer-ec-manage .program:nth-last-child(1) {
  border-bottom: none;
}

.summer-ec-manage .studentTr td.md-cell {
  background: #f7f7f7;
  height: 30px;
}

.summer-ec-manage .toggleBtn {
  position: absolute;
  left: 0;
  top: 4px;
}

.summer-ec-manage .acSelect {
  margin: 0;
  width: 80%;
  height: 40px;
}

.summer_list {
  padding: 10px 20px;
  overflow: hidden;
  position: relative;
}

.summer_list .roundClass {
  cursor: pointer;
  white-space: inherit !important;
}

.summer_list .course_date_title {
  padding-left: 24px;
}

.summer_list .course_date_title span {
  min-width: 100px;
}

.summer_list .course_date_title span:nth-of-type(1) {
  min-width: 180px;
}

.summer_list .course_date_table .ap-input table.md-table {
  padding: 0;
}

.summer_list .course_date_table .ap-input table.md-table thead.md-head {
  display: none;
}

.summer_list .canClick {
  color: #7986CB;
  cursor: pointer;
}

.summer_list .choose_school_title {
  font-size: 16px;
  height: 52px;
  line-height: 52px;
  font-family: PingFangSC-Semibold;
  color: #439adf;
}

.summer_list .basic_info span {
  height: 52px;
  line-height: 52px;
  margin-left: 5px;
}

.summer_list .basic_info div {
  padding: 15px;
  border-right: 1px solid #DCDCDC;
}

.summer_list .basic_info div:last-child {
  border: 0;
}

.summer_list .point {
  cursor: pointer;
}

.summer_list .toggle_btn {
  margin: 0;
  border: 2px solid #439adf;
  border-radius: 3px;
  padding: 0;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  margin-left: 5px;
}

.summer_list .toggle_btn md-icon {
  color: #439adf;
  margin: 0;
  margin-left: -4px;
  margin-top: -6px;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
}

.summer_list .small_score_container {
  position: absolute;
  top: 46px;
  z-index: 100;
  left: 10px;
  background: #fff;
  padding: 10px;
  overflow-x: auto;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.summer_list .small_score_container .scoreDiv {
  border: 1px solid #DCDCDC;
  margin-right: 10px;
  padding: 10px;
}

.summer_list .small_score_container .scoreDiv .span1 {
  font-size: 18px;
  color: #4A4A4A;
  font-family: PingFangSC-Medium;
}

.summer_list .small_score_container .scoreDiv .span2 {
  font-size: 36px;
  font-family: Helvetica-Bold;
  color: #439ADF;
}

.summer_list .small_score_container .scoreDiv .span3 {
  font-size: 12px;
  font-family: PingFangSC-Regular;
  color: #4A4A4A;
  white-space: nowrap;
  margin-right: 5px;
}

.summer_list .small_score_container .scoreDiv .span4 {
  font-size: 24px;
  font-family: Helvetica-Bold;
  color: #4A4A4A;
  text-align: center;
}

.summer_list .small_score_container .scoreDiv:last-child {
  margin: 0;
}

.summer_list form {
  padding: 10px 0;
}

.summer_list md-select[disabled] .md-select-value {
  background: none;
}

.summer_list .add_btn {
  margin: 0;
  min-width: 78px;
  height: 32px;
  min-height: 32px;
  line-height: 32px;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  color: white;
  box-shadow: 0px 1px 2px 0px rgba(67, 154, 223, 0.6);
}

.summer_list .add_btn:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.summer_list .add_btn md-icon {
  font-size: 18px;
  height: 20px;
  width: 20px;
  min-width: 20px;
  min-height: 20px;
  color: #fff;
}

.summer_list .table1 td.md-cell div.disabled {
  pointer-events: none;
  cursor: crosshair;
}

.summer_list .fix {
  background: #7986CB;
}

.summer_list .fix span {
  text-indent: 24px;
}

.summer_list .left_fix {
  border-right: 1px solid #ccc;
}

.summer_list .top_fix {
  border-bottom: 1px solid #ccc;
}

.summer_list .table1 table th {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background: #7986CB;
  border-bottom: 1px solid #979797;
  z-index: 2;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.summer_list .table1 table th span {
  font-size: 14px;
  font-family: Helvetica-Bold;
  color: #fff;
}

.summer_list .table1 table th:first-child {
  left: 0px;
  z-index: 3;
}

.summer_list .table1 table tr:first-child td {
  padding-top: 0px;
}

.summer_list .table1 table td {
  padding: 3px 0px;
  height: 0;
}

.summer_list .table1 table td:first-child .box {
  padding-left: 8px;
  border-left: 1px solid #9B9B9B;
  font-weight: bold;
}

.summer_list .table1 table td .box {
  padding: 0px 8px;
  padding-left: 10px;
  height: 100%;
  min-height: 40px;
  border-bottom: 1px solid #9B9B9B;
  border-top: 1px solid #9B9B9B;
  position: relative;
}

.summer_list .table1 table td span {
  font-size: 14px;
  color: #4a4a4a;
  line-height: 14px;
}

.summer_list .table1 table td:first-child {
  z-index: 2;
  position: sticky;
  position: -webkit-sticky;
  left: 0px;
}

.summer_list .table1 table td:first-child span {
  font-size: 16px;
  font-family: PingFangSC-Semibold;
  color: #439ADF;
}

.summer_list .table1 table td:first-child .text_container {
  max-width: 249px;
}

.summer_list .table1 table td:first-child, .summer_list .table1 table th:first-child {
  min-width: 160px;
}

.summer_list .table1 table td:nth-of-type(2) span {
  white-space: nowrap;
}

.summer_list .table1 table .arrow_left {
  position: absolute;
  left: 249px;
  top: 19px;
  background: #fff;
  color: #439ADF;
  cursor: pointer;
}

.summer_list .table1 table .three_btn {
  display: none;
  position: absolute;
  width: 100%;
  height: 43px;
  top: 4px;
  left: 0;
  background: rgba(74, 74, 74, 0.6);
}

.summer_list .table1 table .three_btn .md-button {
  padding: 0;
  margin: 0;
  border: 1px solid #fff;
  min-width: 30%;
  color: #fff;
}

.summer_list .table1 table .td_container:hover .three_btn {
  display: block;
}

.summer_list .table1 table.md-table td.md-cell {
  border: none;
  height: 40px;
  padding: 8px 0;
}

.summer_list .text_container {
  padding-left: 24px;
  position: absolute;
  left: 0;
  min-width: 100px;
  display: -webkit-box;
  font-size: 10px;
  height: 40px;
  width: 100%;
}

.summer_list .text_container span {
  max-height: 29px;
}

.summer_list .bor:hover {
  background-color: #f8f8f8;
  cursor: pointer;
}

.summer_list .popup {
  position: absolute;
  top: 6px;
  background: white;
  z-index: 50;
  left: 100%;
  width: 164px;
}

.summer_list .school_library {
  position: absolute;
  height: 98%;
  right: -600px;
  top: 1%;
  z-index: 10;
  overflow-x: hidden;
  overflow-y: hidden;
  border-radius: 3px;
  background: #fff;
  width: 600px;
  color: #4A4A4A;
  transition: right 0.3s;
  -webkit-transition: right 0.3s;
}

.summer_list .school_library .rightBox {
  width: 100%;
  height: 100%;
  padding: 10px;
}

.summer_list .school_library .boxTop {
  width: 100%;
}

.summer_list .school_library .boxTop hr {
  border-color: rgba(0, 0, 0, 0.18);
}

.summer_list .school_library .boxTop h2 {
  font-size: 18px;
  padding-left: 10px;
  padding-top: 10px;
}

.summer_list .school_library .boxTop .searchBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
}

.summer_list .school_library .boxTop .searchBox .searchItem, .summer_list .school_library .boxTop .searchBox .searchItem_ {
  height: 40px;
  padding: 0 10px;
  margin: 10px 0;
  position: relative;
}

.summer_list .school_library .boxTop .searchBox .searchItem > span, .summer_list .school_library .boxTop .searchBox .searchItem_ > span {
  font-size: 14px;
  font-weight: bold;
  color: #439adf;
}

.summer_list .school_library .boxTop .searchBox .searchItem md-icon, .summer_list .school_library .boxTop .searchBox .searchItem_ md-icon {
  position: absolute;
  right: 22px;
  bottom: 3px;
}

.summer_list .school_library .boxTop .searchBox .searchItem .filterBtb, .summer_list .school_library .boxTop .searchBox .searchItem_ .filterBtb {
  width: 100%;
}

.summer_list .school_library .boxTop .searchBox .searchItem {
  width: 45%;
}

.summer_list .school_library .boxTop .searchBox .searchItem_ {
  width: 27%;
}

.summer_list .school_library .boxTop .searchBox .searchItem.datePicker {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.summer_list .school_library .boxTop .searchBox .searchItem.datePicker ap-datepicker input {
  width: 85px;
}

.summer_list .school_library .boxTop .searchBox .searchItem.tuofu md-input-container {
  width: 34%;
  display: inline-block;
}

.summer_list .school_library .boxTop .searchBox .searchItem.tuofu md-input-container ap-button {
  float: right;
}

.summer_list .school_library .boxBottom {
  width: 100%;
}

.summer_list .school_library .boxBottom table {
  overflow-y: auto;
  display: block;
}

.summer_list .school_library .boxBottom th {
  background: #fff;
  z-index: 1;
  position: -webkit-sticky;
  position: sticky;
  top: 0 !important;
}

.summer_list .school_library .boxBottom td {
  padding-bottom: 10px;
  border-bottom: 1px solid #DCDCDC;
}

.summer_list .school_library .boxBottom td a {
  color: #7986CB;
  cursor: pointer;
}

.summer_list .school_list_custom_column md-dialog-content {
  overflow: scroll;
}

.summer_list .school_list_custom_column .md-title {
  height: 42px;
  background: #439adf;
  font-size: 20px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  color: white;
  line-height: 42px;
  text-indent: 10px;
}

.summer_list .school_list_custom_column .custom_title {
  height: 30px;
  font-size: 18px;
  font-family: Helvetica-Bold;
  font-weight: bold;
  color: #439adf;
  padding-left: 10px;
  padding-top: 10px;
}

.summer_list .school_list_custom_column .child_btn {
  min-width: 148px;
  margin: 0;
  box-sizing: border-box;
  padding: 0px 5px;
  height: 48px;
  background: white;
  position: relative;
  overflow: inherit;
  padding: 5px;
}

.summer_list .school_list_custom_column .child_btn span {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  border: 1px solid #979797;
  display: block;
  line-height: 40px;
}

.summer_list .school_list_custom_column .child_btn span:hover {
  background: #439ADF;
  color: #fff;
  border-color: #439ADF;
}

.summer_list .school_list_custom_column .child_btn .child_active {
  background: #439ADF;
  color: #fff;
  border-color: #439ADF;
}

.summer_list .school_list_custom_column .child_btn:hover {
  background-color: #fff;
}

.summer_list .major_college_panel {
  background: white;
  width: 800px;
  height: 400px;
  margin-top: -200px;
  margin-left: -400px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 2px #ccc;
}

.summer_list .major_college_panel .title {
  padding: 5px 10px;
  color: #439adf;
  font-size: 20px;
}

.summer_list .major_college_panel .tip {
  padding: 5px 10px 0 10px;
}

.summer_list .major_college_panel md-autocomplete {
  background: none;
  height: 28px;
}

.summer_list .major_college_panel md-autocomplete md-autocomplete-wrap {
  height: 28px;
  box-shadow: none;
  border: 1px solid #ccc;
}

.summer_list .major_college_panel md-autocomplete md-autocomplete-wrap input {
  padding: 0 10px;
  height: 26px;
  line-height: 26px;
}

.summer_list .major_college_panel md-autocomplete .md-show-clear-button button {
  width: 26px;
  height: 26px;
}

.summer_list .major_college_panel .add_btn {
  margin: 0;
  margin-left: 10px;
  min-width: 60px;
  height: 28px;
  min-height: 28px;
  line-height: 28px;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  color: white;
  box-shadow: 0px 1px 2px 0px rgba(67, 154, 223, 0.6);
}

.summer_list .major_college_panel .add_btn:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.summer_list .major_college_panel .add_btn md-icon {
  font-size: 18px;
  height: 20px;
  width: 20px;
  min-width: 20px;
  min-height: 20px;
  color: #fff;
}

.summer_list .major_college_panel .content {
  padding: 5px 10px;
  padding-top: 0;
  overflow-y: auto;
  height: 250px;
}

.summer_list .major_college_panel .content table {
  border: 1px solid #ccc;
  border-bottom: none;
  width: 100%;
}

.summer_list .major_college_panel .content table thead tr {
  background: #7986CB;
  color: #fff;
  border-bottom: 1px solid #979797;
  height: 30px;
  line-height: 30px;
}

.summer_list .major_college_panel .content table td {
  border-bottom: 1px solid #ccc;
}

.summer_list .major_college_panel .content table th {
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  background: #7986CB;
}

.summer_list .major_college_panel .content table td, .summer_list .major_college_panel .content table th {
  font-size: 16px;
  text-align: center;
}

.summer_list .major_college_panel .content table .major_list {
  padding: 0;
  height: 1px;
}

.summer_list .major_college_panel .content table .major_list .major_box {
  border-bottom: 1px solid #ccc;
}

.summer_list .major_college_panel .content table .major_list .major_box:hover .delete_icon, .summer_list .major_college_panel .content table .major_list .major_box:hover .primary_btn {
  visibility: visible;
}

.summer_list .major_college_panel .content table .major_list .major_box:nth-last-child(1) {
  border-bottom: none;
}

.summer_list .major_college_panel .content table .delete_icon {
  cursor: pointer;
  visibility: hidden;
}

.summer_list .major_college_panel .content table .primary_btn {
  padding: 0;
  width: 24px;
  height: 24px;
  min-height: 24px;
  visibility: hidden;
}

.summer_list .question_content {
  position: absolute;
  height: 98%;
  right: -500px;
  top: 1%;
  z-index: 100;
  overflow-x: hidden;
  overflow-y: hidden;
  border-radius: 3px;
  background: #fff;
  width: 500px;
  color: #4A4A4A;
  transition: right 0.3s;
  -webkit-transition: right 0.3s;
}

.summer_list .question_content .title {
  padding: 0 20px;
  background: #7986CB;
  color: #fff;
  height: 40px;
  line-height: 40px;
}

.summer_list .question_content .title md-icon {
  width: 24px;
  height: 24px;
  color: #fff;
}

.summer_list .question_content .bottom_content {
  height: 796px;
  overflow-y: auto;
}

.summer_list .question_content .bottom_content md-list md-list-item .md-list-item-inner {
  padding: 15px 0 5px 0;
}

.summer_list .question_content .bottom_content md-list md-list-item .md-list-item-inner .action {
  align-self: flex-end;
}

.summer_list .question_content .bottom_content md-list md-list-item .md-list-item-inner h3, .summer_list .question_content .bottom_content md-list md-list-item .md-list-item-inner p {
  padding-top: 10px;
}

.summer_list .advance {
  position: absolute;
  top: 30%;
  width: 80%;
  left: 10%;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.summer_list .advance .title {
  padding: 0 20px;
  background: #7986CB;
  color: #fff;
  height: 40px;
  line-height: 40px;
}

.summer_list .advance .title md-icon {
  width: 24px;
  height: 24px;
  color: #fff;
}

.summer_list .advance .advanceContent {
  padding: 20px;
  background: #fff;
}

.summer_list .toast {
  color: red;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.summer_list .tooltip {
  min-width: 300px;
  min-height: 70px;
  position: absolute;
  background: #fff;
  padding: 0 10px;
  box-sizing: border-box;
  z-index: 11;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.summer_list .selected_course {
  background-color: rgba(220, 220, 220, 0.5);
}

.summer_list .three_btn {
  display: none;
  position: absolute;
  width: 100%;
  height: 43px;
  top: 4px;
  left: 0;
  background: rgba(74, 74, 74, 0.6);
}

.summer_list .three_btn .md-button {
  padding: 0;
  margin: 0;
  border: 1px solid #fff;
  min-width: 30%;
  color: #fff;
}

.summer_list .shadowGray {
  box-sizing: border-box;
  width: 96%;
  height: 96%;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  font-weight: bold;
  color: #fff;
  border: 2px solid #fff;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
  line-height: 100%;
  transition: display 0.4s ease 0s;
}

.summer_list .shadowGray .delete {
  text-align: center;
  font-size: 16px;
  height: 30px;
  line-height: 30px;
  margin: 0;
}

.summer_list .table1 table tbody tr td:first-child {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  background-color: #fff;
}

.summer_list .table1 table tbody tr td:first-child:hover .shadowGray {
  display: block;
}

.summer_list .noteDiv {
  position: absolute;
  top: 10%;
  width: 500px;
  left: 50%;
  margin-left: -200px;
  z-index: 20;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  background: #fff;
}

.summer_list_create {
  padding: 10px;
  position: relative;
}

.summer_list_create .tableTitle {
  text-align: center;
  height: 30px;
  background: #7986CB;
  color: #fff;
  line-height: 30px;
  font-size: 14px;
  font-family: "PingFang SC";
  font-weight: bold;
}

.summer_list_create .ng-isolate-scope td {
  border: none !important;
}

.summer_list_create .redColor {
  border: 1px solid red !important;
}

.summer_list_create .opt-col {
  border-left: 1px solid #DCDCDC;
}

.summer_list_create .table-row {
  border-top: 1px solid #DCDCDC;
}

.summer_list_create .status_btn {
  margin-left: 10px;
}

.summer_list_create .status_btn .md-button {
  margin: 0;
  min-width: 84px;
  height: 28px;
  min-height: 28px;
  line-height: 28px;
  border-radius: 4px;
  font-size: 12px;
}

.summer_list_create .status_btn .dea {
  border: 1px solid #9b9b9b;
  color: #9b9b9b;
}

.summer_list_create .status_btn .dea:hover {
  background: #9b9b9b;
  color: #fff;
}

.summer_list_create .status_btn .act {
  border: 1px solid #7ED321;
  color: #7ED321;
}

.summer_list_create .status_btn .act:hover {
  background: #7ED321;
  color: #fff;
}

.summer_list_create .score_table_fix {
  overflow-y: auto;
  height: 100%;
  border: 1px solid #DCDCDC;
  box-sizing: border-box;
}

.summer_list_create .score_table_fix textarea {
  width: 100%;
  border: none;
}

.summer_list_create .score_table_fix textarea:focus {
  outline: 2px solid #0091ea;
}

.summer_list_create .score_table_fix table.md-table {
  overflow-y: auto;
}

.summer_list_create .score_table_fix table.md-table thead.md-head > tr.md-row {
  height: 36px;
}

.summer_list_create .score_table_fix table.md-table thead.md-head > tr.md-row th.md-column {
  padding: 0px 17px;
  background-color: #EEEEEE;
  text-indent: 0px;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  text-align: center;
  font-size: 16px;
}

.summer_list_create .score_table_fix table.md-table tbody.md-body tr.md-row {
  position: relative;
  height: 36px;
}

.summer_list_create .score_table_fix table.md-table tbody.md-body tr.md-row td.md-cell {
  position: relative;
  padding: 0 0 0 17px;
  text-indent: 0px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 2px solid #fff;
}

.summer_list_create .toggle_tab {
  margin-bottom: 10px;
  min-height: 36px;
  margin-right: 30px;
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
}

.summer_list_create .toggle_tab > .md-button {
  margin: 0;
  width: 176px;
  color: #4A4A4A;
}

.summer_list_create .toggle_tab > .md-button.active {
  color: #FFFFFF;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

.summer_list_create .program_list_content {
  overflow-y: auto;
  border-top: 1px solid #DCDCDC;
}

.summer_list_create .program_list_content .content table tbody tr .td_first ap-form-input {
  transition: all 0.2s ease 0s;
}

.summer_list_create .program_list_content .content table tbody tr .td_first div input {
  transition: all 0.2s ease 0s;
}

.summer_list_create .program_list_content .content table tbody tr .td_first .grayPower {
  background-color: rgba(128, 128, 105, 0.3);
}

.summer_list_create .program_list_content, .summer_list_create .prompt_list_content, .summer_list_create .material_list_content {
  height: 555px;
}

.summer_list_create .table-btn {
  background: #439ADF;
  width: 27px;
  height: 27px;
  border-radius: 3px;
  padding: 0;
  min-width: 27px;
  min-height: 27px;
  line-height: 27px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.summer_list_create .table-btn:not([disabled]).md-focused, .summer_list_create .table-btn:not([disabled]):hover {
  background-color: #439ADF;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.summer_list_create .table-btn > md-icon {
  color: #fff;
  font-size: 21px;
}

.summer_list_create .opt-col {
  border-right: 1px solid #DCDCDC;
  border-bottom: 1px solid #DCDCDC;
}

.summer_list_create .material_list_content .title {
  font-size: 20px;
  color: #439adf;
  padding: 3px 0;
  height: 40px;
}

.summer_list_create .appli_edit_list {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -112px;
}

.summer_list_create .appli_edit_list .md-button {
  margin: 0;
  min-width: 30px;
  line-height: 18px;
  background: #fff;
  font-size: 14px;
  color: #fff;
  background-color: rgba(67, 154, 223, 0.8);
  font-family: PingFangSC-Regular;
  height: 224px;
  width: 30px;
  white-space: normal;
  z-index: 2;
  border-radius: 16px 0 0 16px;
}

.summer_list_create .appli_edit_list .md-button md-icon {
  color: #fff;
  margin: 0;
}

.summer_list_create .editListDiv {
  position: absolute;
  height: 100%;
  width: 90%;
  right: -90%;
  background: #fff;
  z-index: 1;
  transition: all 0.3s;
  border-left: 1px #DCDCDC solid;
  overflow-y: auto;
}

.summer_list_create .editListDiv .title {
  font-family: Helvetica-Bold;
  color: #439adf;
  height: 46px;
  line-height: 46px;
  border-bottom: 1px solid #DCDCDC;
  border-top: 1px solid #DCDCDC;
  padding-left: 15px;
}

.summer_list_create .editListDiv .title h1 {
  font-size: 20px;
}

.summer_list_create .editListDiv .loadMore {
  color: #439adf;
  cursor: pointer;
}

.summer_list_create .editListDiv .page_list {
  margin-bottom: 20px;
}

.summer_list_create .editListDiv .page_list .md-button {
  padding: 0px;
  margin: 0px;
  color: #428bca;
  border: 1px solid #dee5e7;
  border-left: none;
  background: white;
  width: 30px;
  height: 30px;
  min-width: 0px;
  min-height: 0px;
  line-height: 28px;
}

.summer_list_create .editListDiv .page_list .md-button span {
  font-size: 10px;
  font-weight: bold;
}

.summer_list_create .editListDiv .page_list .md-button:first-child {
  border-left: 1px solid #dee5e7;
}

.summer_list_create .editListDiv .page_list .md-button.active {
  background: #428bca;
  color: white;
}

.summer_list_create .editListDiv .page_list .md-button:hover {
  background: #dee5e7;
}

.summer_list_create .editListDiv .page_list .md-button.active:hover {
  background: #428bca;
}

.summer_list_create .editListDiv table th {
  font-weight: bold;
}

.summer_list_create .editListDiv table tr {
  height: 50px !important;
  border-bottom: 1px solid #DCDCDC;
}

.summer_list_create .editListDiv table th,
.summer_list_create .editListDiv table td {
  padding: 0;
  padding-left: 10px;
  height: 50px !important;
  line-height: 50px;
}

.summer_list_create .editListDiv table .chexiao {
  cursor: pointer;
}

.summer_list_create .editListDiv table .active {
  background: #428bca;
  color: white;
}

.summer_list_create .editListDiv table .show {
  position: relative;
}

.summer_list_create .warning {
  min-width: 40px;
  margin: 0;
  color: red;
}

.summer_list_create .loading {
  display: flex;
  min-height: 150px;
  justify-content: center;
  align-items: center;
}

.summer_list_create .tag {
  font-family: "Monospaced Number","Chinese Quote",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-variant: tabular-nums;
  box-sizing: border-box;
  margin: auto 8px;
  padding: 0 7px;
  list-style: none;
  display: inline-block;
  line-height: 22px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fafafa;
  font-size: 12px;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 1;
  cursor: pointer;
  white-space: nowrap;
}

.summer_list_create .tag .anticon {
  display: inline-block;
  font-style: normal;
  vertical-align: -.125em;
  text-align: center;
  text-transform: none;
  line-height: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.summer_list_create .tag .close {
  display: inline-block;
  line-height: 20px;
  font-size: 12px;
  font-size: 10px\9;
  transform: scale(0.83333333) rotate(0);
  cursor: pointer;
  margin-left: 3px;
  transition: all .3s;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
}

.summer_list_create .subject_box {
  border-right: 1px #DCDCDC solid;
  border-bottom: 1px #DCDCDC solid;
}

.summer_list_create .subject_box .ap-input-label {
  flex: 1 1 100%;
  max-width: 20%;
  background-color: #EEEEEE;
  border-left: 1px solid #DCDCDC;
  border-right: 1px solid #DCDCDC;
  min-height: 36px;
  padding-left: 15px;
  text-indent: 0px;
  transition: all 100ms;
  -webkit-transition: all 50ms;
  cursor: pointer;
  box-sizing: border-box;
}

.summer_list_create .subject_box .ap-input-label:hover {
  background: -webkit-linear-gradient(left, #EEEEEE, #ccc);
}

.summer_list_create .subject_box .ap-input {
  flex: 1 1 100%;
  border: none;
  min-height: 36px;
  text-indent: 17px;
  box-sizing: border-box;
  max-width: 100%;
}

.summer_list_create .subject_box .ap-input:focus {
  outline: 2px solid #0091ea;
}

.summer_list_create .newPromptPanel {
  background: white;
  padding: 10px;
  width: 1000px;
  height: 300px;
  margin-top: -150px;
  margin-left: -500px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 2px #ccc;
}

.summer_list_create .newPromptPanel .title {
  padding: 5px 10px;
  color: #439adf;
  font-size: 20px;
}

.summer_list_create .newPromptPanel .toggle_tab {
  margin-bottom: 10px;
  min-height: 36px;
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
}

.summer_list_create .newPromptPanel .toggle_tab > .md-button {
  margin: 0;
  color: #4A4A4A;
}

.summer_list_create .newPromptPanel .toggle_tab > .md-button.active {
  color: #FFFFFF;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

.summer_list_create .newPromptPanel .can_switch {
  height: 30px;
}

.summer_list_create .newPromptPanel .can_switch .md-button {
  margin: 0;
  min-width: 84px;
  height: 24px;
  min-height: 24px;
  line-height: 24px;
  background: #fff;
  font-size: 14px;
  border: 1px solid #439ADF;
  color: #439ADF;
}

.summer_list_create .newPromptPanel .can_switch .md-button:nth-of-type(1) {
  border-radius: 3px 0px 0px 3px;
}

.summer_list_create .newPromptPanel .can_switch .md-button:nth-of-type(2) {
  border-radius: 0px 3px 3px 0px;
  border-left: none;
}

.summer_list_create .newPromptPanel .can_switch .btnActive {
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  color: #fff;
}

.summer_list_create .newPromptPanel .tag {
  font-family: "Monospaced Number","Chinese Quote",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-variant: tabular-nums;
  box-sizing: border-box;
  margin: 5px;
  text-indent: 3px;
  padding: 0 7px;
  list-style: none;
  display: inline-block;
  line-height: 22px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fafafa;
  font-size: 12px;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 1;
  cursor: pointer;
  white-space: nowrap;
}

.summer_list_create .newPromptPanel .tag .anticon {
  display: inline-block;
  font-style: normal;
  vertical-align: -.125em;
  text-align: center;
  text-transform: none;
  line-height: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.summer_list_create .newPromptPanel .tag .close {
  display: inline-block;
  line-height: 20px;
  font-size: 12px;
  font-size: 10px\9;
  transform: scale(0.83333333) rotate(0);
  cursor: pointer;
  margin-left: 3px;
  transition: all .3s;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
}

.summer_list_create .tableTitle {
  text-align: center;
  height: 30px;
  background: #7986CB;
  color: #fff;
  line-height: 30px;
  font-size: 14px;
  font-family: "PingFang SC";
  font-weight: bold;
}

.summer_list_create .borderBtm div:last-child {
  border-bottom: 1px solid #dcdcdc;
}

.summer_list_create .flex-5 {
  max-width: 7%;
}

.summer_list_create .lineTo {
  max-width: 100% !important;
}

.summer_list_create .lineTo div.ap-input-label {
  max-width: 20%;
}

.summer_list_create .lineTo div.ap-multiple-select {
  max-width: 98%;
}

.summer_list_create .lineTo div.ap-multiple-select p {
  line-height: 26px;
}

.grayPower {
  background-color: rgba(128, 128, 105, 0.3);
}

.summer_list_info {
  padding: 10px;
  position: relative;
}

.summer_list_info .tableTitle {
  text-align: center;
  height: 30px;
  background: #7986CB;
  color: #fff;
  line-height: 30px;
  font-size: 14px;
  font-family: "PingFang SC";
  font-weight: bold;
}

.summer_list_info .ng-isolate-scope td {
  border: none !important;
}

.summer_list_info .redColor {
  border: 1px solid red !important;
}

.summer_list_info .opt-col {
  border-left: 1px solid #DCDCDC;
}

.summer_list_info .table-row {
  border-top: 1px solid #DCDCDC;
}

.summer_list_info .status_btn {
  margin-left: 10px;
}

.summer_list_info .status_btn .md-button {
  margin: 0;
  min-width: 84px;
  height: 28px;
  min-height: 28px;
  line-height: 28px;
  border-radius: 4px;
  font-size: 12px;
}

.summer_list_info .status_btn .dea {
  border: 1px solid #9b9b9b;
  color: #9b9b9b;
}

.summer_list_info .status_btn .dea:hover {
  background: #9b9b9b;
  color: #fff;
}

.summer_list_info .status_btn .act {
  border: 1px solid #7ED321;
  color: #7ED321;
}

.summer_list_info .status_btn .act:hover {
  background: #7ED321;
  color: #fff;
}

.summer_list_info .score_table_fix {
  overflow-y: auto;
  height: 100%;
  border: 1px solid #DCDCDC;
  box-sizing: border-box;
}

.summer_list_info .score_table_fix textarea {
  width: 100%;
  border: none;
}

.summer_list_info .score_table_fix textarea:focus {
  outline: 2px solid #0091ea;
}

.summer_list_info .score_table_fix table.md-table {
  overflow-y: auto;
}

.summer_list_info .score_table_fix table.md-table thead.md-head > tr.md-row {
  height: 36px;
}

.summer_list_info .score_table_fix table.md-table thead.md-head > tr.md-row th.md-column {
  padding: 0px 17px;
  background-color: #EEEEEE;
  text-indent: 0px;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  text-align: center;
  font-size: 16px;
}

.summer_list_info .score_table_fix table.md-table tbody.md-body tr.md-row {
  position: relative;
  height: 36px;
}

.summer_list_info .score_table_fix table.md-table tbody.md-body tr.md-row td.md-cell {
  position: relative;
  padding: 0 0 0 17px;
  text-indent: 0px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 2px solid #fff;
}

.summer_list_info .toggle_tab {
  margin-bottom: 10px;
  min-height: 36px;
  margin-right: 30px;
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
}

.summer_list_info .toggle_tab > .md-button {
  margin: 0;
  width: 176px;
  color: #4A4A4A;
}

.summer_list_info .toggle_tab > .md-button.active {
  color: #FFFFFF;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

.summer_list_info .program_list_content {
  overflow-y: auto;
  border-top: 1px solid #DCDCDC;
}

.summer_list_info .program_list_content .content table tbody tr .td_first .grayPower {
  background-color: rgba(128, 128, 105, 0.3);
}

.summer_list_info .program_list_content, .summer_list_info .prompt_list_content, .summer_list_info .material_list_content {
  height: 555px;
}

.summer_list_info .table-btn {
  background: #439ADF;
  width: 27px;
  height: 27px;
  border-radius: 3px;
  padding: 0;
  min-width: 27px;
  min-height: 27px;
  line-height: 27px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.summer_list_info .table-btn:not([disabled]).md-focused, .summer_list_info .table-btn:not([disabled]):hover {
  background-color: #439ADF;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.summer_list_info .table-btn > md-icon {
  color: #fff;
  font-size: 21px;
}

.summer_list_info .opt-col {
  border-right: 1px solid #DCDCDC;
  border-bottom: 1px solid #DCDCDC;
}

.summer_list_info .material_list_content .title {
  font-size: 20px;
  height: 40px;
  color: #439adf;
  padding: 3px 0;
}

.summer_list_info .appli_edit_list {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -112px;
}

.summer_list_info .appli_edit_list .md-button {
  margin: 0;
  min-width: 30px;
  line-height: 18px;
  background: #fff;
  font-size: 14px;
  color: #fff;
  background-color: rgba(67, 154, 223, 0.8);
  font-family: PingFangSC-Regular;
  height: 224px;
  width: 30px;
  white-space: normal;
  z-index: 2;
  border-radius: 16px 0 0 16px;
}

.summer_list_info .appli_edit_list .md-button md-icon {
  color: #fff;
  margin: 0;
}

.summer_list_info .editListDiv {
  position: absolute;
  height: 100%;
  width: 90%;
  right: -90%;
  background: #fff;
  z-index: 1;
  transition: all 0.3s;
  border-left: 1px #DCDCDC solid;
  overflow-y: auto;
}

.summer_list_info .editListDiv .title {
  font-family: Helvetica-Bold;
  color: #439adf;
  height: 46px;
  line-height: 46px;
  border-bottom: 1px solid #DCDCDC;
  border-top: 1px solid #DCDCDC;
  padding-left: 15px;
}

.summer_list_info .editListDiv .title h1 {
  font-size: 20px;
}

.summer_list_info .editListDiv .loadMore {
  color: #439adf;
  cursor: pointer;
}

.summer_list_info .editListDiv .page_list {
  margin-bottom: 20px;
}

.summer_list_info .editListDiv .page_list .md-button {
  padding: 0px;
  margin: 0px;
  color: #428bca;
  border: 1px solid #dee5e7;
  border-left: none;
  background: white;
  width: 30px;
  height: 30px;
  min-width: 0px;
  min-height: 0px;
  line-height: 28px;
}

.summer_list_info .editListDiv .page_list .md-button span {
  font-size: 10px;
  font-weight: bold;
}

.summer_list_info .editListDiv .page_list .md-button:first-child {
  border-left: 1px solid #dee5e7;
}

.summer_list_info .editListDiv .page_list .md-button.active {
  background: #428bca;
  color: white;
}

.summer_list_info .editListDiv .page_list .md-button:hover {
  background: #dee5e7;
}

.summer_list_info .editListDiv .page_list .md-button.active:hover {
  background: #428bca;
}

.summer_list_info .editListDiv table th {
  font-weight: bold;
}

.summer_list_info .editListDiv table tr {
  height: 50px !important;
  border-bottom: 1px solid #DCDCDC;
}

.summer_list_info .editListDiv table th,
.summer_list_info .editListDiv table td {
  padding: 0;
  padding-left: 10px;
  height: 50px !important;
  line-height: 50px;
}

.summer_list_info .editListDiv table .chexiao {
  cursor: pointer;
}

.summer_list_info .editListDiv table .active {
  background: #428bca;
  color: white;
}

.summer_list_info .editListDiv table .show {
  position: relative;
}

.summer_list_info .warning {
  min-width: 40px;
  margin: 0;
  color: red;
}

.summer_list_info .loading {
  display: flex;
  min-height: 150px;
  justify-content: center;
  align-items: center;
}

.summer_list_info .tag {
  font-family: "Monospaced Number","Chinese Quote",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-variant: tabular-nums;
  box-sizing: border-box;
  margin: auto 8px;
  padding: 0 7px;
  list-style: none;
  display: inline-block;
  line-height: 22px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fafafa;
  font-size: 12px;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 1;
  cursor: pointer;
  white-space: nowrap;
}

.summer_list_info .tag .anticon {
  display: inline-block;
  font-style: normal;
  vertical-align: -.125em;
  text-align: center;
  text-transform: none;
  line-height: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.summer_list_info .tag .close {
  display: inline-block;
  line-height: 20px;
  font-size: 12px;
  font-size: 10px\9;
  transform: scale(0.83333333) rotate(0);
  cursor: pointer;
  margin-left: 3px;
  transition: all .3s;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
}

.summer_list_info .subject_box {
  border-right: 1px #DCDCDC solid;
  border-bottom: 1px #DCDCDC solid;
}

.summer_list_info .subject_box .ap-input-label {
  flex: 1 1 100%;
  max-width: 20%;
  background-color: #EEEEEE;
  border-left: 1px solid #DCDCDC;
  border-right: 1px solid #DCDCDC;
  min-height: 36px;
  padding-left: 15px;
  text-indent: 0px;
  transition: all 100ms;
  -webkit-transition: all 50ms;
  cursor: pointer;
  box-sizing: border-box;
}

.summer_list_info .subject_box .ap-input-label:hover {
  background: -webkit-linear-gradient(left, #EEEEEE, #ccc);
}

.summer_list_info .subject_box .ap-input {
  flex: 1 1 100%;
  border: none;
  min-height: 36px;
  text-indent: 17px;
  box-sizing: border-box;
  max-width: 100%;
}

.summer_list_info .subject_box .ap-input:focus {
  outline: 2px solid #0091ea;
}

.summer_list_info .newPromptPanel {
  background: white;
  padding: 10px;
  width: 1000px;
  height: 300px;
  margin-top: -150px;
  margin-left: -500px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 2px #ccc;
}

.summer_list_info .newPromptPanel .title {
  padding: 5px 10px;
  color: #439adf;
  font-size: 20px;
}

.summer_list_info .newPromptPanel .toggle_tab {
  margin-bottom: 10px;
  min-height: 36px;
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
}

.summer_list_info .newPromptPanel .toggle_tab > .md-button {
  margin: 0;
  color: #4A4A4A;
}

.summer_list_info .newPromptPanel .toggle_tab > .md-button.active {
  color: #FFFFFF;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

.summer_list_info .newPromptPanel .can_switch {
  height: 30px;
}

.summer_list_info .newPromptPanel .can_switch .md-button {
  margin: 0;
  min-width: 84px;
  height: 24px;
  min-height: 24px;
  line-height: 24px;
  background: #fff;
  font-size: 14px;
  border: 1px solid #439ADF;
  color: #439ADF;
}

.summer_list_info .newPromptPanel .can_switch .md-button:nth-of-type(1) {
  border-radius: 3px 0px 0px 3px;
}

.summer_list_info .newPromptPanel .can_switch .md-button:nth-of-type(2) {
  border-radius: 0px 3px 3px 0px;
  border-left: none;
}

.summer_list_info .newPromptPanel .can_switch .btnActive {
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  color: #fff;
}

.summer_list_info .newPromptPanel .tag {
  font-family: "Monospaced Number","Chinese Quote",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-variant: tabular-nums;
  box-sizing: border-box;
  margin: 5px;
  text-indent: 3px;
  padding: 0 7px;
  list-style: none;
  display: inline-block;
  line-height: 22px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fafafa;
  font-size: 12px;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 1;
  cursor: pointer;
  white-space: nowrap;
}

.summer_list_info .newPromptPanel .tag .anticon {
  display: inline-block;
  font-style: normal;
  vertical-align: -.125em;
  text-align: center;
  text-transform: none;
  line-height: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.summer_list_info .newPromptPanel .tag .close {
  display: inline-block;
  line-height: 20px;
  font-size: 12px;
  font-size: 10px\9;
  transform: scale(0.83333333) rotate(0);
  cursor: pointer;
  margin-left: 3px;
  transition: all .3s;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
}

.summer_list_info .grayPower {
  background-color: rgba(128, 128, 105, 0.3);
}

.summer_list_info .summer_list_info .program_list_content .content table tbody tr td ap-form-input {
  transition: all 0.2s ease 0s;
}

.summer_list_info .summer_list_info .program_list_content .content table tbody tr td div input {
  transition: all 0.2s ease 0s;
}

.summer_list_info .tableTitle {
  text-align: center;
  height: 30px;
  background: #7986CB;
  color: #fff;
  line-height: 30px;
  font-size: 14px;
  font-family: "PingFang SC";
  font-weight: bold;
}

.summer_list_info .flex-5 {
  max-width: 7%;
}

.summer_list_info .lineTo {
  max-width: 100% !important;
}

.summer_list_info .lineTo div.ap-input-label {
  max-width: 20%;
}

.summer_list_info .lineTo div.ap-multiple-select {
  max-width: 98%;
}

.summer_list_info .lineTo div.ap-multiple-select p {
  line-height: 26px;
}

#summer-school {
  position: relative;
  padding: 20px;
}

#summer-school .question_content {
  position: absolute;
  height: 95%;
  right: -500px;
  z-index: 100;
  overflow-x: hidden;
  overflow-y: hidden;
  border-radius: 3px;
  background: #fff;
  width: 500px;
  color: #4A4A4A;
}

#summer-school .question_content .title {
  padding: 0 20px;
  background: #7986CB;
  color: #fff;
  height: 40px;
  line-height: 40px;
}

#summer-school .question_content .title md-icon {
  width: 24px;
  height: 24px;
  color: #fff;
}

#summer-school .question_content .bottom_content {
  height: 796px;
  overflow-y: auto;
}

#summer-school .question_content .bottom_content md-list md-list-item .md-list-item-inner {
  padding: 15px 0 5px 0;
}

#summer-school .question_content .bottom_content md-list md-list-item .md-list-item-inner .action {
  align-self: flex-end;
}

#summer-school .question_content .bottom_content md-list md-list-item .md-list-item-inner h3, #summer-school .question_content .bottom_content md-list md-list-item .md-list-item-inner p {
  padding-top: 10px;
}

#summer-school .status_btn {
  margin-bottom: 10px;
}

#summer-school .status_btn .md-button {
  margin: 0;
  min-width: 84px;
  height: 28px;
  min-height: 28px;
  line-height: 28px;
  border-radius: 4px;
  font-size: 12px;
}

#summer-school .status_btn .dea {
  border: 1px solid #9b9b9b;
  color: #9b9b9b;
}

#summer-school .status_btn .dea:hover {
  background: #9b9b9b;
  color: #fff;
}

#summer-school .status_btn .act {
  border: 1px solid #7ED321;
  color: #7ED321;
}

#summer-school .status_btn .act:hover {
  background: #7ED321;
  color: #fff;
}

#summer-school .mid {
  padding-bottom: 16px;
}

#summer-school .mid .select_title {
  color: #439ADF;
  padding-bottom: 8px;
  font-size: 18px;
}

#summer-school .mid .search_box {
  padding-right: 16px;
  position: relative;
}

#summer-school .mid .search_box md-autocomplete {
  background: none;
  height: 32px;
}

#summer-school .mid .search_box md-autocomplete md-autocomplete-wrap {
  height: 32px;
  box-shadow: none;
  border: 1px solid #9B9B9B;
}

#summer-school .mid .search_box md-autocomplete md-autocomplete-wrap input {
  padding: 0 10px;
  height: 32px;
  line-height: 32px;
}

#summer-school .mid .add_btn {
  margin: 0;
  min-width: 78px;
  height: 32px;
  min-height: 32px;
  line-height: 32px;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  color: white;
  box-shadow: 0px 1px 2px 0px rgba(67, 154, 223, 0.6);
}

#summer-school .mid .add_btn:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

#summer-school .mid .add_btn md-icon {
  font-size: 18px;
  height: 20px;
  width: 20px;
  min-width: 20px;
  min-height: 20px;
  color: #fff;
}

#summer-school .mid .add_paper {
  background-image: linear-gradient(45deg, #5EE1BF 0, #439ADF 100%);
  padding: 0 2px;
  margin: 0px;
  color: white;
}

#summer-school .mid .paperIcon {
  color: white;
  font-size: 18px;
  margin-top: 4px;
  margin-right: -4px;
}

#summer-school .page_list, #summer-school .edit_list {
  padding-bottom: 0;
  padding-top: 10px;
}

#summer-school .page_list .md-button, #summer-school .edit_list .md-button {
  padding: 0px;
  margin: 0px;
  color: #428bca;
  border: 1px solid #dee5e7;
  border-left: none;
  background: white;
  width: 30px;
  height: 30px;
  min-width: 0px;
  min-height: 0px;
  line-height: 28px;
}

#summer-school .page_list .md-button span, #summer-school .edit_list .md-button span {
  font-size: 10px;
  font-weight: bold;
}

#summer-school .page_list .md-button:first-child, #summer-school .page_list .md-button:nth-of-type(3), #summer-school .edit_list .md-button:first-child, #summer-school .edit_list .md-button:nth-of-type(3) {
  border-left: 1px solid #dee5e7;
}

#summer-school .page_list .md-button.active, #summer-school .edit_list .md-button.active {
  background: #428bca;
  color: white;
}

#summer-school .page_list .md-button:hover, #summer-school .edit_list .md-button:hover {
  background: #dee5e7;
}

#summer-school .page_list .md-button.active:hover, #summer-school .edit_list .md-button.active:hover {
  background: #428bca;
}

#summer-school .scroll_btn {
  position: absolute;
  z-index: 50;
  padding: 0px;
  margin: 0px;
  width: 20px;
  height: 40px;
  background: #7986CB;
  border-radius: 0;
}

#summer-school .scroll_btn md-icon {
  color: #fff;
}

#summer-school .scroll_btn_left {
  right: 0px;
  top: 0px;
}

#summer-school .scroll_btn_right {
  right: 0px;
  top: 0px;
}

#summer-school .table_container {
  padding-right: 10px;
  height: 100%;
  overflow: auto;
}

#summer-school .table_container table {
  overflow: inherit;
}

#summer-school .table_container table thead th {
  padding: 10px 3px;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background: #7986CB;
  z-index: 10;
  white-space: nowrap;
}

#summer-school .table_container table thead th:first-child > div,
#summer-school .table_container table thead th:nth-child(2) > div {
  padding-left: 10px !important;
}

#summer-school .table_container table thead th:last-child {
  position: sticky;
  position: -webkit-sticky;
  right: -10px;
  background: #fff;
  margin-left: 15px;
}

#summer-school .table_container table tbody tr:first-child td {
  padding-top: 0px;
}

#summer-school .table_container table tbody tr {
  cursor: pointer;
}

#summer-school .table_container table tbody tr:hover td {
  box-sizing: border-box;
  background: rgba(238, 238, 238, 0.97);
}

#summer-school .table_container table tbody tr:hover td td .box {
  border: none;
}

#summer-school .table_container table tbody tr td:last-child {
  position: sticky;
  position: -webkit-sticky;
  right: -10px;
  background: #fff;
}

#summer-school .table_container table tbody tr td:last-child .box {
  border: 0 !important;
  border-left: 1px solid #9B9B9B !important;
}

#summer-school .table_container table tbody td {
  padding: 3px 0px;
}

#summer-school .table_container table tbody td .box {
  padding: 0px 8px;
  padding-left: 23px;
  height: 43px;
  border-bottom: 1px solid #9B9B9B;
  border-top: 1px solid #9B9B9B;
  position: relative;
}

#summer-school .table_container table tbody td:first-child .box {
  padding-left: 8px;
  border-left: 1px solid #9B9B9B;
  font-weight: bold;
}

#summer-school .table_container table tbody td:nth-child(2) .box {
  padding-left: 8px;
  font-weight: bold;
}

#summer-school .table_container table tbody td:nth-child(3) .box {
  padding-left: 8px;
  font-weight: bold;
}

#summer-school .add_school {
  background-image: linear-gradient(45deg, #5EE1BF 0, #439ADF 100%);
  padding: 0 2px;
  margin: 0px;
  color: white;
}

#summer-school .add_school:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

#summer-school .shoolIcon {
  color: white;
  font-size: 18px;
  margin-top: 4px;
  margin-right: -4px;
}

#summer-school .question_title {
  position: absolute;
  right: 0;
  top: 50%;
  width: 25px;
  z-index: 101;
}

#summer-school .question_title .md-button {
  margin: 0;
  min-width: 25px;
  line-height: 18px;
  background: #fff;
  font-size: 14px;
  color: #fff;
  background-color: rgba(67, 154, 223, 0.8);
  font-family: PingFangSC-Regular;
  height: 224px;
  width: 30px;
  white-space: normal;
  z-index: 2;
  border-radius: 16px 0 0 16px;
}

#summer-school .question_title .md-button md-icon {
  color: #fff;
  margin: 0;
}

#summer-school .list_mask {
  position: absolute;
  width: 98%;
  top: 5%;
  height: 82%;
  z-index: 100;
}

#summer-school .app_list {
  position: relative;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  width: 90%;
  height: 90%;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

#summer-school .app_list .application_container {
  overflow: auto;
  height: 85%;
  overflow: auto;
}

#summer-school .app_list .application_container table {
  overflow: inherit;
}

#summer-school .app_list .application_container table thead th {
  padding: 10px 3px;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background: #7986CB;
  z-index: 10;
  white-space: nowrap;
}

#summer-school .app_list .application_container table thead th:first-child > div,
#summer-school .app_list .application_container table thead th:nth-child(2) > div {
  padding-left: 10px !important;
}

#summer-school .app_list .application_container table tbody tr:first-child td {
  padding-top: 0px;
}

#summer-school .app_list .application_container table tbody td {
  padding: 3px 0px;
}

#summer-school .app_list .application_container table tbody td .box {
  padding: 0px 8px;
  padding-left: 23px;
  height: 43px;
  border-bottom: 1px solid #9B9B9B;
  border-top: 1px solid #9B9B9B;
  position: relative;
}

#summer-school .app_list .application_container table tbody td .box .loadMore {
  color: #439adf;
  cursor: pointer;
}

#summer-school .app_list .application_container table tbody td:first-child .box {
  padding-left: 12px;
  border-left: 1px solid #9B9B9B;
  font-weight: bold;
}

#summer-school .app_list .application_container table tbody td:nth-child(2) .box {
  padding-left: 8px;
  font-weight: bold;
}

#summer-school .app_list .application_container table tbody td:nth-child(3) .box {
  padding-left: 8px;
  font-weight: bold;
  text-align: center;
}

#summer-school .app_list .application_container table tbody td:nth-last-child(1) .box {
  border-right: 1px solid #9B9B9B;
}

#summer-school .app_list .application_container table tbody td .goDetail a {
  cursor: pointer;
  color: #72ACE3;
}

#summer-school .app_list .application_container table tbody tr td .action span {
  cursor: pointer;
}

#summer-school .app_list .closebtn {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #000;
}

#summer-school .app_list .chexiao {
  margin-right: 5px;
}

#summer-school .app_list .action {
  padding-left: 10px !important;
}

#summer-school .app_list .edit_list {
  position: absolute;
  bottom: 5px;
  left: 50%;
  margin-left: -140px;
}

#summer-school .downloadAttr {
  margin-left: 5px;
  color: blue;
  cursor: pointer;
}

.loadMoreForm .subject_box {
  border-right: 1px #DCDCDC solid;
  border-bottom: 1px #DCDCDC solid;
}

.loadMoreForm .subject_box .ap-input-label {
  flex: 1 1 100%;
  max-width: 20%;
  background-color: #EEEEEE;
  border-left: 1px solid #DCDCDC;
  border-right: 1px solid #DCDCDC;
  min-height: 36px;
  padding-left: 15px;
  text-indent: 0px;
  transition: all 100ms;
  -webkit-transition: all 50ms;
  cursor: pointer;
  box-sizing: border-box;
  line-height: 36px;
}

.loadMoreForm .subject_box .ap-input-label:hover {
  background: -webkit-linear-gradient(left, #EEEEEE, #ccc);
}

.loadMoreForm .subject_box .ap-input {
  flex: 1 1 100%;
  border: none;
  min-height: 36px;
  text-indent: 17px;
  box-sizing: border-box;
  max-width: 100%;
}

.loadMoreForm .subject_box .ap-input:focus {
  outline: 2px solid #0091ea;
}

.summer_school_info {
  padding: 10px;
  position: relative;
  height: 600px;
}

.summer_school_info .redColor {
  border: 1px solid red !important;
}

.summer_school_info .opt-col {
  border-left: 1px solid #DCDCDC;
}

.summer_school_info .table-row {
  border-top: 1px solid #DCDCDC;
}

.summer_school_info .status_btn {
  margin-left: 10px;
}

.summer_school_info .status_btn .md-button {
  margin: 0;
  min-width: 84px;
  height: 28px;
  min-height: 28px;
  line-height: 28px;
  border-radius: 4px;
  font-size: 12px;
}

.summer_school_info .status_btn .dea {
  border: 1px solid #9b9b9b;
  color: #9b9b9b;
}

.summer_school_info .status_btn .dea:hover {
  background: #9b9b9b;
  color: #fff;
}

.summer_school_info .status_btn .act {
  border: 1px solid #7ED321;
  color: #7ED321;
}

.summer_school_info .status_btn .act:hover {
  background: #7ED321;
  color: #fff;
}

.summer_school_info .score_table_fix {
  border: 1px solid #DCDCDC;
  box-sizing: border-box;
}

.summer_school_info .score_table_fix textarea {
  width: 100%;
  border: none;
}

.summer_school_info .score_table_fix textarea:focus {
  outline: 2px solid #0091ea;
}

.summer_school_info .score_table_fix table.md-table thead.md-head > tr.md-row {
  height: 36px;
}

.summer_school_info .score_table_fix table.md-table thead.md-head > tr.md-row th.md-column {
  padding: 0px 17px;
  background-color: #EEEEEE;
  text-indent: 0px;
  z-index: 1;
  text-align: center;
  font-size: 16px;
}

.summer_school_info .score_table_fix table.md-table tbody.md-body tr.md-row {
  position: relative;
  height: 36px;
}

.summer_school_info .score_table_fix table.md-table tbody.md-body tr.md-row td.md-cell {
  position: relative;
  padding: 0 0 0 17px;
  text-indent: 0px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 2px solid #fff;
}

.summer_school_info .toggle_tab {
  margin-bottom: 10px;
  min-height: 36px;
  margin-right: 30px;
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
}

.summer_school_info .toggle_tab > .md-button {
  margin: 0;
  width: 176px;
  color: #4A4A4A;
}

.summer_school_info .toggle_tab > .md-button.active {
  color: #FFFFFF;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

.summer_school_info .program_list_content {
  overflow-y: auto;
  border-top: 1px solid #DCDCDC;
}

.summer_school_info .program_list_content, .summer_school_info .prompt_list_content, .summer_school_info .material_list_content {
  margin-right: 30px;
}

.summer_school_info .table-btn {
  background: #439ADF;
  width: 27px;
  height: 27px;
  border-radius: 3px;
  padding: 0;
  min-width: 27px;
  min-height: 27px;
  line-height: 27px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.summer_school_info .table-btn:not([disabled]).md-focused, .summer_school_info .table-btn:not([disabled]):hover {
  background-color: #439ADF;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.summer_school_info .table-btn > md-icon {
  color: #fff;
  font-size: 21px;
}

.summer_school_info .opt-col {
  border-right: 1px solid #DCDCDC;
  border-bottom: 1px solid #DCDCDC;
}

.summer_school_info .material_list_content {
  overflow-y: auto;
}

.summer_school_info .material_list_content .title {
  font-size: 20px;
  color: #439adf;
  padding: 3px 0;
}

.summer_school_info .appli_edit_list {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -112px;
}

.summer_school_info .appli_edit_list .md-button {
  margin: 0;
  min-width: 30px;
  line-height: 18px;
  background: #fff;
  font-size: 14px;
  color: #fff;
  background-color: rgba(67, 154, 223, 0.8);
  font-family: PingFangSC-Regular;
  height: 224px;
  width: 30px;
  white-space: normal;
  z-index: 2;
  border-radius: 16px 0 0 16px;
}

.summer_school_info .appli_edit_list .md-button md-icon {
  color: #fff;
  margin: 0;
}

.summer_school_info .editListDiv {
  position: absolute;
  height: 100%;
  width: 90%;
  right: -90%;
  background: #fff;
  z-index: 1;
  transition: all 0.3s;
  border-left: 1px #DCDCDC solid;
  overflow-y: auto;
}

.summer_school_info .editListDiv .title {
  font-family: Helvetica-Bold;
  color: #439adf;
  height: 46px;
  line-height: 46px;
  border-bottom: 1px solid #DCDCDC;
  border-top: 1px solid #DCDCDC;
  padding-left: 15px;
}

.summer_school_info .editListDiv .title h1 {
  font-size: 20px;
}

.summer_school_info .editListDiv .loadMore {
  color: #439adf;
  cursor: pointer;
}

.summer_school_info .editListDiv .page_list {
  margin-bottom: 20px;
}

.summer_school_info .editListDiv .page_list .md-button {
  padding: 0px;
  margin: 0px;
  color: #428bca;
  border: 1px solid #dee5e7;
  border-left: none;
  background: white;
  width: 30px;
  height: 30px;
  min-width: 0px;
  min-height: 0px;
  line-height: 28px;
}

.summer_school_info .editListDiv .page_list .md-button span {
  font-size: 10px;
  font-weight: bold;
}

.summer_school_info .editListDiv .page_list .md-button:first-child {
  border-left: 1px solid #dee5e7;
}

.summer_school_info .editListDiv .page_list .md-button.active {
  background: #428bca;
  color: white;
}

.summer_school_info .editListDiv .page_list .md-button:hover {
  background: #dee5e7;
}

.summer_school_info .editListDiv .page_list .md-button.active:hover {
  background: #428bca;
}

.summer_school_info .editListDiv table th {
  font-weight: bold;
}

.summer_school_info .editListDiv table tr {
  height: 50px !important;
  border-bottom: 1px solid #DCDCDC;
}

.summer_school_info .editListDiv table th,
.summer_school_info .editListDiv table td {
  padding: 0;
  padding-left: 10px;
  height: 50px !important;
  line-height: 50px;
}

.summer_school_info .editListDiv table .chexiao {
  cursor: pointer;
}

.summer_school_info .editListDiv table .active {
  background: #428bca;
  color: white;
}

.summer_school_info .editListDiv table .show {
  position: relative;
}

.summer_school_info .warning {
  min-width: 40px;
  margin: 0;
  color: red;
}

.summer_school_info .loading {
  display: flex;
  min-height: 150px;
  justify-content: center;
  align-items: center;
}

.summer_school_info .tag {
  font-family: "Monospaced Number","Chinese Quote",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-variant: tabular-nums;
  box-sizing: border-box;
  margin: auto 8px;
  padding: 0 7px;
  list-style: none;
  display: inline-block;
  line-height: 22px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fafafa;
  font-size: 12px;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 1;
  cursor: pointer;
  white-space: nowrap;
}

.summer_school_info .tag .anticon {
  display: inline-block;
  font-style: normal;
  vertical-align: -.125em;
  text-align: center;
  text-transform: none;
  line-height: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.summer_school_info .tag .close {
  display: inline-block;
  line-height: 20px;
  font-size: 12px;
  font-size: 10px\9;
  transform: scale(0.83333333) rotate(0);
  cursor: pointer;
  margin-left: 3px;
  transition: all .3s;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
}

.summer_school_info .subject_box {
  border-right: 1px #DCDCDC solid;
  border-bottom: 1px #DCDCDC solid;
}

.summer_school_info .subject_box .ap-input-label {
  flex: 1 1 100%;
  max-width: 20%;
  background-color: #EEEEEE;
  border-left: 1px solid #DCDCDC;
  border-right: 1px solid #DCDCDC;
  min-height: 36px;
  padding-left: 15px;
  text-indent: 0px;
  transition: all 100ms;
  -webkit-transition: all 50ms;
  cursor: pointer;
  box-sizing: border-box;
}

.summer_school_info .subject_box .ap-input-label:hover {
  background: -webkit-linear-gradient(left, #EEEEEE, #ccc);
}

.summer_school_info .subject_box .ap-input {
  flex: 1 1 100%;
  border: none;
  min-height: 36px;
  text-indent: 17px;
  box-sizing: border-box;
  max-width: 100%;
}

.summer_school_info .subject_box .ap-input:focus {
  outline: 2px solid #0091ea;
}

.newPromptPanel {
  background: white;
  padding: 10px;
  width: 1000px;
  height: 300px;
  margin-top: -150px;
  margin-left: -500px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 2px #ccc;
}

.newPromptPanel .title {
  padding: 5px 10px;
  color: #439adf;
  font-size: 20px;
}

.newPromptPanel .toggle_tab {
  margin-bottom: 10px;
  min-height: 36px;
  box-shadow: 0px 1px 6px 0px rgba(0, 109, 213, 0.26);
}

.newPromptPanel .toggle_tab > .md-button {
  margin: 0;
  color: #4A4A4A;
}

.newPromptPanel .toggle_tab > .md-button.active {
  color: #FFFFFF;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
}

.newPromptPanel .can_switch {
  height: 30px;
}

.newPromptPanel .can_switch .md-button {
  margin: 0;
  min-width: 84px;
  height: 24px;
  min-height: 24px;
  line-height: 24px;
  background: #fff;
  font-size: 14px;
  border: 1px solid #439ADF;
  color: #439ADF;
}

.newPromptPanel .can_switch .md-button:nth-of-type(1) {
  border-radius: 3px 0px 0px 3px;
}

.newPromptPanel .can_switch .md-button:nth-of-type(2) {
  border-radius: 0px 3px 3px 0px;
  border-left: none;
}

.newPromptPanel .can_switch .btnActive {
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  color: #fff;
}

.newPromptPanel .tag {
  font-family: "Monospaced Number","Chinese Quote",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-variant: tabular-nums;
  box-sizing: border-box;
  margin: 5px;
  text-indent: 3px;
  padding: 0 7px;
  list-style: none;
  display: inline-block;
  line-height: 22px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fafafa;
  font-size: 12px;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 1;
  cursor: pointer;
  white-space: nowrap;
}

.newPromptPanel .tag .anticon {
  display: inline-block;
  font-style: normal;
  vertical-align: -.125em;
  text-align: center;
  text-transform: none;
  line-height: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.newPromptPanel .tag .close {
  display: inline-block;
  line-height: 20px;
  font-size: 12px;
  font-size: 10px\9;
  transform: scale(0.83333333) rotate(0);
  cursor: pointer;
  margin-left: 3px;
  transition: all .3s;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
}


#process_task_top a:hover {
  border-bottom: 2px solid #2e9bdd;
  color: #2e9bdd;
}



.trainManage {
  padding-left: 20px;
}

.trainManage .train_btn {
  border-radius: 8px;
  border: 1px solid #439ADF;
  font-size: 16px;
  color: #439ADF;
  width: 110px;
}

.trainManage .btn_not {
  box-shadow: none !important;
}

.trainManage .btn_checked {
  background-image: linear-gradient(45deg, #439ADF 0, #78CAF1 100%);
  color: white;
  border: 1px solid #FFFFFF;
}

.trainManage .train-toolbar-tools {
  position: relative;
  z-index: 2;
  font-size: 20px;
  height: 40px;
  line-height: 40px;
  width: 100%;
  padding: 0 16px;
  letter-spacing: .005em;
  box-sizing: border-box;
  font-weight: 400;
}

.trainManage .md-style {
  height: 40px;
  min-height: 40px;
  font-size: 20px;
  background: #7986CB !important;
  color: #fff !important;
}

.trainManage .listItem {
  height: 38px;
  min-height: 38px;
  color: #439ADF;
  background: #FFFFFF;
}

.trainManage .icon {
  cursor: pointer;
  color: white !important;
  margin-right: 12px;
}

.trainManage .delicon {
  margin: 0;
  top: 0;
  position: absolute;
  right: 0;
  cursor: pointer;
  margin-right: 12px;
  z-index: 1000;
}

.trainManage .out {
  margin-top: 8px;
  padding-bottom: 10px;
}

.trainManage .left {
  border: 1px solid #DCDCDC;
}

.trainManage .right {
  margin-left: 10px;
  border: 1px solid #DCDCDC;
  margin-right: 40px;
  padding: 15px;
}

.trainManage .active {
  background: #EDEDED;
}

.trainManage .lesson_time {
  height: 58px;
  line-height: 58px;
  font-size: 16px;
  color: #4A4A4A;
  font-weight: 500;
}

.trainManage .lesson_time_input {
  color: #4A4A4A;
  font-size: 16px;
  font-weight: 500;
}

.trainManage .lesson_summary_p {
  font-size: 20px;
  color: #439ADF;
  font-weight: 500;
}

.trainManage .lesson_textarea {
  border: 0;
}

.trainManage .textarea_more {
  margin-top: 8px;
  color: #439ADF;
  cursor: pointer;
  text-decoration: underline;
}

.trainManage .chapter {
  height: 44px;
  line-height: 58px;
  font-size: 16px;
  color: #4A4A4A;
  font-weight: 500;
  border-bottom: 1px solid #979797;
}

.trainManage .chapter input {
  font-size: 16px;
  color: #4A4A4A;
  font-weight: 500;
}

.trainManage .chapter_icon {
  color: #000;
  margin: 20px 0 0 10px;
}

.trainManage .chapterBtn {
  margin-top: 15px;
  margin-left: 0;
  width: 120px;
  border: 1px solid #4A4A4A;
  border-radius: 8px;
  color: #4A4A4A;
}

.trainManage .chapter_del {
  margin-bottom: 0;
  width: 120px;
  border: 1px solid #4A4A4A;
  border-radius: 8px;
  color: #4A4A4A;
}

.trainManage .demo-md-panel {
  min-height: 500px;
}

.trainManage .demo-dialog-example {
  background: white;
  border-radius: 4px;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
  width: 500px;
}

.trainManage .demo-dialog-content {
  padding: 0 15px;
  width: 100%;
}

.trainManage .demo-dialog-content img {
  height: 300px;
  margin: auto;
}

.trainManage .demo-dialog-button {
  width: 100%;
}

.trainManage .uploadItem {
  height: 48px;
  line-height: 48px;
}

.trainManage .deleteBtn {
  width: 40px;
  min-width: 40px;
}

.trainManage .chapterBtn input {
  position: absolute;
  right: 0px;
  top: 0px;
  opacity: 0;
  -ms-filter: 'alpha(opacity=0)';
  font-size: 200px;
}

.trainManage .md-button[disabled] {
  color: #DCDCDC !important;
}

#admin .grid-list-header figure {
  font-weight: 700;
}

#admin .grid-list-item {
  cursor: pointer;
  outline: none;
}

#admin .grid-list-item:hover {
  background: #f6f8f8;
}

#admin .page_list button {
  width: 30px;
  height: 30px;
  border: 1px solid #dee5e7;
  background: white;
  color: #428bca;
  border-left: none;
}

#admin .page_list button span {
  font-size: 10px;
}

#admin .page_list button.active {
  background: #428bca;
  color: white;
}

#admin .page_list button.active:hover {
  background: #428bca;
}

#admin .page_list button:hover {
  background: #dee5e7;
}

#admin .page_list button.noHover:hover {
  background: none;
}

#admin .page_list button:focus {
  outline: none;
}

#admin .role_divider {
  border-left: 1px solid #ccc;
  margin-left: 3px;
}

#admin .positionBtn {
  font-size: 10px;
  padding: 10px 0 10px 20px;
}

#admin .positionBtn .md-button {
  margin: 0;
  border-radius: 36px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
}

#admin .positionBtn .md-button:hover {
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  color: #fff;
}

#admin .positionBtn .md-button.btnActive {
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  color: #fff;
}

#admin .status_btn .md-button {
  margin: 0;
  min-width: 84px;
  height: 28px;
  min-height: 28px;
  line-height: 28px;
  border-radius: 4px;
  font-size: 12px;
}

#admin .status_btn .dea {
  border: 1px solid #9b9b9b;
  color: #9b9b9b;
}

#admin .status_btn .dea:hover {
  background: #9b9b9b;
  color: #fff;
}

#admin .status_btn .act {
  border: 1px solid #7ED321;
  color: #7ED321;
}

#admin .status_btn .act:hover {
  background: #7ED321;
  color: #fff;
}

#admin .status_btn .all {
  border: 1px solid #439ADF;
  color: #439ADF;
}

#admin .status_btn .all:hover {
  background: #439ADF;
  color: #fff;
}

#admin .status_btn .deaActive {
  background: #9b9b9b;
  color: #fff;
}

#admin .status_btn .actActive {
  background: #7ED321;
  color: #fff;
}

#admin .status_btn .allActive {
  background: #439ADF;
  color: #fff;
}

.md-dialog-container {
  z-index: 108;
}

.md-dialog-container md-dialog {
  min-width: 50%;
}

.md-dialog-container md-dialog md-dialog-content {
  overflow: hidden;
}

.md-dialog-container md-dialog md-dialog-content .md-dialog-content {
  padding: 0;
}

.md-select-menu-container {
  z-index: 110;
}

#show_user {
  min-height: 408px;
}

#show_user md-content {
  padding: 20px;
}

#show_user md-pagination-wrapper {
  width: 100% !important;
}

#show_user md-pagination-wrapper md-tab-item {
  width: 50%;
}

#show_user md-pagination-wrapper md-tab-item.md-active {
  color: black;
}

.grid-list figure {
  justify-content: flex-start;
  padding-left: 10px;
  overflow: hidden;
}

.searchDiv {
  padding: 0 10px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.searchDiv input {
  width: 100px;
  height: 30px;
  padding: 0;
  color: #666;
  z-index: 2;
  border: 0 none;
}

.searchDiv input:focus {
  outline: 0 none;
}

.searchDiv md-icon:hover {
  animation: move 1s ease infinite alternate;
  -webkit-animation: move 1s ease infinite alternate;
}

@keyframes move {
  0% {
    -webkit-transform: scale(1);
  }
  100% {
    -webkit-transform: scale(2);
  }
}

webform-input {
  display: block;
  position: relative;
}

webform-input .no-border {
  border: none;
}

webform-input table tbody, webform-input table thead, webform-input table tr {
  display: block;
}

webform-input table td {
  padding: 0px;
  padding-right: 10px;
}

webform-input table th p {
  font-size: 12px;
  color: black;
}

webform-input md-select[disabled] .md-select-value {
  margin-bottom: 1px;
}

webform-input table.md-table th.md-column {
  white-space: inherit;
}

webform-input md-select:not([disabled]):focus .md-select-value {
  border: none;
}

webform-input md-select.md-no-underline {
  position: absolute;
  width: 100%;
  bottom: 6px;
  right: 0px;
  margin-left: -2px;
}

webform-input md-select.md-no-underline md-select-value.md-select-value {
  border: none;
  justify-content: flex-end;
}

webform-input md-select.md-no-underline md-select-value.md-select-value span:first-child {
  display: none;
}

webform-input md-select.ps.md-no-underline {
  width: 20%;
}

webform-input label {
  text-align: left;
}

webform-input md-datepicker {
  display: flex;
  flex-direction: row;
  align-items: center;
}

webform-input md-datepicker .md-datepicker-input-container {
  flex: 1;
}

.website_past_performance .top {
  background: white;
  margin-top: 10px;
}

.website_past_performance .top .periodBtn .text {
  color: #3facf5;
}

.website_past_performance .top .periodBtn .icon {
  color: #3facf5;
  margin-top: -2px;
}

.website_past_performance .top md-autocomplete md-autocomplete-wrap {
  height: 28px;
  box-shadow: none;
  border: 1px solid #9B9B9B;
  border-radius: 3px;
}

.website_past_performance .top md-autocomplete md-autocomplete-wrap input {
  padding: 0 10px;
  height: 28px;
  line-height: 28px;
}

.website_past_performance .top md-autocomplete .md-show-clear-button button {
  width: 28px;
  height: 28px;
}

.website_past_performance .top .add {
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  padding: 0;
  margin: 0;
  margin-left: 10px;
  background: white;
  border-radius: 3px;
  border: 2px solid #439adf;
}

.website_past_performance .top .add md-icon {
  color: #439adf;
  font-size: 24px;
  font-weight: bold;
}

.website_past_performance .containerBox {
  background-color: white;
  padding-bottom: 5px;
  margin: 10px 0px;
  padding-right: 10px;
}

.website_past_performance .containerBox table.md-table td.md-cell {
  border-top: 3px rgba(0, 0, 0, 0.205) solid;
}

.website_past_performance .containerBox .table {
  border-right: 1px solid #ccc;
  overflow: auto;
}

.website_past_performance .containerBox .table .thClass {
  min-width: 160px;
  border-right: 1px solid #ccc;
  z-index: 52;
  line-height: 40px;
  font-size: 16px;
  padding: 0 !important;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background: white;
  border-bottom: 1px solid #ccc;
}

.website_past_performance .containerBox .table .thClass > span {
  padding-left: 24px;
}

.website_past_performance .containerBox .table .aliasImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  vertical-align: middle;
  margin: 5px 0px;
  margin-right: 6px;
  border: 1px solid #ccc;
}

.website_past_performance .containerBox .table .tdClass {
  border-right: 1px solid #ccc;
  z-index: 4;
  padding: 0 !important;
}

.website_past_performance .containerBox .table .tdClass > span {
  padding-left: 24px;
}

.website_past_performance .containerBox .table .tdClass .totalnum {
  text-align: center;
  width: 100%;
  display: inline-block;
  padding-left: 0;
}

.website_past_performance .containerBox .table .tdClass .formInput {
  min-height: 30px;
}

.website_past_performance .containerBox .table .tdClass .formInput .ap-input {
  max-height: 200px;
  overflow-y: auto;
}

.website_past_performance .containerBox .table .tdClass .formInput .opt-col {
  min-width: 40px;
}

.website_past_performance .containerBox .table .tdClass ap-form-input.score_table table.md-table {
  padding: 0;
}

.website_past_performance .containerBox .table .tdClass ap-form-input.score_table table.md-table thead.md-head > tr.md-row th.md-column {
  text-align: center;
}

.website_past_performance .containerBox .table .tdClass .resultTable {
  width: 100%;
  margin: 0px;
  max-height: 200px;
  overflow-y: auto;
  display: block;
}

.website_past_performance .containerBox .table .tdClass .resultTable th {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  font-weight: bold;
  border-right: 1px solid #ccc;
  background-color: #EEEEEE;
  height: 36px;
  border-collapse: collapse;
  text-align: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  min-width: 100px;
}

.website_past_performance .containerBox .table .tdClass .resultTable th:last-child {
  border-right: none;
}

.website_past_performance .containerBox .table .tdClass .resultTable tr {
  height: 36px;
  border-bottom: 1px solid #ccc;
}

.website_past_performance .containerBox .table .tdClass .resultTable tr td {
  text-align: center;
  border-right: 1px solid #ccc;
  padding: 0 5px;
  position: relative;
  min-width: 90px;
  word-break: break-word;
}

.website_past_performance .containerBox .table .tdClass .resultTable tr td:last-child {
  border-right: none;
}

.website_past_performance .containerBox .table .tdClass .resultTable .is_new {
  background: #F4511E;
  position: absolute;
  border-radius: 2px;
  padding: 3px;
  font-size: 11px;
  color: #fff;
  display: inline-block;
  line-height: 12px;
  font-family: PingFangSC-Semibold;
  right: 0;
  top: 0;
}

.website_past_performance .containerBox .table .tdClass .ranking {
  padding: 0 2px 2px;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 15px;
  background: #989898;
  color: #fff;
  min-width: 15px;
  text-align: center;
}

.website_past_performance .datepick {
  margin-left: 10px;
}

.website_past_performance .datepick ap-datepicker {
  margin-left: 10px;
}

.website_past_performance .datepick ap-datepicker input {
  padding: 3px 0;
}























.ForgotPassword-formContainer {
  margin-top: 80px;
  margin-bottom: 80px;
}







.Login-formContainer {
  margin-top: 80px;
  margin-bottom: 80px;
}
















.ResetPassword-formContainer {
  margin-top: 80px;
  margin-bottom: 80px;
  background: red;
}








































#roadmap-content {
  padding: 20px;
  position: relative;
}

#roadmap-content .container {
  background: #fff;
}

#roadmap-content .header {
  text-align: center;
  margin-bottom: 20px;
}

#roadmap-content .header p {
  font-size: 18px;
  color: #439ADF;
  font-weight: 500;
}

#roadmap-content .template {
  width: 100%;
}

#roadmap-content .template > div {
  display: block;
}

#roadmap-content .card {
  position: relative;
  margin: 15px auto;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  max-width: 500px;
}

#roadmap-content .card ._title {
  color: #2D2D2D;
  padding: 0 20px;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #DCDCDC;
}

#roadmap-content .card ._content {
  padding: 20px;
  min-height: 200px;
}

#roadmap-content ._del {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 8px;
  color: rgba(0, 0, 0, 0.3);
  font-size: 16px;
}

#roadmap-content textarea {
  max-height: 200px;
  overflow-y: auto;
}

#roadmap-content .action {
  margin-top: 0px;
  min-height: 55px;
  width: 100%;
}

#roadmap-content .action .btn {
  min-width: 50px;
  min-height: auto;
  padding: 0 5px;
  height: 24px;
  line-height: 24px;
  background-color: #78caf1;
  color: white;
  background-image: linear-gradient(45deg, #439adf 0px, #78caf1 100%);
  border: 0px;
  border-radius: 3px;
  font-size: 12px;
}

#roadmap-content h2 {
  color: #757575;
}

#roadmap-content .tableOut {
  position: relative;
  margin-top: 15px;
}

#roadmap-content .fms {
  position: absolute;
  top: 0;
  right: 0;
}

#roadmap-content .tempdiv {
  height: auto;
}

#roadmap-content .tempdiv .ap-input {
  flex: 1 1 100%;
  border: none;
  min-height: 36px;
  text-indent: 17px;
  box-sizing: border-box;
  max-width: 100%;
}

#roadmap-content .tempdiv .ap-input:focus {
  outline: 2px solid #0091ea;
}

#roadmap-content .tempdiv .ap-input-btn {
  background: #439ADF;
  width: 27px;
  height: 27px;
  border-radius: 3px;
  padding: 0px;
  min-width: 27px;
  min-height: 27px;
  line-height: 27px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  text-indent: 0px;
}

#roadmap-content .tempdiv .ap-input-btn md-icon {
  color: #fff;
  font-size: 21px;
}

#roadmap-content .tempdiv .add-btn {
  margin-left: 0;
}

#roadmap-content .tempdiv .panelDiv {
  width: 430px;
  position: absolute;
  top: 20%;
  left: 60%;
  z-index: 3;
  margin-left: -250px;
  background: #fff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

#roadmap-content .tempdiv .panelDiv .ap-input-btn {
  background: #439ADF;
  width: 27px;
  height: 27px;
  border-radius: 3px;
  padding: 0px;
  min-width: 27px;
  min-height: 27px;
  line-height: 27px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  text-indent: 0px;
}

#roadmap-content .tempdiv .panelDiv .ap-input-btn md-icon {
  color: #fff;
  font-size: 21px;
}

#roadmap-content .tempdiv .panelDiv .add-btn {
  margin-left: 0;
}

#roadmap-content .tempdiv .panelDiv .toolbar {
  height: 32px;
  min-height: 24px;
  border-bottom: 1px solid #DCDCDC;
}

#roadmap-content .tempdiv .panelDiv md-toolbar {
  background: #fff;
}

#roadmap-content .tempdiv .panelDiv .inputx {
  padding: 20px 10px;
  font-size: 20px;
}

#roadmap-content .tempdiv .panelDiv .inputx .checkboxdemoSelectAll {
  padding-left: 30px;
}

#roadmap-content .tempdiv .panelDiv .inputx .uploadList {
  padding-left: 40px;
}

#roadmap-content .tempdiv .panelDiv .buttondiv {
  border-top: 1px solid #DCDCDC;
}

#roadmap-content .tempdiv .panelDiv table th {
  font-weight: bold;
}

#roadmap-content .tempdiv .panelDiv table tr {
  border-bottom: 1px solid #DCDCDC;
}

#roadmap-content .tempdiv .panelDiv table th,
#roadmap-content .tempdiv .panelDiv table td {
  padding: 0;
  padding: 5px 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#roadmap-content .tempdiv .panelDiv table .chexiao {
  cursor: pointer;
}

#roadmap-content .gradeSelect {
  position: absolute;
  top: 10px;
  left: 100px;
  width: 100px;
  background: #fff;
  height: 100px;
}

#roadmap-content .gradeSelect .tabItem {
  width: 100%;
  box-sizing: border-box;
  padding-left: 16px;
  height: 35px;
  line-height: 35px;
  cursor: pointer;
  color: #757575;
  font-family: "PingFang SC";
  transition: background 0.3s ease 0s;
}

#roadmap-content .gradeSelect .tabItem:hover {
  background: rgba(158, 158, 158, 0.3);
}

#roadmap-content md-table-container {
  display: block;
  overflow: auto;
}

#roadmap-content md-table-container td {
  text-align: center;
  padding: 5px 0;
}

#roadmap-content .md-column {
  background-color: #EEEEEE;
  border-left: 1px solid #DCDCDC;
  text-indent: 0px;
  padding: 5px 20px;
}

#roadmap-content ._del5 {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.3);
  font-size: 16px;
}

#roadmap-content .recordDiv {
  margin: 20px 0;
}

#roadmap-content .recordDiv .datePickerClass input {
  border: none;
}

#roadmap-content .recordDiv > div {
  margin: 10px 0;
}

#roadmap-model {
  width: 100%;
}

#roadmap-model .container2 {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 20px;
}

#roadmap-model .container2 .vision {
  width: 100%;
  padding-bottom: 20px;
}

#roadmap-model .container2 .vision .search {
  position: relative;
  margin-top: 20px;
}

#roadmap-model .container2 .vision .search input {
  width: 300px;
  height: 28px;
  padding-left: 10px;
}

#roadmap-model .container2 .vision .search .add_btn {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  min-width: 55px;
  height: 32px;
  min-height: 32px;
  line-height: 32px;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  color: white;
  box-shadow: 0px 1px 2px 0px rgba(67, 154, 223, 0.6);
}

#roadmap-model .container2 .vision .search .add_btn md-icon {
  font-size: 18px;
  height: 20px;
  width: 20px;
  min-width: 20px;
  min-height: 20px;
  color: #fff;
}

#roadmap-model .container2 .vision .visionContent {
  margin-top: 40px;
  width: 100%;
}

#roadmap-model .container2 .vision .visionContent .card {
  position: relative;
  margin: 0 auto;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  width: 90%;
  cursor: pointer;
  transition: all 0.1s ease 0s;
}

#roadmap-model .container2 .vision .visionContent .card:hover {
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.1);
}

#roadmap-model .container2 .vision .visionContent .card ._title {
  height: 50px;
  border-bottom: 1px solid #DCDCDC;
}

#roadmap-model .container2 .vision .visionContent .card ._title p:first-child {
  margin-left: 20px;
}

#roadmap-model .container2 .vision .visionContent .card ._content {
  min-height: 200px;
  box-sizing: border-box;
  padding: 20px;
}

#roadmap-model .container2 .vision .visionContent .card ._content h4 {
  margin-bottom: 5px;
}

#roadmap-model .container2 .vision .visionContent .card ._content .underOne {
  margin-top: 20px;
}

#roadmap-model .container2 .vision .visionContent .card ._del {
  position: absolute;
  top: 11px;
  right: 8px;
  color: rgba(0, 0, 0, 0.3);
  font-size: 16px;
}

#roadmap-model .container2 .major major-model {
  width: 100%;
}

#roadmap-model .container2 .college {
  width: 100%;
}

#roadmap-model .container2 .college college-model {
  width: 100%;
}

#roadmap-model .container2 .recommendSummerSchool {
  width: 100%;
}

#roadmap-model .container2 .recommendSummerSchool recommend-summer-school-model {
  width: 100%;
}

#college {
  width: 100%;
}

#college .all {
  width: 100%;
  font-size: 12px;
  font-family: PingFangSC-Regular;
  color: #4a4a4a;
}

#college .all .select_h2 {
  font-size: 14px;
  font-family: PingFangSC-Semibold;
  padding: 10px 0;
}

#college .all .filter_btn {
  cursor: pointer;
  margin-right: 10px;
  line-height: 18px;
}

#college .all .filter_btn.active {
  color: #fe8800;
}

#college .all .filter_btn:hover {
  color: #fe8800;
}

#college .all .stu_body p, #college .all .stu_body div span {
  color: #4ac1fc !important;
}

#major {
  width: 100%;
  padding-bottom: 20px;
  position: relative;
}

#major .search {
  position: relative;
  margin-top: 35px;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

#major .search md-autocomplete {
  background: none;
  height: 32px;
}

#major .search md-autocomplete md-autocomplete-wrap {
  height: 32px;
  box-shadow: none;
  border: 1px solid #9B9B9B;
}

#major .search md-autocomplete md-autocomplete-wrap input {
  padding: 0 10px;
  height: 32px;
  line-height: 32px;
}

#major .search > input {
  width: 200px;
  height: 28px;
  padding-left: 10px;
}

#major .search .add_btn {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  min-width: 55px;
  height: 32px;
  min-height: 32px;
  line-height: 32px;
  background-image: linear-gradient(225deg, #78CAF1 0%, #439ADF 100%);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  color: white;
  box-shadow: 0px 1px 2px 0px rgba(67, 154, 223, 0.6);
}

#major .search .add_btn md-icon {
  font-size: 18px;
  height: 20px;
  width: 20px;
  min-width: 20px;
  min-height: 20px;
  color: #fff;
}

#major .majorContent {
  margin-top: 40px;
  width: 100%;
  overflow-y: auto;
  padding-top: 10px;
}

#major .majorContent .card {
  position: relative;
  margin: 0 auto;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  width: 90%;
  transition: all 0.1s ease 0s;
}

#major .majorContent .card:hover {
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.1);
}

#major .majorContent .card ._title {
  height: 50px;
  border-bottom: 1px solid #DCDCDC;
}

#major .majorContent .card ._title p:first-child {
  margin-left: 20px;
}

#major .majorContent .card ._content {
  min-height: 150px;
  box-sizing: border-box;
  padding: 20px;
}

#major .majorContent .card ._action {
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px 15px 15px;
}

#major .majorContent .card ._del {
  position: absolute;
  top: 11px;
  right: 8px;
  color: rgba(0, 0, 0, 0.3);
  font-size: 16px;
}

#major .edit_list {
  position: absolute;
  bottom: -15px;
  left: 50%;
  margin-left: -91px;
}

#major .edit_list {
  padding-bottom: 0;
  padding-top: 10px;
}

#major .edit_list .md-button {
  padding: 0px;
  margin: 0px;
  color: #428bca;
  border: 1px solid #dee5e7;
  border-left: none;
  background: white;
  width: 30px;
  height: 30px;
  min-width: 0px;
  min-height: 0px;
  line-height: 28px;
}

#major .edit_list .md-button span {
  font-size: 10px;
  font-weight: bold;
}

#major .edit_list .md-button:first-child, #major .edit_list .md-button:nth-of-type(3) {
  border-left: 1px solid #dee5e7;
}

#major .edit_list .md-button.active {
  background: #428bca;
  color: white;
}

#major .edit_list .md-button:hover {
  background: #dee5e7;
}

#major .edit_list .md-button.active:hover {
  background: #428bca;
}

#recommend-summer-school {
  width: 100%;
}

#recommend-summer-school .all {
  width: 100%;
  position: relative;
}

#recommend-summer-school .all .rightBox {
  width: 100%;
  height: 100%;
  padding: 10px;
}

#recommend-summer-school .all .boxTop {
  width: 100%;
}

#recommend-summer-school .all .boxTop hr {
  border-color: rgba(0, 0, 0, 0.18);
}

#recommend-summer-school .all .boxTop h2 {
  font-size: 18px;
  padding-left: 10px;
  padding-top: 10px;
}

#recommend-summer-school .all .boxTop .searchBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
}

#recommend-summer-school .all .boxTop .searchBox .searchItem, #recommend-summer-school .all .boxTop .searchBox .searchItem_ {
  height: 40px;
  padding: 0 10px;
  margin: 10px 0;
  position: relative;
}

#recommend-summer-school .all .boxTop .searchBox .searchItem > span, #recommend-summer-school .all .boxTop .searchBox .searchItem_ > span {
  font-size: 14px;
  font-weight: bold;
  color: #439adf;
}

#recommend-summer-school .all .boxTop .searchBox .searchItem md-icon, #recommend-summer-school .all .boxTop .searchBox .searchItem_ md-icon {
  position: absolute;
  right: 22px;
  bottom: 3px;
}

#recommend-summer-school .all .boxTop .searchBox .searchItem .filterBtb, #recommend-summer-school .all .boxTop .searchBox .searchItem_ .filterBtb {
  width: 100%;
}

#recommend-summer-school .all .boxTop .searchBox .searchItem {
  width: 45%;
}

#recommend-summer-school .all .boxTop .searchBox .searchItem_ {
  width: 27%;
}

#recommend-summer-school .all .boxTop .searchBox .searchItem.datePicker {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

#recommend-summer-school .all .boxTop .searchBox .searchItem.datePicker ap-datepicker input {
  width: 85px;
}

#recommend-summer-school .all .boxTop .searchBox .searchItem.tuofu md-input-container {
  width: 34%;
  display: inline-block;
}

#recommend-summer-school .all .boxTop .searchBox .searchItem.tuofu md-input-container ap-button {
  float: right;
}

#recommend-summer-school .all .boxBottom {
  width: 100%;
}

#recommend-summer-school .all .boxBottom table {
  overflow-y: auto;
}

#recommend-summer-school .all .boxBottom th {
  background: #fff;
  z-index: 1;
  position: -webkit-sticky;
  position: sticky;
  top: 0 !important;
}

#recommend-summer-school .all .boxBottom th:last-child {
  text-align: center;
}

#recommend-summer-school .all .boxBottom td {
  padding-bottom: 10px;
  border-bottom: 1px solid #DCDCDC;
}

#recommend-summer-school .all .boxBottom td a {
  color: #7986CB;
  cursor: pointer;
}

#recommend-summer-school .all .boxBottom td .md-button {
  min-height: 28px !important;
  min-width: 50px !important;
  line-height: 28px !important;
  margin: 0px !important;
}

#recommend-summer-school .all .boxBottom td:last-child {
  text-align: center;
  min-width: 106px;
}

#recommend-summer-school .all .advance {
  position: absolute;
  top: 30%;
  width: 80%;
  left: 10%;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

#recommend-summer-school .all .advance .title {
  padding: 0 20px;
  background: #7986CB;
  color: #fff;
  height: 40px;
  line-height: 40px;
}

#recommend-summer-school .all .advance .title md-icon {
  width: 24px;
  height: 24px;
  color: #fff;
}

#recommend-summer-school .all .advance .advanceContent {
  padding: 20px;
  background: #fff;
}

/*# sourceMappingURL=app.css.map */
